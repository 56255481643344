import { useState } from 'react'

import { Box } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getDisabilityClaimsIndex } from 'services/organizer/holisticPayrollServices'

import {
  claimsIndexColumnsData,
  getOrganizerClaimsTableActions,
} from './helpers'

export default function OrganizerDisabilityClaimsIndex() {
  const { clearCompany } = useUser()
  const [claimsTotals, setClaimsTotals] = useState({})
  const [activeStatus, setActiveStatus] = useState('pending')

  const handleChange = (newValue) => setActiveStatus(newValue)

  const fetchCompanyClaims = (pageIndex, search) => {
    return {
      queryKey: [
        'organizerIndexDisabilityClaims',
        pageIndex,
        search,
        activeStatus,
      ],
      queryFunction: () =>
        getDisabilityClaimsIndex({
          searchParams: {
            page: pageIndex + 1,
            search,
            per_page: 20,
            filter: activeStatus,
          },
        }),
      queryOptions: {
        onSuccess: ({ data }) => {
          setClaimsTotals(data.totals || {})
          clearCompany()
        },
      },
    }
  }

  const organizerClaimsTableActions = getOrganizerClaimsTableActions({
    activeStatus,
    handleChange,
    claimsTotals,
  })

  return (
    <Page
      header="Reclamación de incapacidades"
      documentTitle="Reclamación de incapacidades"
      grid
    >
      <Box sx={{ gridColumn: '1 / -1' }}>
        <Table
          data={fetchCompanyClaims}
          columns={claimsIndexColumnsData}
          actions={organizerClaimsTableActions}
          options={{
            customQueryFetch: 'claims',
            version: { toolbar: 'v2' },
            alignActionsCell: 'center',
            alignActionsHeader: 'center',
          }}
        />
      </Box>
    </Page>
  )
}
