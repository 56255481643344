import { isAlegraDist, isFreeCompany } from 'utils/auth'

import * as routes from 'config/routes'

const useGetCardsData = (subscription) => {
  const cards = [
    {
      title: 'Configuración avanzada',
      dataCy: 'advanced-settings',
      description: 'Edita la configuración avanzada de tu empresa',
      btnText: 'Editar',
      to: routes.SETTINGS_ADVANCED(),
    },
    {
      title: 'Nómina electrónica',
      dataCy: 'electronic-payroll',
      description:
        'Realiza tu proceso de habilitación, conexión y configuración de Nómina electrónica a tu cuenta de Aleluya.',
      btnText: 'Editar',
      to: routes.SETTINGS_ELECTRONIC_PAYROLL(),
    },
    {
      title: !isAlegraDist() ? 'Contabilidad' : 'Conceptos contables',
      dataCy: 'alegra-distributor',
      description:
        'Edita y agrega aquí los conceptos contables de tu companía.',
      btnText: 'Editar',
      to: isAlegraDist()
        ? routes.SETTINGS_INTEGRATION_ALEGRA_ACCOUNTING()
        : routes.SETTINGS_ACCOUNTING(),
    },
    {
      title: 'Beneficios',
      dataCy: 'benefits',
      description: 'Beneficios y accesibilidad para empleados',
      btnText: 'Editar',
      to: routes.SETTINGS_WORKERS_BENEFITS(),
    },
    {
      title: 'Usuarios',
      dataCy: 'users',
      description: 'Administra el perfil de tus usuarios.',
      btnText: 'Editar',
      to: routes.SETTINGS_USERS(),
    },
    {
      title: 'Integraciones',
      dataCy: 'integrations',
      description:
        'Integra tus aplicaciones favoritas con Aleluya y gestiona todo desde un solo lugar.',
      btnText: 'Editar',
      to: routes.SETTINGS_INTEGRATION_INDEX(),
    },
    {
      title: 'Historial - Acciones',
      dataCy: 'history-actions',
      description:
        'Ver los cambios y operaciones que se han realizado en tu companía.',
      btnText: 'Ver historial',
      to: routes.SETTINGS_COMPANY_HISTORY(),
    },
    {
      title: 'Suscripción',
      dataCy: 'subscription',
      description:
        'Ver y modificar datos de tu suscripción, facturación y método de pago.',
      btnText: 'Ir a suscripción',
      to: routes.SUBSCRIPTION_INDEX(),
      hidden: isFreeCompany(subscription.status, subscription?.payment_status),
    },
  ]

  return cards
}

export default useGetCardsData
