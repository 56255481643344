import { useFormikContext } from 'formik'
import { useState } from 'react'

import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'
import { formatDate } from 'utils/dateTime'
import useCompanyWorkCenters from 'utils/hooks/company/decree2012Service'
import useDebouncedState from 'utils/hooks/useDebouncedState'
import { risks } from 'utils/worker'

import { formatActivityData, getNoOptionsText } from './helpers'

const ActivityCIIU = ({ isEdit, label }) => {
  const companyId = getCompanyId()
  const { setFieldValue, values } = useFormikContext()
  const initialDate = formatDate(values?.initial_day)
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useDebouncedState('', 300)

  const { companyWorkCentersQuery } = useCompanyWorkCenters({
    serviceParams: {
      queryKey: ['getCIIU', companyId, initialDate, inputValue],
      searchParams: {
        filter: initialDate,
        per_page: '20',
        search: inputValue,
      },
    },
    queryOptions: {
      enabled: Boolean(initialDate) && Boolean(inputValue),
      onSuccess: ({ data }) => {
        setOptions(formatActivityData(data))
      },
    },
  })

  const onChange = (__, ciiu) => {
    const riskType = companyWorkCentersQuery.data?.find?.(
      ({ id }) => id === ciiu?.id
    )?.risk_type

    setFieldValue('risk_type', risks[riskType])
    setFieldValue('activity_code_id', ciiu)
  }

  const handleChange = (__, value) => {
    setInputValue(value)
    if (!value) setOptions([])
  }

  const isOptionEqualToValue = (option, newValue) => {
    if (isEdit) {
      return option?.name?.includes(newValue?.name)
    }

    return true
  }

  const noOptionsText = getNoOptionsText(inputValue, companyWorkCentersQuery)

  return (
    <FormField
      freeSolo={values.activity_code_id || noOptionsText === ''}
      noOptionsText={noOptionsText}
      name="activity_code_id"
      variant="autocomplete"
      label={label || 'Actividad CIIU'}
      placeholder="Actividad CIIU"
      options={options}
      onChange={onChange}
      onInputChange={handleChange}
      optional={false}
      isOptionEqualToValue={isOptionEqualToValue}
      disabled={!initialDate}
    />
  )
}

export default ActivityCIIU
