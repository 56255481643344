import {
  Autocomplete,
  Button,
  ListItemText,
  OutlinedInput,
  autocompleteClasses,
  listItemTextClasses,
  outlinedInputClasses,
} from '@mui/material'

import Icon from 'components/UI/Icon'

const DateField = ({
  id,
  options,
  value: givenValue,
  onChange,
  sx,
  placeholder,
  ...restProps
}) => {
  const renderInput = ({ InputProps, InputLabelProps, ...params }) => {
    return (
      <OutlinedInput
        {...params}
        {...InputProps}
        {...InputLabelProps}
        placeholder={placeholder}
        sx={(theme) => ({
          padding: `${theme.spacing(0, 4, 0, 2)} !important`,
          fontWeight: '700 !important',
          fontSize: '0.875rem',
          color: theme.palette.black.main,
          [`& .${outlinedInputClasses.input}`]: { fontWeight: 700 },
          '& input::placeholder': {
            fontWeight: 700,
            fontSize: '0.875rem',
            color: theme.palette.black.main,
          },
        })}
      />
    )
  }

  const getOptionLabel = (option) => {
    return option?.name || ''
  }

  const renderOption = (props, option) => {
    if (option === null)
      return (
        <ListItemText key="clear-button">
          <Button
            onClick={props.onClick}
            variant="text"
            sx={(theme) => ({
              padding: `${theme.spacing(0, 2)} !important`,
              color: theme.palette.info.dark,
              '&:focus, &:hover': {
                color: theme.palette.info.dark,
              },
            })}
          >
            Limpiar filtro
          </Button>
        </ListItemText>
      )

    return (
      <ListItemText
        {...props}
        primary={getOptionLabel(option)}
        sx={(theme) => ({
          [`.${listItemTextClasses.primary}`]: {
            ...(option === null && {
              fontWeight: 700,
              color: theme.palette.info.dark,
            }),
          },
        })}
      />
    )
  }
  return (
    <Autocomplete
      id={id}
      options={[null, ...options]}
      value={givenValue}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      onChange={(__, selectedValue) => onChange(selectedValue)}
      popupIcon={
        <Icon
          name="filter"
          sx={(theme) => ({
            color: theme.palette.black.main,
          })}
        />
      }
      {...restProps}
      fullWidth
      renderOption={renderOption}
      isOptionEqualToValue={(option, value) => {
        return (
          option?.id === value?.id &&
          option.name.substring(0, 3) === value.name.substring(0, 3)
        )
      }}
      sx={[
        {
          [`& .${autocompleteClasses.popupIndicator}`]: { transform: 'none' },
          [`& .${autocompleteClasses.clearIndicator}`]: { display: 'none' },
        },
        sx,
      ]}
      componentsProps={{ popper: { style: { width: 'fit-content' } } }}
    />
  )
}

export default DateField
