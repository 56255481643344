import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Link, Typography, useMediaQuery } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { isObjectEmpty, wait } from 'utils/general'
import useItemsService from 'utils/hooks/payroll/items'
import usePayrollService from 'utils/hooks/payroll/payroll'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'
import {
  generateEmptyPayrollItem,
  nonSalaryIncomeRecurrentConcepts,
  salaryIncomeRecurrentConcepts,
} from 'utils/payroll'
import { getGeneralContractCategory } from 'utils/worker'

import { useNoveltyType, usePeriodAPI } from '../../helpers'
import CreatePayrollConceptModal from '../CreatePayrollConceptModal'
import NonSalaryIncome from './NonSalaryIncome'
import SalaryIncome from './SalaryIncome'
import Info from './helpers'
import useIncomeConfig from './useIncomeConfig'

const IncomeModal = ({ state, handleClose }) => {
  const [activeTab, setActiveTab] = useState(0)
  const queryClient = useQueryClient()
  const { payrollState } = useNoveltyType()
  const { updatePeriodCallback } = usePeriodAPI()
  const { workerName, workerPayroll } = payrollState
  const payrollId = workerPayroll.id
  const { getInitialData } = useIncomeConfig()
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  const generalContractCategory = getGeneralContractCategory(
    workerPayroll.contract_category
  )

  const isContractorApprenticeOrStudent = [
    'contractor',
    'apprentice',
    'student',
  ].includes(generalContractCategory)

  const [initialData, setInitialData] = useState({})
  const [incomes, setIncomes] = useState({
    salary_income: [],
    non_salary_income: [],
  })
  const [concepts, setConcepts] = useState({
    salary_income: [],
    non_salary_income: [],
  })
  const [connectivityAidDays, setConnectivityAidDays] = useState(
    workerPayroll.connectivity_aid_days
  )

  const [isCreatePayrollConceptModalOpen, setIsCreatePayrollConceptModalOpen] =
    useState(false)
  const [nameCreatePayrollConcept, setNameCreatePayrollConcept] = useState('')
  const [deletedItems, setDeletedItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const confirm = useConfirm()
  const salaryIncomeQueryKey = ['payrollConcepts', 'salary_income', payrollId]
  const nonSalaryIncomeQueryKey = [
    'payrollConcepts',
    'non_salary_income',
    payrollId,
  ]
  const salaryIncomeItemsQueryKey = [
    'itemsByCategory',
    'salary_income',
    payrollId,
  ]
  const nonSalaryIncomeItemsQueryKey = [
    'itemsByCategory',
    'non_salary_income',
    payrollId,
  ]

  const { payrollConceptsQuery: salaryConceptsQuery } =
    usePayrollConceptsService({
      serviceParams: {
        queryKey: salaryIncomeQueryKey,
        conceptsCategory: 'salary_income',
      },
    })

  const { payrollConceptsQuery: nonSalaryConceptsQuery } =
    usePayrollConceptsService({
      serviceParams: {
        queryKey: nonSalaryIncomeQueryKey,
        conceptsCategory: 'non_salary_income',
      },
    })

  const { itemsQuery: salaryItemsQuery } = useItemsService({
    serviceParams: {
      queryKey: salaryIncomeItemsQueryKey,
      payrollId,
      conceptsCategory: 'salary_income',
    },
  })

  const { itemsQuery: nonSalaryItemsQuery } = useItemsService({
    serviceParams: {
      queryKey: nonSalaryIncomeItemsQueryKey,
      payrollId,
      conceptsCategory: 'non_salary_income',
    },
    queryOptions: {
      enabled:
        salaryConceptsQuery.isSuccess &&
        nonSalaryConceptsQuery.isSuccess &&
        salaryItemsQuery.isSuccess,
      onSuccess: ({ data: nonSalaryItemsData }) => {
        const initialModalData = getInitialData(
          salaryConceptsQuery.data,
          nonSalaryConceptsQuery.data,
          salaryItemsQuery.data,
          nonSalaryItemsData
        )
        setInitialData(initialModalData)
        setConcepts({
          salary_income: initialModalData.salaryIncomeConcepts,
          non_salary_income: initialModalData.nonSalaryIncomeConcepts,
        })
        setIncomes({
          salary_income: initialModalData.salary_income,
          non_salary_income: initialModalData.non_salary_income,
        })
      },
    },
  })

  const { itemsMutation } = useItemsService({
    queryOptions: { enabled: false },
  })

  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  const isGettingData =
    salaryConceptsQuery.isLoading ||
    nonSalaryConceptsQuery.isLoading ||
    salaryItemsQuery.isLoading ||
    nonSalaryItemsQuery.isLoading

  const handleSelectItem = (payrollConceptId, index, category) => {
    const prevItem = incomes[category][index]

    const newConcepts = { ...concepts }
    let selectedConcept

    newConcepts[category] = newConcepts[category].map((concept) => {
      const conceptCopy = { ...concept }

      if (prevItem.payroll_concept_id === conceptCopy.id)
        conceptCopy.selected = false

      if (conceptCopy.id === payrollConceptId) {
        conceptCopy.selected = true

        selectedConcept = conceptCopy
      }

      return conceptCopy
    })

    const newIncomes = { ...incomes }

    newIncomes[category] = [...newIncomes[category]]

    const newItem = {
      ...prevItem,
      name: selectedConcept.name,
      payroll_concept_id: selectedConcept.id,
      coded_name: selectedConcept.coded_name,
    }

    newIncomes[category][index] = newItem

    setConcepts(newConcepts)
    setIncomes(newIncomes)
  }

  const handleChangeItemValue = async (e, index, category) => {
    const value = Number(e.target.rawValue)

    const oldIncomes = incomes
    const newIncomes = { ...incomes }

    newIncomes[category] = [...newIncomes[category]]

    newIncomes[category][index] = { ...newIncomes[category][index], value }

    setIncomes(newIncomes)

    if (category === 'non_salary_income') {
      const totalPayrollIncome =
        workerPayroll.base_salary +
        workerPayroll.overtime_value +
        workerPayroll.novelties_value +
        workerPayroll.salary_income +
        workerPayroll.non_salary_income +
        value

      if (value > Math.ceil(totalPayrollIncome * 0.4)) {
        await wait(500)
        confirm({
          category: 'warning',
          description: (
            <>
              Estás ingresando bonificaciones por encima del 40% de los ingresos
              salariales. Al hacerlo Aleluya calculará de nuevo la base para la
              liquidación de aportes a seguridad social siguiendo lo estipulado
              en la Ley 1393 de 2010. Para conocer más al respecto haz{' '}
              <Link
                href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-como-calcula-aleluya-el-excedente-del-40-de-ingresos-no-constitutivos-de-salario-de-acuerdo-con-la-ley-1393-de-2010"
                target="_blank"
              >
                click aquí
              </Link>
              .
            </>
          ),
          okText: 'Sí, continuar',
          onCancel: () => setIncomes(oldIncomes), // revert the value changed
        })
      }
    }
  }

  const handleAddConcept = (category) => {
    const newIncomes = { ...incomes }

    newIncomes[category] = [...newIncomes[category]]

    newIncomes[category].push(generateEmptyPayrollItem())

    setIncomes(newIncomes)
  }

  const handleDeleteItem = (index, category) => {
    const newIncomes = { ...incomes }

    newIncomes[category] = newIncomes[category].slice(0)

    const itemToDelete = newIncomes[category][index]

    newIncomes[category].splice(index, 1)

    const incomesRecurrentConcepts = salaryIncomeRecurrentConcepts.concat(
      nonSalaryIncomeRecurrentConcepts
    )

    if (!incomesRecurrentConcepts.includes(itemToDelete.coded_name)) {
      setDeletedItems([...deletedItems, itemToDelete])
    }
    if (itemToDelete.payroll_concept_id !== null) {
      const newConcepts = { ...concepts }

      newConcepts[category] = newConcepts[category].map((concept) => {
        const conceptCopy = { ...concept }

        if (itemToDelete.payroll_concept_id === conceptCopy.id)
          conceptCopy.selected = false

        return conceptCopy
      })
      setConcepts(newConcepts)
    }

    if (newIncomes[category].length === 0) {
      newIncomes[category].push(generateEmptyPayrollItem())
    }

    setIncomes(newIncomes)
  }

  const handleCloseCreatePayrollConceptModal = (createdConcept, category) => {
    if (createdConcept) {
      const newConcepts = {
        ...concepts,
        [category]: [
          ...concepts[category],
          { ...createdConcept, selected: true },
        ],
      }

      setConcepts(newConcepts)

      const newIncomes = {
        ...incomes,
        [category]: [
          ...incomes[category],
          generateEmptyPayrollItem(createdConcept.id, createdConcept.name),
        ],
      }

      setIncomes(newIncomes)
    }

    setIsCreatePayrollConceptModalOpen(false)
  }

  const onCloseModal = () => {
    handleClose()
    queryClient.removeQueries(salaryIncomeQueryKey)
    queryClient.removeQueries(nonSalaryIncomeQueryKey)
    queryClient.removeQueries(salaryIncomeItemsQueryKey)
    queryClient.removeQueries(nonSalaryIncomeItemsQueryKey)
  }

  const handleSendData = async () => {
    try {
      setIsLoading(true)
      const dataToSend = [...deletedItems]
      const incomesRecurrentConcepts = salaryIncomeRecurrentConcepts.concat(
        nonSalaryIncomeRecurrentConcepts
      )

      Object.keys(incomes).forEach((k) => {
        const incomesType = incomes[k]
        const initIncomes = initialData[k]?.map((i) => ({ ...i }))

        // Compare initial incomes with current incomes to get the changed ones
        incomesType.forEach((inc) => {
          let incChecked = false
          initIncomes.some((initInc) => {
            if (
              inc.payroll_concept_id &&
              inc.payroll_concept_id === initInc.payroll_concept_id
            ) {
              if (inc.value !== initInc.value) {
                dataToSend.push({
                  ...inc,
                  id: initInc.id,
                })
              }
              // Have to disable this line because this key is needed out of loop
              // eslint-disable-next-line no-param-reassign
              initInc.checked = true
              incChecked = true

              return true
            }
            return false
          })

          let addToDataSend
          if (incomesRecurrentConcepts.includes(inc.coded_name)) {
            addToDataSend =
              !incChecked && inc.payroll_concept_id && inc.value > 0
          } else {
            addToDataSend = !incChecked && inc.payroll_concept_id
          }
          if (addToDataSend) {
            dataToSend.push(inc)
          }
        })

        // Those not checked are added to deletion
        initIncomes?.forEach((initInc) => {
          if (!initInc.checked && initInc.payroll_concept_id) {
            dataToSend.push({
              id: initInc.id,
              payroll_concept_id: initInc.payroll_concept_id,
              value: 0,
            })
          }
        })
      })

      let dataResponse = null

      if (dataToSend.length > 0) {
        await itemsMutation.mutateAsync(
          {
            mutationMethod: 'PUT',
            payrollId,
            deductionItem: dataToSend,
          },
          {
            onSuccess: ({ data }) => {
              dataResponse = data
              Mixpanel.track(MIXPANEL_EVENTS.PAYROLL_BONUS_ADD, {
                company_id: getCompanyId(),
                user_id: getUserId(),
                user_role: getUserRole(),
              })
            },
          }
        )
      }

      if (workerPayroll.connectivity_aid_days !== connectivityAidDays) {
        dataResponse = await payrollMutation.mutateAsync({
          mutationMethod: 'PUT_CONNECTIVITY_AIDS',
          payrollId,
          quantity: connectivityAidDays,
        })

        dataResponse = dataResponse.data
        dataResponse.payroll.connectivity_aid_days =
          dataResponse.connectivity_aid.quantity
      }

      setIsLoading(false)
      onCloseModal()
      updatePeriodCallback(dataResponse)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  const getTabsConfig = () => {
    const tabsConfig = [
      {
        label: 'Ingresos constitutivos',
        dataCy: 'tab-salary-income',
      },
      {
        label: 'Ingresos no constitutivos',
        dataCy: 'tab-non-salary-income',
      },
    ]

    if (isContractorApprenticeOrStudent) {
      tabsConfig.shift()
    }

    return tabsConfig
  }

  return (
    <Modal
      open={state.open}
      header={`¿Reportale ingresos a ${workerName}?`}
      onOk={handleSendData}
      okText="Guardar"
      onCancel={onCloseModal}
      isLoading={isLoading}
      dialogProps={{
        'data-cy': 'income_modal',
      }}
      disableOkButton={connectivityAidDays > 30}
      paperSx={{
        maxWidth: {
          tablet: '48.5rem',
        },
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        })}
      >
        <Box>
          <Typography
            paragraph
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
              color: theme.palette.black.dark,
            })}
          >
            Acá podrás ingresar los ingresos{' '}
            <Link
              href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-registrar-un-ingreso-constitutivo-de-salario-a-un-empleado"
              target="_blank"
            >
              constitutivos
            </Link>{' '}
            y los{' '}
            <Link
              href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-como-registrar-un-ingreso-no-constitutivo-de-salario-a-un-empleado"
              target="_blank"
            >
              NO constitutivos
            </Link>{' '}
            de salario.
          </Typography>

          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              marginBottom: theme.spacing(4),
            })}
          >
            <RoundedTabs
              background="gray"
              value={activeTab}
              onChange={handleChangeTab}
              tabsConfig={getTabsConfig()}
            />
          </Box>

          {!isGettingData && !isObjectEmpty(initialData) ? (
            <>
              {!isContractorApprenticeOrStudent && activeTab === 0 ? (
                <SalaryIncome
                  handleAddConcept={handleAddConcept}
                  concepts={concepts}
                  incomes={incomes}
                  setIsCreatePayrollConceptModalOpen={
                    setIsCreatePayrollConceptModalOpen
                  }
                  setNameCreatePayrollConcept={setNameCreatePayrollConcept}
                  isTabletUp={isTabletUp}
                  handleSelectItem={handleSelectItem}
                  handleChangeItemValue={handleChangeItemValue}
                  handleDeleteItem={handleDeleteItem}
                />
              ) : null}

              {activeTab === 1 || isContractorApprenticeOrStudent ? (
                <NonSalaryIncome
                  handleAddConcept={handleAddConcept}
                  concepts={concepts}
                  incomes={incomes}
                  setIsCreatePayrollConceptModalOpen={
                    setIsCreatePayrollConceptModalOpen
                  }
                  setNameCreatePayrollConcept={setNameCreatePayrollConcept}
                  workerPayroll={workerPayroll}
                  handleSelectItem={handleSelectItem}
                  handleChangeItemValue={handleChangeItemValue}
                  handleDeleteItem={handleDeleteItem}
                  connectivityAidDays={connectivityAidDays}
                  setConnectivityAidDays={setConnectivityAidDays}
                />
              ) : null}
            </>
          ) : (
            <LoadingBox />
          )}
        </Box>
        {isCreatePayrollConceptModalOpen ? (
          <CreatePayrollConceptModal
            category={nameCreatePayrollConcept}
            handleClose={handleCloseCreatePayrollConceptModal}
          />
        ) : null}
        <Info />
      </Box>
    </Modal>
  )
}

export default IncomeModal
