import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import selfieWoman from 'assets/images/views/common/selfie-woman.png'

import usePremiumFeature from './usePremiumFeature'

const PageGetPremiumHR = () => {
  const { openPremiumFeatureModal } = usePremiumFeature()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const handleOpenPremiumFeatureModal = () => {
    openPremiumFeatureModal()
  }

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <Box
        sx={(theme) => ({
          display: 'grid',
          justifyItems: 'center',
          height: '100%',
          gridTemplateColumns: '1fr',
          alignItems: 'center',
          padding: theme.spacing(0, 4),
          gap: theme.spacing(2),
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1.5fr 1fr',
          },
          [theme.breakpoints.up('laptop')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
          }}
        >
          <Typography variant="h1">
            ¡Ups! Lo sentimos. Actualmente, no cuentas con los superpoderes
            necesarios.
          </Typography>
          <Typography variant="body1" color="black.dark">
            ¡Mejora tu plan y desbloquea un mundo de funcionalidades asombrosas
            que te harán la vida mucho más fácil! Imagina poder gestionar tus
            empleados de manera eficiente, con reportes detallados y acceso a
            características exclusivas que agilizarán tus tareas diarias.
          </Typography>
          <Button
            onClick={handleOpenPremiumFeatureModal}
            sx={{ width: 'fit-content' }}
          >
            Activar superpoderes
          </Button>
        </Box>
        {isMobile ? null : (
          <img
            loading="lazy"
            src={selfieWoman}
            alt=""
            width={158}
            height={302}
          />
        )}
      </Box>
    </Box>
  )
}

export default PageGetPremiumHR
