import useLocalStorage from '@rehooks/local-storage'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

const useUpdateCompanyPhoneModal = ({ company, user }) => {
  const {
    onboarding: {
      workers_number: workersNumber,
      onboarding_step: onboardingStep,
    },
  } = company
  const hasTwentyorMoreWorkers = workersNumber !== '1-19'
  const [updatePhoneShowed, setUpdatePhoneShowed] = useLocalStorage(
    'update_phone_showed'
  )

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const isInteractiveTourFinished =
    queryParams.get('interactive_tour_finished') === 'true'
  const isOnboardingCompleted = onboardingStep == 'finished'
  const [state, setState] = useState({
    open:
      hasTwentyorMoreWorkers &&
      !user.phone &&
      !updatePhoneShowed &&
      isInteractiveTourFinished &&
      isOnboardingCompleted,
  })

  const openUpdateCompanyPhoneModal = () => {
    setState({ open: true })
  }

  const closeUpdateCompanyPhoneModal = () => {
    setUpdatePhoneShowed(true)
    setState({ open: false })
  }

  return {
    updateCompanyPhoneModalState: state,
    openUpdateCompanyPhoneModal,
    closeUpdateCompanyPhoneModal,
  }
}

export default useUpdateCompanyPhoneModal
