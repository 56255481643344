import { Form, Formik } from 'formik'
import { bindPopover } from 'material-ui-popup-state/hooks'

import { Box, Button, Popover, Typography, useTheme } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import usePayrollService from 'utils/hooks/payroll/payroll'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { usePeriodAPI } from '../helpers'

const CommentsDialog = ({ payroll, popupState }) => {
  const themeInstance = useTheme()
  const { updatePeriodCallback } = usePeriodAPI()
  const { handleError } = useErrorHandler()
  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleSubmit = (values, form) => {
    if (
      (!payroll.comments && values.comments.trim()) ||
      (payroll.comments && payroll.comments !== values.comments.trim())
    ) {
      payrollMutation.mutate(
        {
          mutationMethod: 'PATCH',
          payrollId: payroll.id,
          data: {
            payroll: values,
          },
        },
        {
          onSuccess: ({ data }) => {
            updatePeriodCallback(data)
            popupState.close()
          },
        },
        {
          onError: (error) => handleError(error, form),
        }
      )
    } else {
      popupState.close()
    }
  }

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={2}
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.white.light,
          borderRadius: '0.75rem',
          padding: theme.spacing(3, 4),
          maxWidth: '22rem',
        }),
      }}
    >
      <Box>
        <Formik
          initialValues={{ comments: payroll.comments || '' }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <Typography variant="h5" color="black.main" mb={2}>
                  {`Agregar nota a ${payroll.worker_name}`}
                </Typography>
                <FormField
                  name="comments"
                  variant="textarea"
                  placeholder="Nota personal"
                  rows="4"
                  inputProps={{
                    maxLength: 500,
                  }}
                  sx={{
                    backgroundColor: themeInstance.palette.white.light,
                  }}
                />
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    gap: theme.spacing(2),
                    marginTop: theme.spacing(2),
                  })}
                >
                  <Button type="submit" size="small" disabled={isSubmitting}>
                    Guardar
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={isSubmitting}
                    onClick={popupState.close}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Popover>
  )
}

export default CommentsDialog
