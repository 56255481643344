import { Form, Formik } from 'formik'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { Typography } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import useWorker from 'components/Worker/useWorker'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { formatValues } from 'utils/form'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useTerminationService from 'utils/hooks/worker/terminationService'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import * as routes from 'config/routes'

import Fields from './Fields'
import TouchedAutoRefresh from './TouchedAutoRefresh'
import { validationSchema } from './helpers'

const ContractTerminationForm = ({ isEditing }) => {
  const { workerId, contractId } = useParams()
  const navigate = useNavigate()
  const { worker, refreshWorker } = useWorker({ useCache: true })
  const [dirtyTermination, setDirtyTermination] = useState({})
  const confirm = useConfirm()
  const { showErrorMessage, showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const formRef = useRef(null)
  const queryKey = [
    isEditing ? 'getTermination' : 'getTerminationPreview',
    contractId,
  ]
  const { terminationMutation, terminationQuery } = useTerminationService({
    serviceParams: {
      queryKey,
      contractId,
    },
    mutationOptions: {
      mutationKey: 'refreshTerminationPreview',
    },
  })
  const baseTermination = terminationQuery.data
  const modifiedTermination = terminationMutation.data?.data

  const fetchTerminationPreview = useCallback(
    async (termination) => {
      try {
        const terminationPreview = await terminationMutation.mutateAsync(
          {
            mutationMethod: 'CREATE_PREVIEW',
            contractId,
            termination,
          },
          {
            onSuccess: ({ data }) => {
              if (data) {
                formRef.current?.setValues(data)
              }
            },
            onError: (error) => {
              if (error.errors && error.errors[0].code === '0706') {
                // redirect to editing if user came to new termination if already was terminated
                navigate(
                  routes.WORKER_CONTRACT_TERMINATION_EDIT(workerId, contractId)
                )
              } else {
                handleError(error)
              }
            },
          }
        )

        return terminationPreview.data
      } catch (error) {
        handleError(error)
      }
    },
    [contractId, handleError, navigate, terminationMutation, workerId]
  )

  const handleCancel = () => {
    navigate(routes.WORKER_SHOW(workerId))
  }

  const submitTermination = async (values, form) => {
    const terminationRes = formatValues(values)

    try {
      if (isEditing) {
        terminationMutation.mutate(
          {
            mutationMethod: 'UPDATE',
            contractId,
            termination: terminationRes,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(queryKey)
              showSuccessMessage(
                'La liquidación se ha actualizado correctamente.'
              )
              navigate(routes.WORKER_SHOW(workerId), { replace: true })
              refreshWorker()
            },
          },
          {
            onError: (error) => handleError(error),
          }
        )
      } else {
        terminationMutation.mutate(
          {
            mutationMethod: 'CREATE',
            contractId,
            termination: terminationRes,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(queryKey)
              showSuccessMessage(
                '¡El contrato ha sido liquidado de forma exitosa!'
              )
              navigate(routes.WORKER_SHOW(workerId), { replace: true })
              refreshWorker()

              Mixpanel.track(MIXPANEL_EVENTS.TERMINATE_WORKER_V2, {
                user_id: getUserId(),
                user_role: getUserRole(),
                company_id: getCompanyId(),
                worker_id: worker?.id,
                contract_category: worker?.contract_category,
              })
            },
          },
          {
            onError: (error) => handleError(error),
          }
        )
      }
    } catch (error) {
      handleError(error, form, {
        errorsToNotificate: [
          { object: 'worker_payment', code: '0305' },
          { object: 'cause', code: '0002' },
          { object: 'novelty' },
        ],
      })
    }
  }

  const onSubmit = (values, form) => {
    if (values.worker_payment < 0) {
      showErrorMessage(
        'El pago total de la liquidación no debe ser menor que 0.'
      )
      form.setTouched({})
      form.setSubmitting(false)
    } else {
      confirm({
        title: isEditing ? 'Editar liquidación' : 'Liquidar persona',
        description: isEditing
          ? '¿Estás seguro de editar esta liquidación?'
          : '¿Estás seguro de liquidar a esta persona? Este cambio no puede deshacerse. Si quieres, luego podrás reincorporarla de nuevo.',
        type: 'warning',
        okText: isEditing ? 'Guardar' : 'Liquidar',
        onOk: () => submitTermination(values, form),
        onCancel: () => {
          form.setTouched({})
          form.setSubmitting(false)
        },
      })
    }
  }

  const termination = useMemo(
    () => modifiedTermination || baseTermination || {},
    [baseTermination, modifiedTermination]
  )

  return (
    <Page
      documentTitle={`Liquidación${worker ? ` de ${worker.fullName}` : ''}`}
      header={
        <>
          <Typography variant="h2">
            Liquidación
            {worker ? (
              <>
                {' de '}
                <Link
                  color="primary.dark"
                  to={routes.WORKER_SHOW(workerId)}
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {worker.fullName}
                </Link>
              </>
            ) : null}
          </Typography>
          <Typography sx={(theme) => ({ marginTop: theme.spacing(2) })}>
            Para calcular la liquidación laboral se tiene en cuenta: Si fue por
            justa causa, el tiempo de servicio, salario, prestaciones sociales,
            bonificaciones, comisiones y vacaciones.
          </Typography>
        </>
      }
      isLoading={terminationQuery.isLoading}
      isLoadingWithModal={terminationMutation.isLoading}
    >
      {!terminationQuery.isLoading ? (
        <Formik
          innerRef={formRef}
          initialValues={terminationQuery.data || {}}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          <Form>
            <TouchedAutoRefresh
              termination={termination}
              dirtyTermination={dirtyTermination}
              setDirtyTermination={setDirtyTermination}
              fetchTerminationPreview={fetchTerminationPreview}
            />
            <Fields
              dirtyTermination={dirtyTermination}
              handleCancel={handleCancel}
              isEditing={isEditing}
              isSaving={
                terminationMutation.isLoading && formRef.current?.isSubmitting
              }
              termination={termination}
            />
          </Form>
        </Formik>
      ) : null}
    </Page>
  )
}

export default ContractTerminationForm
