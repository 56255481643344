import { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Button, Card, Typography } from '@mui/material'

import { Image } from 'components/UI/Image'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { getCompanyId } from 'utils/company'
import { isTest } from 'utils/general'
import useConfetti from 'utils/hooks/useConfetti'
import useNotifications from 'utils/hooks/useNotifications'

import elBackground from 'assets/images/views/payroll/el-background.svg'

import SummaryTable from '../Summary/Index'
import PayrollActions from './ActionGroup/Actions'
import PeriodTotals from './PeriodTotals'
import { usePeriod, useReturnPayPayroll } from './helpers'

const GeneratedPayroll = () => {
  const { throwConfetti } = useConfetti()

  const {
    period: { contracts_counter: contractsCounter = {}, id: periodId },
    payPreviousPayroll,
    hasEarlyPayment,
  } = usePeriod()
  const handleReturnToPayPayroll = useReturnPayPayroll()
  const { showInfoMessage } = useNotifications()
  const companyId = getCompanyId()
  const queryClient = useQueryClient()
  const payrollsCacheData = queryClient.getQueryData(
    ['periodPayrolls', companyId, periodId],
    {
      exact: false,
    }
  )
  const hasEarlyPaymentFromCache = payrollsCacheData?.data?.early_payment

  const showExclusiveFileAlert =
    ((hasEarlyPaymentFromCache && hasEarlyPayment) ||
      hasEarlyPaymentFromCache ||
      contractsCounter.terminated !== 0) &&
    !payPreviousPayroll &&
    !isTest

  useEffect(() => {
    if (showExclusiveFileAlert) {
      showInfoMessage(
        'Recuerda que las personas liquidadas o con pago anticipado de vacaciones (sin días trabajados después de vacaciones) genera un archivo exclusivo para pagar en el banco.',
        { persist: true, preventDuplicate: true }
      )
    }

    let confettiTimer

    if (!payPreviousPayroll) {
      confettiTimer = setTimeout(() => {
        throwConfetti({ origin: { x: 0.5, y: 0.8 }, particleCount: 150 })
        throwConfetti({ origin: { x: 0.2, y: 0.4 }, particleCount: 150 })
        throwConfetti({ origin: { x: 0.8, y: 0.4 }, particleCount: 150 })
      }, 500)
    }

    return () => {
      clearTimeout(confettiTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!payPreviousPayroll ? (
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridColumn: '1 / -1',
            columnGap: theme.spacing(3),
            rowGap: theme.spacing(3),
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridTemplateRows: 'repeat(2, max-content)',
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: 'repeat(6, 1fr)',
              columnGap: theme.spacing(4),
              rowGap: theme.spacing(4),
            },
            [theme.breakpoints.up('desktop')]: {
              gridTemplateColumns: 'repeat(12, 1fr)',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              borderRadius: '1rem',
              gridColumn: '1 / -1',
              gridRow: '1',
              [theme.breakpoints.up('desktop')]: {
                gridColumn: 'span 8',
              },
            })}
          >
            <Card
              sx={(theme) => ({
                backgroundColor: theme.palette.complementary2.main,
                borderRadius: '1rem',
                padding: theme.spacing(3, 6),
                minHeight: '17.5rem',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                [theme.breakpoints.up('tablet')]: {
                  padding: theme.spacing(3, 23, 3, 6),
                  overflow: 'initial',
                  backgroundImage: `url(${elBackground})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                },
              })}
            >
              <Box
                component={(props) => (
                  <Image
                    {...props}
                    src="f2547230-bba6-46e4-12d4-e0f93f3f7700"
                    width={130}
                    height={367}
                  />
                )}
                sx={{
                  position: 'absolute',
                  top: '-2rem',
                  right: '2rem',
                  margin: 'auto',
                  display: {
                    mobile: 'none',
                    tablet: 'block',
                  },
                }}
              />
              <Typography
                variant="h3"
                sx={(theme) => ({ marginBottom: theme.spacing(1) })}
              >
                ¡Aleluya! Liquidaste tu nómina en menos de lo que canta un
                gallo.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1">
                  Creamos para ti tus archivos para pago en banco, colillas de
                  pago, archivo para pago de seguridad social, interfaz contable
                  y resúmenes de nómina. Recuerda que puedes escribirnos a{' '}
                  <OrganizationEmail sx={{ textDecoration: 'inherit' }} /> si
                  necesitas que te rescatemos con algo.
                </Typography>
                <Box
                  sx={(theme) => ({
                    marginTop: theme.spacing(4),
                  })}
                >
                  <Button onClick={handleReturnToPayPayroll}>Regresar</Button>
                </Box>
              </Box>
            </Card>
          </Box>
          <Box
            sx={(theme) => ({
              gridColumn: '1 / -1',
              gridRow: '3',
              [theme.breakpoints.up('desktop')]: {
                gridColumn: 'span 4',
                gridRow: '1 / span 2',
              },
              height: '100%',
            })}
          >
            <Card
              sx={(theme) => ({
                borderRadius: '1rem',
                padding: theme.spacing(2),
              })}
            >
              <Typography
                variant="h4"
                sx={(theme) => ({
                  marginBottom: theme.spacing(3),
                })}
              >
                Esto es lo que debes pagar
              </Typography>
              <PeriodTotals stack compact />
            </Card>
          </Box>
          <Box
            sx={(theme) => ({
              gridColumn: '1 / -1',
              gridRow: '4',
              paddingBottom: theme.spacing(10),
              [theme.breakpoints.up('desktop')]: {
                gridColumn: 'span 8',
                gridRow: '2',
              },
            })}
          >
            <PayrollActions />
          </Box>
        </Box>
      ) : null}
      {payPreviousPayroll ? (
        <Box>
          <Typography
            variant="h2"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
            })}
          >
            Resumen novedades en este periodo
          </Typography>
          <SummaryTable />
        </Box>
      ) : null}
    </Box>
  )
}

export default GeneratedPayroll
