import { useQueryClient } from 'react-query'

import { Typography } from '@mui/material'

import useTransactionResponseModal from 'components/Subscription/Atoms/TransactionResponseModal/useTransactionResponseModal'
import { useDowngradeToEntrepreneurPlanModal } from 'components/Subscription/Atoms/useDowngradeToEntrepreneurPlanModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Modal from 'components/UI/Modal/Modal'
import useWorker from 'components/Worker/useWorker'

import { getCompanyId } from 'utils/company'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'

import notification from 'messages/notification'

const WorkerShowActivationModal = ({
  onCancel,
  isADowngradeToEntrepreneurPlan,
  showUpgradeToPayrollOnlyPlan,
}) => {
  const { worker, refreshWorker } = useWorker({ useCache: true })
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { subscription, isCompanyUsing20241111Plans } = useSubscription()
  const { openDowngradeToEntrepreneurPlanModal } =
    useDowngradeToEntrepreneurPlanModal()
  const { openTransactionResponseModal } = useTransactionResponseModal()
  const { workerMutation } = useWorkerService({
    queryOptions: { enabled: false },
  })

  const handleSubmitActivation = () => {
    const newState = { active: !worker.active }

    workerMutation.mutate(
      {
        mutationMethod: 'PATCH',
        workerId: worker.id,
        worker: newState,
      },
      {
        onSuccess: async () => {
          if (subscription?.type === 'year' || isCompanyUsing20241111Plans) {
            await queryClient.invalidateQueries([
              'getSubscription',
              getCompanyId(),
            ])
          }

          if (worker.active && isADowngradeToEntrepreneurPlan) {
            openDowngradeToEntrepreneurPlanModal()
          }

          if (!worker.active && showUpgradeToPayrollOnlyPlan) {
            openTransactionResponseModal({
              subscriptionTypeValidators: {
                isPremiumExpiredSubscription: false,
              },
              downgradeData: false,
            })
          }

          refreshWorker()
          onCancel()
          showSuccessMessage(notification.WORKER_ACTIVATION_SUCCESS)
        },
      }
    )
  }

  return (
    <Modal
      open
      header={`¿Estás seguro que deseas ${
        worker.active ? 'desactivar' : 'activar'
      } esta persona?`}
      onOk={handleSubmitActivation}
      onCancel={onCancel}
      okText={worker.active ? 'Sí, desactivarlo' : 'Sí, activarlo'}
      isLoading={workerMutation.isLoading}
    >
      {worker.active ? (
        <>
          <Typography gutterBottom>
            Al desactivarlo ten en cuenta que:
          </Typography>
          <ol>
            <li>
              No aparecerá en la sección de Liquidar Nomina, ni podrá acceder a
              la plataforma.
            </li>
            <li>
              No quedará marcado como &ldquo;Retiro&rdquo; en tu archivo de
              seguridad social.
            </li>
          </ol>
          <br />
          <Typography>
            <i>
              Si es empleado o pensionado y saldrá de la compañía, te
              recomendamos <b>liquidarlo</b>, para que el historial de pagos
              quede correcto y se genere la novedad en tu archivo de Seguridad
              Social.
            </i>
          </Typography>
        </>
      ) : (
        <Typography>
          Activándolo comenzará a aparecer en la sección de personas, en la
          sección de Liquidar Nomina y tendrá acceso a sus datos,{' '}
          <i>(Si no quieres esto, desactivalo en Configuración)</i>. ¿Deseas
          continuar?
        </Typography>
      )}
    </Modal>
  )
}

export default WorkerShowActivationModal
