import { Link as RouterLink } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import OrganizationEmail from 'components/UI/OrganizationEmail'

import executiveMan from 'assets/images/views/common/executive-man.png'
import globesMan from 'assets/images/views/common/globes-man.png'
import shapesWoman from 'assets/images/views/common/shapes-woman.png'

import Summary from './Summary'

const getContentFromResponse = (status, response) => {
  const contentByPaymentStatus = {
    APPROVED: {
      image: (
        <img loading="lazy" src={shapesWoman} alt="" width={293} height={313} />
      ),
      headerText: (
        <>
          <Typography variant="h4" gutterBottom>
            ¡Tu pago ha sido
            <br />
            procesado con éxito!
          </Typography>
          <Typography gutterBottom>
            Revisa tu correo para confirmar que{' '}
            {response.name || 'Aportes en Línea'} recibió el pago. 🙂
          </Typography>
        </>
      ),
    },
    PENDING: {
      image: (
        <img
          loading="lazy"
          src={executiveMan}
          alt=""
          width={230}
          height={312}
        />
      ),
      headerText: (
        <>
          <Typography variant="h4" gutterBottom>
            Tu pago está
            <br />
            pendiente de aprobación.
          </Typography>
          <Typography gutterBottom>
            La institución financiera está procesando el pago y aún no tenemos
            respuesta por favor no reintentes el pago hasta que tengas claro si
            el pago fue rechazado o aprobado.
          </Typography>
        </>
      ),
      footerText: (
        <>
          Si tienes alguna duda, escríbenos por el chat o a{' '}
          <OrganizationEmail color="primary.dark" />, o contacta a tu
          institución financiera.
        </>
      ),
    },
    DECLINED: {
      image: (
        <img loading="lazy" src={globesMan} alt="" width={232} height={320} />
      ),
      headerText: (
        <>
          <Typography variant="h4" gutterBottom>
            Parece que
            <br />
            algo salió mal.
          </Typography>
          <Typography gutterBottom>
            {response.message ||
              response.error?.message ||
              'Ocurrió un error al intentar realizar tu pago. Por favor inténtalo nuevamente.'}
          </Typography>
        </>
      ),
      footerText: (
        <>
          Si el error persiste, escríbenos por el chat o a{' '}
          <OrganizationEmail color="primary.dark" />, y te ayudaremos con mucho
          gusto en lo que necesites.
        </>
      ),
    },
  }

  return contentByPaymentStatus[status]
}

const TransactionStatus = ({
  status,
  response = {},
  buttonText,
  buttonLinkTo = {},
}) => {
  const { headerText, image, footerText } = getContentFromResponse(
    status,
    response
  )

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        gridTemplateColumns: 'minmax(auto, 720px)',
        gridTemplateRow: 'auto',
        height: '100%',
        padding: theme.spacing(2),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: theme.spacing(4),
            },
          })}
        >
          <div>{headerText}</div>
          {image}
        </Box>
        <Summary response={response} />
        {footerText ? (
          <Typography gutterBottom align="center">
            {footerText}
          </Typography>
        ) : null}
        <Button
          color="primary"
          component={RouterLink}
          sx={(theme) => ({
            alignSelf: 'center',
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            minWidth: '15.625rem',
          })}
          {...buttonLinkTo}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  )
}

export default TransactionStatus
