import { useState } from 'react'

import { generateEmptyPayrollItem } from 'utils/payroll'

/**
 * Used in conjunction with DeductionsModal
 */
const useDeductionsConfig = () => {
  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = () => setShowModal(true)

  const handleCloseModal = () => setShowModal(false)

  const getInitialData = (
    allDeductionConcepts = [],
    allDeductionsItems = [],
    contractCategory
  ) => {
    let loanConcept
    const deductionConcepts = []
    const loanItems = []
    const deductionItems = []
    let hasDefaultLoanItem = false
    let hasPayrollRetentionItem = false
    let payrollRetentionConcept

    for (let i = 0; i < allDeductionConcepts.length; i += 1) {
      const concept = allDeductionConcepts[i]

      if (concept.coded_name === 'loan') {
        loanConcept = concept
      } else if (concept.coded_name === 'withholding_tax') {
        payrollRetentionConcept = concept
      } else {
        for (let j = 0; j < allDeductionsItems.length; j += 1) {
          const item = allDeductionsItems[j]

          // just will run on the first traverse to get the items
          if (i === 0) {
            if (item.coded_name === 'loan') {
              loanItems.push(item)

              // TODO: backend should provide a better way to get the default loan
              if (item.name === 'Préstamo') hasDefaultLoanItem = true
            } else {
              if (item.coded_name === 'withholding_tax')
                hasPayrollRetentionItem = true
              deductionItems.push(item)
            }
          }

          // mark selected concepts
          if (item.payroll_concept_id === concept.id) {
            concept.selected = true
            // minor performance enhancement
            if (i !== 0) break
          }
        }
        if (concept.coded_name !== 'avista_payroll_credit') {
          deductionConcepts.push(concept)
        }
      }
    }

    // adds the default loan item
    if (!hasDefaultLoanItem)
      loanItems.push(generateEmptyPayrollItem(loanConcept.id, loanConcept.name))

    // add empty payroll_retention item
    // in other words, when employee the item must be always visible
    if (
      (contractCategory === 'employee' || contractCategory === 'terminated') &&
      !hasPayrollRetentionItem
    ) {
      deductionItems.push(
        generateEmptyPayrollItem(
          payrollRetentionConcept.id,
          payrollRetentionConcept.name,
          payrollRetentionConcept.coded_name
        )
      )
    }
    // if there are not items except of the payroll_retention item
    if (
      deductionItems.filter((ded) => ded.coded_name !== 'payroll_retention')
        .length === 0
    )
      deductionItems.push(generateEmptyPayrollItem())

    return {
      loanItems,
      deductionConcepts,
      deductionItems,
    }
  }

  return {
    showModal,
    handleOpenModal,
    handleCloseModal,
    getInitialData,
  }
}

export default useDeductionsConfig
