import { Box, Typography } from '@mui/material'

import Alert from 'components/UI/MaterialUI/Alert/Alert'
import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

import Tooltip from './Tooltip'

const Info = ({ novelty }) => {
  const titleHeader = (
    <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(1) })}>
      Nota:
      {novelty !== 'payroll_retention' ? (
        <Tooltip
          title={
            <>
              <TooltipLink
                href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-registrar-ingresos-recurrentes-a-tu-empleado"
                target="_blank"
              >
                Aquí
              </TooltipLink>{' '}
              puedes encontrar cómo registrar ingresos o deducciones
              recurrentes.
            </>
          }
          disableInteractive={false}
        />
      ) : null}
    </Box>
  )

  return (
    <Alert
      sx={(theme) => ({ borderRadius: theme.spacing(2) })}
      severity="info"
      icon={false}
      header={titleHeader}
    >
      <Typography variant="body2">
        {novelty === 'payroll_retention' ? (
          <>
            Si quieres <strong>activar</strong> el cálculo automático de la
            retención en la fuente y <strong>editar</strong> los conceptos
            relacionados con este valor
          </>
        ) : (
          <>
            Si deseas configurar préstamos o deducciones recurrentes para que se
            apliquen automáticamente en cada periodo
          </>
        )}
        , ingresa al perfil del empleado y entra a la pestaña Conceptos
        Recurrentes.
      </Typography>
    </Alert>
  )
}

export default Info
