import { Box, Typography, useMediaQuery } from '@mui/material'

import { Image } from 'components/UI/Image'

export function IntroHeader() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      sx={({ palette, shadows, spacing }) => ({
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '12rem',
        paddingX: spacing(4),
        marginTop: spacing(6),
        borderRadius: '1rem',
        backgroundColor: palette.complementary1.main,
        boxShadow: shadows[3],
      })}
    >
      <Box
        sx={({ spacing, breakpoints }) => ({
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: spacing(2),
          width: '100%',
          [breakpoints.up('tablet')]: {
            width: '65%',
          },
          [breakpoints.up('laptop')]: {
            width: '70%',
          },
        })}
      >
        <Typography variant="h2">Primeros pasos</Typography>
        <Typography variant="body1">
          ¡Gestiona tu nómina sin complicaciones! Completa los siguientes pasos
          y desbloquea todo el potencial de Aleluya.
        </Typography>
      </Box>
      {!isMobile ? (
        <Image
          src="930594a7-20bc-4c91-b16e-47c8d65b8e00"
          width={292}
          height={292}
          style={{ position: 'absolute', top: '-4rem', right: '0.5rem' }}
        />
      ) : null}
    </Box>
  )
}
