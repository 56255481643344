import { Box, Button, Grow } from '@mui/material'

import { capitalize } from 'utils/general'

import { useDatePickerContext } from '.'

const CalendarMonthGrid = ({ open, state, onCloseMonthGrid, months }) => {
  const { isDesktop } = useDatePickerContext()

  const onChange = (index) => {
    const date = months[index].value
    state.setFocusedDate(date)
    onCloseMonthGrid()
  }

  return (
    <Grow in={open} unmountOnExit>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          right: 0,
          left: 0,
          top: theme.spacing(7),
          transformOrigin: 'top center 0',
          width: '100%',
          height: `calc(100% - ${theme.spacing(7)} - 4.5rem)`,
          backgroundColor: theme.palette.white.main,
          zIndex: '2',
          ...(isDesktop && {
            height: `calc(100% - ${theme.spacing(7)})`,
          }),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridTemplateRows: 'repeat(3, 1fr)',
            gap: theme.spacing(1),
            padding: theme.spacing(1),
            height: '100%',
            alignItems: 'center',
          })}
        >
          {months.map((monthItem, index) => (
            <Button
              key={monthItem.label}
              onClick={() => onChange(index)}
              size="small"
              sx={(theme) => ({
                backgroundColor: 'transparent',
                color: theme.palette.black.main,
                width: '100%',
                borderRadius: theme.spacing(1),
                height: '100%',
                padding: theme.spacing(1),
                fontSize: theme.typography.body1.fontSize,
                maxHeight: theme.spacing(10),
                '&:hover': {
                  color: theme.palette.white.main,
                },
              })}
            >
              {capitalize(monthItem.label)}
            </Button>
          ))}
        </Box>
      </Box>
    </Grow>
  )
}

export default CalendarMonthGrid
