import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'
import useCompanyWorkCenters from 'utils/hooks/company/decree2012Service'

import messages from 'messages/contract'

const RiskTypeField = ({ contractCategory, optional, ...restProps }) => {
  const companyId = getCompanyId()
  const { companyWorkCentersQuery } = useCompanyWorkCenters({
    serviceParams: { queryKey: ['getWorkCenters', companyId] },
  })

  const workCenterData = companyWorkCentersQuery?.data?.map((workCenter) => ({
    id: workCenter?.id,
    name: `${workCenter?.name} - ${workCenter?.activity_code?.risk_type}`,
    risk_type: workCenter?.activity_code?.risk_type_coded_name,
  }))

  return contractCategory &&
    contractCategory !== 'contractor' &&
    contractCategory !== 'schooling_trainee' ? (
    <FormField
      name="work_center"
      label="Centro de trabajo - Nivel de riesgo"
      placeholder="Centro de trabajo - Nivel de riesgo"
      variant="autocomplete"
      options={workCenterData || []}
      tooltipContent={messages.work_center}
      optional={optional}
      {...restProps}
    />
  ) : null
}

export default RiskTypeField
