import { Chip, Typography } from '@mui/material'

import Link from 'components/UI/MaterialUI/Link'

import { getUserId } from 'utils/auth'
import { getRoleDisplayName } from 'utils/company'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import * as routes from 'config/routes'

export default function getColumnsData(updateUser, changeCompany) {
  return [
    {
      Header: 'Nombre',
      accessor: 'name',
      Cell: ({
        row: {
          original: { id, name, role, worker_id: workerId, active },
        },
      }) =>
        active ? (
          <Link
            to={routes.DASHBOARD}
            onClick={() => {
              updateUser({ role, workerId, active })
              changeCompany({ id })
              Mixpanel.track(MIXPANEL_EVENTS.SELECT_COMPANY_FROM_PARTNERS, {
                user_id: getUserId(),
                user_role: role,
                company_id: id,
                worker_id: workerId,
              })
            }}
            color="black.main"
          >
            {name}
          </Link>
        ) : (
          <Typography variant="body2">{name}</Typography>
        ),
    },
    {
      Header: 'Rol',
      accessor: 'role',
      Cell: ({ row }) => {
        return <Chip label={getRoleDisplayName(row.original.role)} />
      },
    },
    {
      Header: 'Activo',
      accessor: 'active',
      Cell: ({ row }) => <span>{row.original.active ? 'Sí' : 'No'}</span>,
    },
  ]
}
