import { Box, Typography } from '@mui/material'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

const Tooltip = ({ title, disableInteractive = true }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TooltipInfoIcon
        title={
          <Typography
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.white.main,
            })}
          >
            {title}
          </Typography>
        }
        iconProps={{
          sx: {
            margin: '0 !important',
          },
        }}
        tooltipProps={{
          disableInteractive,
        }}
      />
    </Box>
  )
}

export default Tooltip
