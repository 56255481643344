import { Box, Button, Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

import { discardedTitles, resumeProcessText } from './helpers'

const DiscardedModal = ({
  state,
  handleClose,
  reopenProcessModal,
  resetDiscardedModalState,
}) => {
  const incomingTitle = discardedTitles[state.process]

  return (
    <Modal
      open={state.open}
      header="Esta acción ha sido descartada"
      onCancel={handleClose}
      hideFooter
      paperSx={{
        maxWidth: '45.5rem',
      }}
      dialogProps={{
        TransitionProps: {
          onExited: resetDiscardedModalState,
        },
      }}
    >
      <Typography variant="body1" color="black.dark">
        En caso de que desees retomar el proceso de{' '}
        {resumeProcessText[state.process]} con {state.workerName}, da clic en{' '}
        <b>{incomingTitle}.</b>
      </Typography>

      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button onClick={reopenProcessModal}>{incomingTitle}</Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
      </Box>
    </Modal>
  )
}

export default DiscardedModal
