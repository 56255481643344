import Avatar from 'components/UI/Avatar'
import Link from 'components/UI/MaterialUI/Link'

import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

import AddNovelties from './AddNovelties'

function getBaseSalaryText(workerType) {
  let text

  switch (workerType) {
    case 'contractor':
      text = 'Honorarios base'
      break
    case 'apprentice':
      text = 'Cuota de apoyo'
      break
    case 'student':
      text = 'Pago estudiante'
      break
    default:
      text = 'Salario base'

      break
  }

  return text
}

function getWorkerPaymentText(workerType) {
  let text

  switch (workerType) {
    case 'contractor':
      text = 'Total pago contratista'
      break
    case 'apprentice':
      text = 'Total pago aprendiz'
      break
    case 'student':
      text = 'Total pago a estudiante'
      break
    default:
      text = 'Total pago empleado'
      break
  }

  return text
}

export const getNoveltiesCost = (noveltiesDetail = {}) => {
  let total = 0

  Object.keys(noveltiesDetail)?.forEach((noveltyType) => {
    const sign = ['deductions', 'loans', 'payroll_retention'].includes(
      noveltyType
    )
      ? -1
      : 1

    total += (noveltiesDetail[noveltyType]?.value || 0) * sign
  })

  return total
}

export const noveltyName = {
  overtime: 'Horas extras',
  surcharge: 'Recargos',
  company_overtime: 'Otros conceptos',
  holidays: 'Vacaciones',
  incapacities: 'Incapacidades',
  licenses: 'Licencias',
  additional_income: 'Ingresos adicionales',
  deductions: 'Deducciones',
  loans: 'Préstamos',
  payroll_retention: 'Retefuente',
}

export const getColumnsData = (workerType) => {
  const columns = [
    {
      Header: 'Personas',
      accessor: 'worker_name',
      Cell: ({ row }) => {
        const avatarId = (row.index % 5) + 1
        return (
          <>
            <Avatar
              avatarId={avatarId}
              src={row.original.worker_picture}
              sx={(theme) => ({
                marginRight: theme.spacing(1),
              })}
            />
            <Link
              to={routes.WORKER_SHOW(row.original.worker_id)}
              color="black.dark"
            >
              {row.original.worker_name}
            </Link>
          </>
        )
      },
      customWidth: '300px',
    },
    {
      Header: getBaseSalaryText(workerType),
      accessor: 'base_salary',
      Cell: ({ row }) => formatCurrency(row.original.base_salary),
      tooltip: 'Este es el salario base mensual',
      noWrapHeader: true,
    },
    {
      Header: 'Novedades',
      accessor: 'novelties',
      Cell: ({ row }) => {
        return (
          <AddNovelties
            payroll={row.original}
            dataCy={`add-novelty-${row.id}`}
          />
        )
      },
      disableSortBy: true,
      noWrapHeader: true,
    },
    {
      Header: getWorkerPaymentText(workerType),
      accessor: 'worker_payment',
      Cell: ({ row }) => formatCurrency(row.original.worker_payment),
    },

    // Hidden column to search by worker_id_number
    {
      accessor: 'worker_id_number',
    },
  ]

  const visibleColumns = columns.filter((column) => !column.hidden)

  return visibleColumns
}

export default getColumnsData
