import { useState } from 'react'
import { useQueryClient } from 'react-query'

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import { useUser } from 'components/App/UserContext/useUser'
import PaymentModal from 'components/Subscription/Atoms/PaymentModal/PaymentModal'
import useTransactionResponseModal from 'components/Subscription/Atoms/TransactionResponseModal/useTransactionResponseModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Icon from 'components/UI/Icon'
import Tooltip from 'components/UI/Tooltip'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import ExtraWorkersCounter from '../ExtraWorkersCounter'
import { getCardsData, getModalInfo } from './helpers'

const SubscriptionConfirmationModal = ({
  planUpgrade,
  selectedModality,
  isAnUpgrade,
  modalityUpgrade,
  selectedPlan,
  activeUntil,
  currentWorkersNumber,
  isChangingModality,
  currentPlanPayment,
  isCurrentPlan,
  isPremiumExpiredSubscription,
}) => {
  const [totalPayment, setTotalPayment] = useState(null)
  const { openTransactionResponseModal } = useTransactionResponseModal()
  const { refreshCompany, isAPartnerChild } = useUser()
  const { subscription } = useSubscription()
  const queryClient = useQueryClient()
  const [extraWorkers, setExtraWorkers] = useState(0)
  const modals = useModals()
  const themeInstance = useTheme()

  const {
    payrolls_size: currentWorkers,
    month_value: monthValue,
    partner: isPartner,
    additional_workers_info: {
      minimum_payment_value: minimumPaymentValue,
    } = {},
    type: currentSubscriptionType,
  } = subscription || {}

  /**
   * Due to our business logic, an immediate payment is required when the subscription is expired, it's a current plan payment, or it's an upgrade.
   * In other cases, the adjustment in subscription is just approved without a payment.
   */
  const getImmediatePayment =
    isAnUpgrade || isPremiumExpiredSubscription || currentPlanPayment

  /**
   * Determines the payment value to be calculated based on the type of subscription change.
   *
   * If the subscription is being upgraded (`isAnUpgrade`) and the current subscription is still active (`!isPremiumExpiredSubscription`),
   * this variable will represent the prorated payment value, which is calculated based on the remaining time until the next payment date
   * and the payment already made.
   *
   * Otherwise, the value will reflect the full cost of the selected plan according to the chosen selected modality (month or year).
   */
  const getProratedPaymentValue = isAnUpgrade && !isPremiumExpiredSubscription

  /**
   * Checks if the total payment amount is processable based on the minimum payment value.
   * If the total payment is less than the minimum payment value, then the payment can't be processed by our payment providers.
   * In those cases, the adjustment in subscription is just approved without a payment.
   */
  const isPaymentProcessable = totalPayment > minimumPaymentValue

  const modality = selectedModality === 'year' ? 'yearly' : 'monthly'

  const showExtraWorkersCounter =
    selectedModality === 'year' && getImmediatePayment

  const queryKey = [
    getProratedPaymentValue ? 'getProratedPaymentValue' : 'getFullPaymentValue',
    getCompanyId(),
  ]

  const subscriptionTypeValidators = {
    isCurrentPlan,
    isChangingModality,
    currentPlanPayment,
    isPremiumExpiredSubscription,
  }

  const { subscriptionMutation } = useSubscriptionService({
    serviceParams: {
      queryKey,
      newPlanId: selectedPlan?.id,
      subscriptionType: selectedModality,
      actionType:
        isAnUpgrade && currentSubscriptionType === 'month'
          ? 'upgrade_info'
          : undefined,
    },
    queryOptions: {
      enabled: !isAPartnerChild,
      onSuccess: ({ data }) => {
        if (isPartner) {
          setTotalPayment(monthValue)
          return
        }

        setTotalPayment(
          isAnUpgrade && !isPremiumExpiredSubscription
            ? data?.additional_workers_info?.extra_workers_value
            : data?.subscription_value?.[modality]?.total
        )
      },
    },
  })

  const buttonText = () => {
    if (isAPartnerChild) return 'Activar mi plan'
    if (isAnUpgrade) return 'Mejorar mi plan'
    if (currentPlanPayment) return 'Pagar mi plan'
    return 'Cambiar mi plan'
  }

  const { text } = getModalInfo(
    currentPlanPayment,
    modalityUpgrade,
    planUpgrade,
    subscription,
    isChangingModality,
    isPremiumExpiredSubscription,
    isPartner,
    isAPartnerChild,
    activeUntil
  )

  const cardsData = getCardsData(
    totalPayment,
    activeUntil,
    themeInstance,
    currentWorkersNumber,
    planUpgrade,
    selectedModality,
    isPremiumExpiredSubscription
  )

  const handleContinueUpdateSubscription = () => {
    if (getImmediatePayment && isPaymentProcessable) {
      modals.openModal({
        id: 'paymentModal',
        content: (
          <PaymentModal
            handleClose={() => modals.closeAll()}
            currentPlan={selectedPlan}
            extraWorkersEdit={extraWorkers || 0}
            handlePreviousModal={() => modals.closeModal('paymentModal')}
            subscriptionChange
            newSubscriptionType={selectedModality}
            subscriptionTypeValidators={subscriptionTypeValidators}
            currentPlanPayment={currentPlanPayment}
            isFromImmediatePayment={true}
          />
        ),
        modalProps: {
          header: 'Método de pago',
          hideFooter: true,
          dialogProps: {
            fullWidth: true,
            maxWidth: 'sm',
          },
          paperSx: (theme) => ({
            maxWidth: '40rem',
            [theme.breakpoints.down('tablet')]: { padding: theme.spacing(1) },
          }),
        },
      })
    } else {
      subscriptionMutation.mutate(
        {
          mutationMethod: 'PUT',
          mutationKey: 'updateSubscription',
          newPlanId: selectedPlan?.id,
          subscriptionType: selectedModality,
        },
        {
          onSuccess: async ({ data: downgradeData }) => {
            await refreshCompany()
            queryClient.invalidateQueries(['getPlans', getCompanyId()])
            modals.closeModal('advancedSubscriptionConfirmationModal')

            Mixpanel.track(MIXPANEL_EVENTS.CHANGE_SUBSCRIPTION_V2, {
              company_id: getCompanyId(),
              user_id: getUserId(),
              user_role: getUserRole(),
              new_plan_id: selectedPlan?.id,
              new_plan_coded_name:
                selectedPlan?.codedName || selectedPlan?.coded_name,
              new_type: selectedModality,
              new_plan_name: selectedPlan?.planName || selectedPlan?.name,
              previous_plan_id: subscription?.plan?.id,
              previous_plan_coded_name: subscription?.plan?.coded_name,
              previous_type: subscription?.type,
              previous_plan_name: subscription?.plan?.name,
            })

            openTransactionResponseModal({
              subscriptionTypeValidators,
              downgradeData,
            })
          },
          onError: () => {
            modals.closeModal('advancedSubscriptionConfirmationModal')
          },
        }
      )
    }
  }

  return (
    <>
      <Typography
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(2) })}
      >
        {text}
      </Typography>
      {!isAPartnerChild ? (
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: theme.spacing(2),
            [theme.breakpoints.down('tablet')]: {
              gridTemplateColumns: '1fr',
            },
          })}
        >
          {cardsData?.map((data) => {
            const { id, label, value, baseColor, footnote, tooltipText } = data
            return (
              <Box
                key={id}
                sx={(theme) => ({
                  position: 'relative',
                  borderRadius: '0.5rem',
                  padding: theme.spacing(1.5, 2, 2),
                  overflow: 'hidden',
                  boxShadow: theme.shadows[3],
                  height: '8.5rem',
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    marginBottom: theme.spacing(1),
                    alignItems: 'center',
                    columnGap: theme.spacing(0.5),
                  })}
                >
                  <Typography variant="h5" sx={{ zIndex: 1 }}>
                    {label}
                  </Typography>
                  {tooltipText ? (
                    <Tooltip
                      description={tooltipText}
                      placement="bottom-start"
                      tooltipSx={(theme) => ({
                        boxShadow: theme.shadows[7],
                      })}
                    >
                      <Box sx={{ display: 'flex' }}>
                        <Icon name="info" sx={{ fontSize: '1.5rem' }} />
                      </Box>
                    </Tooltip>
                  ) : null}
                </Box>
                {value || typeof value === 'number' ? (
                  <Typography
                    variant="h2"
                    sx={(theme) => ({
                      marginBottom: theme.spacing(1),
                    })}
                  >
                    {value}
                  </Typography>
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={40} color="primary" />
                  </Box>
                )}
                {footnote && value ? (
                  <Typography color="black.light" variant="body2">
                    {footnote}
                  </Typography>
                ) : null}
                <Divider
                  absolute
                  sx={{
                    borderBottomWidth: 5,
                    borderColor: baseColor,
                  }}
                />
              </Box>
            )
          })}
        </Box>
      ) : null}

      {!isPartner && showExtraWorkersCounter ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            marginTop: theme.spacing(4),
          })}
        >
          <Typography variant="h5">¿Deseas agregar personas extras?</Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            })}
          >
            {(subscription.type === 'year' && planUpgrade) ||
            modalityUpgrade ? (
              <Typography>
                Estas personas extras se te cobrarán proporcionalmente a los
                meses faltantes de tu plan.
              </Typography>
            ) : null}

            <ExtraWorkersCounter
              extraWorkers={extraWorkers}
              setExtraWorkers={setExtraWorkers}
              setTotalPayment={setTotalPayment}
              selectedPlan={selectedPlan}
              selectedModality={selectedModality}
              modalityUpgrade={modalityUpgrade}
              getProratedPaymentValue={getProratedPaymentValue}
              paymentModality={modality}
              currentWorkers={currentWorkers}
              currentSubscriptionType={currentSubscriptionType}
              currentPlanPayment={currentPlanPayment}
            />
          </Box>
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button
          size="large"
          onClick={() => handleContinueUpdateSubscription()}
          sx={(theme) => ({ padding: theme.spacing(0, 5) })}
        >
          {buttonText()}
        </Button>
        <Button
          size="large"
          variant="outlined"
          onClick={() => modals.closeAll()}
          sx={(theme) => ({ padding: theme.spacing(0, 9) })}
        >
          Cancelar
        </Button>
      </Box>
    </>
  )
}

export default SubscriptionConfirmationModal
