import { useLocation } from 'react-router-dom'

import { Typography } from '@mui/material'

import Link from 'components/UI/MaterialUI/Link'

import * as routes from 'config/routes'

const LinkToSignup = () => {
  const location = useLocation()

  return (
    <Typography variant="body2" color="white.dark">
      ¿Tu empresa aún no tiene cuenta?{' '}
      <Link
        sx={{ fontSize: '0.875rem' }}
        to={{
          pathname: routes.SIGN_UP,
          search: location.search,
        }}
      >
        Crear cuenta
      </Link>
    </Typography>
  )
}

export default LinkToSignup
