import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'

import { Button, Menu, MenuItem } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import Icon from 'components/UI/Icon'

import socialBenefitsService from 'services/payroll/socialBenefitsService'

import { usePeriod } from '../../helpers'

const SocialBenefitsMenu = () => {
  const {
    period: { id: periodId },
    options: { social_benefits: socialBenefits = [] },
  } = usePeriod()
  const { enqueueDownload } = useDownloadManager()

  const handleDownloadFile = (sbPeriodId) => {
    enqueueDownload({
      name: 'archivo de Prestaciones Sociales',
      fileCode: 'social_benefits_file',
      firebasePath: `periods/${periodId}/social_benefits_file`,
      service: () =>
        socialBenefitsService.getSocialBenefitsFile(periodId, sbPeriodId),
    })
  }

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'socialBenefitsMenu',
  })

  const sbToDownload = socialBenefits.filter((sb) => sb.action === 'update')

  return (
    <>
      <Button
        variant="text"
        startIcon={<Icon name="file-pen-line" />}
        endIcon={<Icon name="chevron-down" />}
        size="small"
        sx={{
          minHeight: '2rem',
          height: '100%',
          justifyContent: 'flex-start',
          textAlign: 'start',
        }}
        {...bindTrigger(popupState)}
      >
        Archivo prestaciones sociales
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {sbToDownload.map((sb) => {
          const sbPeriodId = sb.social_benefits_period_id

          return (
            <MenuItem
              key={sbPeriodId}
              onClick={() => handleDownloadFile(sbPeriodId)}
            >
              {sb.label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default SocialBenefitsMenu
