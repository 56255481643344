import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import RouteNotFound from 'components/App/Error/NotFound'
import Loading from 'components/UI/Loading/Loading'
import TransactionStatus from 'components/UI/TransactionStatus/TransactionStatus'

import { formatDisplayDateString } from 'utils/dateTime'
import { isObjectEmpty } from 'utils/general'

import socialSecurityService from 'services/payroll/socialSecurityService'

import * as routes from 'config/routes'

const buttonDetails = ({
  form_key: formKey,
  period_id: periodId,
  formType,
  provider,
}) => ({
  paid: {
    payment_status: 'APPROVED',
    buttonText: 'Ver historial de pagos',
    buttonLinkTo: {
      to: routes.SETTINGS_SOCIAL_SECURITY_INDEX(),
      state: {
        provider,
      },
    },
  },
  pending: {
    payment_status: 'PENDING',
    buttonText: 'Ver historial de pagos',
    buttonLinkTo: {
      to: routes.SETTINGS_SOCIAL_SECURITY_INDEX(),
      state: {
        provider,
      },
    },
  },
  declined: {
    payment_status: 'DECLINED',
    buttonText: 'Volver a realizar el pago',
    buttonLinkTo: {
      to: routes.PERIOD_SOCIAL_SECURITY_SHOW(periodId),
      state: { formKey, formType, provider },
    },
  },
})

const SocialSecurityTransactionResponse = () => {
  const [response, setResponse] = useState({})
  const [notFound, setNotFound] = useState(false)

  const location = useLocation()
  const queryData = queryString.parse(location.search)

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        const { company, social_security_integration_payment: paymentData } =
          await socialSecurityService.checkPaymentStatus(
            queryData.period_id,
            queryData.form_key
          )

        setResponse({
          ...company,
          ...paymentData,
          date: formatDisplayDateString(paymentData.date),
        })
      } catch (error) {
        const res = error.errors && error.errors[0]

        if (res?.code === '1101') {
          setResponse(res)
        } else {
          setNotFound(true)
        }
      }
    }

    checkPaymentStatus()
  }, [queryData.form_key, queryData.period_id])

  if (notFound) return <RouteNotFound />
  if (isObjectEmpty(response)) return <Loading />

  const data = buttonDetails({
    ...queryData,
    formType: response.form_type,
    provider: response.provider,
  })[response.form_status]

  return (
    <TransactionStatus
      response={response}
      status={data.payment_status}
      buttonText={data.buttonText}
      buttonLinkTo={data.buttonLinkTo}
    />
  )
}

export default SocialSecurityTransactionResponse
