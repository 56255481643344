import { useCompanyService } from 'utils/hooks/company/companyService'

import {
  ONBOARDING_AREA,
  ONBOARDING_COMPANY,
  ONBOARDING_GOAL,
} from 'config/routes'

export function useOnboardingMutation() {
  const { companyMutation } = useCompanyService({
    queryOptions: { enabled: false },
  })

  const handleUpdateOnboarding = ({ data, callback }) => {
    companyMutation.mutate(
      {
        mutationMethod: 'PATCH',
        company: data,
      },
      {
        onSuccess: async ({ data }) => {
          if (callback) {
            await callback(data)
          }
        },
      }
    )
  }

  return {
    handleUpdateOnboarding,
    isLoading: companyMutation.isLoading,
  }
}

export function useHasPendingOnboarding(step) {
  const PATHNAME = {
    company_data: ONBOARDING_COMPANY(),
    user_area: ONBOARDING_AREA(),
    main_goal: ONBOARDING_GOAL(),
  }

  let value = false

  if (typeof step === 'string') {
    if (step !== 'finished') {
      value = true
    }
  }

  return { pathname: PATHNAME[step], value }
}
