import { Navigate } from 'react-router-dom'

import auth from 'utils/auth'
import { hasSelectedCompany } from 'utils/company'

import * as routes from 'config/routes'

/**
 * View relacted to a company so a company needs to be selected to this route to be accessed
 */
const View = ({ children }) => {
  const isWorker = auth.isWorker()

  if (hasSelectedCompany() && !isWorker) {
    return children
  }

  return (
    <Navigate
      to={isWorker ? routes.WORKER_PROFILE_SHOW() : routes.COMPANY_INDEX()}
    />
  )
}

export default View
