import {
  Box,
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
} from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import { useSupportMenuContext } from 'components/App/SupportMenuContext'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'

import { partyPopperEmoji } from 'utils/emojis'

import ManWalkingWithBird from 'assets/images/views/common/man_walking_with_bird.png'

export function useDowngradeToEntrepreneurPlanModal() {
  const modals = useModals()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const { openSupportMenuFromOutside } = useSupportMenuContext()

  const handleClose = () => {
    modals.closeModal('downgradeToEntrepreneurPlanModal')
  }

  const openDowngradeToEntrepreneurPlanModal = () => {
    modals.openModal({
      id: 'downgradeToEntrepreneurPlanModal',
      content: (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              mobile: '1fr',
              tablet: '17rem 1fr',
            },
          }}
        >
          {!isMobile ? (
            <>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.accent1.main,
                  width: '34.625rem',
                  height: '34.625rem',
                  borderRadius: '100%',
                  position: 'absolute',
                  left: theme.spacing(-38.125),
                  top: theme.spacing(-13),
                })}
              />
              <Box
                component="img"
                alt="Un hombre caminando con un pájaro en sus hombros"
                src={ManWalkingWithBird}
                width={256}
                height={256}
                sx={(theme) => ({
                  zIndex: 1,
                  objectFit: 'contain',
                  margin: 'auto 0',
                  marginLeft: theme.spacing(-4),
                })}
              />
            </>
          ) : null}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(2),
                gap: theme.spacing(2),
              })}
            >
              <Typography variant="h4">
                <Emoji code={partyPopperEmoji} /> ¡Tu cuenta ahora es Plan
                Emprendedor!
              </Typography>
              <ButtonBase
                onClick={handleClose}
                data-cy="close-dialog-button"
                sx={(theme) => ({
                  alignSelf: 'self-start',
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
              </ButtonBase>
            </Box>

            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2),
                color: 'black.dark',
              })}
            >
              <Typography
                variant="body2"
                sx={{ width: { mobile: '90%', tablet: '25.6875rem' } }}
              >
                Al tener 3 o menos personas, tu cuenta pasa automáticamente al{' '}
                <b>Plan Emprendedor</b>, donde seguirás disfrutando de los
                mismos superpoderes de Aleluya por solo <b>$50.000 mensuales</b>
                .
              </Typography>

              <Typography
                variant="body2"
                sx={{ width: { mobile: '90%', tablet: '25.6875rem' } }}
              >
                Este cambio de plan se reflejará en tu próxima fecha de cobro,
                siempre que mantengas <b>3 o menos personas</b> registradas.
              </Typography>

              <Typography
                variant="body2"
                sx={{ width: { mobile: '90%', tablet: '25.6875rem' } }}
              >
                ¿Tienes dudas? Puedes comunicarte con nuestro{' '}
                <Button
                  variant="text"
                  onClick={() => {
                    handleClose()
                    openSupportMenuFromOutside()
                  }}
                  sx={{ height: 'auto', color: 'info.main' }}
                >
                  equipo de soporte
                </Button>
                .
              </Typography>
            </Box>

            <Button
              size="large"
              onClick={handleClose}
              sx={(theme) => ({
                width: { mobile: '100%', tablet: '12.5rem' },
                marginTop: theme.spacing(3),
              })}
            >
              Cerrar
            </Button>
          </Box>
        </Box>
      ),
      modalProps: {
        onCloseModal: handleClose,
        hideFooter: true,
        paperSx: {
          maxWidth: '49rem',
        },
        contentWrapperSx: {
          overflow: 'hidden',
        },
      },
    })
  }

  return { openDowngradeToEntrepreneurPlanModal }
}
