import { Box } from '@mui/material'

import CertificateDownloadCard from 'components/Worker/WorkersOnboarding/SSAffiliation/InProcessModal/atoms/CertificateDownloadCard'

import { getCompanyId } from 'utils/company'
import useCompanyHolisticPayrollService from 'utils/hooks/HolisticPayroll/CompayService'

export default function ClaimSupportModalContent({
  claimsDetailId,
  claimNoveltyId,
}) {
  const companyId = getCompanyId()
  const {
    companyHolisticPayrollQuery: { data: disabilityClaimsDetailQuery = {} },
  } = useCompanyHolisticPayrollService({
    serviceParams: {
      queryKey: ['getClaimStatusDetail', companyId],
      claimsDetailId,
      claimNoveltyId,
      withDocument: true,
    },
  })

  const hasDocument = disabilityClaimsDetailQuery?.claim_support_file

  return (
    <Box
      sx={({ spacing }) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        marginY: spacing(5),
      })}
    >
      <CertificateDownloadCard
        iconName="document"
        backgroundColor={(theme) => theme.palette.warning.main}
        institutionName="Documento de incapacidad"
        isCompleted={hasDocument}
        documentUrl={disabilityClaimsDetailQuery?.claim_support_file}
      />
    </Box>
  )
}
