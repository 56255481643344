import * as yup from 'yup'

import ActivityCIIU from 'components/Company/Form/Fields/WorkCenters/ActivityCIIU'
import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'

import { documentTypes, documentTypesOptions } from 'utils/company'
import { getDirtyValues } from 'utils/form'

import { BasicInfoCard } from './BasicInfoCard'

function getCompanyName(companyName) {
  return companyName?.startsWith('Empresa Aleluya_') && /\d+$/.test(companyName)
    ? ''
    : companyName
}

export function companyInfoItems({ company, isEditing }) {
  return (
    <>
      <BasicInfoCard
        data={{
          label: 'Razón social',
          key: 'company_name',
          icon: 'factory',
          initialValue: {
            value: getCompanyName(company?.name),
            label: getCompanyName(company?.name),
          },
          field: (
            <FormField name="name" label="Razón social" optional={false} />
          ),
        }}
        isEditingForm={isEditing}
      />
      <BasicInfoCard
        data={{
          label: 'Tipo de documento',
          key: 'identification_type',
          icon: 'id-card',
          initialValue: {
            value: company?.document_type,
            label: documentTypes[company?.document_type],
          },
          field: (
            <DocumentTypeField
              optional={false}
              options={documentTypesOptions}
            />
          ),
        }}
        isEditingForm={isEditing}
      />
      <BasicInfoCard
        data={{
          label: 'Número de documento',
          key: 'id_number',
          icon: 'coins',
          initialValue: {
            value: company?.id_number,
            label: company?.id_number,
          },
          field: (
            <CompanyIdentificationNumberField
              name="id_number"
              optional={false}
              nitContainerSx={{ width: '100%' }}
            />
          ),
        }}
        isEditingForm={isEditing}
      />
    </>
  )
}

export function workCenterItems({ workCenter, isEditing }) {
  return (
    <>
      <BasicInfoCard
        data={{
          label: 'Fecha de inicio',
          key: 'initial_day',
          icon: 'calendar-days',
          field: (
            <FormField
              name="initial_day"
              label="Fecha de inicio"
              variant="datepicker"
              optional={false}
            />
          ),
          initialValue: {
            value: workCenter?.initial_day,
            label: workCenter?.initial_day,
          },
        }}
        isEditingForm={isEditing}
      />
      <BasicInfoCard
        data={{
          label: 'Nombre',
          key: 'center_work_name',
          icon: 'book-user',
          field: (
            <FormField
              name="center_work_name"
              label="Nombre"
              optional={false}
            />
          ),
          initialValue: {
            value: workCenter?.name,
            label: workCenter?.name,
          },
        }}
        isEditingForm={isEditing}
      />
      <BasicInfoCard
        data={{
          label: 'Código centro trabajo',
          key: 'job_code',
          icon: 'file-spreadsheet',
          field: (
            <FormField
              name="code"
              label="Código centro de trabajo"
              optional={false}
              variant="number"
              thousandSeparator=""
            />
          ),
          initialValue: {
            value: workCenter?.code,
            label: workCenter?.code,
          },
        }}
        isEditingForm={isEditing}
      />
      <BasicInfoCard
        data={{
          label: 'Código actividad CIIU',
          key: 'activity_code',
          icon: 'file-pen-line',
          field: (
            <ActivityCIIU
              isEdit={true}
              optional={false}
              label="Código actividad CIIU"
            />
          ),
          initialValue: {
            value: workCenter?.activity_code?.ciiu_code,
            label: workCenter?.activity_code?.ciiu_code,
          },
        }}
        isEditingForm={isEditing}
      />
      <BasicInfoCard
        data={{
          label: 'Nivel de riesgo',
          key: 'risk_type',
          icon: 'shield-check',
          field: (
            <FormField
              name="risk_type"
              label="Nivel de riesgo"
              isDisabled={true}
              optional={false}
              disabled
            />
          ),
          initialValue: {
            value: workCenter?.activity_code?.risk_type,
            label: workCenter?.activity_code?.risk_type,
          },
        }}
        isEditingForm={isEditing}
      />
    </>
  )
}

export function getInitialValues({ company, companyWorkCenter }) {
  return {
    name: getCompanyName(company?.name),
    document_type: company?.document_type,
    id_number: company?.id_number,
    verification_digit: company?.verification_digit,
    initial_day: companyWorkCenter?.initial_day,
    center_work_name: companyWorkCenter?.name,
    code: companyWorkCenter?.code || '',
    activity_code_id: companyWorkCenter?.activity_code
      ? {
          id: companyWorkCenter?.activity_code?.id,
          name: companyWorkCenter?.activity_code?.ciiu_code,
        }
      : null,
    risk_type: companyWorkCenter?.activity_code?.risk_type || '',
  }
}

export const validationSchema = yup.object({
  name: yup.string().required(),
  document_type: yup.string().required(),
  id_number: yup.string().nullable().required(),
  initial_day: yup.string().required(),
  center_work_name: yup.string().required(),
  code: yup.string().required(),
  activity_code_id: yup.object().nullable().required(),
  risk_type: yup.string().required(),
})

export function getCompanyValuesToSend(values, initialValues = {}) {
  return getDirtyValues(
    {
      name: initialValues.name,
      document_type: initialValues.document_type,
      id_number: initialValues.id_number,
    },
    {
      name: values.name,
      document_type: values.document_type,
      id_number: values.id_number,
    }
  )
}

export function getWorkCenterValuesToSend(values, initialValues = {}) {
  return getDirtyValues(
    {
      name: initialValues.center_work_name,
      code: initialValues.code,
      activity_code_id: initialValues.activity_code_id?.id,
      risk_type: initialValues.risk_type,
      initial_day: initialValues.initial_day,
    },
    {
      name: values.center_work_name,
      code: values.code,
      activity_code_id: values.activity_code_id?.id,
      risk_type: values.risk_type,
      initial_day: values.initial_day,
    }
  )
}
