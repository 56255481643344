import {
  Tab as MuiTab,
  Tabs as MuiTabs,
  tabClasses,
  tabsClasses,
} from '@mui/material'

const Tabs = ({ value, onChange, tabs, wrapped, sx, ...rest }) => {
  return (
    <MuiTabs
      value={value}
      onChange={onChange}
      {...rest}
      sx={[
        (theme) => ({
          height: '5rem',
          [`& .${tabsClasses.indicator}`]: {
            display: 'none',
          },
          [`& .${tabsClasses.flexContainer}`]: {
            gap: theme.spacing(4),
            height: '100%',
          },
          [`& .${tabClasses.root}`]: {
            textTransform: 'none',
            display: 'flex',
            color: theme.palette.black.light,
            borderRadius: '1rem',
            backgroundColor: theme.palette.white.main,
            padding: theme.spacing(2, 3),
            height: '100%',
            ...theme.typography.h5,
          },
          [`& .${tabClasses.selected}`]: {
            color: `${theme.palette.primary.dark} !important`,
            border: `2px solid ${theme.palette.primary.dark}`,
          },
        }),
        sx,
      ]}
    >
      {tabs.map((tab, index) =>
        tab.hidden ? null : (
          <MuiTab
            key={tab.key || index}
            label={tab.label}
            value={tab.key}
            icon={tab.icon}
            wrapped={wrapped}
            data-cy={tab.cyData || ''}
          />
        )
      )}
    </MuiTabs>
  )
}

export default Tabs
