import { getDayOfWeek, isSameMonth, isToday } from '@internationalized/date'
import { useCalendarCell } from '@react-aria/calendar'
import { useFocusRing } from '@react-aria/focus'
import { useLocale } from '@react-aria/i18n'
import { mergeProps } from '@react-aria/utils'
import { useRef } from 'react'

import { Box } from '@mui/material'

import Tooltip from 'components/UI/Tooltip'

import { isTest } from 'utils/general'

import { useDateRangePickerContext } from '.'
import { isBetweenDates } from './helpers'

const CalendarCell = ({ state, date, startDate }) => {
  const { locale } = useLocale()
  const { tooltipTitle, tooltipDescription, initialDay, endDay } =
    useDateRangePickerContext()
  const ref = useRef()
  const {
    cellProps,
    buttonProps,
    isSelected,
    isDisabled,
    isUnavailable,
    formattedDate,
  } = useCalendarCell({ date }, state, ref)
  const dataCy = `${date.year}-${date.month}-${date.day}`
  const isCurrentDate = isToday(date, state.timeZone)
  const [isDayBetween, isFirstDay, isLastDay] = isBetweenDates(
    date,
    initialDay,
    endDay
  )

  /**
   * We add rounded corners on the left for the first day of the month,
   * the first day of each week, and the start date of the selection.
   * We add rounded corners on the right for the last day of the month,
   * the last day of each week, and the end date of the selection.
   */
  const dayOfWeek = getDayOfWeek(date, locale)
  const isRoundedLeft = isFirstDay || dayOfWeek === 1 || date.day === 1
  const isRoundedRight =
    isLastDay ||
    dayOfWeek === 0 ||
    date.day === date.calendar.getDaysInMonth(date)

  const isOutsideMonth = !isSameMonth(startDate, date)

  const { focusProps, isFocusVisible } = useFocusRing()

  return (
    <Box
      component="td"
      {...cellProps}
      sx={(theme) => ({
        padding: theme.spacing(0.375, 0),
      })}
    >
      <Tooltip
        title={
          !isTest && isUnavailable && tooltipTitle ? tooltipTitle : undefined
        }
        description={
          !isTest && isUnavailable && tooltipDescription
            ? tooltipDescription
            : undefined
        }
        enterNextDelay={500}
      >
        <Box
          {...mergeProps(buttonProps, focusProps)}
          ref={ref}
          hidden={isOutsideMonth}
          data-cy={dataCy}
          sx={(theme) => ({
            width: '2.5rem',
            height: '2.5rem',
            outline: '2px solid transparent',
            outlineOffset: 2,
            ...(isRoundedLeft && {
              borderBottomLeftRadius: 99999,
              borderTopLeftRadius: 99999,
            }),
            ...(isRoundedRight && {
              borderBottomRightRadius: 99999,
              borderTopRightRadius: 99999,
            }),
            ...(isDisabled && {
              color: theme.palette.black.light,
            }),
            ...(isDayBetween && {
              backgroundColor:
                theme.palette.primary[300] ?? theme.palette.primary.light,
            }),
          })}
        >
          <Box
            sx={(theme) => ({
              position: 'relative',
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'default',
              userSelect: 'none',
              color: theme.palette.black.dark,
              ...(isDisabled && {
                color: theme.palette.white.dark,
              }),
              ...((isFirstDay || isLastDay) && {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.white.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                },
              }),
              ...(isDayBetween &&
                !(isFirstDay || isLastDay) && {
                  color: theme.palette.black.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                  },
                }),
              ...(!isDayBetween &&
                !isDisabled &&
                !(isFirstDay || isLastDay) && {
                  '&:hover': {
                    backgroundColor: theme.palette.white.main,
                  },
                }),
              ...(isFocusVisible && {
                boxShadow: `inset 0 0 0 2px ${theme.palette.white.main}, 0 0 0 2px ${theme.palette.primary.main}`,
              }),
              ...(isUnavailable && {
                color: theme.palette.white.dark,
                cursor: 'not-allowed',
                '&:hover': {
                  backgroundColor: 'transparent !important',
                },
              }),
            })}
          >
            {formattedDate}
            <Box
              component="span"
              sx={(theme) => ({
                position: 'absolute',
                display: 'none',
                height: '0.4rem',
                width: '0.4rem',
                backgroundColor: theme.palette.primary.dark,
                borderRadius: '50%',
                bottom: '0.3rem',
                ...(isCurrentDate && {
                  display: 'block',
                }),
                ...(isCurrentDate &&
                  isSelected && {
                    backgroundColor: theme.palette.primary.dark,
                  }),
              })}
            />
          </Box>
        </Box>
      </Tooltip>
    </Box>
  )
}

export default CalendarCell
