import { Formik } from 'formik'
import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography, useMediaQuery } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useOvertimeService from 'utils/hooks/payroll/overtime'
import usePayrollService from 'utils/hooks/payroll/payroll'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'
import { generateNewCustomConcept } from 'utils/payroll'

import { useNoveltyType, usePeriod, usePeriodAPI } from '../../helpers'
import CardGrid from '../common/CardGrid'
import HeaderCell from '../common/CardGrid/HeaderCell'
import HeaderRow from '../common/CardGrid/HeaderRow'
import OvertimeItem from './Item'
import WorkedHours from './WorkedHours'
import {
  getColumnsWidth,
  getDataToSend,
  getModalHeader,
  getValidationSchema,
  overtimeDescription,
  validateEmptyNewExtraHour,
} from './helpers'
import useOvertimeConfig from './useOvertimeConfig'

const OvertimeModal = ({ state, handleClose }) => {
  const { period } = usePeriod()
  const { payrollState } = useNoveltyType()
  const { updatePeriodCallback } = usePeriodAPI()
  const { workerName, workerPayroll, noveltyConcept } = payrollState
  const queryClient = useQueryClient()
  const { getOvertimeConcepts, addNewOvertimeItem } = useOvertimeConfig()
  const { id: payrollId, contract_category: contractCategory } = workerPayroll
  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })
  const [currentOvertimeData, setCurrentOvertimeData] = useState({})
  const [initialData, setInitialData] = useState()
  const [activeNewExtraHour, setActiveNewExtraHour] = useState(false)
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const isInvalidating = useRef(false)
  const overtimeQueryKey = ['overtimeByPayroll', payrollId]
  const payrollConceptsQueryKey = ['payrollConcepts', 'overtime', payrollId]
  const isContractorWithPerHourSalary =
    workerPayroll.contract_category === 'contractor' &&
    workerPayroll.salary_category === 'per_hour'

  const { payrollConceptsQuery, payrollConceptsMutation } =
    usePayrollConceptsService({
      serviceParams: {
        queryKey: payrollConceptsQueryKey,
        conceptsCategory: 'overtime',
      },
    })

  const { overtimeQuery, overtimeMutation } = useOvertimeService({
    serviceParams: {
      queryKey: overtimeQueryKey,
      payrollId,
    },
    queryOptions: {
      enabled: payrollConceptsQuery.isSuccess,
      onSuccess: ({ data }) => {
        const overtimeConcepts = getOvertimeConcepts(
          payrollConceptsQuery?.data,
          data
        )
        setCurrentOvertimeData(overtimeConcepts)
        setInitialData(overtimeConcepts)
        isInvalidating.current = false
      },
    },
  })

  const isGettingData =
    overtimeQuery.isLoading || payrollConceptsQuery.isLoading

  const isMutatingData =
    overtimeMutation.isLoading ||
    payrollConceptsMutation.isLoading ||
    isInvalidating.current ||
    payrollMutation.isLoading

  const handleShowAddExtraHour = () => {
    setActiveNewExtraHour(true)
  }

  const handleHideExtraHour = () => {
    setActiveNewExtraHour(false)
  }

  const handleChangeItem = (event, itemConceptId, category) => {
    let itemConceptQuantity = Number(event.target.rawValue)

    if (itemConceptQuantity > 235) itemConceptQuantity = 235

    const updatedData = addNewOvertimeItem(
      currentOvertimeData,
      category,
      itemConceptId,
      itemConceptQuantity
    )
    setCurrentOvertimeData((previousOvertimeData) => ({
      ...previousOvertimeData,
      [category]: updatedData,
    }))
  }

  const handleDeleteItem = (payrollConceptId) => {
    payrollConceptsMutation.mutate(
      {
        mutationMethod: 'DELETE',
        payrollConceptId,
      },
      {
        onSuccess: ({ message }) => {
          showSuccessMessage(message)
          const newOvertimeData = {
            ...currentOvertimeData,
            others: currentOvertimeData.others.filter(
              (item) => item.id !== payrollConceptId
            ),
          }
          setCurrentOvertimeData(newOvertimeData)
        },
      }
    )
  }

  const submitNewExtraHour = (form) => {
    const { values } = form

    const dataToSend = generateNewCustomConcept({
      payrollId,
      payrollConcepts: [values],
      category: 'overtime',
    })

    return payrollConceptsMutation.mutateAsync(
      {
        mutationMethod: 'PUT',
        concepts: dataToSend,
      },
      {
        onSuccess: ({ data }) => {
          updatePeriodCallback(data)
          handleHideExtraHour()
          showSuccessMessage(
            'El nuevo concepto de hora extra fue creado exitosamente.'
          )
          form.resetForm()

          Mixpanel.track(MIXPANEL_EVENTS.ADDED_OVERTIME_AND_SURCHARGES, {
            company_id: getCompanyId(),
            user_id: getUserId(),
            user_role: getUserRole(),
          })
        },
      }
    )
  }

  const submitWorkedDays = (values) => {
    return payrollMutation.mutateAsync(
      {
        mutationMethod: 'PATCH',
        payrollId,
        data: {
          payroll: { worked_time: values },
        },
      },
      {
        onSuccess: ({ data }) => {
          updatePeriodCallback(data)

          Mixpanel.track(MIXPANEL_EVENTS.ADDED_OVERTIME_AND_SURCHARGES, {
            company_id: getCompanyId(),
            user_id: getUserId(),
            user_role: getUserRole(),
          })
        },
      },
      {
        onError: (error) => handleError(error),
      }
    )
  }

  const submitItemsData = () => {
    const dataToSend = getDataToSend(initialData, currentOvertimeData)
    if (dataToSend.length !== 0) {
      return overtimeMutation.mutateAsync(
        {
          mutationMethod: 'PUT',
          payrollId,
          items: dataToSend,
        },
        {
          onSuccess: ({ data }) => {
            updatePeriodCallback(data)

            Mixpanel.track(MIXPANEL_EVENTS.ADDED_OVERTIME_AND_SURCHARGES, {
              company_id: getCompanyId(),
              user_id: getUserId(),
              user_role: getUserRole(),
            })
          },
        }
      )
    }
    return null
  }

  const onCloseModal = () => {
    handleClose()
    queryClient.removeQueries(payrollConceptsQueryKey)
    queryClient.removeQueries(overtimeQueryKey)
  }

  const handleSendData = async (form) => {
    const { values } = form
    const { worked_time: workedTime } = values
    let hasValidNewExtraHour = false

    if (activeNewExtraHour) {
      if (validateEmptyNewExtraHour(values)) {
        // if ALL fields are empty, just ignore creating the new extra hour
        // reset form so no validation error message will be shown
        form.resetForm()
      } else if (!form.isValid) {
        // if some field has a valid value
        // set them touched to show validation on other fields
        form.setTouched({ name: true, constant_value: true, quantity: true })
        return
      } else {
        hasValidNewExtraHour = true
      }
    }
    const overtimePromises = []
    let conceptsResponse
    const itemsResponse = submitItemsData()

    if (hasValidNewExtraHour) {
      conceptsResponse = submitNewExtraHour(form)
      overtimePromises.push(conceptsResponse)
    }

    if (itemsResponse) {
      overtimePromises.push(itemsResponse)
    }

    try {
      await Promise.all(overtimePromises)

      if (
        isContractorWithPerHourSalary &&
        workedTime !== workerPayroll.worked_time
      ) {
        await submitWorkedDays(workedTime)
      }

      if (overtimePromises.length > 0) {
        isInvalidating.current = true
        await queryClient.invalidateQueries(payrollConceptsQueryKey, {
          stale: true,
        })
        await queryClient.invalidateQueries(overtimeQueryKey, { stale: true })
      }

      // only when a new hour has been created the modal will not be closed
      if (!(conceptsResponse || (!conceptsResponse && hasValidNewExtraHour))) {
        onCloseModal()
      }
    } catch (error) {
      handleError(error)
    }
  }

  const { extra_hours: extraHours, surcharges, others } = currentOvertimeData
  const isCompanyOvertime = noveltyConcept === 'others'
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))
  const isLaptopUp = useMediaQuery((theme) => theme.breakpoints.up('laptop'))
  const columnsWidth = getColumnsWidth({
    isTabletUp,
    isLaptopUp,
    withDeleteButton: isCompanyOvertime,
  })

  return (
    <Formik
      initialValues={{
        name: '',
        constant_value: '',
        quantity: '',
        worked_time: workerPayroll.worked_time,
      }}
      validationSchema={getValidationSchema(
        activeNewExtraHour,
        isContractorWithPerHourSalary,
        period?.length || 'monthly'
      )}
      enableReinitialize
    >
      {(form) => {
        return (
          <Modal
            open={state.open}
            header={getModalHeader(noveltyConcept, workerName)}
            onOk={() => handleSendData(form)}
            okText="Guardar"
            onCancel={onCloseModal}
            isLoading={isMutatingData}
            paperSx={{
              maxWidth: {
                tablet: '45rem',
                laptop: '48.5rem',
              },
            }}
          >
            <Typography
              paragraph
              sx={(theme) => ({
                marginBottom: theme.spacing(4),
                color: theme.palette.black.dark,
              })}
            >
              {overtimeDescription[noveltyConcept]}
            </Typography>
            <>
              {!isGettingData && !isObjectEmpty(currentOvertimeData) ? (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                    backgroundColor: theme.palette.white.main,
                    borderRadius: 'inherit',
                  })}
                >
                  {!isContractorWithPerHourSalary ? (
                    <CardGrid gridColumns={columnsWidth}>
                      <HeaderRow>
                        <HeaderCell
                          sx={{
                            gridColumn: 1,
                          }}
                          desktopOnly
                        >
                          Concepto
                        </HeaderCell>
                        <HeaderCell
                          sx={{
                            gridColumn: 2,
                          }}
                          desktopOnly
                        >
                          Valor sobre hora ordinaria
                        </HeaderCell>
                        <HeaderCell
                          sx={{
                            gridColumn: 3,
                          }}
                          desktopOnly
                        >
                          # de horas
                        </HeaderCell>
                      </HeaderRow>
                      {noveltyConcept === 'extra_hours' ? (
                        <OvertimeItem
                          category="extra_hours"
                          data={extraHours}
                          onChange={handleChangeItem}
                        />
                      ) : null}
                      {noveltyConcept === 'surcharges' ? (
                        <OvertimeItem
                          category="surcharges"
                          data={surcharges}
                          onChange={handleChangeItem}
                        />
                      ) : null}
                      {noveltyConcept === 'others' ? (
                        <OvertimeItem
                          category="others"
                          data={others}
                          onChange={handleChangeItem}
                          onDeleteItem={handleDeleteItem}
                          activeNewExtraHour={activeNewExtraHour}
                          handleShowAddExtraHour={handleShowAddExtraHour}
                          handleHideExtraHour={handleHideExtraHour}
                          novelty={noveltyConcept}
                        />
                      ) : null}
                    </CardGrid>
                  ) : null}
                  {noveltyConcept === 'others' &&
                  contractCategory === 'contractor' ? (
                    <WorkedHours />
                  ) : null}
                </Box>
              ) : (
                <LoadingBox />
              )}
            </>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default OvertimeModal
