import { useState } from 'react'

import {
  Box,
  Button,
  Chip,
  FormControl,
  Typography,
  alpha,
} from '@mui/material'

export function ModalitySwitch({ onChange, modality, discount = 0 }) {
  const isYearly = modality === 'year'

  return (
    <FormControl
      fullWidth
      sx={{
        display: 'flex',
        alignItems: 'center',
        maxWidth: '14.57rem',
        position: 'relative',
      }}
    >
      <Box
        sx={(theme) => ({
          maxWidth: '14.57rem',
          width: '100%',
          height: '3.75rem',
          backgroundColor: 'accent1.main',
          borderRadius: '1.875rem',
          boxShadow: theme.shadows[3],
          display: 'flex',
          alignItems: 'center',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            userSelect: 'none',
            width: '100%',
            padding: 0,
            margin: 0,
          }}
        >
          <Button
            variant="large"
            onClick={() => onChange('month')}
            sx={{
              backgroundColor: !isYearly ? 'primary.dark' : 'transparent',
              color: !isYearly ? 'white.main' : 'black.main',
              '&:hover': {
                backgroundColor: !isYearly ? 'primary.dark' : 'transparent',
              },
            }}
          >
            Mensual
          </Button>
          <Button
            onClick={() => onChange('year')}
            sx={{
              backgroundColor: isYearly ? 'primary.dark' : 'transparent',
              color: isYearly ? 'white.main' : 'black.main',
              '&:hover': {
                backgroundColor: isYearly ? 'primary.dark' : 'transparent',
              },
            }}
          >
            Anual
          </Button>
        </Box>
        {discount ? (
          <>
            <Chip
              label={
                <Typography variant="small" fontWeight={700}>
                  {`${discount}% DCTO`}
                </Typography>
              }
              size="small"
              sx={(theme) => ({
                position: 'absolute',
                right: '-1%',
                top: '-80%',
                backgroundColor: alpha(theme.palette.info.dark, 0.2),
                boxShadow: theme.shadows[2],
                '& .MuiChip-label': {
                  weight: 600,
                  color: theme.palette.info.dark,
                },
                [theme.breakpoints.up('sm')]: {
                  right: '-33%',
                  top: '-45%',
                },
                [theme.breakpoints.up('laptop')]: {
                  right: '-40%',
                  top: '-45%',
                },
              })}
            />
            <Box
              component="svg"
              width={32}
              height={29}
              fill="none"
              sx={(theme) => ({
                position: 'absolute',
                color: theme.palette.primary.dark,
                top: 0,
                right: '-2.8rem',
                scale: 1.2,
              })}
            >
              <path
                d="M4.525 23.128c.954.778 1.925.696 2.815.236a9.962 9.962 0 0 0 2.197-1.547 19.628 19.628 0 0 0 3.467-4.288l-.996-.823c-.944-.748-1.963-1.42-2.802-2.254-1.478-1.486-1.96-3.342-1.608-5.409.36-2.113 1.938-3.471 4.103-3.45 3.576.04 5.674 2.192 6.062 5.196.11.884.03 1.8-.066 2.697-.072.744-.297 1.465-.48 2.357.422.091.834.279 1.222.229.894-.106 1.85-.157 2.634-.53 4.412-2.094 7.333-5.44 8.193-10.365.195-1.1.278-2.228-.194-3.325-.084-.192.114-.599.3-.773.315-.286.791-.206.992.106.345.557.686 1.175.82 1.806.54 2.614.037 5.123-1.201 7.438-1.258 2.356-3.022 4.303-5.194 5.875-2.287 1.654-4.792 2.486-7.638 2.16-.37-.042-.754-.053-1.3-.092-2.025 2.562-3.89 5.415-7.018 7.352.657.407 1.15.685 1.614.998.418.279.554.697.469 1.183-.086.462-.658.863-1.205.798-.522-.065-1.048-.145-1.541-.322-2.128-.744-4.255-1.514-6.383-2.284a3.395 3.395 0 0 1-.615-.287c-.729-.441-.995-1.238-.518-1.936 1.408-2.044 2.873-4.048 4.317-6.062.124-.178.298-.408.485-.456.343-.084.742-.155 1.047-.032.193.07.38.512.337.755-.085.588-.235 1.181-.492 1.71-.36.743-.843 1.426-1.255 2.144-.193.37-.365.777-.568 1.195Zm9.76-8.107c.923-1.588 1.197-2.901.95-4.203-.34-1.761-1.727-2.863-3.538-2.838-.935.01-1.552.503-1.759 1.421-.289 1.268.032 2.392.888 3.348.906 1.032 2.027 1.752 3.46 2.272Z"
                fill="currentColor"
              />
            </Box>
          </>
        ) : null}
      </Box>
    </FormControl>
  )
}

export function useModalitySwitch({ subscription, plansData }) {
  const [modality, setModality] = useState(subscription?.type || 'month')

  const annualDiscountValue = Math.abs(
    plansData[0]?.subscription_value?.discounts?.yearly_percentage * 100
  )

  const handleChangeModality = (newModality) => {
    setModality(newModality)
  }

  return { modality, handleChangeModality, annualDiscountValue }
}
