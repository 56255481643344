import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { getAllHistoryActions } from 'services/settings/historyService'

import columnsData from './columnsData'

const SettingsHistory = () => {
  const fetchCompanyLogs = (page, search) => {
    return {
      queryKey: ['companyLogs', page, search],
      queryFunction: () => getAllHistoryActions({ page: page + 1, search }),
      queryOptions: {
        onSuccess: () => {
          Mixpanel.track(MIXPANEL_EVENTS.VISIT_SETTINGS_HISTORY_LOGS, {
            company_id: getCompanyId(),
            user_id: getUserId(),
            user_role: getUserRole(),
          })
        },
      },
    }
  }

  return (
    <Page header="Historial de acciones">
      <Table columns={columnsData} data={fetchCompanyLogs} />
    </Page>
  )
}

export default SettingsHistory
