import { Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

const HiringAlertModal = ({ state, handleClose }) => {
  return (
    <Modal
      open={state.open}
      header={state.title}
      onOk={state.action}
      okText={state.actionLabel}
      onCancel={handleClose}
      cancelText="Regresar"
      isLoading={state.isLoading}
      hideCancelButton={state.hideCancelButton}
      paperSx={{
        maxWidth: {
          tablet: '41rem',
        },
      }}
    >
      <Typography variant="body1" color="black.dark">
        {state.description}
      </Typography>
    </Modal>
  )
}

export default HiringAlertModal
