import { useFormikContext } from 'formik'

import FormField from 'components/UI/Formik/FormField/Index'

import { contributorSubtypeOptions } from 'utils/worker'

const ContributorSubtype = ({
  name = 'contributor_subtype',
  contractCategory,
  optional,
  isFromAffiliation = false,
  ...props
}) => {
  const { setFieldValue, values } = useFormikContext()

  const showContributorSubtypeField =
    contractCategory &&
    ['part_time_contract', 'employee'].includes(contractCategory)

  const { entities_to_affiliate: entitiesToAffiliate } = values || {}

  const handleContructorSubtypeChange = ({ target: { value } }) => {
    setFieldValue('contributor_subtype', value)
    if (
      isFromAffiliation &&
      value !== 'no_subtype' &&
      entitiesToAffiliate?.length > 0
    )
      setFieldValue(
        'entities_to_affiliate',
        entitiesToAffiliate.filter((entity) => entity !== 'pension_provider')
      )
  }

  return showContributorSubtypeField ? (
    <FormField
      name={name}
      label="Subtipo de cotizante"
      variant="select"
      options={contributorSubtypeOptions || []}
      placeholder="Seleccione un subtipo..."
      optional={optional}
      onChange={handleContructorSubtypeChange}
      {...props}
    />
  ) : null
}

export default ContributorSubtype
