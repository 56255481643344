import { Navigate, useLocation, useSearchParams } from 'react-router-dom'

import { Box } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Page from 'components/UI/Page/Page'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { useCompanyService } from 'utils/hooks/company/companyService'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { PERIOD_PAY_PAYROLL, WORKER_INDEX } from 'config/routes'
import { DASHBOARD } from 'config/routes'

import { BasicCompanyInfoModal } from '../Modals/BasicCompanyInfoModal'
import { useBasicCompanyInfoModal } from '../Modals/BasicCompanyInfoModal/useBasicCompanyInfoModal'
import UpdateCompanyPhoneModal from '../Modals/UpdateCompanyPhoneModal'
import useUpdateCompanyPhoneModal from '../Modals/UpdateCompanyPhoneModal/useUpdateCompanyPhoneModal'
import { WelcomeModal } from '../Modals/Welcome'
import { useWelcomeModal } from '../Modals/Welcome/useWelcomeModal'
import { IntroHeader } from './Header'
import { StepCard } from './StepCard'
import { getStepsStatuses } from './helpers'

export function OnboardingIntro() {
  const { company, user } = useUser()
  const { welcomeModalState, closeWelcomeModal } = useWelcomeModal()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const {
    updateCompanyPhoneModalState,
    openUpdateCompanyPhoneModal,
    closeUpdateCompanyPhoneModal,
  } = useUpdateCompanyPhoneModal({ company, user })

  const { onboarding_first_steps: onboardingFirstSteps = {} } = company
  const {
    basicCompanyInfoModalState,
    openBasicCompanyInfoModal,
    closeBasicCompanyInfoModal,
  } = useBasicCompanyInfoModal()

  useCompanyService({
    serviceParams: {
      queryKey: ['companyInformation', company?.id],
      searchParams: {
        interactive_tour_finished: true,
      },
    },
    queryOptions: {
      enabled: searchParams.get('interactive_tour_finished') === 'true',
      onSuccess: () => {
        Mixpanel.track(MIXPANEL_EVENTS.INTERACTIVE_TOUR_COMPLETED, {
          user_id: getUserId(),
          company_id: getCompanyId(),
          user_role: getUserRole(),
        })
      },
    },
  })
  const { showWelcomeModal } = location.state || {}
  const { companyDataStatus, firstWorkerStatus, firstPayrollStatus } =
    getStepsStatuses(onboardingFirstSteps)

  if (onboardingFirstSteps?.finished_all_steps) {
    return <Navigate to={DASHBOARD} />
  }

  return (
    <Page documentTitle="Primeros pasos">
      <IntroHeader />
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.spacing(4.5),
        })}
      >
        <Box
          sx={({ spacing, breakpoints }) => ({
            maxWidth: '47rem',
            width: '100%',
            padding: spacing(2.5),
            display: 'grid',
            gridTemplateColumns: '1fr',
            rowGap: spacing(2),
            [breakpoints.down('tablet')]: {
              padding: 0,
            },
          })}
        >
          <StepCard
            stepNumber={1}
            title="Datos básicos empresa"
            description="Agrega los datos de tu empresa y personaliza tu cuenta."
            status={companyDataStatus}
            action={openBasicCompanyInfoModal}
          />
          <StepCard
            stepNumber={2}
            title="Crear personas"
            description="Carga la información de tu equipo y centraliza todo en un solo lugar."
            status={firstWorkerStatus}
            path={WORKER_INDEX('?tab=active')}
          />
          <StepCard
            stepNumber={3}
            title="Liquidar primera nómina"
            description="Realiza tu primera liquidación de nómina ."
            status={firstPayrollStatus}
            path={PERIOD_PAY_PAYROLL()}
          />
          {basicCompanyInfoModalState.open ? (
            <BasicCompanyInfoModal
              state={basicCompanyInfoModalState}
              handleClose={closeBasicCompanyInfoModal}
            />
          ) : null}
          {showWelcomeModal ? (
            <WelcomeModal
              state={welcomeModalState}
              handleClose={closeWelcomeModal}
              company={company}
              openUpdateCompanyPhoneModal={openUpdateCompanyPhoneModal}
            />
          ) : null}
          {updateCompanyPhoneModalState.open ? (
            <UpdateCompanyPhoneModal
              state={updateCompanyPhoneModalState}
              handleClose={closeUpdateCompanyPhoneModal}
            />
          ) : null}
        </Box>
      </Box>
    </Page>
  )
}
