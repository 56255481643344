import { Box } from '@mui/material'

import TotalCard from 'components/UI/Card/TotalCard'

import { formatCurrency } from 'utils/format'

import { decree376PeriodTotalsConfiguration } from './helpers'

const Review = ({ totals }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridColumn: '1 / -1',
        columnGap: theme.spacing(3),
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridAutoRows: 'max-content',
        marginBottom: theme.spacing(8),
        rowGap: theme.spacing(3),
        [theme.breakpoints.up('tablet')]: {
          gridTemplateColumns: 'repeat(6, 1fr)',
          columnGap: theme.spacing(4),
        },
        [theme.breakpoints.up('desktop')]: {
          gridTemplateColumns: 'repeat(12, 1fr)',
        },
      })}
    >
      {Object.entries(totals).map(([totalKey, totalValue]) => (
        <Box
          key={totalKey}
          sx={(theme) => ({
            gridColumn: '1 / -1',
            [theme.breakpoints.up('tablet')]: {
              gridColumn: 'span 3',
            },
            [theme.breakpoints.up('desktop')]: {
              gridColumn: 'span 4',
            },
          })}
        >
          <TotalCard
            title={decree376PeriodTotalsConfiguration[totalKey]?.title}
            value={formatCurrency(totalValue || 0)}
            backgroundColor={
              decree376PeriodTotalsConfiguration[totalKey]?.backgroundColor
            }
          />
        </Box>
      ))}
    </Box>
  )
}

export default Review
