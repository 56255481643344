import { Box, Button, useMediaQuery } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import { useCancelSubscriptionModal } from 'components/Subscription/Atoms/CancelSubscription/useCancelSubscriptionModal'
import { useConfirmOldPlansCancellation } from 'components/Subscription/Atoms/CancelSubscription/useConfirmOldPlansCancellation'
import Icon from 'components/UI/Icon'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { isFreeCompany } from 'utils/auth'

import { WHATSAPP_SALES } from 'config/organization'

import { ModalitySwitch, useModalitySwitch } from '../../Atoms/ModalitySwitch'
import { PlansContainer } from '../../Atoms/PlanUIComponents'
import useSubscription from '../../Atoms/useSubscription'
import { getPlansFormatted, usePlansData } from '../../helpers'
import SubscriptionCardsContent from './SubscriptionCardsContent'

const SubscriptionCardsModal = ({ isPlanBaseAliados }) => {
  const modals = useModals()
  const { subscription, isCompanyUsing20241111Plans } = useSubscription()
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { openConfirmOldPlansCancellation } = useConfirmOldPlansCancellation()
  const { openCancelSubscriptionModal } = useCancelSubscriptionModal()

  const { plansData, isPlansQueryLoading } = usePlansData()
  const { modality, handleChangeModality, annualDiscountValue } =
    useModalitySwitch({ subscription, plansData })

  const plansFormatted = getPlansFormatted({
    plansData,
    modality,
  })

  const freeUserButtonText = xsDown
    ? 'Conversar sobre planes'
    : 'Conversar sobre los planes y suscripciones'

  if (isPlansQueryLoading) {
    return <LoadingBox />
  }

  const {
    active_until: activeUntil,
    status,
    payment_status: paymentStatus,
    partner: isPartner,
  } = subscription || {}

  const isAFreeCompany = isFreeCompany(status, paymentStatus)

  const currentPlan = plansData?.find((plan) => plan?.current_plan)
  const currentPlanIndex = plansData.indexOf(currentPlan)

  const handleContinueCancellation = () => {
    modals.closeModal('subscriptionCardsModal')

    const handleOpenCancelSubscriptionModal = () => {
      openCancelSubscriptionModal({ activeUntil })
    }

    if (!isCompanyUsing20241111Plans) {
      openConfirmOldPlansCancellation({
        handleOpenCancelSubscriptionModal,
      })
    } else {
      handleOpenCancelSubscriptionModal()
    }
  }

  return (
    <Box
      sx={(theme) =>
        isPlanBaseAliados
          ? {
              margin: theme.spacing(9, 0),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }
          : {}
      }
    >
      {!isPartner ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 0, 2),
          })}
        >
          <ModalitySwitch
            onChange={handleChangeModality}
            modality={modality}
            discount={annualDiscountValue}
          />
        </Box>
      ) : null}

      <PlansContainer
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          columnGap: theme.spacing(3),
          rowGap: theme.spacing(4),
          ...(isPartner && {
            boxShadow: 'none',
            '[data-current-plan="true"]': { marginTop: 0 },
          }),
          [theme.breakpoints.up('tablet')]: {
            columnGap: theme.spacing(4),
            alignContent: 'center',
            rowGap: theme.spacing(8),
          },
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: isPartner ? '1fr' : 'repeat(2, 1fr)',
          },
          [theme.breakpoints.up('laptop')]: {
            gridTemplateColumns: isPartner
              ? '1fr'
              : `repeat(${plansFormatted.length}, 1fr)`,
          },
          [theme.breakpoints.between('sm', 'laptop')]: {
            '& div:last-child': {
              gridColumn: 'span 2',
              justifySelf: 'center',
            },
          },
        })}
      >
        {plansFormatted.map((plan, index) => {
          return (
            <SubscriptionCardsContent
              key={plan.id}
              planData={plan}
              isAFreeCompany={isAFreeCompany}
              selectedModality={modality}
              selectedPlanIndex={index}
              currentPlanIndex={currentPlanIndex}
              currentPlanModality={subscription.type}
              plansData={plansData}
              isPlanBaseAliados={isPlanBaseAliados}
            />
          )
        })}
      </PlansContainer>
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          columnGap: theme.spacing(3),
          margin: theme.spacing(7, 0, 0),
          [theme.breakpoints.down(isAFreeCompany ? 'tablet' : 'laptop')]: {
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: theme.spacing(3),
          },
        })}
      >
        {plansData.length > 1 ? (
          <>
            <Button
              variant="outlined"
              href={WHATSAPP_SALES}
              target="_blank"
              endIcon={<Icon name="whatsapp" />}
              sx={{ width: '100%', maxWidth: '27rem' }}
            >
              {!isAFreeCompany
                ? 'Conversar sobre tu suscripción'
                : freeUserButtonText}
            </Button>
          </>
        ) : null}

        {!isAFreeCompany && status !== 'premium_canceled' ? (
          <Button
            onClick={handleContinueCancellation}
            variant="outlined"
            endIcon={<Icon name="circle-x" />}
            sx={{ width: '100%', maxWidth: '27rem' }}
          >
            Cancelar suscripción
          </Button>
        ) : null}
      </Box>
    </Box>
  )
}

export default SubscriptionCardsModal
