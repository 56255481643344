import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import useTransactionResponseModal from 'components/Subscription/Atoms/TransactionResponseModal/useTransactionResponseModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import { PLANS_BY_CODED_NAME } from 'components/Subscription/helpers'
import Button from 'components/UI/Button/Button'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'
import Stepper from 'components/UI/Stepper'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { getWorkerDirtyValues } from '../../../Form/helpers'
import BasicFields from './StepsForm/BasicFormFields'
import LaborFields from './StepsForm/LaborFormFields'
import PaymentFields from './StepsForm/PaymentFormFields'
import SocialSecurityFields from './StepsForm/SocialSecurityFormFields'
import { getInitialValues, stepsData } from './helpers'

const NewWorkerModal = ({
  state,
  handleClose,
  openFirstWorkerAddedModal,
  setTab,
}) => {
  const { showHRFeatures } = usePremiumFeature()
  const navigate = useNavigate()
  const [currentWorker, setCurrentWorker] = useState({})
  const [currentStep, setCurrentStep] = useState(0)
  const [progressStep, setProgressStep] = useState(0)

  const [showTransactionResponseModal, setShowTransactionResponseModal] =
    useState(false)

  const { company } = useUser()
  const { subscription } = useSubscription()
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const { openTransactionResponseModal } = useTransactionResponseModal()

  const { workerId, workerName } = state
  const workerQueryKey = ['getWorkerById', workerId]

  const isAnUpgradeToPayrollOnlyPlan =
    subscription.plan.coded_name ===
      PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan &&
    subscription.payrolls_size === 3

  const { workerQuery, workerMutation } = useWorkerService({
    serviceParams: { queryKey: workerQueryKey, workerId },
    queryOptions: {
      enabled: Boolean(workerId),
    },
  })

  const initialValues = getInitialValues({
    worker: !workerId ? currentWorker : workerQuery.data,
    currentStep,
  })

  const handleClickStep = (index) => {
    setCurrentStep(index)
  }

  const handlePreviousStep = () => {
    setCurrentStep((previous) => previous - 1)
  }

  const callbackError = (error, form) => {
    handleError(error, form, {
      errorsToNotificate: [
        { object: 'worker' },
        { object: 'id_number' },
        { object: 'email' },
        // error returned when a user update the end_date of a contract but it can't
        // be done because the deductions exceed the value to be paid to the worker
        { object: 'worker_payment' },
      ],
      errorFieldMap: { user: 'email' },
    })

    form.setSubmitting(false)
  }

  const resetModalState = () => {
    setCurrentStep(0)
    setProgressStep(0)
    setCurrentWorker({})
  }

  const closeModal = async ({ showFirstWorkerAddedModal }) => {
    resetModalState()
    handleClose()

    if (showFirstWorkerAddedModal) {
      await queryClient.invalidateQueries([
        'companyInformation',
        getCompanyId(),
      ])

      openFirstWorkerAddedModal()
    }

    if (showTransactionResponseModal) {
      openTransactionResponseModal({
        subscriptionTypeValidators: { isPremiumExpiredSubscription: false },
        downgradeData: false,
      })
    }
  }

  const removeSocialSecurityStep = [
    'contractor',
    'student_decree_055',
  ].includes(
    workerQuery?.data?.contract_category || currentWorker?.contract_category
  )

  const callbackSuccess = async (response, isEditing) => {
    queryClient.invalidateQueries(['getSubscription', getCompanyId()])

    if (currentStep === 1 && isAnUpgradeToPayrollOnlyPlan) {
      setShowTransactionResponseModal(true)
    }

    setCurrentWorker({ ...response, id: response?.id || workerId })
    await queryClient.invalidateQueries(workerQueryKey, { exact: false })

    if (currentStep < stepsData.length - (removeSocialSecurityStep ? 2 : 1)) {
      setCurrentStep((previousStep) => previousStep + 1)

      if (currentStep === progressStep) {
        setProgressStep((previousStep) => previousStep + 1)
      }

      // if it's the first step and it's creating a new worker
      if (currentStep === 0 && !isEditing) {
        showSuccessMessage('La persona fue creada exitosamente')
      }
    } else {
      if (
        // TODO: make navigation to '?tab=new' for all contracts once
        // API sends all workers in /worker_onboardings endpoint
        [
          workerQuery?.data?.contract_category,
          currentWorker?.contract_category,
        ].includes('employee') &&
        showHRFeatures
      ) {
        navigate('?tab=new', {
          state: {
            addWorkerManually: true,
          },
        })

        setTab('new')
      }

      await queryClient.invalidateQueries(['getCompanyOnboardings'], {
        exact: false,
      })

      await queryClient.invalidateQueries(['filteredWorkers'], { exact: false })

      closeModal({
        showFirstWorkerAddedModal:
          (currentStep === 3 ||
            (removeSocialSecurityStep && currentStep === 2)) &&
          !company.onboarding_first_steps.first_worker,
      })
    }
  }

  const handleSubmit = async (values, form) => {
    const isEditing =
      (!workerId && Boolean(currentWorker.id)) || Boolean(workerId)

    const { dirtyWorker } = getWorkerDirtyValues(
      initialValues,
      values,
      isEditing
    )

    if (!isObjectEmpty(dirtyWorker)) {
      if (isEditing) {
        workerMutation.mutate(
          {
            mutationMethod: 'PATCH',
            worker: dirtyWorker,
            workerId: workerId || currentWorker.id,
          },
          {
            onSuccess: async ({ data: response }) => {
              await callbackSuccess(response, isEditing)
            },
            onError: (error) => {
              callbackError(error, form)
            },
          }
        )
      } else {
        Object.assign(dirtyWorker, {
          fullName: `${dirtyWorker.name} ${dirtyWorker.last_name}`,
        })

        workerMutation.mutate(
          {
            mutationMethod: 'POST',
            worker: { worker: dirtyWorker },
          },
          {
            onSuccess: async ({ data: response }) => {
              Mixpanel.track(MIXPANEL_EVENTS.ADDED_NEW_EMPLOYEE, {
                user_id: getUserId(),
                user_role: getUserRole(),
                company_id: getCompanyId(),
              })

              await callbackSuccess(response)
            },
            onError: (error) => {
              callbackError(error, form)
            },
          }
        )
      }
    } else {
      // Handles an edge case where a user creates a worker with a contract
      // that allows adding payment information without closing the modal.
      // If the user then changes the contract to one that does not require social security,
      // the process only takes three steps to complete and since the last step is already
      // completed, we need to close the modal.
      if (currentStep === 2 && removeSocialSecurityStep) {
        if (!company.onboarding_first_steps.first_worker) {
          closeModal({
            showFirstWorkerAddedModal: true,
          })

          await queryClient.invalidateQueries(['filteredWorkers'], {
            exact: false,
          })
        } else {
          closeModal()
        }

        await queryClient.invalidateQueries([
          'companyInformation',
          getCompanyId(),
        ])

        return
      }

      setCurrentStep((previousStep) => previousStep + 1)

      if (currentStep === progressStep) {
        setProgressStep((previousStep) => previousStep + 1)
      }
    }
  }

  const handleCloseBeforeShowingPlanChange = () => {
    if (showTransactionResponseModal) {
      openTransactionResponseModal({
        subscriptionTypeValidators: { isPremiumExpiredSubscription: false },
        downgradeData: false,
      })
    }
    handleClose()
  }

  return (
    <Modal
      open={state.open}
      onCancel={handleCloseBeforeShowingPlanChange}
      header={
        !workerName
          ? 'Crear nueva Persona'
          : `Completar el registro de ${workerName}`
      }
      hideFooter
      isLoading={workerMutation.isLoading}
      paperSx={{
        maxWidth: {
          tablet: '45.5rem',
        },
      }}
      dialogProps={{
        TransitionProps: {
          onExited: resetModalState,
        },
      }}
    >
      <Box>
        <Typography variant="body1" color="black.dark">
          La información de la persona será utilizada para ayudarte a generar la
          nómina más rápida que has visto. Recuerda que siempre podrás regresar
          a editar cualquier valor.
        </Typography>
        {workerQuery.isLoading ? (
          <LoadingBox />
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={stepsData[currentStep]?.schemaValidation}
            enableReinitialize
          >
            {(form) => {
              const { handleSubmit: onSubmitForm, values } = form

              const removeSocialSecurityStep =
                ['contractor', 'student_decree_055'].includes(
                  values?.contract_category
                ) ||
                ((['contractor', 'student_decree_055'].includes(
                  currentWorker?.contract_category
                ) ||
                  ['contractor', 'student_decree_055'].includes(
                    workerQuery?.data?.contract_category
                  )) &&
                  !values?.contract_category)

              return (
                <>
                  <Box
                    sx={(theme) => ({
                      marginY: theme.spacing(3),
                    })}
                  >
                    <Stepper
                      stepsData={
                        removeSocialSecurityStep
                          ? stepsData.slice(0, -1)
                          : stepsData
                      }
                      current={currentStep}
                      progress={progressStep}
                      onChangeStep={handleClickStep}
                    />
                  </Box>
                  <Form>
                    {currentStep === 0 ? <BasicFields /> : null}
                    {currentStep === 1 ? (
                      !('contract_category' in (values || currentWorker)) ? (
                        <LoadingBox />
                      ) : (
                        <LaborFields isCreatingWorker />
                      )
                    ) : null}
                    {currentStep === 2 ? (
                      !('payment_method' in (values || currentWorker)) ? (
                        <LoadingBox />
                      ) : (
                        <PaymentFields />
                      )
                    ) : null}
                    {currentStep === 3 ? (
                      !('health_provider' in (values || currentWorker)) ? (
                        <LoadingBox />
                      ) : (
                        <SocialSecurityFields
                          worker={workerQuery?.data || currentWorker}
                        />
                      )
                    ) : null}
                  </Form>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      gap: theme.spacing(2),
                      marginTop: theme.spacing(6),
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      [theme.breakpoints.up('tablet')]: {
                        justifyContent: 'flex-start',
                      },
                    })}
                  >
                    <Button
                      onClick={() => {
                        onSubmitForm()
                      }}
                      loading={workerMutation.isLoading}
                      disabled={workerMutation.isLoading}
                    >
                      {currentStep === 3 ||
                      (currentStep === 2 && removeSocialSecurityStep)
                        ? 'Finalizar'
                        : 'Guardar y continuar'}
                    </Button>
                    {currentStep === 0 ? (
                      <Button
                        variant="outlined"
                        onClick={handleCloseBeforeShowingPlanChange}
                        disabled={workerMutation.isLoading}
                      >
                        Cancelar
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={handlePreviousStep}
                        disabled={workerMutation.isLoading}
                      >
                        Regresar
                      </Button>
                    )}
                  </Box>
                </>
              )
            }}
          </Formik>
        )}
      </Box>
    </Modal>
  )
}

export default NewWorkerModal
