import { klona } from 'klona'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { useBreadcrumbsConfig } from 'components/App/BreadcrumbsProvider'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import { formatPeriodDateRange } from 'utils/dateTime'
import { isObjectEmpty } from 'utils/general'
import usePeriodService from 'utils/hooks/payroll/periodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import formatPeriod from 'services/payroll/helpers'
import periodService from 'services/payroll/periodService'

import * as routes from 'config/routes'

import DescriptionCardHistory from '../Atoms/DescriptionCardHistory/Index'
import PayrollActions from './ActionGroup/Actions'
import DropDownPeriod from './DropDownPeriod'
import AccountingFileModal from './Modals/AccountingFileModal'
import BankFileModal from './Modals/BankFileModal'
import DeductionsModal from './Modals/Deductions'
import InactivePeriodEditingModal from './Modals/InactivePeriodEditingModal'
import IncomeModal from './Modals/Income'
import NoveltiesModal from './Modals/Novelties'
import NoveltyTypeModal from './Modals/NoveltyType'
import OvertimeModal from './Modals/Overtime'
import PayrollSummaryFileModal from './Modals/PayrollSummaryFileModal'
import PayslipModal from './Modals/PayslipModal'
import SeveranceFileModal from './Modals/SeveranceFileModal'
import SinglePayslipModal from './Modals/SinglePayslipModal'
import WorkedDaysModal from './Modals/WorkedDaysModal'
import useAccountingFileModal from './Modals/useAccountingFileModal'
import useBankFileModal from './Modals/useBankFileModal'
import useDeductionsModal from './Modals/useDeductionsModal'
import useInactivePeriodEditingModal from './Modals/useInactivePeriodEditingModal'
import useIncomeModal from './Modals/useIncomeModal'
import useNoveltiesModal from './Modals/useNoveltiesModal'
import useNoveltyTypeModal from './Modals/useNoveltyTypeModal'
import useOvertimeModal from './Modals/useOvertimeModal'
import usePayrollConfiguration, {
  PAYROLL_ACTION_TYPES,
} from './Modals/usePayrollConfiguration'
import usePayrollSummaryFileModal from './Modals/usePayrollSummaryFileModal'
import usePayslipModal from './Modals/usePayslipModal'
import useSeveranceFileModal from './Modals/useSeveranceFileModal'
import useSinglePayslipModal from './Modals/useSinglePayslipModal'
import useWorkedDaysModal from './Modals/useWorkedDaysModal'
import PeriodTotals from './PeriodTotals'
import {
  NoveltyTypeContext,
  PeriodAPIContext,
  PeriodContext,
  getPaidSocialBenefits,
  getSeveranceDescription,
} from './helpers'

const PayrollContent = ({
  payPreviousPayroll,
  payPayrollContent,
  generatedPayrollContent,
}) => {
  const { periodId } = useParams()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [showContent, setShowContent] = useState(false)
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false)
  const { handleError } = useErrorHandler()
  const { setBreadcrumbConfig } = useBreadcrumbsConfig()
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const companyId = getCompanyId()
  const [payrollState, payrollDispatch] = usePayrollConfiguration()
  const hasEarlyPayment = useRef(false)
  const [activeContract, setActiveContract] = useState('')
  const [activeLocation, setActiveLocation] = useState('')

  const payrollsQueryKey = useMemo(
    () => ['periodPayrolls', companyId],
    [companyId]
  )

  const periodQueryKey = useMemo(
    () => [
      payPreviousPayroll ? 'periodById' : 'currentPeriod',
      companyId,
      ...(periodId ? [periodId] : []),
    ],
    [companyId, payPreviousPayroll, periodId]
  )

  const { periodQuery } = usePeriodService({
    serviceParams: {
      queryKey: periodQueryKey,
      periodId,
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        let response
        if (data.contracts_counter) {
          Object.keys(data.contracts_counter).some((key) => {
            if (data?.contracts_counter[key] > 0) {
              response = key
              return true
            }
            return false
          })
        }
        setActiveContract(response)
      },
    },
  })

  const { periodQuery: periodConfigurationQuery } = usePeriodService({
    serviceParams: {
      queryKey: ['periodConfiguration', companyId, periodQuery.data?.id],
      periodId: periodQuery.data?.id,
    },
    queryOptions: {
      enabled:
        periodQuery.isSuccess &&
        (Boolean(periodId) || Boolean(periodQuery.data?.id)),
      onSuccess: () => {
        if (!payPreviousPayroll) {
          if (
            periodQuery?.data?.payrolls_paid &&
            pathname !== routes.PERIOD_GENERATED_PAYROLL()
          )
            navigate(routes.PERIOD_GENERATED_PAYROLL())
          if (
            !periodQuery?.data?.payrolls_paid &&
            pathname !== routes.PERIOD_PAY_PAYROLL()
          )
            navigate(routes.PERIOD_PAY_PAYROLL())
        }
        setShowContent(true)
      },
    },
  })

  const period = useMemo(() => {
    return formatPeriod(periodQuery.data || {})
  }, [periodQuery.data])

  const {
    periodQuery: { data: locationQueryData },
  } = usePeriodService({
    serviceParams: {
      queryKey: ['locationsByPeriod', companyId],
      periodId: period.id,
    },
    queryOptions: {
      enabled: Boolean(period.id),
    },
  })

  const locations = useMemo(
    () => locationQueryData?.locations || [],
    [locationQueryData]
  )

  const options = useMemo(
    () => periodConfigurationQuery.data || {},
    [periodConfigurationQuery.data]
  )
  const { initial_day: initialDay, end_day: endDay } = period
  const isGeneratedPayroll = pathname.includes('generated_payroll')
  const severanceDescription = getSeveranceDescription(options.social_benefits)

  const { noveltyTypeModalState, openNoveltyTypeModal, closeNoveltyTypeModal } =
    useNoveltyTypeModal({ payrollState, payrollDispatch })
  const { workedDaysModalState, openWorkedDaysModal, closeWorkedDaysModal } =
    useWorkedDaysModal({ payrollState, payrollDispatch })
  const { overtimeModalState, openOvertimeModal, closeOvertimeModal } =
    useOvertimeModal({ payrollState, payrollDispatch })
  const { noveltiesModalState, openNoveltiesModal, closeNoveltiesModal } =
    useNoveltiesModal({ payrollState, payrollDispatch })
  const { incomeModalState, openIncomeModal, closeIncomeModal } =
    useIncomeModal({ payrollState, payrollDispatch })
  const { deductionsModalState, openDeductionsModal, closeDeductionsModal } =
    useDeductionsModal({ payrollState, payrollDispatch })

  const {
    payslipModalState,
    openPayslipModal,
    closePayslipModal,
    handleDownloadPayslip,
  } = usePayslipModal(period, severanceDescription)
  const {
    singlePayslipModalState,
    openSinglePayslipModal,
    closeSinglePayslipModal,
  } = useSinglePayslipModal()
  const { bankFileModalState, openBankFileModal, closeBankFileModal } =
    useBankFileModal(period)
  const {
    severanceFileModalState,
    openSeveranceFileModal,
    closeSeveranceFileModal,
  } = useSeveranceFileModal()
  const {
    accountingFileModalState,
    openAccountingFileModal,
    closeAccountingFileModal,
  } = useAccountingFileModal(period)
  const {
    payrollSummaryFileState,
    openPayrollSummaryFileModal,
    closePayrollSummaryFileModal,
  } = usePayrollSummaryFileModal(period)
  const {
    inactivePeriodEditingModalState,
    openInactivePeriodEditingModal,
    closeInactivePeriodEditingModal,
  } = useInactivePeriodEditingModal()

  const updatePeriodCallback = useCallback(
    (updatedData) => {
      if (!updatedData) return

      if (updatedData?.payroll?.early_payment_type !== 'no_early_payment') {
        hasEarlyPayment.current = true
      }

      queryClient.setQueryData(periodQueryKey, (oldPeriod) => {
        return {
          data: {
            ...oldPeriod?.data,
            ...updatedData?.period,
          },
        }
      })

      queryClient.setQueriesData(
        {
          queryKey: payrollsQueryKey,
          exact: false,
          active: true,
        },
        (oldPeriod) => {
          const payrollItemIndex = oldPeriod?.data?.payrolls?.findIndex?.(
            (item) => item?.id === updatedData?.payroll?.id
          )
          const payrollsCopy = klona(oldPeriod?.data?.payrolls)

          // eslint-disable-next-line no-underscore-dangle
          if (!updatedData.payroll?.__deleted__) {
            payrollsCopy[payrollItemIndex] = {
              ...payrollsCopy[payrollItemIndex],
              ...updatedData.payroll,
              details: {
                ...updatedData.details,
              },
            }
          } else {
            payrollsCopy?.splice(payrollItemIndex, 1)
          }

          return {
            ...oldPeriod,
            data: {
              ...oldPeriod.data,
              payrolls: payrollsCopy,
            },
          }
        }
      )

      payrollDispatch({
        type: PAYROLL_ACTION_TYPES.UPDATE_MODAL_CONFIGURATION,
        config: {
          updatedData,
        },
      })
    },
    [payrollDispatch, payrollsQueryKey, periodQueryKey, queryClient]
  )

  useEffect(() => {
    const updatePayrollView = async () => {
      if (payPreviousPayroll) {
        showLoadingModal()
        try {
          await periodService.updateCurrent({ payrolls_paid: false })
        } catch (error) {
          handleError(error)
        }
        hideLoadingModal()
      }
    }

    if (!payPreviousPayroll) {
      setBreadcrumbConfig('/periods_history/*', {
        onClick: () => updatePayrollView(),
      })
    } else {
      setBreadcrumbConfig('/pay_payroll', {
        onClick: () => updatePayrollView(),
      })
    }
  }, [
    handleError,
    hideLoadingModal,
    payPreviousPayroll,
    setBreadcrumbConfig,
    showLoadingModal,
  ])

  const handleToggleLoadingModal = useCallback((value) => {
    setIsLoadingModalOpen(value)
  }, [])

  const currentPayrollTitle = isGeneratedPayroll
    ? 'Resumen de nómina'
    : 'Liquidar nómina'

  const getTitle = () => {
    const baseRange =
      initialDay && endDay ? formatPeriodDateRange(initialDay, endDay) : ''

    if (isGeneratedPayroll) return `Resumen periodo ${baseRange}`
    if (payPreviousPayroll) return `Periodo ${baseRange}`

    return `Nómina del periodo ${baseRange}`
  }

  const renderDescriptionCardHistory = () => {
    const paidSocialBenefits = getPaidSocialBenefits(options?.social_benefits)

    if (
      paidSocialBenefits?.length === 0 ||
      pathname !== routes.PERIOD_PAY_PREVIOUS_PAYROLL(periodId)
    ) {
      return null
    }

    if (paidSocialBenefits && paidSocialBenefits?.length > 0) {
      return (
        <Box
          sx={(theme) => ({
            gridColumn: '1/-1',
            marginBottom: theme.spacing(11.5),
          })}
        >
          <DescriptionCardHistory paidSocialBenefits={paidSocialBenefits} />
        </Box>
      )
    }

    return null
  }

  const periodContextValue = useMemo(
    () => ({
      period,
      options,
      payPreviousPayroll,
      isGeneratedPayroll,
      hasEarlyPayment: hasEarlyPayment.current,
      locations,
      activeLocation,
      activeContract,
    }),
    [
      locations,
      isGeneratedPayroll,
      options,
      payPreviousPayroll,
      period,
      activeLocation,
      activeContract,
    ]
  )

  const noveltyTypeContextValue = useMemo(
    () => ({
      payrollState,
    }),
    [payrollState]
  )

  const periodAPIContextValue = useMemo(
    () => ({
      openPayslipModal,
      openSinglePayslipModal,
      openBankFileModal,
      openSeveranceFileModal,
      openAccountingFileModal,
      handleToggleLoadingModal,
      updatePeriodCallback,
      openPayrollSummaryFileModal,
      openNoveltyTypeModal,
      openWorkedDaysModal,
      openOvertimeModal,
      openNoveltiesModal,
      openIncomeModal,
      openDeductionsModal,
      openInactivePeriodEditingModal,
      setActiveLocation,
      setActiveContract,
    }),
    [
      handleToggleLoadingModal,
      openAccountingFileModal,
      openBankFileModal,
      openDeductionsModal,
      openInactivePeriodEditingModal,
      openIncomeModal,
      openNoveltiesModal,
      openNoveltyTypeModal,
      openOvertimeModal,
      openPayrollSummaryFileModal,
      openPayslipModal,
      openSeveranceFileModal,
      openSinglePayslipModal,
      openWorkedDaysModal,
      updatePeriodCallback,
    ]
  )

  return (
    <PeriodContext.Provider value={periodContextValue}>
      <NoveltyTypeContext.Provider value={noveltyTypeContextValue}>
        <PeriodAPIContext.Provider value={periodAPIContextValue}>
          <Page
            header={
              (payPreviousPayroll ||
                (!payPreviousPayroll && !isGeneratedPayroll)) &&
              showContent ? (
                <>
                  <Typography
                    variant="h2"
                    sx={(theme) => ({
                      gridColumn: '1 / -1',
                      [theme.breakpoints.up('tablet')]: {
                        gridColumn: 'span 4',
                      },
                      [theme.breakpoints.up('laptop')]: {
                        gridColumn: 'span 8',
                      },
                    })}
                  >
                    {getTitle()}
                  </Typography>
                  {!!period && !isObjectEmpty(period) ? (
                    <Box
                      sx={(theme) => ({
                        gridColumn: '-1 / 1',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        [theme.breakpoints.up('tablet')]: {
                          gridColumn: '-1 / -3',
                        },
                        [theme.breakpoints.up('laptop')]: {
                          gridColumn: '-1 / -4',
                        },
                      })}
                    >
                      <DropDownPeriod
                        period={period}
                        generatedPayroll={isGeneratedPayroll}
                      />
                    </Box>
                  ) : null}
                </>
              ) : null
            }
            documentTitle={
              payPreviousPayroll ? getTitle() : currentPayrollTitle
            }
            isLoadingWithModal={isLoadingModalOpen}
            isLoading={!showContent || !periodQuery.data}
            grid
          >
            {renderDescriptionCardHistory()}
            {payPreviousPayroll ? <PeriodTotals currentView="payroll" /> : null}
            {payPreviousPayroll && !isGeneratedPayroll ? (
              <PayrollActions />
            ) : null}
            <Box
              sx={{
                gridColumn: '1 / -1',
              }}
            >
              {isGeneratedPayroll ? (
                generatedPayrollContent
              ) : (
                <>
                  {payPreviousPayroll ? (
                    <Typography
                      variant="h2"
                      sx={(theme) => ({
                        marginBottom: theme.spacing(4),
                      })}
                    >
                      Reporta o edita las novedades de este periodo
                    </Typography>
                  ) : null}
                  {!isGeneratedPayroll ? payPayrollContent : null}
                </>
              )}
            </Box>
            {/** Select novelty type modal */}
            {noveltyTypeModalState.open ? (
              <NoveltyTypeModal
                state={noveltyTypeModalState}
                handleClose={closeNoveltyTypeModal}
              />
            ) : null}
            {/** Worked days modal */}
            {workedDaysModalState.open ? (
              <WorkedDaysModal
                state={workedDaysModalState}
                handleClose={closeWorkedDaysModal}
              />
            ) : null}
            {/** Overtime modal */}
            {overtimeModalState.open ? (
              <OvertimeModal
                state={overtimeModalState}
                handleClose={closeOvertimeModal}
              />
            ) : null}
            {/** Novelties modal */}
            {noveltiesModalState.open ? (
              <NoveltiesModal
                state={noveltiesModalState}
                handleClose={closeNoveltiesModal}
              />
            ) : null}
            {/** Income modal */}
            {incomeModalState.open ? (
              <IncomeModal
                state={incomeModalState}
                handleClose={closeIncomeModal}
              />
            ) : null}
            {/** Deductions modal */}
            {deductionsModalState.open ? (
              <DeductionsModal
                state={deductionsModalState}
                handleClose={closeDeductionsModal}
              />
            ) : null}
            {payslipModalState.open ? (
              <PayslipModal
                state={payslipModalState}
                handleClose={closePayslipModal}
                handleDownloadPayslip={handleDownloadPayslip}
                severanceDescription={severanceDescription}
              />
            ) : null}
            {singlePayslipModalState.open ? (
              <SinglePayslipModal
                state={singlePayslipModalState}
                handleClose={closeSinglePayslipModal}
                severanceDescription={severanceDescription}
              />
            ) : null}
            {bankFileModalState.open ? (
              <BankFileModal
                state={bankFileModalState}
                handleClose={closeBankFileModal}
              />
            ) : null}
            {severanceFileModalState.open ? (
              <SeveranceFileModal
                state={severanceFileModalState}
                handleClose={closeSeveranceFileModal}
                period={period}
              />
            ) : null}
            {accountingFileModalState.open ? (
              <AccountingFileModal
                state={accountingFileModalState}
                handleClose={closeAccountingFileModal}
              />
            ) : null}
            {payrollSummaryFileState.open ? (
              <PayrollSummaryFileModal
                state={payrollSummaryFileState}
                handleClose={closePayrollSummaryFileModal}
              />
            ) : null}
            {inactivePeriodEditingModalState.open ? (
              <InactivePeriodEditingModal
                state={inactivePeriodEditingModalState}
                handleClose={closeInactivePeriodEditingModal}
              />
            ) : null}
          </Page>
        </PeriodAPIContext.Provider>
      </NoveltyTypeContext.Provider>
    </PeriodContext.Provider>
  )
}

export default PayrollContent
