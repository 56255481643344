const ALELUYA_COLORS = {
  PRIMARY: {
    dark: 'rgb(154, 61, 224)',
    main: 'rgb(185, 114, 240)',
    light: 'rgb(197, 139, 245)',
    300: 'rgb(219, 184, 250)',
    200: 'rgb(235, 215, 253)',
    100: 'rgb(244, 233, 254)',
  },
  COMPLEMENTARY_1: {
    dark: 'rgb(28, 229, 194)',
    main: 'rgb(71, 248, 211)',
    light: 'rgb(143, 255, 228)',
    300: 'rgb(199, 255, 241)',
  },
  COMPLEMENTARY_2: {
    dark: 'rgb(251, 225, 73)',
    main: 'rgb(253, 242, 153)',
    light: 'rgb(253, 249, 196)',
    300: 'rgb(254, 252, 232)',
  },
  ACCENT_1: {
    dark: 'rgb(184, 179, 167)',
    main: 'rgb(248, 242, 225)',
    light: 'rgb(247, 247, 237)',
  },
  ACCENT_2: {
    dark: 'rgb(194, 80, 39)',
    main: 'rgb(244, 101, 50)',
    light: 'rgb(247, 156, 123)',
  },
  ERROR: {
    dark: 'rgb(236, 45, 48)',
    main: 'rgb(246, 76, 76)',
    light: 'rgb(255, 204, 210)',
    500: 'rgb(235, 111, 112)',
    400: 'rgb(244, 152, 152)',
    200: 'rgb(255, 235, 238)',
  },
  SUCCESS: {
    dark: 'rgb(12, 157, 97)',
    main: 'rgb(71, 184, 129)',
    light: 'rgb(192, 229, 209)',
    500: 'rgb(107, 196, 151)',
    400: 'rgb(151, 212, 180)',
    200: 'rgb(215, 244, 225)',
  },
  INFO: {
    dark: 'rgb(58, 112, 226)',
    main: 'rgb(59, 130, 246)',
    light: 'rgb(228, 242, 255)',
    500: 'rgb(75, 161, 255)',
    400: 'rgb(147, 200, 255)',
    200: 'rgb(241, 248, 255)',
  },
  WARNING: {
    dark: 'rgb(236, 131, 20)',
    main: 'rgb(243, 164, 43)',
    light: 'rgb(252, 236, 201)',
    500: 'rgb(246, 189, 83)',
    400: 'rgb(249, 215, 142)',
    200: 'rgb(254, 249, 236)',
  },
  WHITE: {
    dark: 'rgb(136, 136, 136)',
    main: 'rgb(255, 255, 255)',
    light: 'rgb(224, 225, 225)',
  },
  BLACK: {
    dark: 'rgb(69, 69, 69)',
    main: 'rgb(30, 30, 30)',
    light: 'rgb(93, 93, 93)',
  },
}

export default ALELUYA_COLORS
