import { Box } from '@mui/material'

import Avatar from 'components/UI/Avatar'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

export const getColumnsData = ({ openNewWorkerModal, tab }) => [
  {
    Header: 'Personas',
    accessor: 'name',
    Cell: ({ row }) => {
      const avatarId = (row.index % 5) + 1

      return (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            paddingY: theme.spacing(2),
            gap: theme.spacing(1),
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(0.5),
            })}
          >
            <Avatar
              avatarId={avatarId}
              src={row.original.picture}
              sx={(theme) => ({
                marginRight: theme.spacing(1),
              })}
            />
            <Link to={routes.WORKER_SHOW(row.original.id)} color="black.main">
              {`${row.original.name}\u00A0${row.original.last_name}`}
            </Link>
          </Box>
          {!row.original.finish_registration && tab === 'active' ? (
            <Button
              variant="outlined"
              size="small"
              sx={(theme) => ({
                marginLeft: theme.spacing(5),
                width: 'fit-content',
              })}
              onClick={() => {
                openNewWorkerModal({
                  workerId: row.original.id,
                  workerName: `${row.original.name} ${row.original.last_name}`,
                })
              }}
              endIcon={<Icon name="circle-check" />}
            >
              Completar
            </Button>
          ) : null}
        </Box>
      )
    },
    alignHeader: 'left',
    alignCell: 'left',
    customMinWidth: '17rem',
  },
  {
    Header: 'Salario base',
    accessor: 'base_salary',
    Cell: ({ row }) => formatCurrency(row.original.base_salary),
    noWrapHeader: true,
    alignHeader: 'left',
    alignCell: 'left',
  },
  {
    Header: 'Cargo',
    accessor: 'position',
    alignHeader: 'left',
    alignCell: 'left',
    customMinWidth: '14rem',
  },
]
