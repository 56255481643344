import { useEffect, useState } from 'react'

import { Box, Skeleton, Typography } from '@mui/material'

import { getWordSingular } from 'utils/format'

const AlertCountdown = ({ deadline, textColor = 'black.main' }) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [hasTime, setHasTime] = useState(false)

  const countdownData = [
    {
      text: getWordSingular('Días', days === 1),
      time: days,
    },
    {
      text: getWordSingular('Hrs', hours === 1),
      time: hours,
    },
    {
      text: getWordSingular('Mins', minutes === 1),
      time: minutes,
    },
  ]

  useEffect(() => {
    const getTime = () => {
      const parsedDeadline = new Date(`${deadline}T23:59:59-05:00`)
      const currentTime = Date.now()
      const time = Date.parse(parsedDeadline) - currentTime

      if (time < 0) {
        setDays(0)
        setHours(0)
        setMinutes(0)
        setSeconds(0)
      } else {
        setDays(Math.floor(time / (1000 * 60 * 60 * 24)))
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24))
        setMinutes(Math.floor((time / 1000 / 60) % 60))
        setSeconds(Math.floor((time / 1000) % 60))
      }

      setHasTime(true)
    }

    const interval = setInterval(getTime, 1000)
    getTime()

    return () => clearInterval(interval)
  }, [seconds, minutes, hours, deadline])

  return hasTime ? (
    <Box
      sx={{
        width: '7.063rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 2.1rem)',
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: 'repeat(3, 2.4rem)',
          },
        })}
      >
        {countdownData.map((data) => {
          return (
            <Box
              key={data.text}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={700}
                  color={textColor}
                  sx={{ letterSpacing: '0.044rem' }}
                >
                  {data.time < 10 ? `0${data.time}` : data.time}
                </Typography>

                {data.text === 'Mins' ? null : (
                  <Typography
                    variant="body2"
                    fontWeight={700}
                    sx={{ position: 'absolute', right: '-10%' }}
                    color={textColor}
                  >
                    -
                  </Typography>
                )}
              </Box>

              <Typography
                variant="body2"
                fontWeight={700}
                sx={{ letterSpacing: '0.044rem' }}
                color={textColor}
              >
                {data.text}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  ) : (
    <Box
      sx={(theme) => ({
        width: '7.063rem',
        display: 'flex',
        columnGap: theme.spacing(0.5),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '30%',
          alignItems: 'center',
        }}
      >
        <Skeleton
          variant="text"
          sx={{ fontSize: '0.5rem' }}
          animation="wave"
          width="80%"
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: '1rem' }}
          animation="wave"
          width="100%"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '30%',
          alignItems: 'center',
        }}
      >
        <Skeleton
          variant="text"
          sx={{ fontSize: '0.5rem' }}
          animation="wave"
          width="80%"
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: '1rem' }}
          animation="wave"
          width="100%"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '30%',
          alignItems: 'center',
        }}
      >
        <Skeleton
          variant="text"
          sx={{ fontSize: '0.5rem' }}
          animation="wave"
          width="80%"
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: '1rem' }}
          animation="wave"
          width="100%"
        />
      </Box>
    </Box>
  )
}

export default AlertCountdown
