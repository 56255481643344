import useModals from 'components/App/ModalsManager/useModals'

import CancelSubscriptionModal from './CancelSubscriptionModalContent'

export function useCancelSubscriptionModal() {
  const modals = useModals()

  const handleClose = () => {
    modals.closeModal('cancelSubscriptionModal')
  }

  const openCancelSubscriptionModal = ({
    activeUntil,
    isFromOrganizerView = false,
    subscriptionCancellationData,
  }) => {
    modals.openModal({
      id: 'cancelSubscriptionModal',
      content: (
        <CancelSubscriptionModal
          activeUntil={activeUntil}
          isFromOrganizerView={isFromOrganizerView}
          subscriptionCancellationData={subscriptionCancellationData}
          handleClose={handleClose}
        />
      ),
      modalProps: {
        hideFooter: true,
        header: '¿Cancelar suscripción?',
        paperSx: {
          maxWidth: '45.5rem',
        },
      },
    })
  }

  return { openCancelSubscriptionModal }
}
