import { Button } from '@mui/material'

const LinkButton = ({ children, ...props }) => {
  return (
    <Button variant="link" {...props}>
      {children}
    </Button>
  )
}

export default LinkButton
