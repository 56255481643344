import queryString from 'query-string'
import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import Loading from 'components/UI/Loading/Loading'

import auth from 'utils/auth'
import useDistributorSessionService from 'utils/hooks/auth/distributorService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { ONBOARDING_AREA } from 'config/routes'

import messages from 'messages/notification'

const IntegrationLogin = () => {
  const { showErrorMessage, showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const location = useLocation()
  const navigate = useNavigate()
  const queryData = queryString.parse(location.search)
  const { distributorSessionMutation } = useDistributorSessionService()

  useEffect(() => {
    distributorSessionMutation.mutate(
      {
        mutationMethod: 'POST',
        token: queryData.token,
      },
      {
        onSuccess: ({ data }) => {
          auth.logIn(data)
          showSuccessMessage(messages.LOGIN_OK)

          if (data.sign_up) {
            navigate(ONBOARDING_AREA())
          } else {
            navigate('/')
          }
        },
        onError: (error) => {
          showErrorMessage(error.errors && error.errors[0].message)
          handleError(error)
        },
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (auth.isAuthenticated()) return <Navigate to="/" />

  return <Loading message="Estamos procesando la información..." />
}

export default IntegrationLogin
