import { Box, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

export function BasicInfoCard({ data, isEditingForm }) {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        ...(data.key === 'company_name' && {
          gridColumn: '1 / -1',
        }),
      })}
    >
      {isEditingForm ? (
        data.field
      ) : (
        <>
          <Box
            sx={(theme) => ({
              borderRadius: '100%',
              padding: theme.spacing(1),
              backgroundColor: theme.palette.info[400],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            <Icon
              name={data.icon}
              sx={{
                fontSize: '2rem',
              }}
            />
          </Box>
          <Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1),
              })}
            >
              <Typography variant="h5" color="black.main">
                {data.label}
              </Typography>
            </Box>
            {data?.initialValue?.label ? (
              <Typography variant="body1" color="white.dark">
                {data?.initialValue?.label}
              </Typography>
            ) : (
              <Typography variant="body1" color="error.dark">
                Pendiente
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}
