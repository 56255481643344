import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { useUser } from 'components/App/UserContext/useUser'
import Table from 'components/UI/Table/Table'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'

import {
  getDisabilityClaimsDetail,
  updateDisabilityClaimsData,
} from 'services/HolisticPayroll/CompanyService'

import DisabilityClaimsTableEmptyState from './Commons/DisabilityClaimsTableEmptyState'
import {
  claimsDetailTableValidationSchema,
  getColumnsData,
  useTableAction,
} from './helpers'

export default function DisabilityClaimsDetailsTable({
  activeTab,
  activeStatus,
  setActiveStatus,
}) {
  const [claimsTotals, setClaimsTotals] = useState({})
  const { company } = useUser()
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()
  const tableActions = useTableAction({
    activeStatus,
    activeTab,
    setActiveStatus,
    claimsTotals,
    claimsDetailId: company.company_claims_detail_id,
  })

  const isOrganizerRoute = location.pathname.includes('organizer')
  const columnsData = getColumnsData(isOrganizerRoute)

  const refreshClaims = () => {
    queryClient.invalidateQueries('disabilityClaimsDetail')
  }

  const companyId = company.id
  const fetchDisabilityClaimsDetail = (pageIndex, search) => {
    return {
      queryKey: [
        'disabilityClaimsDetail',
        pageIndex,
        search,
        activeStatus,
        companyId,
      ],
      queryFunction: () =>
        getDisabilityClaimsDetail({
          searchParams: {
            page: pageIndex + 1,
            search,
            per_page: 20,
            filter: activeStatus,
          },
          claimsDetailId: company.company_claims_detail_id,
        }),
      queryOptions: {
        onSuccess: ({ data }) => {
          setClaimsTotals(data.totals || {})
        },
      },
    }
  }

  const handleUpdateClaimsData = (oldClaimData, newClaimData) => {
    const dirtyValues = getDirtyValues(
      oldClaimData,
      newClaimData,
      claimsDetailTableValidationSchema.fields
    )

    if (!isObjectEmpty(dirtyValues)) {
      const correctedClaimData = new FormData()

      correctedClaimData.append('institution_id', dirtyValues.institution_id)
      correctedClaimData.append('status', 'in_progress')

      return {
        data: {
          claimsDetailId: company.company_claims_detail_id,
          claimNoveltyId: newClaimData.id,
          correctedClaimData: correctedClaimData,
        },
        mutationFunction: updateDisabilityClaimsData,
        mutateOptions: {
          onSuccess: () => {
            refreshClaims()
            showSuccessMessage(
              'La entidad de la reclamación ha sido actualizada correctamente'
            )
          },
        },
      }
    }

    return {}
  }

  return (
    <Table
      data={fetchDisabilityClaimsDetail}
      columns={columnsData}
      actions={tableActions}
      options={{
        customQueryFetch: 'novelty_claims',
        emptyStateComponent: (
          <DisabilityClaimsTableEmptyState
            textOption="admin"
            activeTab={activeTab}
          />
        ),
        alignActionsCell: 'center',
        alignActionsHeader: 'center',
        version: {
          toolbar: 'v2',
        },
        hiddenColumns: [
          'novelty_days',
          'novelty_initial_day',
          'novelty_end_day',
        ],
      }}
      editable={{
        claimsDetailTableValidationSchema,
        onUpdateRow: handleUpdateClaimsData,
        hideDelete: true,
        isEditable: (rowData) =>
          !rowData.institution_name && rowData.status === 'no_credential',
      }}
    />
  )
}
