import { Image } from 'components/UI/Image'

import { isArusDist } from 'utils/auth'

const AuthLogo = () => {
  const imgProps = {
    alt: 'Logo aleluya',
    src: '9854d5ec-a0cd-40b9-9d4f-f4d9596a3d00',
    width: 124,
    height: 56,
  }

  if (isArusDist()) {
    imgProps.alt = 'Logo suaporte'
    imgProps.src = '502ad045-2c9e-4a32-503a-448cf471c100'
    imgProps.width = 190
    imgProps.height = 42
  }

  return (
    <Image src={imgProps.src} width={imgProps.width} height={imgProps.height} />
  )
}

export default AuthLogo
