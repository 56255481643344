import { Box, Typography } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'

import { formatDisplayDateString } from 'utils/dateTime'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'

import { noveltyDescription } from './helpers'

const AffiliationNoveltyModal = ({
  state = {},
  handleClose,
  openInProcessModal,
}) => {
  const affiliationQueryKey = ['getAffiliationById', state.workerId]

  const {
    affiliationsQuery: { data: affiliationsData, isLoading },
  } = useAffiliationsService({
    serviceParams: {
      queryKey: affiliationQueryKey,
      workerId: state.workerId,
    },
  })

  const {
    rejected_comment: noveltyComment,
    created_at: affiliationCreationDate,
    occurrence_at: noveltyDate,
    occurrence_reason: noveltyReason,
  } = affiliationsData || {}

  const handleOpenInProcessModal = () => {
    handleClose()
    openInProcessModal({
      workerId: state.workerId,
    })
  }

  return (
    <Modal
      open={state.open}
      header="Tu solicitud de afiliación a seguridad social tiene una novedad"
      onCancel={handleClose}
      cancelText="Cerrar"
      okText="Ver afiliación"
      onOk={handleOpenInProcessModal}
      paperSx={{
        maxWidth: '45.5rem',
      }}
    >
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          })}
        >
          <Typography variant="h4" color="info.dark">
            Fecha de creación:{' '}
            {formatDisplayDateString(affiliationCreationDate)}
          </Typography>
          <Typography color="black.dark">
            <b>Novedad:</b>{' '}
            {noveltyReason !== 'other'
              ? noveltyDescription[noveltyReason]
              : noveltyComment}
          </Typography>
          {noveltyComment && noveltyReason !== 'other' ? (
            <Typography color="black.dark">
              <b>Comentario adicional:</b> {noveltyComment}
            </Typography>
          ) : null}
          <Typography color="black.dark">
            <b>Fecha de la novedad:</b> {formatDisplayDateString(noveltyDate)}
          </Typography>
        </Box>
      )}
    </Modal>
  )
}

export default AffiliationNoveltyModal
