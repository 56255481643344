import * as yup from 'yup'

import { Link } from '@mui/material'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const getValidationSchema = (
  activeNewExtraHour,
  isContractorWithPerHourSalary,
  periodType
) => {
  const defaultSchema = yup.object({
    name: yup.string().trim().min(3).required(),
    constant_value: yup
      .number()
      .max(10, 'El valor sobre la hora ordinaria no debe ser mayor de 10')
      .moreThan(0, 'El valor debe ser mayor que 0')
      .required(),
    quantity: yup
      .number()
      .lessThan(100, 'El valor debe ser menor que 100')
      .moreThan(0, 'El valor debe ser mayor que 0'),
  })

  if (isContractorWithPerHourSalary && !activeNewExtraHour) {
    if (periodType === 'monthly')
      return yup.object({
        worked_time: yup
          .number()
          .integer()
          .min(0)
          .max(29, 'El valor debe ser menor o igual a 29'),
      })

    return yup.object({
      worked_time: yup
        .number()
        .integer()
        .min(0)
        .max(15, 'El valor debe ser menor o igual a 15'),
    })
  }

  return defaultSchema
}

export const getDataToSend = (initData = {}, currData = {}) => {
  const dataToSend = []
  const categories = ['extra_hours', 'surcharges', 'others']

  categories.forEach((category) => {
    const initCategoryData = initData[category] || []
    const currCategoryData = currData[category] || []

    for (let i = 0; i < currCategoryData.length; i += 1) {
      const { quantity } = currCategoryData[i]
      const { quantity: initialQuantity } = initCategoryData[i] || {}

      if (quantity !== undefined) {
        if (initialQuantity === undefined && quantity !== 0) {
          dataToSend.push({
            payroll_concept_id: currCategoryData[i].id,
            quantity,
          })
        }

        if (initialQuantity !== undefined && quantity !== initialQuantity) {
          dataToSend.push({
            id: currCategoryData[i].itemId,
            quantity,
          })
        }
      }
    }
  })

  return dataToSend
}

export const validateEmptyNewExtraHour = (values) => {
  const { name, constant_value: constantValue, quantity } = values

  return !(/\S/.test(name) || /\S/.test(constantValue) || /\S/.test(quantity))
}

export const overtimeDescription = {
  extra_hours: (
    <>
      Ingresa aquí el número de horas extras. Si tienes inquietudes ingresa a{' '}
      <Link
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-registrar-horas-extras-y-recargos-en-aleluya"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
  surcharges: (
    <>
      Ingresa aquí el número de horas con recargo. Si tienes inquietudes ingresa
      a{' '}
      <Link
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-registrar-horas-extras-y-recargos-en-aleluya"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
  others: (
    <>
      Si deseas crear una nueva hora extra o recargo con un valor sobre la hora
      ordinaria diferentes a los estipulados por la ley, aquí puedes crear
      nuevos conceptos y definir el valor que desees. Si tienes inquietudes
      ingresa a{' '}
      <Link
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-crear-un-nuevo-concepto-para-una-hora-extra-y-o-recargo-con-aleluya"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
}

const noveltyLabel = {
  extra_hours: 'las horas extras',
  surcharges: 'las horas con recargo',
  others: 'otras horas extras',
}

export const getModalHeader = (novelty, workerName) => {
  return `¿Reportale ${noveltyLabel[novelty]} a ${workerName}?`
}

export const getColumnsWidth = ({
  isTabletUp,
  isLaptopUp,
  withDeleteButton = false,
}) => {
  let remainingColumns = '1fr'
  if (!withDeleteButton) {
    if (isTabletUp && !isLaptopUp) {
      remainingColumns = '6.875rem'
    } else if (isLaptopUp) {
      remainingColumns = '6.125rem'
    }

    return `1fr 1fr ${remainingColumns}`
  }

  if (isTabletUp && !isLaptopUp) {
    remainingColumns = '0.5fr 2.5rem'
  } else if (isLaptopUp) {
    remainingColumns = '0.5fr 2.5rem'
  }

  return `1fr 1fr ${remainingColumns}`
}
