import { useTheme } from '@mui/material'

import Table from 'components/UI/Table/Table'

import Toolbar from './Toolbar'
import { columns } from './helpers'

const Decree376DataTable = ({ decreePayments }) => {
  const theme = useTheme()

  const getCustomRowStyle = ({ company_balance: companyBalance }) => {
    if (companyBalance === 0) {
      return {
        backgroundColor: theme.palette.white.light,
      }
    }
    return {}
  }

  return decreePayments ? (
    <Table
      data={decreePayments}
      columns={columns}
      components={{
        Toolbar,
      }}
      options={{
        selection: true,
        selectionId: 'paid_in_period',
        rowStyle: getCustomRowStyle,
        version: {
          toolbar: 'v2',
        },
      }}
    />
  ) : null
}

export default Decree376DataTable
