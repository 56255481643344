import { useQueryClient } from 'react-query'

import { Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

import { formatNumberDisplayDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import useNotifications from 'utils/hooks/useNotifications'
import useWageService from 'utils/hooks/worker/wageService'
import { getMinimumSalary, wageCategories } from 'utils/worker'

const WageUpdateConfirmModal = ({ handleClose, state }) => {
  const { wageMutation } = useWageService({
    queryOptions: { enabled: false },
  })

  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()

  const { newWageData, open } = state

  const handleConfirmWageUpdate = () => {
    wageMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'updateWorkerWage',
        wage: {
          worker_id: newWageData.worker_id,
          base_salary: newWageData.new_base_salary,
          initial_day: newWageData.date_new_salary,
          category: newWageData.wage_category,
          transport_subsidy: newWageData.transport_subsidy,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('getAllWorkersWages')
          showSuccessMessage(
            `El salario de ${newWageData.name} se actualizó exitosamente.`
          )
          handleClose()
        },
      }
    )
  }

  const currentYear = new Date().getFullYear()
  const belowMinimumSalary = newWageData.new_base_salary < getMinimumSalary()
  const belowIntegralSalary =
    newWageData.wage_category === 'integral_salary' &&
    newWageData.new_base_salary < getMinimumSalary({ multiplier: 13 })

  return (
    <Modal
      open={open}
      okText="Actualizar salario"
      isLoading={wageMutation.isLoading}
      header={
        <Typography variant="h3">
          {belowMinimumSalary
            ? `¿Estás seguro de que el nuevo salario para ${newWageData.name} estará por debajo del
          mínimo?`
            : belowIntegralSalary
              ? `¿Estás seguro que el nuevo salario para ${newWageData.name} será menor a ${formatCurrency(getMinimumSalary({ multiplier: 13 }))}, el cual corresponde al valor establecido para el salario integral en el ${currentYear}?`
              : `Estás a punto de actualizar el salario de ${newWageData.name}`}
        </Typography>
      }
      onOk={handleConfirmWageUpdate}
      onCancel={handleClose}
      paperSx={{ maxWidth: '47rem' }}
    >
      <Typography variant="body1" color="black.dark">
        El nuevo salario será de tipo{' '}
        <strong>{wageCategories[newWageData.wage_category]}</strong>, con un
        valor de <strong>{formatCurrency(newWageData.new_base_salary)}</strong>
        {newWageData.transport_subsidy
          ? `${' '}+ subsidio de transporte`
          : null}{' '}
        y aplicará a partir del{' '}
        <strong>{formatNumberDisplayDate(newWageData.date_new_salary)}</strong>.
      </Typography>
    </Modal>
  )
}

export default WageUpdateConfirmModal
