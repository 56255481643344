import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Typography } from '@mui/material'

import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import { getSocialBenefitsById } from 'services/worker/socialBenefitsService'

import * as routes from 'config/routes'

import { columnsData } from './helpers'

const SocialBenefitsShow = () => {
  const { worker } = useWorker()
  const [socialBenefitName, setSocialBenefitName] = useState(null)

  const { workerId, contractId, socialBenefitId } = useParams()

  const fetchSocialBenefits = (page) => {
    const queryKey = ['socialBenefitsById', contractId, socialBenefitId, page]
    return {
      queryKey,
      queryFunction: () =>
        getSocialBenefitsById(contractId, socialBenefitId, {
          page: page + 1,
        }),
      queryOptions: {
        onSuccess: ({ data }) => {
          setSocialBenefitName(data.label)
        },
      },
    }
  }

  return (
    <Page
      documentTitle={`Pagos de ${socialBenefitName || 'Prestación social'} de ${
        worker ? worker.fullName : 'trabajador'
      }`}
      header={
        <Typography variant="h5">
          Pagos de {socialBenefitName || 'Prestación social'} de{' '}
          {worker ? (
            <Link
              to={routes.WORKER_SHOW(workerId)}
              sx={{
                fontSize: 'inherit',
              }}
            >
              {worker.fullName}
            </Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
    >
      <Table
        columns={columnsData}
        data={fetchSocialBenefits}
        options={{ toolbar: false, customQueryFetch: 'payments' }}
      />
    </Page>
  )
}

export default SocialBenefitsShow
