import { useState } from 'react'
import { useQueryClient } from 'react-query'

import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'

import { getUserId, getUserRole } from 'utils/auth'
import { accountingCategoryNames, getCompanyId } from 'utils/company'
import useAccountingService from 'utils/hooks/settings/accounting'
import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'
import useNotifications from 'utils/hooks/useNotifications'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import messages from 'messages/notification'

import CategoryAccordion from '../CategoryAccordion'
import { integratedCounterpartCodeOptions } from '../helpers'

const SettingsAccountingAccounts = ({ configuration }) => {
  const [expanded, setExpanded] = useState(false)
  const [integratedCounterpartCode, setintegratedCounterpartCode] = useState(
    configuration?.integrated_counterpart_code
  )
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()

  const { companyConfigurationMutation } = useCompanyConfigurationService({
    queryOptions: { enabled: false },
  })

  const companyId = getCompanyId()
  const accountingCodeNames = Object.keys(accountingCategoryNames)
  const {
    accountingQuery: {
      data: accountingCodesQueryData = {},
      isLoading: accountingCodesQueryIsLoading,
    } = {},
  } = useAccountingService({
    serviceParams: {
      categories: accountingCodeNames,
      queryKey: ['accountingCodes', companyId],
    },
  })
  const accountingCodesQueryDataArray = Object.entries(accountingCodesQueryData)

  const handleChange = (category) => (_, isExpanded) => {
    setExpanded(isExpanded ? category : false)
  }

  const handleChangeIntegratedCounterpartCode = ({
    target: { name, value },
  }) => {
    const booleanValue = value === 'true'

    setintegratedCounterpartCode(booleanValue)

    companyConfigurationMutation.mutate(
      {
        mutationMethod: 'PUT',
        configuration: { [name]: booleanValue },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('companyConfiguration')
          showSuccessMessage(messages.CHANGES_DONE_SUCCESS)
          Mixpanel.track(MIXPANEL_EVENTS.SAVED_ACCOUNTING_CHANGES, {
            company_id: getCompanyId(),
            user_id: getUserId(),
            user_role: getUserRole(),
            section: 'integrated_counterpart_code',
          })
        },
      }
    )
  }

  return (
    <div data-cy="accounting-codes-section">
      <Typography variant="h6" gutterBottom style={{ marginTop: '2rem' }}>
        Cuentas Contables
      </Typography>
      <Typography>
        Configura tus conceptos de nómina, los cuales se usarán para generar el
        archivo para tu software contable y al momento de liquidar tu nómina.
      </Typography>
      <Alert sx={(theme) => ({ margin: theme.spacing(1, 0) })} severity="info">
        Recuerda que el prefijo de cada cuenta será el código cuenta contable de
        tus Centros de Costos PUC.
      </Alert>
      <Alert
        severity="info"
        icon={false}
        sx={(theme) => ({ marginBottom: theme.spacing(2) })}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ marginBottom: '1rem' }}>
            ¿Cómo deseas configurar las contrapartidas de subsidio de
            transporte, otros ingresos, horas extras y recargos?
          </FormLabel>
          <RadioGroup
            name="integrated_counterpart_code"
            value={integratedCounterpartCode}
            onChange={handleChangeIntegratedCounterpartCode}
          >
            {integratedCounterpartCodeOptions.map((opt) => (
              <FormControlLabel
                key={opt.value}
                label={opt.label}
                value={opt.value}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Alert>
      <Paper>
        {accountingCodesQueryIsLoading ? (
          <LoadingBox />
        ) : (
          accountingCodesQueryDataArray.map((categoryQueryData) => {
            const categoryKey = categoryQueryData[0]
            const categoryData = categoryQueryData[1]

            return (
              <CategoryAccordion
                key={categoryKey}
                expanded={expanded}
                category={categoryKey}
                handleChange={handleChange}
                integratedCounterpartCode={integratedCounterpartCode}
                categoryCodeData={categoryData}
              />
            )
          })
        )}
      </Paper>
    </div>
  )
}

export default SettingsAccountingAccounts
