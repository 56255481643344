import { createContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import TotalCardCompact from 'components/UI/Card/TotalCardCompact'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import { addDays, checkExpireDays, formatPeriodDateRange } from 'utils/dateTime'
import { isObjectEmpty } from 'utils/general'
import useElectronicPayrollPeriodService from 'utils/hooks/ElectronicPayroll/electronicPayrollPeriod'

import formatPeriod from 'services/payroll/helpers'

import { SETTINGS_ADVANCED } from 'config/routes'

import TableElectronicPayrollPeriod from './DataTable/Table/Table'
import ElectronicPayrollHeader from './Header'
import TotalEmittedRejectedModal from './ModalRejected/TotalEmittedRejectedModal'
import useTotalEmittedRejectedModal from './ModalRejected/useTotalEmittedRejectedModal'
import SummaryTable from './Summary'
import { getConfigurationCopy, getDetailCard } from './helpers'

export const ElectronicPeriodContext = createContext()

const ElectronicPayroll = () => {
  const { periodId } = useParams()
  const [electronicPeriod, setElectronicPeriod] = useState({})
  const [showSummary, setShowSummary] = useState(false)
  const electronicPayrollPeriodQueryKey = [
    'electronicPayrollPeriod',
    getCompanyId(),
    periodId,
    '',
  ]

  const {
    closeTotalEmittedRejectedModal,
    openTotalEmittedRejectedModal,
    totalEmittedRejectedModalState,
  } = useTotalEmittedRejectedModal()

  const { electronicPayrollPeriodQuery } = useElectronicPayrollPeriodService({
    serviceParams: {
      queryKey: electronicPayrollPeriodQueryKey,
      periodId,
      electronicPayrollStatuses: '',
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        const formattedPeriod = formatPeriod(data)
        setElectronicPeriod(formattedPeriod)
      },
    },
  })
  const { initial_day: initialDay, end_day: endDay } =
    electronicPayrollPeriodQuery.data || {}
  const periodDateRange =
    initialDay && endDay ? formatPeriodDateRange(initialDay, endDay) : ''

  const cardDetails = getDetailCard(
    electronicPeriod,
    openTotalEmittedRejectedModal
  )

  // Validation for 60 days from first authorization
  const expiryDaysFromAuthorization = addDays(
    electronicPeriod.authorization_date,
    60
  )
  const hasExpiry = checkExpireDays(expiryDaysFromAuthorization)

  // Variable for get the copy from the configuration option
  const copyAlertElectronicPayroll =
    getConfigurationCopy(electronicPeriod?.electronic_payroll_social_benefit) ||
    ''

  return (
    <ElectronicPeriodContext.Provider
      value={{
        period: electronicPeriod,
        setShowSummary,
        socialBenefitsConfiguration:
          electronicPayrollPeriodQuery.data?.electronic_payroll_social_benefit,
      }}
    >
      <Page
        header={
          !isObjectEmpty(electronicPeriod) ? (
            <Box
              sx={{
                gridColumn: '1 / -1',
              }}
            >
              <ElectronicPayrollHeader
                title={`Nómina electrónica del periodo ${periodDateRange}`}
                period={electronicPeriod}
              />
            </Box>
          ) : null
        }
        documentTitle="Nómina electrónica"
        isLoading={electronicPayrollPeriodQuery?.isLoading}
        grid
      >
        {!isObjectEmpty(electronicPeriod) ? (
          <>
            {!hasExpiry && copyAlertElectronicPayroll ? (
              <Alert
                severity="warning"
                icon={false}
                header="¡Nómina Electrónica!"
                closable
                sx={(theme) => ({
                  maxWidth: '25rem',
                  position: 'absolute',
                  right: theme.spacing(5),
                  zIndex: 1,
                  [theme.breakpoints.down('sm')]: {
                    right: theme.spacing(2.5),
                  },
                })}
              >
                {copyAlertElectronicPayroll}
                <br />
                <Link to={SETTINGS_ADVANCED()}>Configurar</Link>
              </Alert>
            ) : null}
            <Box
              sx={{
                gridColumn: '1 / -1',
              }}
            >
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  gridTemplateColumns: {
                    mobile: '1fr',
                    tablet: 'repeat(auto-fill, minmax(16rem, 1fr))',
                  },
                  rowGap: theme.spacing(2),
                  columnGap: theme.spacing(3),
                  marginBottom: theme.spacing(7),
                })}
              >
                {cardDetails.map(
                  ({
                    title,
                    value,
                    backgroundColor,
                    textColor,
                    icon,
                    isWhiteText,
                    showDetailButton,
                    tooltipInfo,
                  }) => (
                    <TotalCardCompact
                      key={title}
                      title={title}
                      value={value}
                      backgroundColor={backgroundColor}
                      textColor={textColor}
                      icon={icon}
                      isWhiteText={isWhiteText}
                      showDetailButton={showDetailButton}
                      tooltipInfo={tooltipInfo}
                    />
                  )
                )}
              </Box>
            </Box>
            <Box
              sx={{
                gridColumn: '1 / -1',
              }}
            >
              {showSummary ? (
                <SummaryTable electronicPayrollId={periodId} />
              ) : (
                <TableElectronicPayrollPeriod
                  electronicPayrollsData={electronicPeriod}
                />
              )}
            </Box>
          </>
        ) : null}
      </Page>
      {totalEmittedRejectedModalState.open ? (
        <TotalEmittedRejectedModal
          state={totalEmittedRejectedModalState}
          closeModal={closeTotalEmittedRejectedModal}
        />
      ) : null}
    </ElectronicPeriodContext.Provider>
  )
}

export default ElectronicPayroll
