import { useNavigate } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import { PLANS_BY_CODED_NAME } from 'components/Subscription/helpers'

import { isPremiumExpiredByCancellationCompany } from 'utils/auth'
import { removeSuffix } from 'utils/general'

import { SUBSCRIPTION_CHECKOUT } from 'config/routes'

import {
  PersonValue,
  PlanCard,
  PlanDescriptionByCategory,
  PlanName,
  PlanValue,
} from '../../Atoms/PlanUIComponents'
import useSubscription from '../../Atoms/useSubscription'
import SubscriptionConfirmationModal from './SubscriptionConfirmationModal'
import { getModalInfo } from './SubscriptionConfirmationModal/helpers'

const SubscriptionCardsContent = ({
  planData,
  isAFreeCompany,
  selectedModality,
  selectedPlanIndex,
  currentPlanIndex,
  currentPlanModality,
  plansData,
  isPlanBaseAliados = false,
}) => {
  const modals = useModals()
  const { subscription } = useSubscription()
  const navigate = useNavigate()
  const planUpgrade = selectedPlanIndex > currentPlanIndex
  const isChangingModality = currentPlanModality !== selectedModality
  const modalityUpgrade = isChangingModality && currentPlanModality === 'month'
  const {
    codedName,
    isCurrentPlan,
    isRecommendedPlan,
    planName,
    planValue,
    planPaymentModel,
    personValue,
    personPaymentModel,
  } = planData

  const companyWithoutSelectedPlan = plansData.every(
    (plan) => plan.current_plan === false
  )

  const {
    active_until: activeUntil,
    payrolls_size: currentWorkersNumber,
    status,
    payment_methods: { pse: isPseAvailable } = {},
  } = subscription || {}

  const isPremiumExpiredSubscription =
    status === 'premium_expired' ||
    isPremiumExpiredByCancellationCompany(subscription)

  const isPremiumHRPlan = codedName === 'premium_hr_per_worker_plan'
  const codedNameWithoutSuffix = removeSuffix(codedName, -8)

  const navigateTosubscription = () => {
    const state = {
      modality: selectedModality,
      selectedPlan: plansData.find(
        ({ coded_name }) => coded_name === planData.codedName
      ),
      planOptions: plansData,
    }

    navigate(SUBSCRIPTION_CHECKOUT(), { state })
  }

  const isAnUpgrade =
    planUpgrade || (modalityUpgrade && (planUpgrade || isCurrentPlan))

  const currentPlanPayment = isCurrentPlan && !isChangingModality

  const buttonText = () => {
    if (isAnUpgrade) return 'Mejorar mi plan'
    if (currentPlanPayment) return 'Pagar mi plan'
    return 'Cambiar mi plan'
  }

  const { title } = getModalInfo(
    currentPlanPayment,
    modalityUpgrade,
    planUpgrade,
    subscription,
    isChangingModality,
    isPremiumExpiredSubscription,
    activeUntil
  )

  const getImmediatePayment =
    isAnUpgrade || isPremiumExpiredSubscription || currentPlanPayment

  const showExtraWorkersCounter =
    selectedModality === 'year' && getImmediatePayment

  const handleContinuePayment = () => {
    if (isAFreeCompany) {
      modals.closeAll()
      navigateTosubscription()
    } else {
      modals.closeModal('subscriptionCardsModal')
      modals.openModal({
        id: 'subscriptionConfirmationModal',
        content: (
          <SubscriptionConfirmationModal
            selectedPlan={planData}
            selectedModality={selectedModality}
            isAnUpgrade={isAnUpgrade}
            currentPlanPayment={currentPlanPayment}
            activeUntil={activeUntil}
            planUpgrade={planUpgrade}
            modalityUpgrade={modalityUpgrade}
            currentWorkersNumber={currentWorkersNumber}
            isChangingModality={isChangingModality}
            isCurrentPlan={isCurrentPlan}
            isPremiumExpiredSubscription={isPremiumExpiredSubscription}
          />
        ),
        modalProps: {
          header: title,
          hideFooter: true,
          adornment: !showExtraWorkersCounter
            ? [
                {
                  variant: 'signature',
                  color: 'info.light',
                  width: 165,
                  height: 182,
                  sx: {
                    bottom: 85,
                    right: 75,
                  },
                },
              ]
            : null,
          paperSx: {
            maxWidth: '45rem',
            width: '100%',
          },
        },
      })
    }
  }

  return !isPlanBaseAliados ? (
    <PlanCard
      isCurrentPlan={isCurrentPlan && subscription.type === selectedModality}
      isRecommendedPlan={isRecommendedPlan}
      companyWithoutSelectedPlan={companyWithoutSelectedPlan}
      data-cy={`plan_card_${codedNameWithoutSuffix}`}
    >
      <PlanName planName={planName} />
      <PlanValue value={planValue} paymentModel={planPaymentModel} />
      <PersonValue value={personValue} paymentModel={personPaymentModel} />
      <PlanDescriptionByCategory codedName={codedName} />

      {!currentPlanPayment || isPseAvailable ? (
        <Button
          onClick={handleContinuePayment}
          variant={
            (isRecommendedPlan && companyWithoutSelectedPlan) ||
            isPremiumHRPlan ||
            isCurrentPlan
              ? 'contained'
              : 'outlined'
          }
          data-cy={`${removeSuffix(codedName, -8)}_button`}
          sx={(theme) => ({ marginTop: theme.spacing(4), width: '100%' })}
          disabled={
            codedName === PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan &&
            subscription.payrolls_size > 3
          }
        >
          {buttonText()}
        </Button>
      ) : null}
    </PlanCard>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gridColumn: '-1 / 1',
        width: '100%',
        maxWidth: '31.25rem',
        margin: '0 auto',
      }}
    >
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        Aleluya Plan Base Aliados
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h4"
          sx={(theme) => ({
            padding: theme.spacing(2, 2, 0, 2),
          })}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          color="black.dark"
          sx={(theme) => ({
            padding: theme.spacing(2),
          })}
        >
          Con tu plan{' '}
          <Box component="span" color="black.main" fontWeight={700}>
            Aliado
          </Box>{' '}
          podrás automatizar los procesos de nómina de todos tus clientes en un
          mismo lugar. Además, tendrás acceso a soporte prioritario y mucho más.
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          width: '100%',
          padding: theme.spacing(0, 2),
          margin: theme.spacing(3, 0),
        })}
      >
        <Button
          fullWidth
          onClick={handleContinuePayment}
          data-cy={`${removeSuffix(codedName, -8)}_button`}
        >
          {buttonText()}
        </Button>
      </Box>
    </Box>
  )
}

export default SubscriptionCardsContent
