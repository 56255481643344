import {
  Box,
  Button,
  ButtonBase,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import { useSupportMenuContext } from 'components/App/SupportMenuContext'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'

import { starEyesFace } from 'utils/emojis'

import CelebrationDog from 'assets/images/views/common/celebration_dog.png'

export function useUpgradeToPayrollOnlyPlanModal() {
  const modals = useModals()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const { openSupportMenuFromOutside } = useSupportMenuContext()

  const handleClose = () => {
    modals.closeModal('upgradeEntrepreneurPlan')
  }

  const openUpgradeToPayrollOnlyPlanModal = ({ handleOnConfirm }) => {
    modals.openModal({
      id: 'UpgradeEntrepreneurPlan',
      content: (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              mobile: '1fr',
              tablet: '17rem 1fr',
            },
          }}
        >
          {!isMobile ? (
            <>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.accent1.main,
                  width: '34.625rem',
                  height: '34.625rem',
                  borderRadius: '100%',
                  position: 'absolute',
                  left: theme.spacing(-38.125),
                  top: theme.spacing(-13),
                })}
              />
              <Box
                component="img"
                alt="Un perro muy feliz ladrando, por que tu equipo está creciendo"
                src={CelebrationDog}
                width={256}
                height={256}
                sx={(theme) => ({
                  zIndex: 1,
                  objectFit: 'contain',
                  margin: 'auto 0',
                  marginLeft: theme.spacing(-3),
                })}
              />
            </>
          ) : null}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(2),
                gap: theme.spacing(2),
              })}
            >
              <Typography variant="h4">
                ¡Wow! <Emoji code={starEyesFace} /> Tu equipo está creciendo y
                eso es increíble
              </Typography>
              <ButtonBase
                onClick={handleClose}
                data-cy="close-dialog-button"
                sx={(theme) => ({
                  alignSelf: 'self-start',
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
              </ButtonBase>
            </Box>

            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2),
                color: 'black.dark',
              })}
            >
              <Typography
                variant="body2"
                sx={{ width: { mobile: '90%', tablet: '25.6875rem' } }}
              >
                Al contar con 4 o más personas en tú nómina, pasas
                automáticamente al <b>Plan Solo Nómina</b>. Así podrás estar
                tranqui con toda tu nómina y hacer que tu equipo esté más feliz
                que perrito estrenando juguete.
              </Typography>

              <Typography
                variant="body2"
                sx={{ width: { mobile: '90%', tablet: '25.6875rem' } }}
              >
                Si quieres conocer más detalles sobre este y otros planes
                disponibles,{' '}
                <Link
                  fontWeight={600}
                  color="info.main"
                  target="_blank"
                  href="https://aleluya.com/precios"
                >
                  haz clic aquí
                </Link>
                .
              </Typography>

              <Typography
                variant="body2"
                sx={{ width: { mobile: '90%', tablet: '25.6875rem' } }}
              >
                ¿Tienes dudas? Puedes comunicarte con nuestro{' '}
                <Button
                  variant="text"
                  onClick={() => {
                    handleClose()
                    openSupportMenuFromOutside()
                  }}
                  sx={{ height: 'auto', color: 'info.main' }}
                >
                  equipo de soporte
                </Button>
                .
              </Typography>
            </Box>

            <Box
              sx={(theme) => ({
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: theme.spacing(3),
                columnGap: theme.spacing(2),
                flexDirection: { mobile: 'column', tablet: 'row' },
                rowGap: theme.spacing(2),
              })}
            >
              <Button
                size="large"
                onClick={() => {
                  handleOnConfirm()
                  handleClose()
                }}
                sx={{ width: { mobile: '100%', tablet: '12.5rem' } }}
              >
                Continuar
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={handleClose}
                sx={{ width: { mobile: '100%', tablet: '12.5rem' } }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Box>
      ),
      modalProps: {
        onCloseModal: handleClose,
        hideFooter: true,
        paperSx: {
          maxWidth: '49rem',
        },
        contentWrapperSx: {
          overflow: 'hidden',
        },
      },
    })
  }

  return { openUpgradeToPayrollOnlyPlanModal }
}
