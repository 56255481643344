import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import { Chip, Typography, alpha } from '@mui/material'

import FilterButton from 'components/UI/Button/FilterButton'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDateString } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

import DisabilityClaimsEntitiesTable from './DisabilityClaimsEntitiesTable'
import DisabilityClaimsShowTable from './DisabilityClaimsShowTable'

const DISABILITY_CLAIMS_STATUS = {
  rejected: 'Rechazada',
  without_claims: 'Sin novedades',
  pending: 'Pendiente',
  processing: 'En proceso',
  claimed: 'Reclamada',
}

export const disabilityClaimsStatus = {
  without_claims: {
    label: 'Sin novedades',
    backgroundColor: (theme) => alpha(theme.palette.black.light, 0.2),
    textColor: 'black.dark',
  },
  pending: {
    label: 'Pendiente',
    backgroundColor: (theme) => alpha(theme.palette.complementary2.main, 0.2),
    textColor: 'complementary2.dark',
  },
  processing: {
    label: 'En proceso',
    backgroundColor: (theme) => alpha(theme.palette.complementary2.main, 0.2),
    textColor: 'complementary2.dark',
  },
  rejected: {
    label: 'Rechazada',
    backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
    textColor: 'error.dark',
  },
  claimed: {
    label: 'Reclamada',
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
    textColor: 'primary.dark',
  },
}

export const claimsIndexColumnsData = [
  {
    Header: 'Empresa',
    accessor: 'name',
    Cell: ({ row }) => (
      <Link
        to={routes.ORGANIZER_DISABILITY_CLAIMS_SHOW(row.original.id)}
        color="black.main"
        state={{ claimDetailId: row.original.id }}
        sx={{ textDecoration: 'none' }}
      >
        {row.original.name}
      </Link>
    ),
  },
  {
    Header: 'Fecha de reclamación',
    accessor: 'created_at',
    Cell: ({ row }) => (
      <Typography>
        {formatDisplayDateString(row.original.created_at)}
      </Typography>
    ),
  },
  {
    Header: 'Plata a reclamar',
    accessor: 'total_value_to_claim',
    Cell: ({ row }) => (
      <Typography>
        {formatCurrency(row.original.total_value_to_claim)}
      </Typography>
    ),
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: ({ row }) => (
      <Chip
        label={disabilityClaimsStatus[row.original.status].label}
        sx={{
          backgroundColor:
            disabilityClaimsStatus[row.original.status].backgroundColor,
          color: disabilityClaimsStatus[row.original.status].textColor,
        }}
      />
    ),
    alignCell: 'center',
    alignHeader: 'center',
  },
]

export function getOrganizerClaimsTableActions({
  activeStatus,
  handleChange,
  claimsTotals = {},
}) {
  const getOptions = (claimsTotals = {}) =>
    Object.keys(claimsTotals).map((claims) => {
      return {
        label: `${DISABILITY_CLAIMS_STATUS[claims.split('_total')[0]]} (${
          claimsTotals[claims]
        })`,
        value: claims.split('_total')[0],
      }
    })

  return [
    (rowData) => ({
      id: 'details',
      tooltip: 'Ver detalle',
      icon: <Icon name="eye" />,
      buttonProps: {
        component: RouterLink,
        to: routes.ORGANIZER_DISABILITY_CLAIMS_SHOW(rowData.id),
        state: { claimDetailId: rowData.id },
        size: 'medium',
        color: 'complementary1',
        onClick: undefined,
      },
    }),
    {
      id: 'statusFilter',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Pendientes"
          options={getOptions(claimsTotals)}
          value={activeStatus}
          onChange={handleChange}
        />
      ),
    },
  ]
}

export const tabsConfig = [
  {
    label: 'Reclamaciones',
    content: <DisabilityClaimsShowTable />,
  },
  {
    label: 'Credenciales',
    content: <DisabilityClaimsEntitiesTable />,
  },
]

export const noveltyModalValidationSchema = yup.object({
  status: yup.string().nullable().required(),
  comment: yup
    .string()
    .nullable()
    .when('status', {
      is: (status) => status === 'rejected',
      then: yup.string().nullable().required(),
    }),
})

export const showTableValidationSchema = yup.object({
  claim_value: yup.string().nullable(),
  status: yup.string().nullable(),
  institution_id: yup.string().nullable(),
})

export const claimEntitiesValidationSchema = yup.object({
  institution_id: yup.string().nullable().required(),
  username: yup.string().nullable().required(),
  password: yup.string().nullable().required(),
})
