import { useQueryClient } from 'react-query'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'

import Icon from 'components/UI/Icon'
import Table from 'components/UI/Table/Table'

import { getUserId, getUserRole } from 'utils/auth'
import { accountingCategoryNames, getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useAccountingService from 'utils/hooks/settings/accounting'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { createAccountingCodes } from 'services/settings/accountingCodesService'
import { createAccountingInstitutionCodes } from 'services/settings/accountingInstitutionCodesService'

import {
  getAccountDirtyValues,
  getColumns,
  institutionCategoriesNames,
} from './helpers'

const SetttingsAccountingCategoryAccordion = ({
  category,
  isInstitution = false,
  ssAcounting,
  expanded,
  handleChange,
  integratedCounterpartCode,
  categoryCodeData,
}) => {
  const queryClient = useQueryClient()

  const companyId = getCompanyId()

  const mutationOptions = {
    onSuccess: () => {
      if (isInstitution) {
        queryClient.invalidateQueries(['accountingInstitutionsCodes', category])
      } else {
        queryClient.invalidateQueries(['accountingCodes', companyId])
      }
      Mixpanel.track(MIXPANEL_EVENTS.SAVED_ACCOUNTING_CHANGES, {
        company_id: getCompanyId(),
        user_id: getUserId(),
        user_role: getUserRole(),
        section: ssAcounting
          ? 'social_security_accounting_codes'
          : 'accounting_codes',
      })
    },
  }

  const { accountingQuery: accountingInstitutionCodesQuery } =
    useAccountingService({
      serviceParams: {
        category,
        queryKey: ['accountingInstitutionsCodes', category],
      },
      queryOptions: {
        enabled: isInstitution,
      },
    })

  const isEmptyData =
    (accountingInstitutionCodesQuery.data &&
      accountingInstitutionCodesQuery.data.length === 0) ||
    (categoryCodeData && categoryCodeData.length === 0)

  const updateAccountingCode = (oldAccountingData, newAccountingData) => {
    const dirtyValues = getAccountDirtyValues(
      oldAccountingData,
      newAccountingData,
      isInstitution
    )

    const mutationFunction = isInstitution
      ? createAccountingInstitutionCodes
      : createAccountingCodes

    if (!isObjectEmpty(dirtyValues)) {
      return {
        data: {
          accountingCodes: {
            ...dirtyValues,
          },
        },
        mutationFunction,
      }
    }

    return {}
  }

  const columns = getColumns(
    category,
    isInstitution,
    ssAcounting,
    integratedCounterpartCode
  )

  return (
    <Accordion
      expanded={isInstitution ? undefined : expanded === category}
      onChange={isInstitution ? undefined : handleChange(category)}
      disabled={
        accountingInstitutionCodesQuery.isLoading ||
        accountingInstitutionCodesQuery.data?.length === 0 ||
        categoryCodeData?.length === 0
      }
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<Icon name="chevron-down" sx={{ fontSize: '1.5rem' }} />}
        aria-controls={`panel-${category}-content`}
        id={`panel-${category}-header`}
        data-cy={`accordion-${category}`}
      >
        <Typography>
          {isInstitution
            ? institutionCategoriesNames[category]
            : accountingCategoryNames[category]}
          {isEmptyData &&
            ` - Ninguna persona tiene registrada ${institutionCategoriesNames[category]}.`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          display: 'block',
          backgroundColor: theme.palette.white.light,
        })}
      >
        <Table
          columns={columns}
          data={accountingInstitutionCodesQuery.data || categoryCodeData}
          editable={{
            onUpdateRow: !(isInstitution && ssAcounting === 'worker')
              ? updateAccountingCode
              : undefined,
            hideDelete: true,
            tableMinWidth: 960,
            mutationOptions,
          }}
          options={{
            toolbar: false,
            pagination: false,
            customActionsWidth: 200,
            alignActionsHeader: 'center',
            alignActionsCell: 'center',
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default SetttingsAccountingCategoryAccordion
