import aleluya from './aleluya'
import arus from './arus'

export const colors = {
  nominapp: aleluya,
  arus,
}

export const alertColors = {
  error: 'rgb(253, 234, 234)',
  success: 'rgb(234, 246, 240)',
  info: 'rgb(233, 244, 255)',
  warning: 'rgb(254, 247, 232)',
}

export * from './utils'
