import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import Modal from 'components/UI/Modal/Modal'

import { floppyDisk, redCrossMark } from 'utils/emojis'

import motorcycleWoman from 'assets/images/views/common/motorcycle-woman.png'

const infoMessages = {
  period_date_error: {
    title: `¡Relax! Al parecer tu archivo tiene un error`,
    description:
      'Las fechas en el archivo y del periodo seleccionado no coinciden. Verifica que estés cargando las novedades para el periodo correcto. Si por error modificaste las fechas en el archivo, corrígelas y vuelve a realizar la carga de la plantilla.',
    picture: motorcycleWoman,
    emoji: redCrossMark,
  },
  not_found_workers_errors: {
    title: `No pudimos encontrar personas de tu empresa en el archivo`,
    description:
      'Ninguna de las personas a las cuales intentaste cargar novedades pertenece a la compañía. Revisa que estés cargando el formato correspondiente a la empresa actual.',
    picture: motorcycleWoman,
    emoji: redCrossMark,
  },
  excel_format_error: {
    title: `El archivo cargado no tiene el formato correcto`,
    description:
      'El archivo de Excel que cargaste no corresponde al formato del archivo de carga masiva. Recuerda que puedes descargar el archivo correcto desde la opción "Descargar plantilla".',
    picture: motorcycleWoman,
    emoji: redCrossMark,
  },
  not_found_payrolls_errors: {
    title: `No hay nóminas activas para el periodo en el archivo cargado`,
    description:
      'No encontramos nóminas activas dentro del periodo para las personas a las que intentaste cargar novedades. Verifica que estés cargando el archivo correspondiente al periodo seleccionado.',
    picture: motorcycleWoman,
    emoji: redCrossMark,
  },
  confirm_close_errors: {
    title: '¡Pilas! Al cerrar esta ventana se perderán los datos',
    description:
      'Si cierras esta ventana, perderás el listado de errores presentes en el archivo de carga masiva. ¡Tranqui! Puedes generar un resumen antes de salir.',
    picture: motorcycleWoman,
    emoji: floppyDisk,
  },
}

const BulkNoveltiesCommonInfoModal = ({
  configState,
  handleClose,
  openBulkNoveltiesErrorsModal,
  openBulkNoveltiesErrorsSummaryModal,
  openBulkNoveltiesSummaryModal,
}) => {
  const { open, messageType, goBackState } = configState
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const goBack = () => {
    handleClose()

    if (goBackState?.to === 'BulkNoveltiesErrorsModal') {
      openBulkNoveltiesErrorsModal(goBackState?.bulkUploadResult)
    }

    if (goBackState?.to === 'BulkNoveltiesSummaryModal') {
      openBulkNoveltiesSummaryModal(goBackState?.bulkUploadResult)
    }
  }

  const openSummaryModal = () => {
    handleClose()
    openBulkNoveltiesErrorsSummaryModal(goBackState?.bulkUploadResult)
  }

  return (
    <Modal
      open={open}
      hideFooter
      paperSx={{
        maxWidth: '52rem',
        position: 'relative',
      }}
      contentWrapperSx={{
        overflow: 'hidden',
        minHeight: '22.5rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { mobile: '1fr', tablet: '18rem 1fr' },
        }}
      >
        {!isMobile ? (
          <>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.accent1.main,
                width: '27.75rem',
                height: '27.75rem',
                borderRadius: '100%',
                position: 'absolute',
                left: theme.spacing(-18.5),
                top: theme.spacing(-8.75),
              })}
            />
            <Box
              sx={{
                zIndex: 1,
                display: 'flex',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <img
                loading="lazy"
                src={infoMessages[messageType]?.picture}
                alt=""
                width={304}
                height={315}
                style={{
                  position: 'relative',
                  zIndex: 1,
                  transform: 'scale(0.8)',
                  left: '-2rem',
                }}
              />
            </Box>
          </>
        ) : null}
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.up('tablet')]: {
              paddingLeft: theme.spacing(3),
            },
          })}
        >
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="h2"
                sx={(theme) => ({ marginBottom: theme.spacing(2.5) })}
              >
                {infoMessages[messageType]?.title}{' '}
                <Emoji code={infoMessages[messageType]?.emoji} />
              </Typography>
              <ButtonBase
                onClick={handleClose}
                data-cy="close-dialog-button"
                sx={(theme) => ({
                  alignSelf: 'self-start',
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
              </ButtonBase>
            </Box>
            <Typography
              variant="body1"
              color="black.dark"
              sx={(theme) => ({ marginBottom: theme.spacing(2.5) })}
            >
              {infoMessages[messageType]?.description}
            </Typography>
          </Box>

          <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(2) })}>
            {goBackState?.to !== 'BulkNoveltiesErrorsSummaryModal' &&
            messageType === 'confirm_close_errors' ? (
              <>
                <Button
                  onClick={goBack}
                  sx={{
                    width: '12rem',
                  }}
                >
                  Regresar
                </Button>
                <Button variant="outlined" onClick={openSummaryModal}>
                  Resumen de errores
                </Button>
              </>
            ) : (
              <>
                {messageType === 'confirm_close_errors' ? (
                  <Button onClick={openSummaryModal}>Resumen de errores</Button>
                ) : null}
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{
                    width: '12rem',
                  }}
                >
                  Cerrar
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default BulkNoveltiesCommonInfoModal
