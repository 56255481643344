import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'

import useCompanyHolisticPayrollService from 'utils/hooks/HolisticPayroll/CompayService'

import { noveltyModalValidationSchema } from '../helpers'

export default function ClaimNoveltyModalContent({
  claimData = {},
  handleClose,
}) {
  const queryClient = useQueryClient()
  const location = useLocation()

  const { companyHolisticPayrollMutation: disabilityClaimMutation } =
    useCompanyHolisticPayrollService({
      queryOptions: {
        enabled: false,
      },
    })

  const { id, claim_value, status, institution_id } = claimData
  const refreshClaimsTable = () => {
    queryClient.invalidateQueries('organizerDisabilityClaims')
  }

  const handleSubmit = (values) => {
    const correctedClaimData = new FormData()

    correctedClaimData.append('status', values.status)
    correctedClaimData.append('value', claim_value)

    if (institution_id) {
      correctedClaimData.append('institution_id', institution_id)
    }

    if (values.comment) {
      correctedClaimData.append('comment', values.comment)
    }

    disabilityClaimMutation.mutate(
      {
        mutationMethod: 'PUT',
        claimsDetailId: location.state?.claimDetailId,
        claimNoveltyId: id,
        correctedClaimData,
      },
      {
        onSuccess: () => {
          handleClose()
          refreshClaimsTable()
        },
      }
    )
  }

  return (
    <Box>
      <Typography
        color="black.dark"
        sx={({ spacing }) => ({
          marginBottom: spacing(4),
        })}
      >
        Desde acá puedes reportar una novedad o rechazar una reclamación.
        Tambien puedes agregar comentarios adicionales para especificar a
        detalle el motivo y evitar reprocesos con el cliente.
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={noveltyModalValidationSchema}
        initialValues={{
          status,
          comment: null,
        }}
      >
        {({ values }) => {
          return (
            <Box
              component={Form}
              sx={({ spacing }) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: spacing(4),
              })}
            >
              <FormField
                name="status"
                variant="select"
                label="Novedad de la solicitud"
                optional={false}
                options={[
                  {
                    value: 'no_credential',
                    label: 'Faltan credenciales',
                  },
                  {
                    value: 'no_support',
                    label: 'Falta soporte',
                  },
                  {
                    value: 'rejected',
                    label: 'Rechazada',
                  },
                ]}
              />
              <FormField
                name="comment"
                variant="textarea"
                label="Agregar comentarios adicionales"
                rows="4"
                inputProps={{
                  maxLength: 1000,
                }}
                optional={values.status !== 'rejected'}
              />

              <Box
                sx={({ breakpoints, spacing }) => ({
                  display: 'flex',
                  gap: spacing(2),
                  flexDirection: 'column',
                  marginTop: spacing(3),

                  [breakpoints.up('tablet')]: {
                    flexDirection: 'row',
                  },
                })}
              >
                <Button
                  type="submit"
                  loading={disabilityClaimMutation.isLoading}
                >
                  Enviar información
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  Cancelar
                </Button>
              </Box>
            </Box>
          )
        }}
      </Formik>
    </Box>
  )
}
