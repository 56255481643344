import { useState } from 'react'

import {
  AlertTitle,
  Collapse,
  IconButton,
  Alert as MAlert,
  Typography,
  alertClasses,
} from '@mui/material'

import Icon from 'components/UI/Icon'

const Alert = ({
  header,
  severity = 'error',
  closable = false,
  children,
  fullWidth,
  sx = {},
  ...rest
}) => {
  const [open, setOpen] = useState(true)

  return (
    <Collapse
      in={open}
      sx={{
        minHeight: 'unset !important',
        ...(fullWidth && {
          width: '100%',
        }),
      }}
    >
      <MAlert
        severity={severity}
        color={severity}
        iconMapping={{
          error: <Icon name="circle-x" sx={{ fontSize: '1.5rem' }} />,
          success: <Icon name="circle-check" sx={{ fontSize: '1.5rem' }} />,
          info: <Icon name="info" sx={{ fontSize: '1.5rem' }} />,
          warning: <Icon name="circle-alert" sx={{ fontSize: '1.5rem' }} />,
        }}
        sx={[
          {
            ...(fullWidth && {
              display: 'flex',
              justifyContent: 'center',
              [`& .${alertClasses.message}`]: {
                width: '100%',
              },
            }),
          },
          sx,
        ]}
        action={
          closable ? (
            <IconButton
              aria-label="close alert"
              color={severity}
              size="small"
              onClick={() => {
                setOpen(false)
              }}
              sx={(theme) => ({
                backgroundColor: theme.palette[severity].dark,
                width: '1.5rem',
                height: '1.5rem',
                color: theme.palette[severity].light,
                '&:hover': {
                  backgroundColor: theme.palette[severity].dark,
                },
                '&::before': {
                  content: 'none',
                },
              })}
            >
              <Icon name="x" sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          ) : null
        }
        {...rest}
      >
        {header ? (
          <AlertTitle
            sx={(theme) => ({
              marginBottom: theme.spacing(0.5),
            })}
          >
            {header}
          </AlertTitle>
        ) : null}
        {typeof children === 'string' ? (
          <Typography variant="body2">{children}</Typography>
        ) : (
          children
        )}
      </MAlert>
    </Collapse>
  )
}

export default Alert
