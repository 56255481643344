import {
  Box,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Button from '../Button/Button'
import Icon from '../Icon'

const Modal = ({
  open = true,
  header,
  footer,
  hideFooter = false,
  children,
  onOk,
  okText = 'OK',
  disableOkButton = false,
  isLoading = false,
  loadingText = 'Cargando...',
  onCancel,
  onCloseModal = null, // Only when X button behaviour is different to cancel Button
  cancelText = 'Cancelar',
  hideCloseButton = false,
  hideCancelButton = false,
  hideOkButton = false,
  fullScreenBreakpoint = 'tablet',
  alignButtons = 'left',
  dialogProps = {},
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  dialogContentSx = {},
  paperSx = {},
  dataCy,
  contentWrapperSx = {},
  fullWidthButtonsOnMobile = false,
}) => {
  const fullScreen = useMediaQuery((theme) =>
    theme.breakpoints.down(fullScreenBreakpoint)
  )
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const handleClose = (_, reason) => {
    if (reason === 'backdropClick' && !disableBackdropClick && !isLoading) {
      if (onCancel) {
        onCancel()
      }
      return
    }

    if (reason === 'escapeKeyDown' && !disableEscapeKeyDown && !isLoading) {
      if (onCancel) {
        onCancel()
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      aria-labelledby="dialog-title"
      {...dialogProps}
      scroll="body"
      PaperProps={{
        sx: [
          (theme) => ({
            overflow: 'hidden',
            position: 'relative',
            zIndex: 1,
            boxShadow: theme.shadows[5],
            borderRadius: !isMobile ? '1rem' : 0,
          }),
          paperSx,
        ],
        elevation: 0,
        'data-cy': dataCy,
      }}
    >
      <Box
        id="modal-content-wrapper"
        sx={[
          (theme) => ({
            overflow: 'auto',
            height: '100%',
            zIndex: 2,
            position: 'relative',
            padding: theme.spacing(3, 1.5, 6, 1.5),
            [theme.breakpoints.up('tablet')]: {
              padding: theme.spacing(3, 3, 5, 3),
            },
            [theme.breakpoints.up('laptop')]: {
              padding: theme.spacing(4, 4, 5, 4),
            },
          }),
          contentWrapperSx,
        ]}
      >
        {header ? (
          <DialogTitle
            id="dialog-title"
            component="div"
            sx={(theme) => ({
              padding: theme.spacing(0),
              marginBottom: theme.spacing(2.5),
              marginTop: 0,
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              columnGap: theme.spacing(3.5),
              alignItems: 'start',
            })}
          >
            {typeof header === 'string' ? (
              <Typography variant="h3">{header}</Typography>
            ) : (
              header
            )}
            {(onCancel || onCloseModal) && !hideCloseButton ? (
              <ButtonBase
                onClick={onCloseModal || onCancel}
                disabled={isLoading}
                data-cy="close-dialog-button"
                sx={(theme) => ({
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
              </ButtonBase>
            ) : null}
          </DialogTitle>
        ) : null}
        <DialogContent
          sx={[
            {
              padding: 0,
              zIndex: 2,
              overflow: 'visible',
              position: 'relative',
            },
            dialogContentSx,
          ]}
        >
          {typeof children === 'string' ? (
            <DialogContentText>{children}</DialogContentText>
          ) : (
            children
          )}
        </DialogContent>
        {!hideFooter && footer !== null ? (
          <DialogActions
            sx={(theme) => ({
              padding: theme.spacing(0),
              marginTop: theme.spacing(6),
              justifyContent: 'flex-start',
              zIndex: 2,
              position: 'inherit',
            })}
          >
            {footer || (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  width: '100%',
                  gap: theme.spacing(2),
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  [theme.breakpoints.up('tablet')]: {
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  },
                  ...(alignButtons === 'right' && {
                    justifyContent: 'flex-end',
                  }),
                  ...(alignButtons === 'center' && {
                    justifyContent: 'center',
                  }),
                  ...((hideCancelButton || hideOkButton) &&
                    alignButtons === 'center' && {
                      justifyContent: 'center',
                    }),
                  [theme.breakpoints.down('tablet')]: {
                    ...(fullWidthButtonsOnMobile && {
                      flexDirection: 'column',
                    }),
                  },
                })}
              >
                {!hideOkButton ? (
                  <Button
                    onClick={onOk}
                    disabled={isLoading || disableOkButton}
                    loading={isLoading}
                    data-cy="modal_ok_button"
                  >
                    {isLoading ? loadingText : okText}
                  </Button>
                ) : null}
                {!hideCancelButton ? (
                  <Button
                    onClick={onCancel}
                    variant="outlined"
                    disabled={isLoading}
                    data-cy="modal_cancel_button"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.white.main,
                    })}
                  >
                    {cancelText}
                  </Button>
                ) : null}
              </Box>
            )}
          </DialogActions>
        ) : null}
      </Box>
    </Dialog>
  )
}

export default Modal
