import useLocalStorage from '@rehooks/local-storage'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import { useCancelSubscriptionModal } from 'components/Subscription/Atoms/CancelSubscription/useCancelSubscriptionModal'
import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'
import Loading from 'components/UI/Loading/Loading'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'

import { paymentTypesOptions } from 'utils/company'
import { formatDate } from 'utils/dateTime'
import { getDirtyValues } from 'utils/form'
import useCompanyService from 'utils/hooks/organizer/company'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import * as routes from 'config/routes'

import PaymentAdjustmentsHistory from '../PaymentAdjustmentsHistory'
import {
  fieldOptions,
  getCurrentPaymentCategory,
  getInitialValues,
  getValidationSchema,
  paymentCategoriesOptions,
  tooltipMessages,
} from '../helpers'

const OrganizerCompanyPaymentForm = ({ companyId }) => {
  const queryClient = useQueryClient()
  const [, setCompanyId] = useLocalStorage('company_id')
  const [activeTab, setActiveTab] = useState(0)
  const { handleError } = useErrorHandler()
  const confirm = useConfirm()
  const navigate = useNavigate()
  const { openCancelSubscriptionModal } = useCancelSubscriptionModal()

  const activeTabID = paymentCategoriesOptions[activeTab].id
  const companyQueryKey = ['getCompanyFromOrganizer', companyId]
  const plansQueryKey = ['getPlans', companyId]
  const plansQuery = queryClient.getQueryData(plansQueryKey) || {}
  const companyQuery = queryClient.getQueryData(companyQueryKey) || {}
  const paymentData = { ...companyQuery?.data } || {}

  const {
    partner_company_freemium: isPartner,
    next_payment_date: activeUntil,
  } = companyQuery?.data || {}

  paymentData.payment_category = getCurrentPaymentCategory(
    paymentData.payment_category
  )

  const currentDate = formatDate(new Date())

  const { categoryOptions, subscriptionTypeOptions, partnerCategoryOptions } =
    fieldOptions

  const planOptions = (plansQuery?.data?.plans || []).map((plan) => ({
    label: plan.name,
    value: plan.id,
  }))

  planOptions.push({ label: 'Todos', value: 'all_plans' })

  const { companyMutation } = useCompanyService({
    queryOptions: {
      enabled: false,
    },
  })

  const handlePaymentsChange = (values, form) => {
    const dirtyValues = getDirtyValues(paymentData, values)
    let paymentAdjustmentData = {}

    if (activeTabID === 'payment_adjustment') {
      paymentAdjustmentData = values

      if (isPartner) {
        paymentAdjustmentData.adjustment_type = 'month'
        paymentAdjustmentData.plan_id = 'all_plans'
      }

      if (typeof paymentAdjustmentData?.value === 'string') {
        if (paymentAdjustmentData?.value?.includes('.'))
          paymentAdjustmentData.value = +paymentAdjustmentData?.value?.replace(
            /\./g,
            ''
          )
      }

      paymentAdjustmentData = { payment_adjustment: paymentAdjustmentData }
    }

    companyMutation.mutate(
      {
        mutationMethod: activeTabID === 'payment_adjustment' ? 'POST' : 'PATCH',
        company: {
          id: companyId,
          ...dirtyValues,
        },
        paymentAdjustmentData,
        companyId,
      },
      {
        onSuccess: () => {
          setCompanyId(companyId)
          navigate(routes.SUBSCRIPTION_INDEX())
        },
        onError: (error) => handleError(error, form),
      }
    )
  }

  const handleSubscriptionChanges = (form) => {
    companyMutation.mutate(
      {
        mutationMethod: 'PATCH',
        company: {
          id: companyId,
          active_subscription: !paymentData.active_subscription,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(companyQueryKey)
        },
        onError: (error) => {
          handleError(error, form)
        },
      }
    )
  }

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  const isValidPercentageValue = (value) => {
    const { floatValue } = value
    const finalValue = floatValue || 0

    return finalValue <= 100
  }

  const monthsAllowed = (value) => {
    const { floatValue } = value
    const finalValue = floatValue || null

    return finalValue <= 24 || finalValue === null
  }

  const initialValues = getInitialValues(paymentData, activeTabID)

  const handleContinueCancellation = (form) => {
    paymentData.active_subscription
      ? openCancelSubscriptionModal({
          activeUntil,
          isFromOrganizerView: true,
          subscriptionCancellationData: {
            companyId,
            paymentData,
            companyQueryKey,
          },
        })
      : confirm({
          okText: 'Sí',
          cancelText: 'No',
          type: 'warning',
          title: '¿Estás seguro?',
          onOk: () => handleSubscriptionChanges(form),
        })
  }

  return (plansQuery?.isLoading || companyQuery?.isLoading) &&
    activeTabID === 'payment_adjustment' ? (
    <Loading />
  ) : (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: theme.spacing(4),
        })}
      >
        <RoundedTabs
          value={activeTab}
          tabsConfig={paymentCategoriesOptions}
          onChange={handleChangeTab}
          background="gray"
        />
      </Box>

      <Formik
        onSubmit={
          activeTabID === 'remove_subscription'
            ? handleSubscriptionChanges
            : handlePaymentsChange
        }
        initialValues={initialValues}
        validationSchema={getValidationSchema(
          activeTabID,
          isPartner,
          currentDate
        )}
        enableReinitialize
      >
        {(form) => {
          const { handleSubmit, values, setValues } = form

          const handleChangeCategory = (
            _,
            { props: { value: localCategory } }
          ) => {
            let { value, payment_limit_date: paymentLimitDate } = values

            const comparisonValue =
              typeof value === 'string' && value?.includes('.')
                ? +value?.replace(/\./g, '')
                : +value

            if (localCategory === 'percentage' && comparisonValue > 100) {
              value = ''
            }

            if (localCategory === 'additional_value' && paymentLimitDate) {
              paymentLimitDate = null
            }

            setValues({
              ...values,
              value,
              category: localCategory,
              payment_limit_date: paymentLimitDate,
            })
          }

          return (
            <Form>
              <Box
                sx={(theme) => ({
                  marginTop: theme.spacing(2),
                })}
              >
                {![
                  'remove_subscription',
                  'payment_adjustments_history',
                ].includes(activeTabID) ? (
                  <Box>
                    {activeTabID === 'payment_adjustment' ? (
                      <Box
                        sx={(theme) => ({
                          display: 'flex',
                          flexDirection: 'column',
                          gap: theme.spacing(3),
                        })}
                      >
                        <FormField
                          name="category"
                          label="Tipo de ajuste"
                          variant="select"
                          options={
                            isPartner ? partnerCategoryOptions : categoryOptions
                          }
                          optional={false}
                          onChange={handleChangeCategory}
                          tooltipContent={tooltipMessages.category_type}
                        />
                        <FormField
                          name="value"
                          label="Valor del ajuste"
                          variant="currency"
                          align="left"
                          optional={false}
                          isAllowed={
                            values.category === 'percentage'
                              ? isValidPercentageValue
                              : () => true
                          }
                          startAdornment={
                            values.category === 'percentage' ? '%' : '$'
                          }
                          onChange={({ target: { value: newValue } }) =>
                            setValues({
                              ...values,
                              value: newValue,
                            })
                          }
                        />
                        {!isPartner ? (
                          <>
                            <FormField
                              name="plan_id"
                              label="Plan para el que aplica"
                              variant="select"
                              optional={false}
                              options={planOptions}
                              tooltipContent={tooltipMessages.plan_id}
                            />
                            <FormField
                              name="adjustment_type"
                              label="Tipo de suscripción para la que aplica"
                              variant="select"
                              options={subscriptionTypeOptions}
                              optional={false}
                              tooltipContent={tooltipMessages.adjustment_type}
                            />
                          </>
                        ) : null}
                        {values.category === 'additional_value' ? null : (
                          <FormField
                            minDate={currentDate}
                            name="payment_limit_date"
                            label="Fecha límite de pago"
                            variant="datepicker"
                            placeholder="Entre 1 y 24"
                            tooltipContent={tooltipMessages.payment_limit_date}
                          />
                        )}
                        <FormField
                          name="adjustment_months"
                          label="Número de meses de vigencia del ajuste"
                          variant="number"
                          placeholder="Entre 0 y 24 meses"
                          optional={
                            !['recurring_payment', 'unique_payment'].includes(
                              values.category
                            )
                          }
                          isAllowed={monthsAllowed}
                          onChange={({ target: { value: newValue } }) =>
                            setValues({
                              ...values,
                              adjustment_months: newValue,
                            })
                          }
                          tooltipContent={tooltipMessages.adjustment_months}
                        />
                        <FormField
                          name="comment"
                          label="Comentario"
                          optional={false}
                          tooltipContent={tooltipMessages.comment}
                        />
                      </Box>
                    ) : null}
                    {activeTabID === 'change_date' ? (
                      <FormField
                        name="next_payment_date"
                        label="Fecha límite"
                        variant="datepicker"
                      />
                    ) : null}
                    {activeTabID === 'payment_type' ? (
                      <>
                        <FormField
                          name="payment_category"
                          label="Tipo de Pago"
                          options={paymentTypesOptions}
                          variant="select"
                        />
                      </>
                    ) : null}
                    <Box
                      sx={(theme) => ({
                        marginTop: theme.spacing(3),
                        display: 'flex',
                        justifyContent: 'flex-end',
                      })}
                    >
                      <Button
                        color="primary"
                        loading={companyMutation.isLoading}
                        onClick={handleSubmit}
                      >
                        Guardar
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <>
                    {activeTabID === 'payment_adjustments_history' ? (
                      <PaymentAdjustmentsHistory
                        companyId={companyId}
                        plans={plansQuery?.data?.plans || []}
                        monthsAllowed={monthsAllowed}
                      />
                    ) : null}
                    {activeTabID === 'remove_subscription' ? (
                      <Box
                        sx={(theme) => ({
                          margin: theme.spacing(3, 0),
                          display: 'flex',
                          justifyContent: 'center',
                        })}
                      >
                        <Button
                          color="primary"
                          variant={
                            paymentData.active_subscription
                              ? 'outlined'
                              : 'contained'
                          }
                          size="xlarge"
                          onClick={() => handleContinueCancellation(form)}
                          loading={companyMutation.isLoading}
                        >
                          {paymentData.active_subscription
                            ? 'Cancelar'
                            : 'Activar'}{' '}
                          suscripción
                        </Button>
                      </Box>
                    ) : null}
                  </>
                )}
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default OrganizerCompanyPaymentForm
