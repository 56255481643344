import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Button as MButton, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'
import Stepper from 'components/UI/Stepper'

import { getCompanyId } from 'utils/company'
import { technologistWoman } from 'utils/emojis'
import useElectronicSignatureService from 'utils/hooks/electronicSignature/electronicSignature'
import useNotifications from 'utils/hooks/useNotifications'

import PeopleToSign from './FormSteps/PeopleToSign'
import UploadFiles from './FormSteps/UploadFiles'
import ValidationMethod from './FormSteps/ValidationMethod'
import { initialValues, stepsData, validationSchema } from './helpers'

const ElectronicSignatureContent = ({
  currentStep,
  setCurrentStep,
  handleClose,
  openConfirmationModal,
  workerId,
  workerName,
  workerEmail,
}) => {
  const companyId = getCompanyId()
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()

  const [peopleToSign, setPeopleToSign] = useState([
    {
      signed_name: workerName,
      signed_email: workerEmail,
    },
  ])

  const { electronicSignatureMutation } = useElectronicSignatureService({
    queryOptions: { enabled: false },
  })

  const handleSubmitForm = (values, form) => {
    if (currentStep < 2) {
      setCurrentStep((previousStep) => previousStep + 1)
    }

    if (currentStep === 2) {
      const electronicSignatureData = new FormData()

      if (values.date_limit_to_sign !== null) {
        electronicSignatureData.append(
          'date_limit_to_sign',
          values.date_limit_to_sign
        )
      }

      if (values.document instanceof File) {
        electronicSignatureData.append('document', values.document)
      }

      if (values.require_selfie_photo) {
        electronicSignatureData.append(
          'require_selfie_photo',
          values.require_selfie_photo
        )
      }

      electronicSignatureData.append('signers', JSON.stringify(values.signers))

      electronicSignatureData.append(
        'with_attachments',
        values.with_attachments
      )

      electronicSignatureMutation.mutate(
        {
          mutationMethod: 'POST',
          workerId,
          electronicSignatureData,
        },
        {
          onSuccess: async ({ message }) => {
            await queryClient.invalidateQueries([
              'getCompanyOnboardings',
              companyId,
            ])
            showSuccessMessage(message)
            handleClose()
            form.resetForm()
            openConfirmationModal(peopleToSign)
          },
        }
      )
    }
  }

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((previousStep) => previousStep - 1)
    } else {
      handleClose()
    }
  }

  return (
    <>
      {currentStep === 0 ? (
        <Typography
          variant="h3"
          sx={(theme) => ({ margin: theme.spacing(3, 0, 1) })}
        >
          Firma electrónica de documentos <Emoji code={technologistWoman} />
        </Typography>
      ) : null}
      <Typography variant="body1" color="black.dark">
        Recoge firmas de contratos cómo flash, cargando aquí tus documentos y
        permitiendo que las personas firmen desde su celular..
      </Typography>
      <Box sx={(theme) => ({ margin: theme.spacing(4, 0, 0) })}>
        <Stepper
          stepsData={stepsData}
          current={currentStep}
          progress={currentStep}
          onChangeStep={(step) => setCurrentStep(step)}
        />

        <Box sx={(theme) => ({ margin: theme.spacing(3, 0, 0) })}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema[currentStep]}
          >
            {({ handleSubmit }) => {
              return (
                <Form>
                  <Typography
                    variant="h6"
                    color="primary.dark"
                    sx={(theme) => ({
                      margin: theme.spacing(0, 0, 2.5),
                    })}
                  >
                    {stepsData[currentStep].pageTitle}
                  </Typography>
                  {currentStep === 0 ? <UploadFiles /> : null}
                  {currentStep === 1 ? <ValidationMethod /> : null}
                  {currentStep === 2 ? (
                    <PeopleToSign
                      peopleToSign={peopleToSign}
                      setPeopleToSign={setPeopleToSign}
                    />
                  ) : null}
                  <Box
                    sx={(theme) => ({
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      gap: theme.spacing(2),
                      marginTop: theme.spacing(6),
                      flexWrap: 'wrap',
                      [theme.breakpoints.up('tablet')]: {
                        justifyContent: 'flex-start',
                      },
                    })}
                  >
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      loading={electronicSignatureMutation.isLoading}
                    >
                      {currentStep === 2
                        ? 'Enviar documentos'
                        : 'Guardar y continuar'}
                    </Button>
                    <MButton
                      variant="outlined"
                      onClick={handleBack}
                      disabled={electronicSignatureMutation.isLoading}
                    >
                      {currentStep === 0
                        ? 'Cancelar'
                        : 'Volver al paso anterior'}
                    </MButton>
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </Box>
      </Box>
    </>
  )
}

export default ElectronicSignatureContent
