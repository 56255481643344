import * as yup from 'yup'

import { Box, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { yupLocaleES } from 'utils/form'
import { validPassword } from 'utils/regex'

yup.setLocale(yupLocaleES)

export const getValidationSchema = () => {
  return yup.object({
    name: yup
      .string()
      .min(3, 'El nombre es muy corto. Debe contener mínimo 3 caracteres')
      .max(50, 'El nombre es muy largo. Debe contener máximo 200 caracteres.')
      .required(),
    email: yup.string().email().required(),
    password: yup
      .string()
      .min(8, 'Tu contraseña debe contener mínimo 8 caracteres.')
      .matches(
        validPassword,
        'La contraseña debe incluir números, al menos una letra mayúscula, una minúscula, y caracteres especiales como "#$!*@%&".'
      )
      .required(),
  })
}

export const getInitialValues = ({ name, email, phone }) => ({
  password: '',
  company_name: '',
  company_id_number: '',
  // Data from landing page pass by query params
  name: name || '',
  email: email || '',
  phone: phone || '',
})

export function CustomPasswordFieldError({ errors }) {
  return (
    <Box
      sx={({ spacing, breakpoints }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: spacing(1),
        marginTop: spacing(1),
        [breakpoints.down('tablet')]: {
          columnGap: spacing(0.25),
        },
      })}
    >
      {errors.map((error) => {
        return (
          <Box
            key={error.content}
            sx={({ spacing, breakpoints }) => ({
              display: 'flex',
              alignItems: 'center',
              gap: spacing(0.5),
              [breakpoints.down('tablet')]: {
                gap: spacing(0.125),
              },
            })}
          >
            <Icon
              sx={({ palette, spacing }) => ({
                fontSize: '1.1rem',
                paddingBottom: spacing(0.2),
                color: palette.success.dark,
                ...(error.show && {
                  color: palette.error.dark,
                }),
              })}
              name={error.show ? 'x' : 'check'}
            />
            <Typography variant="tiny">{error.content}</Typography>
          </Box>
        )
      })}
    </Box>
  )
}
