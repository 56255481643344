import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import { getAccountingFile } from 'services/payroll/fileService'
import { getAccountingCodes } from 'services/settings/accountingCodesService'
import { getAccountingInstitutionsCodes } from 'services/settings/accountingInstitutionCodesService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, category, categories } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'accountingCodes')
    return getAccountingCodes(category, categories)

  if (currentQueryKey === 'accountingInstitutionsCodes')
    return getAccountingInstitutionsCodes(category)

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    mutationKey,
    periodId,
    providerId,
    fileOptions,
    periodOption,
  } = mutationData

  if (mutationMethod === 'GET' && mutationKey === 'getAccountingFile') {
    return getAccountingFile({
      periodId,
      providerId,
      fileOptions,
      periodOption,
    })
  }

  return null
}

const useAccountingService = ({
  serviceParams = { queryKey: 'accountingCodes' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restAccountingResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => {
        handleError(error)
      },
      ...queryOptions,
    }
  )

  const accountingMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    accountingQuery: {
      data,
      ...restAccountingResponse,
    },
    accountingMutation,
  }
}

export default useAccountingService
