import LinkButton from 'components/UI/Button/LinkButton'
import Link from 'components/UI/MaterialUI/Link'
import NoWrap from 'components/UI/NoWrap'

import { isOrganizer } from 'utils/auth'
import { formatDisplayDate } from 'utils/dateTime'
import { contractCategories } from 'utils/worker'

import * as routes from 'config/routes'

const LinksSeparator = () => {
  return (
    <div
      style={{
        margin: '0 4px',
      }}
    >
      |
    </div>
  )
}

const getColumnsData = (
  workerId,
  handleEditContract,
  handleDeleteTermination,
  handlePayslipTermination,
  handleDeleteContract
) => {
  return [
    {
      Header: '#',
      accessor: 'number',
      customWidth: '4.375rem',
    },
    {
      Header: 'Tipo de contrato',
      accessor: 'category',
      Cell: ({ row }) =>
        contractCategories[row.original.category]
          ? contractCategories[row.original.category]
          : row.original.category,
    },
    {
      Header: 'Fecha inicial',
      accessor: 'initial_day',
      Cell: ({ row }) => formatDisplayDate(row.original.initial_day),
    },
    {
      Header: 'Fecha final',
      accessor: 'end_day',
      Cell: ({ row }) => formatDisplayDate(row.original.end_day) || 'No aplica',
    },
    {
      Header: 'Opciones contrato',
      accessor: 'contract_options',
      Cell: ({ row }) => {
        const { id, terminated, category } = row.original
        return (
          <>
            <Link
              to={routes.WORKER_CONTRACT_WAGE_INDEX(workerId, row.original.id)}
              state={{ contract: row.original }}
            >
              Salarios
            </Link>
            {!terminated ? (
              <>
                <LinksSeparator />
                <LinkButton
                  onClick={() => handleEditContract(row.original)}
                  data-cy="edit_contract_button"
                >
                  Editar
                </LinkButton>
              </>
            ) : null}
            {category === 'employee' ? (
              <>
                <LinksSeparator />
                <Link
                  to={routes.WORKER_CONTRACT_SOCIAL_BENEFITS_INDEX(
                    workerId,
                    row.original.contract_detail.contract_id
                  )}
                >
                  <NoWrap>Prestaciones sociales</NoWrap>
                </Link>
              </>
            ) : null}
            {isOrganizer() ? (
              <>
                <LinksSeparator />
                <LinkButton onClick={() => handleDeleteContract(id)}>
                  Eliminar
                </LinkButton>
              </>
            ) : null}
          </>
        )
      },
    },
    {
      Header: 'Opciones liquidación',
      accessor: 'termination_options',
      Cell: ({ row }) => {
        const { id, terminated, termination } = row.original
        if (terminated) {
          return (
            <>
              <LinkButton onClick={() => handlePayslipTermination(id)}>
                Ver colilla
              </LinkButton>
              <LinksSeparator />
              <Link to={routes.WORKER_CONTRACT_TERMINATION_EDIT(workerId, id)}>
                Editar
              </Link>
              {termination.deleteable ? (
                <>
                  <LinksSeparator />
                  <LinkButton onClick={() => handleDeleteTermination(id)}>
                    Eliminar
                  </LinkButton>
                </>
              ) : null}
            </>
          )
        }

        return (
          <Link to={routes.WORKER_CONTRACT_TERMINATION_NEW(workerId, id)}>
            <NoWrap>Liquidar contrato</NoWrap>
          </Link>
        )
      },
    },
  ]
}

export default getColumnsData
