import { Link } from 'react-router-dom'

import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

import useRetentionsConfigurationService from 'utils/hooks/worker/retentionConfigurationService'

import { PERIOD_PAYROLL_VIEW_DETAILS } from 'config/routes'

import CardTable from '../../common/CardGrid'
import GridRow from '../../common/CardGrid/GridRow'
import HeaderCell from '../../common/CardGrid/HeaderCell'
import HeaderRow from '../../common/CardGrid/HeaderRow'
import MobileCell from '../../common/CardGrid/MobileCell'

const PayrollRetentionValue = ({
  payrollRetentionInfo,
  handleChangeItemValue,
  payrollId,
  workerId,
}) => {
  const queryKey = ['retentionConfigurations', workerId]
  const { retentionsQuery } = useRetentionsConfigurationService({
    serviceParams: {
      queryKey,
      workerId,
    },
    queryOptions: {
      enabled: Boolean(workerId),
      onError: () => {},
    },
  })
  const isPayrollRetentionConceptActive = retentionsQuery?.data?.active

  const { payrollRetentionItem, payrollRetentionItemIndex } =
    payrollRetentionInfo
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(2),
      })}
    >
      <Typography variant="h6" color="black.main">
        Valor de retención en la fuente
      </Typography>
      <CardTable gridColumns="1fr 1fr 0.6fr">
        <HeaderRow>
          <HeaderCell
            sx={{
              gridColumn: 1,
            }}
            desktopOnly
          >
            Concepto
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 2,
            }}
            desktopOnly
          >
            Valor
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 3,
            }}
            desktopOnly
          >
            Acciones
          </HeaderCell>
        </HeaderRow>
        <GridRow>
          <HeaderCell
            sx={{
              gridColumn: 1,
              gridRow: 1,
            }}
            mobileOnly
          >
            Concepto
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 1,
              gridRow: 2,
            }}
            mobileOnly
            alignY="center"
          >
            Valor
          </HeaderCell>
          <HeaderCell
            sx={{
              gridColumn: 1,
              gridRow: 3,
            }}
            mobileOnly
            alignY="center"
          >
            Acciones
          </HeaderCell>
          <MobileCell
            alignX={isTabletUp ? 'left' : 'right'}
            sx={(theme) => ({
              gridColumn: '2 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 1,
              },
            })}
          >
            <Typography
              variant="h6"
              color="black.dark"
              htmlFor="payrollRetentionValueInput"
              component="label"
            >
              {payrollRetentionItem.name}
            </Typography>
          </MobileCell>
          <MobileCell
            alignX={isTabletUp ? 'left' : 'right'}
            alignY="center"
            sx={(theme) => ({
              gridColumn: '2 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 2,
              },
            })}
          >
            <TextField
              sx={{ width: '12rem' }}
              id="payrollRetentionValueInput"
              value={payrollRetentionItem.value}
              onChange={(e) =>
                handleChangeItemValue(e, payrollRetentionItemIndex)
              }
              InputProps={{
                inputComponent: NumberFormatField,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                sx: {
                  maxWidth: '12rem',
                  height: '2rem',
                },
              }}
              data-cy="payroll_retention-input"
            />
          </MobileCell>
          <MobileCell
            alignX={isTabletUp ? 'left' : 'right'}
            alignY="center"
            sx={(theme) => ({
              gridColumn: '2 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 3,
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                columnGap: theme.spacing(1),
                alignItems: 'center',
              })}
            >
              <Button
                component={Link}
                to={`${PERIOD_PAYROLL_VIEW_DETAILS(
                  payrollId
                )}?selected_tab=payroll_retention`}
                target="_blank"
                variant="text"
                size="small"
                sx={{
                  flexShrink: 0,
                }}
                startIcon={
                  isPayrollRetentionConceptActive ? <Icon name="eye" /> : null
                }
                disabled={!isPayrollRetentionConceptActive}
              >
                Ver cálculos
              </Button>
              {!isPayrollRetentionConceptActive ? (
                <TooltipInfoIcon
                  title={
                    <>
                      <Typography variant="h6">
                        ¡Ups! No puedes ver estos cálculos.
                      </Typography>
                      <Typography>
                        Para ver los cálculos por concepto recurrente de
                        Retefuente, activa esta opción en el perfil de la
                        persona.
                      </Typography>
                    </>
                  }
                />
              ) : null}
            </Box>
          </MobileCell>
        </GridRow>
      </CardTable>
    </Box>
  )
}

export default PayrollRetentionValue
