import { useLocation } from 'react-router-dom'

import useModals from 'components/App/ModalsManager/useModals'

import UpdateCredentialsModalContent from './CredentialsModalContent'

const useUpdateCredentialsModal = () => {
  const modals = useModals()
  const location = useLocation()

  const insInHolisticPayrollVeiw =
    location.pathname.includes('holistic_payroll')

  const handleClose = () => modals.closeModal('reviewUpdateCredentialsModal')

  const openUpdateCredentialsModal = (
    workerId,
    processType,
    claimDetailData
  ) => {
    modals.openModal({
      id: 'reviewUpdateCredentialsModal',
      content: (
        <UpdateCredentialsModalContent
          workerId={workerId}
          handleClose={handleClose}
          setLoadingModal={modals.setLoadingModal}
          processType={processType}
          claimDetailData={claimDetailData}
        />
      ),
      modalProps: {
        header: insInHolisticPayrollVeiw
          ? '¡Ojo! Revisa tu solicitud de reclamación'
          : 'Debes revisar tu solicitud de afiliación a seguridad social',
        onCloseModal: handleClose,
        onCancel: handleClose,
        hideFooter: true,
        paperSx: {
          maxWidth: '42rem',
        },
      },
    })
  }

  return {
    openUpdateCredentialsModal,
  }
}

export default useUpdateCredentialsModal
