import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Link, Button as MuiButton, useMediaQuery } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import { usePremiumActions } from 'components/App/Premium/helpers'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import AffiliationActionsModalContent from 'components/Organizer/Affiliations/AffiliationActionsModalContent'
import usePayslipModal from 'components/Period/Payroll/Modals/usePayslipModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'
import useWorker from 'components/Worker/useWorker'

import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'

import * as routes from 'config/routes'

import WorkerShowActivateButton from '../../Body/ActivateButton'
import { WorkerProfileContext } from '../../Show'
import CertificatesMenu from './Menus/CertificatesMenu'
import HistoryMenu from './Menus/HistoryMenu'
import TerminationMenu from './Menus/TerminationMenu'

const WorkerHeaderActions = () => {
  const queryClient = useQueryClient()
  const { affiliationsMutation } = useAffiliationsService({
    queryOptions: {
      enabled: false,
    },
  })
  const { activeTab, setActiveTab } = useContext(WorkerProfileContext)
  const { worker = {}, isFromAffiliationView } = useWorker({ useCache: true })
  const { isWorker: isWorkerRole, user } = useUser()
  const { handleDownloadPayslip } = usePayslipModal()
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const navigate = useNavigate()
  const confirm = useConfirm()
  const modals = useModals()
  const { showSuccessMessage } = useNotifications()
  const { handlePayExtraWorkers } = usePremiumActions()
  const { subscription } = useSubscription()
  const hasToPayNewWorkers = !subscription?.additional_workers_info?.add_workers
  const queryKey = ['getWorkerOptions', worker.id]
  const workerProfileQueryKey = ['getWorkerProfileOptions', worker.id]

  const hasCertificatesAccess =
    worker.workers_income_certificate_access ||
    worker.workers_labor_certificate_access

  const affiliationCache = queryClient.getQueryData([
    'getAffiliationById',
    worker.id,
  ])

  const isAffiliationApproved = affiliationCache?.data?.status === 'approved'
  const isAffiliationReceived = affiliationCache?.data?.status === 'received'
  const isAffiliationRejected = affiliationCache?.data?.status === 'rejected'
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const { workerQuery } = useWorkerService({
    serviceParams: {
      queryKey: isWorkerRole ? workerProfileQueryKey : queryKey,
      workerId: worker.id,
    },
    queryOptions: {
      enabled: Boolean(worker.id),
    },
  })

  const { data: workerOptions } = workerQuery

  const { showPremiumFeatures, showHRFeatures, openPremiumFeatureModal } =
    usePremiumFeature()

  const handleClickViewPayslip = async () => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }
    showLoadingModal()
    await handleDownloadPayslip({ payroll: { id: worker.payroll_id } })
    hideLoadingModal()
  }

  const handleReintegration = () => {
    if (hasToPayNewWorkers) {
      handlePayExtraWorkers()
    } else {
      confirm({
        type: 'warning',
        title: 'Reincorporar persona',
        description: (
          <>
            ¿Estás seguro de querer reincorporar a esta persona? Esta acción no
            puede deshacerse. Si quieres saber más sobre la reincorporación
            puedes leer nuestro{' '}
            <Link
              href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/reintegra-un-empleado-que-ya-ha-sido-liquidado"
              target="_blank"
            >
              Centro de ayuda.
            </Link>
          </>
        ),
        okText: 'Reincorporar',
        onOk: () =>
          navigate(routes.WORKER_CONTRACT_INDEX(worker.id), {
            state: {
              worker,
              createContract: true,
            },
          }),
      })
    }
  }

  const handleTermination = () => {
    navigate(
      routes.WORKER_CONTRACT_TERMINATION_NEW(worker.id, worker.contract_id)
    )
  }

  const handleOpenFreeTime = () => {
    if (activeTab !== 3) {
      setActiveTab(3)
    }
  }

  const isContractor = worker.contract_category === 'contractor'

  const showAffiliationActionsModal = (action) => {
    const isRejectAction = action === 'rejected'

    modals.openModal({
      id: `actionsModal`,
      content: (
        <AffiliationActionsModalContent action={action} workerId={worker.id} />
      ),
      modalProps: {
        header: `${isRejectAction ? (isAffiliationReceived ? 'Rechazar o reportar una novedad en' : 'Rechazar') : 'Aprobar'} afiliación a seguridad social de ${worker.name}`,
        hideFooter: true,
        onCloseModal: () => modals.closeAll(),
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
  }

  const handleReceivedAffiliation = () => {
    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId: worker.id,
        affiliationData: {
          status: 'received',
          new_response: true,
        },
      },
      {
        onSuccess: async () => {
          showSuccessMessage('La afiliación ha sido aceptada')
          modals.closeAll()
          await queryClient.invalidateQueries(['getAllAffiliations'], {
            exact: false,
          })
          navigate(routes.ORGANIZER_AFFILIATIONS())
        },
      }
    )
  }

  if (!workerOptions && workerQuery.isLoading) return <LoadingBox />

  if (isFromAffiliationView) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          [theme.breakpoints.up('tablet')]: {
            flexDirection: 'row',
          },
        })}
      >
        <Button
          onClick={() => handleReceivedAffiliation()}
          disabled={isAffiliationApproved || isAffiliationReceived}
          size="large"
          endIcon={<Icon name="user-check" />}
          loading={affiliationsMutation.isLoading}
          sx={{
            width: {
              mobile: 1,
              tablet: 'auto',
            },
          }}
        >
          Aceptar afiliación
        </Button>
        <Button
          variant="outlined"
          onClick={() => showAffiliationActionsModal('rejected')}
          disabled={isAffiliationApproved || isAffiliationRejected}
          size="large"
          endIcon={<Icon name="user-x" />}
          sx={{
            width: {
              mobile: 1,
              tablet: 'auto',
            },
          }}
        >
          Rechazar afiliación{' '}
          {isAffiliationReceived ? ' o reportar novedad' : ''}
        </Button>
        <Button
          variant="outlined"
          onClick={() => showAffiliationActionsModal('approved')}
          disabled={isAffiliationApproved || !isAffiliationReceived}
          size="large"
          endIcon={<Icon name="user-search" />}
          sx={{
            width: {
              mobile: 1,
              tablet: 'auto',
            },
          }}
        >
          Completar Afiliación
        </Button>
      </Box>
    )
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
      })}
    >
      {/* Certificados */}
      {((showPremiumFeatures && isWorkerRole && hasCertificatesAccess) ||
        !isWorkerRole) &&
      workerOptions?.certificate_options ? (
        <CertificatesMenu
          worker={worker}
          workerOptions={workerOptions}
          isWorkerRole={isWorkerRole}
        />
      ) : null}
      {/* Liquidación */}
      {!isWorkerRole ? (
        <>
          {/* Historiales */}
          {workerOptions?.history_options ? (
            <HistoryMenu worker={worker} workerOptions={workerOptions} />
          ) : null}

          {worker.terminated ? (
            <TerminationMenu
              worker={worker}
              confirm={confirm}
              isEditable={workerOptions?.editable_termination}
            />
          ) : null}
        </>
      ) : null}
      {/* Colilla de pago */}
      {worker.payroll_id &&
      ((showPremiumFeatures && worker.workers_payslips_access) ||
        !isWorkerRole) ? (
        <Button
          variant="outlined"
          onClick={handleClickViewPayslip}
          endIcon={
            showPremiumFeatures ? <Icon name="eye" /> : <PremiumFeatureIcon />
          }
          size="large"
          sx={{
            width: {
              mobile: 1,
              tablet: 'auto',
            },
          }}
        >
          {`Ver última colilla ${isMobile ? '' : 'de pago'}`}
        </Button>
      ) : null}
      {isWorkerRole &&
      (worker.workers_handle_novelties || user.workersHandleNovelties) &&
      showHRFeatures &&
      !isContractor ? (
        <Button
          variant="outlined"
          onClick={handleOpenFreeTime}
          size="large"
          sx={{
            width: {
              mobile: 1,
              tablet: 'auto',
            },
          }}
          endIcon={<Icon name="calendar-1" />}
        >
          Solicitar tiempo fuera
        </Button>
      ) : null}
      {['employee', 'part_time_contract'].includes(worker.contract_category) ? (
        <>
          {!isWorkerRole ? (
            <>
              {worker.terminated ? (
                <Button
                  variant="outlined"
                  onClick={handleReintegration}
                  size="large"
                  sx={{
                    width: {
                      mobile: 1,
                      tablet: 'auto',
                    },
                  }}
                  endIcon={<Icon name="refresh-cw" />}
                >
                  Reincorporar
                </Button>
              ) : (
                <>
                  {worker.terminatable ? (
                    <MuiButton
                      onClick={handleTermination}
                      variant="outlined"
                      data-cy="termination-button"
                      endIcon={<Icon name="circle-dollar-sign" />}
                      size="large"
                      sx={{ width: { mobile: 1, tablet: 'auto' } }}
                    >
                      Liquidar
                    </MuiButton>
                  ) : null}
                </>
              )}
            </>
          ) : null}
        </>
      ) : (
        <WorkerShowActivateButton isWorker={isWorkerRole} worker={worker} />
      )}
    </Box>
  )
}

export default WorkerHeaderActions
