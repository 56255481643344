import generateSimpleMessage from './utils'

export default {
  account_number:
    'Es necesario para generar el archivo con el cual vas a pagar en la sucursal virtual de tu banco una vez hayas liquidado tu nómina. No realizaremos ninguna transacción a este número de cuenta.',
  location:
    'Las opciones de “Sedes” fueron las creadas cuando registraste la empresa. Si deseas agregar otras opciones, debes ir a “Empresa” y editar la sección “Sedes”.',
  area: 'Las opciones de “Áreas” fueron las creadas cuando registraste la empresa. Si deseas agregar otras opciones, debes ir a “Empresa” y editar la sección “Áreas”.',
  position:
    'Las opciones de “Cargos” fueron las creadas cuando registraste la empresa. Si deseas agregar otras opciones, debes ir a “Empresa” y editar la sección “Cargos”.',
  costCenter:
    'El centro de costos permitirá asociar a cada persona a una cuenta contable lo cual facilitará el ingreso de la información al software contable.',
  accumulated_holidays_editing: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-los-días-de-vacaciones-acumuladas',
    'Este valor indica los días de vacaciones pendientes a la fecha de corte del periodo anterior, restándole las vacaciones disfrutadas en el período actual.'
  ),
  proportional_recurrent_payments:
    'Los ingresos o deducciones recurrentes dependerán del número de días trabajados (proporcionales) o serán un valor constante en el periodo (fijos).',
  accumulated_holidays:
    'Número de días de vacaciones disfrutados o remunerados a la fecha de corte del período anterior.',
  allow_web_access: ({ showPremiumFeatures, showHRFeatures }) => {
    if (showHRFeatures)
      return 'Si habilitas el acceso web, esta persona podrá solicitar vacaciones, licencias e incapacidades, visualizar y descargar sus colillas de pago. Te ahorrarás muuucho tiempo.'
    if (showPremiumFeatures)
      return 'Si habilitas el acceso web, esta persona podrá ver sus datos y los pagos realizados, visualizar y descargar sus colillas de pago. Te ahorrarás muuucho tiempo.'
    return 'Si habilitas el acceso web, esta persona podrá ver sus datos y los pagos realizados desde su propio perfil de Aleluya.'
  },
}
