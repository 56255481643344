import { useFormikContext } from 'formik'
import { useState } from 'react'

import { IconButton, InputAdornment } from '@mui/material'

import Icon from 'components/UI/Icon'

import FormField from '../FormField/Index'

const PasswordField = ({
  name,
  label = 'Contraseña',
  validatePassword,
  customPasswordFieldError,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const { setFieldValue } = useFormikContext()

  const handleOnClick = () => setShowPassword((previousValue) => !previousValue)

  const handleOnChange = (event) => {
    const value = event.target.value
    setFieldValue(name, value)
    validatePassword && validatePassword(value)
  }

  return (
    <FormField
      name={name}
      label={label}
      data-cy={name}
      {...props}
      type={showPassword ? 'text' : 'password'}
      customErrorField={customPasswordFieldError}
      onChange={handleOnChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={handleOnClick}
            edge="start"
            disabled={props.disabled}
            sx={(theme) => ({
              backgroundColor: 'transparent',
              color: theme.palette.black.light,
              '::before': {
                content: 'none',
              },
              '&:focus': {
                border: 'none',
                backgroundColor: 'transparent',
                color: theme.palette.black.light,
              },
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&:disabled': {
                backgroundColor: 'transparent',
              },
            })}
          >
            {showPassword ? <Icon name="eye-off" /> : <Icon name="eye" />}
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

export default PasswordField
