import { useLocation } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import usePartnerSubscriptionModal from 'components/Subscription/Index/Plan/PartnerSubscription/usePartnerSubscriptionModal'

import ellipseBackground from 'assets/images/views/common/ellipse_background.svg'
import keys from 'assets/images/views/common/keys.png'

import { COMPANY_INDEX } from 'config/routes'

import { useUser } from '../UserContext/useUser'
import { getSubscriptionAlertCopy, useButtonConfig } from './helpers'

const SubscriptionAlertCard = ({ handleCloseMobileDrawer }) => {
  const location = useLocation()
  const { openPartnerSubscriptionModal } = usePartnerSubscriptionModal()
  const { isAPartnerChild, subscription } = useUser()
  const { status: subscriptionStatus } = subscription || {}
  const { label, action } = useButtonConfig(subscriptionStatus)

  const handleUpdateSubscription = () => {
    if (isAPartnerChild) {
      openPartnerSubscriptionModal()
    } else {
      action()
    }

    handleCloseMobileDrawer()
  }

  if (
    location.pathname === COMPANY_INDEX() ||
    location.pathname.includes('/organizer')
  )
    return null

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        alignSelf: 'center',
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(1.5),
        alignItems: 'center',
        padding: theme.spacing(2, 1, 3, 1),
        color: theme.palette.white.main,
        background:
          'linear-gradient(135deg, rgb(134,140,255) 0%, rgb(67,24,255) 100%)',
        borderRadius: '1rem',
        margin: theme.spacing(10, 3, 3, 3),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          top: '-3.5rem',
          left: '-50%',
          right: '-50%',
        }}
      >
        <Box
          component="img"
          loading="lazy"
          src={keys}
          alt="Usa estas llaves para abrir las puertas de tu nuevo plan"
          sx={{
            backgroundImage: `url(${ellipseBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </Box>
      <Typography
        variant="h5"
        sx={(theme) => ({
          textAlign: 'center',
          paddingTop: theme.spacing(6),
        })}
      >
        ¡¡¡Ohh, Yisus!!!
      </Typography>
      <Box
        sx={(theme) => ({
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(1),
        })}
      >
        {getSubscriptionAlertCopy(subscription)}
      </Box>
      <Button
        onClick={handleUpdateSubscription}
        sx={(theme) => ({
          paddingX: theme.spacing(1),
          border: `2px solid ${theme.palette.white.main} !important`,
          color: theme.palette.black.main,
          '&:hover': {
            color: theme.palette.black.main,
          },
        })}
        size="small"
        variant="outlined"
      >
        <Typography variant="lead2">{label}</Typography>
      </Button>
    </Box>
  )
}

export default SubscriptionAlertCard
