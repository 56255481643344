import { isOrganizer } from 'utils/auth'
import { isDev } from 'utils/general'

import appcues from './integrations/scripts/appcues'
import { googleOptimizeScript } from './integrations/scripts/googleOptimize'
import { googleTagManagerScript } from './integrations/scripts/googleTagManager'
import headwayScript from './integrations/scripts/headway'

// Function to load integration data
export default async function loadIntegrationsData(company, subscription) {
  if (isOrganizer() || isDev) return

  const userId = localStorage.getItem('user_id')

  // Appcues
  await appcues.initialConfiguration(userId, company, subscription)

  // to verify, reload page
  window.Appcues?.page()
}

export const commonScripts = {
  // Analitycs
  googleOptimize: googleOptimizeScript,
  googleTagManager: googleTagManagerScript,
  // Tracking Events
  appcues: appcues.script,
  headway: headwayScript,
}
