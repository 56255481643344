import {
  Box,
  MobileStepper,
  Stepper as MuiStepper,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Typography,
  linearProgressClasses,
  mobileStepperClasses,
  stepConnectorClasses,
  stepLabelClasses,
  useMediaQuery,
} from '@mui/material'

import Icon from './Icon'

const getStepStatus = (index, current, progress) => {
  if (index === current) return 'current'
  if (index < progress) return 'completed'
  if (index > progress) return 'disabled'
  return 'pending'
}

const CustomConnector = () => {
  return (
    <StepConnector
      sx={(theme) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: theme.spacing(2),
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.dark,
          },
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.dark,
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: theme.palette.white.light,
          opacity: 1,
          borderTopWidth: 1,
        },
      })}
    />
  )
}

const Stepper = ({
  current = 0,
  progress,
  onChangeStep: onClickStep,
  stepsData,
  ...props
}) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const stepLabelPropsIcon = ({ completed, index, screen }) => {
    return (
      <Box
        sx={(theme) => ({
          width: '2rem',
          height: '2rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          backgroundColor: 'transparent',
          border: 'none',
          ...theme.typography.lead1,
          ...(!completed &&
            current !== index && {
              backgroundColor: theme.palette.white.light,
              color: theme.palette.white.dark,
            }),
          ...(completed &&
            current !== index && {
              color: theme.palette.primary.dark,
              backgroundColor:
                theme.palette.primary[300] ?? theme.palette.primary.light,
            }),
          ...(current === index &&
            screen !== 'mobile' && {
              color: theme.palette.white.main,
              backgroundColor: theme.palette.primary.dark,
              width: '1.875rem !important',
              height: '1.875rem !important',
            }),
          ...(screen === 'mobile' && {
            color: theme.palette.white.main,
            backgroundColor: theme.palette.primary.dark,
          }),
        })}
      >
        {completed ? (
          <Icon name="check" sx={{ fontSize: '1.2rem' }} />
        ) : (
          index + 1
        )}
      </Box>
    )
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.white.main,
        borderRadius: '1rem',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 0),
      })}
    >
      {mobile ? (
        <Box sx={{ display: 'block', width: '100%' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              columnGap: '0.5rem',
              marginBottom: theme.spacing(2),
              marginLeft: theme.spacing(3),
              [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(1),
                marginLeft: theme.spacing(1.5),
              },
            })}
          >
            {stepLabelPropsIcon({
              completed: true,
              index: current,
              screen: 'mobile',
            })}
            <Typography
              sx={(theme) => ({
                marginLeft: theme.spacing(1),
                fontSize: theme.typography.lead2,
              })}
            >
              {stepsData[current]?.description}
            </Typography>
          </Box>
          <MobileStepper
            variant="progress"
            position="static"
            steps={stepsData.length + 1}
            activeStep={current + 1}
            sx={(theme) => ({
              padding: theme.spacing(1.5, 2.5, 1, 2.5),
              width: '100%',
              boxShadow: 'none',
              [`& .${mobileStepperClasses.progress}`]: {
                backgroundColor: theme.palette.black.light,
                width: '100%',
                height: '0.15rem',
              },
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: `${theme.palette.primary.dark} !important`,
              },
              [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2, 1.5, 1, 1.5),
              },
            })}
          />
        </Box>
      ) : (
        <MuiStepper
          alternativeLabel
          activeStep={current}
          orientation="horizontal"
          connector={<CustomConnector />}
          sx={{
            width: '100%',
            padding: '0.5rem',
          }}
          {...props}
        >
          {stepsData.map((step, index) => {
            const { title, description, id } = step

            const stepStatus = getStepStatus(index, current, progress)

            const completed = stepStatus === 'completed'
            const disabled = stepStatus === 'disabled'

            const stepLabelProps = {}

            stepLabelProps.icon = stepLabelPropsIcon({
              completed,
              index,
            })

            return (
              <Step key={id || title} disabled={disabled} completed={completed}>
                {onClickStep ? (
                  <StepButton
                    onClick={() => !disabled && onClickStep(index)}
                    icon={stepLabelProps.icon}
                    {...stepLabelProps}
                    sx={(theme) => ({
                      [`& .${stepLabelClasses.label}`]: {
                        color: theme.palette.white.dark,
                        marginTop: `${theme.spacing(1)} !important`,
                        fontFamily: `${theme.typography.lead2.fontFamily} !important`,
                        fontSize: `${theme.typography.lead2.fontSize} !important`,
                        fontWeight: `${theme.typography.lead2.fontWeight} !important`,
                        lineHeight: `${theme.typography.lead2.lineHeight} !important`,
                        letterSpacing: `${theme.typography.lead2.letterSpacing} !important`,
                      },
                      [`& .${stepLabelClasses.active}`]: {
                        color: `${theme.palette.black.main} !important`,
                      },
                      [`& .${stepLabelClasses.completed}`]: {
                        color: `${theme.palette.black.light} !important`,
                      },
                    })}
                  >
                    {description}
                  </StepButton>
                ) : (
                  <StepLabel
                    {...stepLabelProps}
                    sx={(theme) => ({
                      [`& .${stepLabelClasses.active}`]: {
                        color: `${theme.palette.primary.main} !important`,
                      },
                    })}
                  >
                    {description}
                  </StepLabel>
                )}
              </Step>
            )
          })}
        </MuiStepper>
      )}
    </Box>
  )
}

export default Stepper
