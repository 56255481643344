import { Link, Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Modal from 'components/UI/Modal/Modal'

import { rightPointingHand } from 'utils/emojis'

import ErrorDetail from './Content/ErrorDetail'

const SingleEmittedRejectedModal = ({ state, closeModal }) => {
  return (
    <Modal
      onCloseModal={closeModal}
      header="Tu emisión de nómina electrónica fue rechazada"
      okText="Cerrar"
      onOk={closeModal}
      hideCancelButton
      paperSx={{
        with: '100%',
        maxWidth: '50rem',
      }}
    >
      <Typography
        variant="lead1"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          columnGap: theme.spacing(0.5),
          marginBottom: theme.spacing(2),
        })}
      >
        <Emoji code={rightPointingHand} />
        <Link
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/preguntas-frecuentes-sobre-la-emisión-de-nómina-electrónica"
          target="_blank"
        >
          Ptss! Mira aquí qué significa cada error y cómo solucionarlo
        </Link>
      </Typography>
      <ErrorDetail errors={state?.errors} />
    </Modal>
  )
}

export default SingleEmittedRejectedModal
