import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'

import { formatDisplayDateString } from 'utils/dateTime'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import { SUBSCRIPTION_INDEX } from 'config/routes'

import { initialValuesCanceled, validationSchemaCanceled } from './helpers'

const SubscriptionCanceledModal = ({ activeUntil }) => {
  const modals = useModals()
  const navigate = useNavigate()

  const { subscriptionMutation } = useSubscriptionService({
    queryOptions: { enabled: false },
  })

  const handleCloseModal = () => {
    modals.closeAll()
    navigate(SUBSCRIPTION_INDEX())
  }

  const onSubmit = (values) => {
    if (values.comment !== null) {
      subscriptionMutation.mutate(
        {
          mutationMethod: 'PUT',
          mutationKey: 'cancelSubscription',
          cancellationData: { comment: values.comment },
        },
        {
          onSuccess: () => handleCloseModal(),
        }
      )
    } else {
      handleCloseModal()
    }
  }

  return (
    <Box>
      <Box sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(4) })}
        >
          Tu suscripción ha sido cancelada exitosamente, ya no realizaremos más
          cobros de tu cuenta. Gracias por confiar en nosotros , esperamos que
          puedas volver pronto. Recuerda que tu cuenta estará activa hasta{' '}
          <b>{formatDisplayDateString(activeUntil)}</b>.
        </Typography>
        <Typography color="black.dark">
          Pero antes de que te vayas por completo! Nos encantaría que pudieras
          dejarnos algún comentario sobre tu tiempo con nosotros, feedback,
          aspectos que te enamoraron de Aleluya y aspectos que hacen que hoy
          terminemos.
        </Typography>
      </Box>
      <Formik
        initialValues={initialValuesCanceled}
        onSubmit={onSubmit}
        validationSchema={validationSchemaCanceled}
        enableReinitialize
      >
        {({ handleSubmit }) => {
          return (
            <Form>
              <FormField
                name="comment"
                variant="textarea"
                label="Es completamente opcional"
                placeholder="Tu opinión es importante"
                rows="4"
              />
              <Box
                sx={(theme) => ({
                  marginTop: theme.spacing(7),
                })}
              >
                <Button
                  loading={subscriptionMutation.isLoading}
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default SubscriptionCanceledModal
