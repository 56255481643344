import { Link as MuiLink } from '@mui/material'

import Link from './Link'

const TooltipLink = ({
  children,
  variant = 'url',
  to,
  href,
  target = '_blank',
  state,
}) => {
  if (variant === 'navigation') {
    return (
      <Link
        sx={(theme) => ({
          ...theme.typography.h6,
          color: theme.palette.complementary1.dark,
          '&:hover': {
            color: theme.palette.complementary1.light,
          },
        })}
        to={to}
        state={state}
      >
        {children}
      </Link>
    )
  }

  return (
    <MuiLink
      sx={(theme) => ({
        ...theme.typography.h6,
        color: theme.palette.complementary1.dark,
        '&:hover': {
          color: theme.palette.complementary1.light,
        },
      })}
      href={href}
      target={target}
    >
      {children}
    </MuiLink>
  )
}

export default TooltipLink
