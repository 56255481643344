import { isValid, parseISO } from 'date-fns'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { areObjectsEqual, isObjectEmpty } from 'utils/general'
import useCompensatedDaysService from 'utils/hooks/payroll/compensatedDays'
import useNoveltiesService from 'utils/hooks/payroll/novelties'
import usePayrollService from 'utils/hooks/payroll/payroll'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { useNoveltyType, usePeriodAPI } from '../../helpers'
import CardGrid from '../common/CardGrid'
import GridRow from '../common/CardGrid/GridRow'
import HeaderCell from '../common/CardGrid/HeaderCell'
import HeaderRow from '../common/CardGrid/HeaderRow'
import GroupDivider from '../common/GroupDivider'
import AccumulatedHolidays from './AccumulatedHolidays'
import CompensatedDays from './CompensatedDays'
import EarlyPayment from './EarlyPayment'
import Header from './Header'
import Holidays from './Holidays'
import Incapacities from './Incapacities'
import Licenses from './Licenses'
import {
  getModalHeader,
  noveltyDescription,
  parseCompensatedDayValue,
} from './helpers'
import useNoveltiesConfig from './useNoveltiesConfig'

const NoveltiesModal = ({ state, handleClose }) => {
  const { payrollState } = useNoveltyType()
  const { updatePeriodCallback } = usePeriodAPI()
  const { workerName, workerPayroll, noveltyConcept } = payrollState
  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const {
    contract_category: contractCategory,
    id: payrollId,
    holidays_average_salary: holidaysAverageSalary,
    novelties_average_salary: noveltiesAverageSalary,
    licenses_average_salary: licensesAverageSalary,
    early_payment_type: earlyPaymentType,
    accumulated_holidays: accumulatedHolidays,
    compensated_days: compensatedDays,
    salary_category: salaryCategory,
    compensated_days_average_salary: compensatedDaysAverageSalary,
    parental_licenses_average_salary: parentalLicensesAverageSalary,
  } = workerPayroll
  const [generalData, setGeneralData] = useState({
    holidays_average_salary: holidaysAverageSalary,
    novelties_average_salary: noveltiesAverageSalary,
    licenses_average_salary: licensesAverageSalary,
    early_payment_type: earlyPaymentType,
    compensated_days_average_salary: compensatedDaysAverageSalary,
    parental_licenses_average_salary: parentalLicensesAverageSalary,
  })
  const holidaysApply = [
    'employee',
    'student_law_789',
    'terminated',
    'part_time_contract',
  ].includes(contractCategory)
  const licensesApply = contractCategory !== 'student_decree_055'
  const [payrollCompensatedDays, setPayrollCompensatedDays] =
    useState(compensatedDays)
  const [novelties, setNovelties] = useState({})
  const [activeTab, setActiveTab] = useState(0)
  const [activeNoveltyEditing, setActiveNoveltyEditing] = useState([])
  const { getNovelties, getSelectedDays } = useNoveltiesConfig()
  const { compensatedDaysMutation } = useCompensatedDaysService()
  const payrollConceptsQueryKey = ['payrollConcepts', 'novelties', payrollId]
  const noveltiesQueryKey = ['noveltiesByPayroll', payrollId]
  const { payrollConceptsQuery } = usePayrollConceptsService({
    serviceParams: {
      queryKey: payrollConceptsQueryKey,
      conceptsCategory: 'novelties',
    },
  })
  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  const { noveltiesQuery, noveltiesMutation } = useNoveltiesService({
    serviceParams: {
      queryKey: noveltiesQueryKey,
      payrollId,
    },
    queryOptions: {
      enabled: payrollConceptsQuery.isSuccess,
      onSuccess: (noveltiesData) => {
        const newNovelties = getNovelties(
          payrollConceptsQuery?.data,
          noveltiesData?.data
        )
        setNovelties(newNovelties)
      },
    },
  })

  const isGettingData =
    payrollConceptsQuery.isLoading || noveltiesQuery.isLoading
  const isMutating =
    compensatedDaysMutation.isLoading ||
    noveltiesMutation.isLoading ||
    payrollMutation.isLoading

  const enableHeaderEditing = (noveltyKey) =>
    setActiveNoveltyEditing((previousValues) => [...previousValues, noveltyKey])

  const disableHeaderEditing = (noveltyKey) =>
    setActiveNoveltyEditing((previousValues) =>
      previousValues.filter((value) => value !== noveltyKey)
    )

  const cancelHeaderEditing = (noveltyKey) => {
    if (noveltyKey === 'holidays_average_salary') {
      setGeneralData((previousGeneralData) => ({
        ...previousGeneralData,
        holidays_average_salary: holidaysAverageSalary,
      }))
    }

    if (noveltyKey === 'compensated_days_average_salary') {
      setGeneralData((previousGeneralData) => ({
        ...previousGeneralData,
        compensated_days_average_salary: compensatedDaysAverageSalary,
      }))
    }

    if (noveltyKey === 'novelties_average_salary') {
      setGeneralData((previousGeneralData) => ({
        ...previousGeneralData,
        novelties_average_salary: noveltiesAverageSalary,
      }))
    }

    if (noveltyKey === 'licenses_average_salary') {
      setGeneralData((previousGeneralData) => ({
        ...previousGeneralData,
        licenses_average_salary: licensesAverageSalary,
      }))
    }

    setActiveNoveltyEditing((previousValues) =>
      previousValues.filter((value) => value !== noveltyKey)
    )
  }

  const handleChangeGeneralData = (event, name) => {
    let { value } = event.target

    if (value === 'true') {
      value = true
    } else if (value === 'false') {
      value = false
    } else {
      value = Number(event.target.rawValue) || event.target.value
    }

    setGeneralData((previousGeneralData) => ({
      ...previousGeneralData,
      [name]: value,
    }))
  }

  const copyNovelties = (noveltyType, noveltyCode) => {
    const noveltiesCopy = { ...novelties }

    noveltiesCopy[noveltyType] = { ...novelties[noveltyType] }

    noveltiesCopy[noveltyType][noveltyCode] = {
      ...novelties[noveltyType][noveltyCode],
    }

    noveltiesCopy[noveltyType][noveltyCode].items = [
      ...novelties[noveltyType][noveltyCode].items,
    ]

    return noveltiesCopy
  }

  const handleAddNovelty = (noveltyType, noveltyCode) => {
    const noveltiesCopy = copyNovelties(noveltyType, noveltyCode)

    noveltiesCopy[noveltyType][noveltyCode].items.push({
      initial_day: null,
      end_day: null,
    })

    setNovelties(noveltiesCopy)
  }

  const handleChangeNovelty = (
    noveltyType,
    noveltyCode,
    index,
    date,
    inputType
  ) => {
    if (isValid(parseISO(date))) {
      const noveltiesCopy = copyNovelties(noveltyType, noveltyCode)
      const noveltiesItems = noveltiesCopy[noveltyType][noveltyCode].items

      noveltiesItems[index] = {
        ...noveltiesItems[index],
      }

      noveltiesItems[index][inputType] = date

      // if initial day is greater than end day, put this in null
      if (
        noveltiesItems[index].initial_day &&
        noveltiesItems[index].end_day &&
        parseISO(noveltiesItems[index].initial_day) >
          parseISO(noveltiesItems[index].end_day)
      ) {
        noveltiesItems[index].end_day = null
      }

      setNovelties(noveltiesCopy)

      if (inputType === 'initial_day') {
        // open end day date picker
        document
          .getElementById(
            `end-date-picker-${noveltyType}-${noveltyCode}-${index}`
          )
          ?.click()
      }
    }
  }

  const handleDeleteNovelty = (noveltyType, noveltyCode, indexToDelete) => {
    const noveltiesCopy = copyNovelties(noveltyType, noveltyCode)

    noveltiesCopy[noveltyType][noveltyCode].items.splice(indexToDelete, 1)

    if (noveltiesCopy[noveltyType][noveltyCode].items.length === 0) {
      noveltiesCopy[noveltyType][noveltyCode].items.push({
        initial_day: null,
        end_day: null,
      })
    }

    if (noveltyType === 'holidays') {
      if (
        noveltiesCopy.holidays.enjoyed_days.items.length === 0 &&
        generalData.early_payment_type !== 'no_early_payment'
      ) {
        const generalDataCopy = {
          ...generalData,
          early_payment_type: 'no_early_payment',
        }

        setGeneralData(generalDataCopy)
      }

      setNovelties(noveltiesCopy)
    } else {
      setNovelties(noveltiesCopy)
    }
  }

  const handleChangeCompensatedDays = ({ target: { value } }) => {
    setPayrollCompensatedDays(parseCompensatedDayValue(value))
  }

  const onCloseModal = () => {
    handleClose()
    queryClient.removeQueries(payrollConceptsQueryKey)
    queryClient.removeQueries(noveltiesQueryKey)
  }

  const handleSendNovelties = async () => {
    try {
      let dataToSend = []
      const initialGeneralData = {
        holidays_average_salary: holidaysAverageSalary,
        novelties_average_salary: noveltiesAverageSalary,
        licenses_average_salary: licensesAverageSalary,
        early_payment_type: earlyPaymentType,
        compensated_days_average_salary: compensatedDaysAverageSalary,
        parental_licenses_average_salary: parentalLicensesAverageSalary,
      }

      // Copy initial novelties
      const initialNovelties = noveltiesQuery.data?.map((noveltyItem) => ({
        ...noveltyItem,
      }))

      Object.entries(novelties).forEach(([, noveltyCategoryGroup]) => {
        Object.entries(noveltyCategoryGroup).forEach(([, noveltyCodeGroup]) => {
          noveltyCodeGroup.items.forEach((novelty) => {
            // if has id means was a previous novelty
            if (novelty.id) {
              initialNovelties.forEach((initialNovelty) => {
                const initialNoveltyCopy = initialNovelty
                // check to which of the initial novelties correspond to
                if (novelty.id === initialNovelty.id) {
                  // if have any change
                  if (
                    (novelty.initial_day !== initialNovelty.initial_day ||
                      novelty.end_day !== initialNovelty.end_day) &&
                    novelty.initial_day &&
                    novelty.end_day
                  ) {
                    dataToSend.push({
                      id: novelty.id,
                      initial_day: novelty.initial_day,
                      end_day: novelty.end_day,
                    })
                  }
                  // This line allow add the better way the holidays
                  initialNoveltyCopy.checked = true
                }
              })
              // new novelty
            } else if (novelty.initial_day && novelty.end_day) {
              dataToSend.push({
                payroll_concept_id: noveltyCodeGroup.id,
                ...novelty,
              })
            }
          })
        })
      })

      // deleted ones
      dataToSend =
        !!initialNovelties &&
        initialNovelties
          .filter((novelty) => !novelty.checked)
          .map((novelty) => ({
            ...novelty,
            initial_day: null,
            end_day: null,
          }))
          .concat(dataToSend)

      const responseData = {}

      if (compensatedDays !== payrollCompensatedDays) {
        const response = await compensatedDaysMutation.mutateAsync({
          mutationMethod: 'PUT',
          payrollId,
          compensatedDays: {
            quantity: payrollCompensatedDays,
          },
        })

        Object.assign(responseData, response.data)
      }

      if (dataToSend.length > 0) {
        const response = await noveltiesMutation.mutateAsync(
          {
            mutationMethod: 'PUT',
            payrollId,
            novelties: dataToSend,
          },
          {
            onSuccess: () => {
              Mixpanel.track(MIXPANEL_EVENTS.ADDED_NOVELTIES, {
                company_id: getCompanyId(),
                user_id: getUserId(),
                user_role: getUserRole(),
              })
            },
          }
        )

        Object.assign(responseData, response.data)
      }

      if (!areObjectsEqual(initialGeneralData, generalData)) {
        const response = await payrollMutation.mutateAsync({
          mutationMethod: 'PATCH',
          payrollId,
          data: {
            payroll: generalData,
          },
        })

        Object.assign(responseData, response.data)
      }

      if (!isObjectEmpty(responseData)) {
        if (compensatedDays !== payrollCompensatedDays) {
          responseData.payroll.compensated_days =
            responseData.compensated_days.quantity
        }

        if (responseData?.worker?.accumulated_holidays) {
          responseData.payroll.accumulated_holidays =
            responseData.worker.accumulated_holidays
        }

        updatePeriodCallback(responseData)
      }

      onCloseModal()
    } catch (error) {
      handleError(error, null, {
        notistackOptions: { preventDuplicate: true },
      })
    }
  }

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  const selectedDays = getSelectedDays(novelties)
  const { holidays, incapacities, licenses } = novelties

  return (
    <Modal
      open={state.open}
      header={getModalHeader(noveltyConcept, workerName, contractCategory)}
      onOk={handleSendNovelties}
      okText="Guardar"
      onCancel={onCloseModal}
      isLoading={isMutating}
      dialogProps={{
        'data-cy': 'novelties_modal',
      }}
      paperSx={{
        maxWidth: {
          tablet: '51rem',
        },
      }}
    >
      <Box>
        <Typography
          paragraph
          sx={(theme) => ({
            marginBottom: theme.spacing(4),
            color: theme.palette.black.dark,
          })}
        >
          {noveltyDescription[noveltyConcept]}
        </Typography>

        <Header
          payroll={workerPayroll}
          holidaysAverageSalary={generalData.holidays_average_salary}
          compensatedDaysAverageSalary={
            generalData.compensated_days_average_salary
          }
          noveltiesAverageSalary={generalData.novelties_average_salary}
          licensesAverageSalary={generalData.licenses_average_salary}
          parentalLicensesAverageSalary={
            generalData.parental_licenses_average_salary
          }
          activeNoveltyEditing={activeNoveltyEditing}
          onEnableEditing={enableHeaderEditing}
          onDisableEditing={cancelHeaderEditing}
          onChangeData={handleChangeGeneralData}
          onOK={disableHeaderEditing}
          noveltyConcept={noveltyConcept}
        />

        {noveltyConcept === 'licenses' && licensesApply ? (
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              marginBottom: theme.spacing(4),
            })}
          >
            <RoundedTabs
              background="gray"
              value={activeTab}
              onChange={handleChangeTab}
              tabsConfig={[
                {
                  label: 'Licencias remuneradas',
                  dataCy: 'tab-paid-leave',
                },
                {
                  label: 'Licencias no remuneradas',
                  dataCy: 'tab-nopaid-leave',
                },
              ]}
            />
          </Box>
        ) : null}
        {!isGettingData && !isObjectEmpty(novelties) ? (
          <>
            <CardGrid gridColumns="1fr 1fr 1.5rem">
              <HeaderRow>
                <HeaderCell
                  sx={{
                    gridColumn: '1',
                  }}
                  desktopOnly
                >
                  Concepto
                </HeaderCell>
              </HeaderRow>
              {noveltyConcept === 'holidays' && holidaysApply ? (
                <>
                  <GridRow gutterBottom>
                    <HeaderCell
                      sx={{
                        gridColumn: '1',
                      }}
                      mobileOnly
                    >
                      Concepto
                    </HeaderCell>
                    <AccumulatedHolidays
                      accumulatedHolidays={accumulatedHolidays}
                      columnsWidth="1fr 1fr 1.5rem"
                    />
                  </GridRow>
                  <GroupDivider />
                  <GridRow gutterBottom>
                    <HeaderCell
                      sx={{
                        gridColumn: '1',
                      }}
                      mobileOnly
                    >
                      Concepto
                    </HeaderCell>
                    <Holidays
                      payroll={workerPayroll}
                      holidays={holidays}
                      onAddNovelty={handleAddNovelty}
                      onChangeNovelty={handleChangeNovelty}
                      onDeleteNovelty={handleDeleteNovelty}
                      selectedDays={selectedDays}
                    />
                  </GridRow>
                  <GroupDivider />
                  {holidays.enjoyed_days.items.length !== 0 &&
                  holidays.enjoyed_days.items[0].end_day ? (
                    <>
                      <GridRow gutterBottom>
                        <HeaderCell
                          sx={{
                            gridColumn: '1',
                          }}
                          mobileOnly
                        >
                          Concepto
                        </HeaderCell>
                        <EarlyPayment
                          holidays={holidays}
                          earlyPayment={generalData.early_payment_type}
                          onChange={handleChangeGeneralData}
                        />
                      </GridRow>
                      <GroupDivider />
                    </>
                  ) : null}
                  <GridRow>
                    <HeaderCell
                      sx={{
                        gridColumn: '1',
                      }}
                      mobileOnly
                    >
                      Concepto
                    </HeaderCell>
                    <CompensatedDays
                      compensatedDays={payrollCompensatedDays}
                      handleCompensatedDays={handleChangeCompensatedDays}
                    />
                  </GridRow>
                </>
              ) : null}
              {noveltyConcept === 'incapacities' ? (
                <Incapacities
                  payroll={workerPayroll}
                  incapacities={incapacities}
                  onAddNovelty={handleAddNovelty}
                  onChangeNovelty={handleChangeNovelty}
                  onDeleteNovelty={handleDeleteNovelty}
                  selectedDays={selectedDays}
                  contract_category={contractCategory}
                  salary_category={salaryCategory}
                />
              ) : null}
              {noveltyConcept === 'licenses' && licensesApply ? (
                <Licenses
                  payroll={workerPayroll}
                  licenses={licenses}
                  onAddNovelty={handleAddNovelty}
                  onChangeNovelty={handleChangeNovelty}
                  onDeleteNovelty={handleDeleteNovelty}
                  selectedDays={selectedDays}
                  contract_category={contractCategory}
                  salary_category={salaryCategory}
                  activeTab={activeTab}
                />
              ) : null}
            </CardGrid>
          </>
        ) : (
          <LoadingBox />
        )}
      </Box>
    </Modal>
  )
}

export default NoveltiesModal
