import { createContext, useContext } from 'react'

import { Box, Paper, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { formatCurrency } from 'utils/format'
import { removeSuffix } from 'utils/general'

import { PLANS_BY_CODED_NAME } from '../helpers'

const PlanCardContext = createContext()
PlanCardContext.displayName = 'PlanCardContext'

export const usePlanCardContext = () => {
  const context = useContext(PlanCardContext)
  if (!context)
    throw new Error('usePlanCardContext must be used within PlanCardProvider')
  return context
}

export function PlansContainer({ children, sx }) {
  return (
    <Paper
      sx={[
        (theme) => ({
          width: '100%',
          marginTop: theme.spacing(8.5),
          padding: theme.spacing(3.75, 2, 4, 2),
          borderRadius: '1.4375rem',
          boxShadow: 'none',
          [theme.breakpoints.up('laptop')]: {
            boxShadow: theme.shadows[7],
          },
        }),
        sx,
      ]}
    >
      {children}
    </Paper>
  )
}

export function PlanCard({
  children,
  isCurrentPlan,
  isRecommendedPlan,
  companyWithoutSelectedPlan = false,
  ...props
}) {
  const isCardHighlighted =
    isCurrentPlan || (isRecommendedPlan && companyWithoutSelectedPlan)

  return (
    <Box
      data-current-plan={isCurrentPlan}
      sx={(theme) => ({
        animation: 'fadeIn 700ms',
        '@keyframes fadeIn': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
        justifyContent: 'center',
        padding: theme.spacing(2, 2),
        boxShadow: theme.shadows[7],
        margin: '0 auto',
        borderRadius: '1.4375rem',
        [theme.breakpoints.up('laptop')]: {
          boxShadow: 'none',
        },
        ...(isCardHighlighted && {
          backgroundColor: 'info.main',
          borderRadius: '2.125rem',
          color: 'white.main',
          [theme.breakpoints.up('laptop')]: {
            padding: theme.spacing(3, 3.125),
            width: '23rem',
            marginTop: '-3.5rem',
          },
        }),
      })}
      {...props}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: isCardHighlighted
            ? 'primary.main'
            : isCurrentPlan
              ? 'primary.main'
              : 'info.main',
          padding: theme.spacing(0.875, 3),
          borderRadius: '1.4375rem',
          justifySelf: 'flex-end',
          visibility: isCurrentPlan || isRecommendedPlan ? 'visible' : 'hidden',
          marginBottom: theme.spacing(2),
          [theme.breakpoints.up('laptop')]: {
            marginBottom: isCardHighlighted
              ? theme.spacing(7)
              : theme.spacing(2),
          },
          height: 'fit-content',
        })}
      >
        <Typography
          variant="lead2"
          sx={{
            color: isCardHighlighted ? 'black.main' : 'white.main',
            textAlign: 'center',
          }}
        >
          {isRecommendedPlan && !isCurrentPlan
            ? 'Plan recomendado'
            : 'Plan actual'}
        </Typography>
      </Box>
      <PlanCardContext.Provider
        value={{
          isCardHighlighted,
        }}
      >
        {children}
      </PlanCardContext.Provider>
    </Box>
  )
}

export function PlanValue({ value, paymentModel }) {
  const { isCardHighlighted } = usePlanCardContext()

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(0.5),
      })}
    >
      <Typography variant="h2">{formatCurrency(value)}</Typography>
      <Typography
        variant="h5"
        sx={{
          color: isCardHighlighted ? 'white.main' : 'black.light',
        }}
      >
        {paymentModel}
      </Typography>
    </Box>
  )
}

export function PersonValue({ value, paymentModel }) {
  const isEntrepreneurPlan = value === 0
  const { isCardHighlighted } = usePlanCardContext()

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(0.5),
      })}
    >
      {isEntrepreneurPlan ? null : (
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '1.1875rem',
            fontFamily: 'Montserrat',
          }}
        >
          +{formatCurrency(value)}
        </Typography>
      )}

      {isEntrepreneurPlan ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          <Icon
            name="circle-check"
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            fontSize="small"
          />
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '0.75rem',
              fontFamily: 'Sora',
            }}
          >
            {paymentModel}
          </Typography>
        </Box>
      ) : (
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '0.75rem',
            fontFamily: 'Sora',
            color: isCardHighlighted ? 'white.main' : 'black.light',
          }}
        >
          {paymentModel}
        </Typography>
      )}
    </Box>
  )
}

export function PlanName({ planName }) {
  return (
    <Typography
      sx={{
        fontWeight: '600',
        fontSize: '1.75rem',
        fontFamily: 'Montserrat',
      }}
    >
      {planName}
    </Typography>
  )
}

export function PlanDescriptionByCategory({ codedName }) {
  const { isCardHighlighted } = usePlanCardContext()

  const {
    old_plans: { basic_plan, full_toys_plan, superpowers_plan },
    new_plans: { entrepreneur_plan, payroll_only_plan, holistic_payroll_plan },
  } = PLANS_BY_CODED_NAME

  const planDescriptionByCategory = {
    [basic_plan]: (
      <>
        <Typography variant="body1">
          Liquida tu nómina y seguridad social en segundos, de forma automática
          y sin errores.
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({ marginTop: theme.spacing(2) })}
        >
          <b>Usuarios administradores:</b> ilimitados
          <br />
          Soporte por chat en línea y en menos de una hora.
        </Typography>
      </>
    ),
    [full_toys_plan]: (
      <>
        <Typography variant="body1">
          Nómina y Recursos Humanos en un solo lugar. Gestiona las personas de
          tu equipo de la forma más eficiente.
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({ marginTop: theme.spacing(2) })}
        >
          <b>Usuarios administradores:</b> ilimitados
          <br />
          Soporte por chat en línea y en menos de una hora.
        </Typography>
      </>
    ),
    [superpowers_plan]: (
      <>
        <Typography variant="body1">
          Nómina y Recursos Humanos en un solo lugar. Gestiona las personas de
          tu equipo de la forma más eficiente.
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({ marginTop: theme.spacing(2) })}
        >
          <b>Usuarios administradores:</b> ilimitados
          <br />
          Ejecutivo de cuenta y soporte prioritario.
        </Typography>
      </>
    ),
    [entrepreneur_plan]: (
      <>
        <Typography variant="body2" color="info.main">
          (Solo pago mensual)
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({ marginTop: theme.spacing(2) })}
        >
          Ideal para emprendedores que están iniciando.
        </Typography>
      </>
    ),
    [payroll_only_plan]: (
      <>
        <Typography variant="body2">
          Ideal para equipos pequeños que buscan tranquilidad total con su
          nómina.
        </Typography>
      </>
    ),
    [holistic_payroll_plan]: (
      <>
        <Typography sx={{ fontSize: '0.625rem' }}>
          (Aleluya ofrecerá 15 días de prueba del plan Nómina holística solo
          para clientes nuevos.)
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({ marginTop: theme.spacing(2) })}
        >
          Ideal para equipos pequeños que consideran que su equipo es su
          prioridad #1.
        </Typography>
      </>
    ),
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        minHeight: '10.375rem',
        maxWidth: '17rem',
        color: isCardHighlighted ? 'white.main' : 'black.dark',
      })}
    >
      {planDescriptionByCategory[
        codedName.includes('20241111') ? codedName : removeSuffix(codedName, -8)
      ] || null}
    </Box>
  )
}
