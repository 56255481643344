import { useMediaQuery } from '@mui/material'

import { Image } from 'components/UI/Image'

import { useUser } from './UserContext/useUser'

const MonogramLogo = () => {
  const { distributor } = useUser()

  const isMobileBreakpoint = useMediaQuery((theme) =>
    theme.breakpoints.down('laptop')
  )

  let imageProps = {}

  if (isMobileBreakpoint) {
    imageProps = {
      alt: 'Aleluya logo',
      src: '9854d5ec-a0cd-40b9-9d4f-f4d9596a3d00',
      width: 130,
    }

    if (distributor === 'arus') {
      imageProps.alt = 'Arus logo'
      imageProps.src = '7bc46401-c689-4b80-4a69-4b366174e200'
      imageProps.width = 160
    }
  } else {
    imageProps = {
      alt: 'Aleluya logo',
      src: '9854d5ec-a0cd-40b9-9d4f-f4d9596a3d00',
      width: 141,
      height: 64,
    }

    if (distributor === 'arus') {
      imageProps.alt = 'Arus logo'
      imageProps.src = '502ad045-2c9e-4a32-503a-448cf471c100'
      imageProps.width = 190
    }
  }

  return (
    <Image
      src={imageProps.src}
      alt={imageProps.alt}
      width={imageProps.width}
      height={imageProps.height}
    />
  )
}

export default MonogramLogo
