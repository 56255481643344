import { Box, Button } from '@mui/material'

const UpdatePaymentMethodButton = ({
  openUpdatePaymentMethodInfoModal,
  handleCloseMobileDrawer,
}) => {
  const handleOnClick = () => {
    handleCloseMobileDrawer()
    openUpdatePaymentMethodInfoModal({ actionMessage: 'invitation_to_update' })
  }

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        height: '4rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(7.5),
        paddingX: theme.spacing(1),
        position: 'relative',
      })}
    >
      <Button
        onClick={handleOnClick}
        sx={(theme) => ({
          paddingX: theme.spacing(3),
          zIndex: 1000,
        })}
      >
        Actualizar método de pago
      </Button>
    </Box>
  )
}

export default UpdatePaymentMethodButton
