import { useState } from 'react'

import {
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  alpha,
} from '@mui/material'

import SelectField from 'components/UI/Formik/FormField/SelectField'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import usePayrollService from 'utils/hooks/payroll/payroll'
import useNotifications from 'utils/hooks/useNotifications'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

const SinglePayslipModal = ({ state, handleClose, severanceDescription }) => {
  const [whatsappNotification, setWhatsappNotification] = useState(null)
  const [payslipType, setPayslipType] = useState(null)
  const { open, payroll } = state

  const {
    payrollMutation,
    payrollQuery: { data: { payslip_type: payslipOptions = [] } = {} },
  } = usePayrollService({
    serviceParams: {
      queryKey: ['getPayslipOptions', payroll.id],
      payrollId: payroll.id,
    },
  })

  const closeModal = () => {
    handleClose()
    setWhatsappNotification(null)
    setPayslipType(null)
  }

  const handleChange = (event) => {
    if (event.target.type === 'radio') {
      setWhatsappNotification(event.target.value)
    }

    if (event.target.name === 'select') {
      setPayslipType(event.target.value)
    }
  }

  const sendOptions = [
    {
      value: true,
      label: (
        <>
          Enviar por WhatsApp
          <Chip
            size="small"
            label="Nuevo"
            sx={(theme) => ({
              padding: theme.spacing(1.5, 1),
              marginLeft: theme.spacing(1),
              color: theme.palette.primary.dark,
              backgroundColor: alpha(theme.palette.primary.light, 0.2),
              '& .MuiChip-label': {
                fontSize: '0.625rem !important',
              },
            })}
          />
        </>
      ),
      disabled: !payroll?.payslip_whatsapp,
    },
    {
      value: false,
      label: 'Enviar por correo',
      disabled: !payroll?.payslip_mail,
    },
  ]

  const { showSuccessMessage } = useNotifications()

  const handleOk = () => {
    payrollMutation.mutate(
      {
        mutationMethod: 'SEND_PAYSLIP',
        payrollId: payroll?.id,
        whatsappNotification,
        payslipType,
      },
      {
        onSuccess: () => {
          showSuccessMessage('La colilla de pago ha sido enviada.')
          Mixpanel.track(MIXPANEL_EVENTS.SEND_PAYSLIPS_V2, {
            company_id: getCompanyId(),
            user_id: getUserId(),
            user_role: getUserRole(),
            payslip_type: payslipType,
            send_type: 'individual',
            sent_by:
              whatsappNotification === 'true'
                ? 'whatsapp'
                : whatsappNotification === 'false'
                  ? 'email'
                  : undefined,
          })
          closeModal()
        },
      }
    )
  }

  return (
    <Modal
      open={open}
      disableOkButton={
        whatsappNotification === null ||
        (payslipOptions.length > 0 && payslipType === null)
      }
      header="Enviar Colilla de Pago"
      okText="Enviar"
      onOk={handleOk}
      onCancel={closeModal}
      isLoading={payrollMutation.isLoading}
    >
      {severanceDescription}
      <Typography color="black.dark">
        Puedes enviar la colilla de pago por WhatsApp o por correo electrónico.
        Se enviará al número celular o correo electrónico que el empleado tenga
        registrado en Datos Personales de su perfil.
      </Typography>
      <RadioGroup
        value={whatsappNotification}
        onChange={handleChange}
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '3fr 2fr',
          columnGap: theme.spacing(3),
          marginTop: theme.spacing(3),
          [theme.breakpoints.down('tablet')]: { gridTemplateColumns: '1fr' },
        })}
      >
        {sendOptions.map((option) => (
          <FormControlLabel
            key={option.value}
            label={option.label}
            value={option.value}
            control={<Radio />}
            disabled={option.disabled}
          />
        ))}
      </RadioGroup>
      {payslipOptions.length > 0 ? (
        <FormControl required sx={(theme) => ({ marginTop: theme.spacing(2) })}>
          <FormLabel id="paylips_type">Tipo de colilla</FormLabel>
          <SelectField
            name="select"
            id="paylips_type"
            options={payslipOptions || []}
            onChange={handleChange}
            value={payslipType}
          />
        </FormControl>
      ) : null}
    </Modal>
  )
}

export default SinglePayslipModal
