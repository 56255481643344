import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import { validPassword } from 'utils/regex'

yup.setLocale(yupLocaleES)

export const initialValues = {
  password: '',
  password_confirmation: '',
}

export const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Tu contraseña debe contener mínimo 8 caracteres.')
    .max(128, 'Tu contraseña debe contener máximo 128 caracteres.')
    .matches(
      validPassword,
      'La contraseña debe incluir números, al menos una letra mayúscula, una minúscula, y caracteres especiales como "#$!*@%&".'
    )
    .required(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden.')
    .required(),
})
