import { useState } from 'react'

import { Box, Typography, useMediaQuery } from '@mui/material'

import Emoji from 'components/UI/Emoji'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import Modal from 'components/UI/Modal/Modal'

import { redCrossMark } from 'utils/emojis'

import thumbDown from 'assets/images/three-dimensional-icons/thumb-down.png'

import {
  getErrorsList,
  getTabsConfig,
  noveltiesConceptsExtended,
} from './helpers'

const BulkNoveltiesErrorsSummaryModal = ({
  state,
  handleClose,
  openBulkNoveltiesErrorsModal,
  openBulkNoveltiesCommonInfoModal,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const [activeTab, setActiveTab] = useState(0)
  const { open, errorsObject, bulkUploadResult } = state || {}

  const handleFixErrors = () => {
    handleClose()
    openBulkNoveltiesErrorsModal(bulkUploadResult)
  }

  const handleConfirmClose = () => {
    handleClose()
    openBulkNoveltiesCommonInfoModal('confirm_close_errors', {
      to: 'BulkNoveltiesErrorsSummaryModal',
      bulkUploadResult,
    })
  }

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  const showExtendedTitle = Object.keys(errorsObject).length === 1
  const useShortCut = Object.keys(errorsObject).length > 2 || isMobile
  const tabsConfig = getTabsConfig(errorsObject, useShortCut)
  const otherErrors = tabsConfig[activeTab].content.other_errors
  const errorsList = getErrorsList(tabsConfig, activeTab)

  return (
    <Modal
      open={open}
      cancelText="Cerrar"
      okText="Corregir errores"
      header={
        <Typography variant="h3">
          Estos son los errores de tu archivo <Emoji code={redCrossMark} />
        </Typography>
      }
      onCancel={handleConfirmClose}
      onOk={handleFixErrors}
      paperSx={{ maxWidth: '50rem' }}
    >
      <Typography variant="body1" color="black.dark">
        Esta es una lista resumida de los errores que tiene tu archivo de carga
        masiva de novedades.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          margin: theme.spacing(4, 0, 3, 0),
        })}
      >
        {showExtendedTitle ? (
          <Typography variant="h6">
            {noveltiesConceptsExtended[Object.keys(errorsObject)[0]]}
          </Typography>
        ) : (
          <RoundedTabs
            background="gray"
            tabsConfig={tabsConfig}
            value={activeTab}
            onChange={handleChangeTab}
          />
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'grid',
          maxHeight: '30rem',
          overflowY: 'auto',
          gridTemplateColumns: '1fr',
          borderRadius: '1rem',
          marginTop: theme.spacing(3),
          gap: theme.spacing(1, 2),
          [theme.breakpoints.down('tablet')]: {
            gridTemplateColumns: '1fr',
          },
          '& section:first-of-type': { paddingTop: theme.spacing(1) },
        })}
      >
        {errorsList.map((errorData) => {
          const { workerId, workerName, workerErrors } = errorData
          const uniqueWorkerErrors = [...new Set(workerErrors)]

          return uniqueWorkerErrors.length > 0 ? (
            <Box
              key={workerId}
              sx={(theme) => ({
                display: 'flex',
                gap: theme.spacing(0.5),
                maxWidth: '90%',
              })}
              component="section"
            >
              <Box
                component="img"
                loading="lazy"
                src={thumbDown}
                alt="Tienes errores en tu archivo"
                width={28}
                height={28}
              />
              <Box
                sx={(theme) => ({
                  marginTop: theme.spacing(0.4),
                })}
              >
                <Typography variant="body2">
                  <b>{workerName} tiene los siguientes errores</b>:
                </Typography>
                <Box
                  component="ul"
                  sx={(theme) => ({
                    paddingLeft: theme.spacing(3),
                    marginTop: theme.spacing(1),
                  })}
                >
                  {uniqueWorkerErrors.map((error, index) => {
                    const key = `${workerId} + ${error} + ${index}`
                    return (
                      <Box
                        key={key}
                        component="li"
                        sx={{
                          fontSize: '0.875rem',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        {error}
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Box>
          ) : null
        })}

        {otherErrors.length > 0 ? (
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(0.5),
              maxWidth: '90%',
            })}
            component="section"
          >
            <Box
              component="img"
              loading="lazy"
              src={thumbDown}
              alt="Tienes errores en tu archivo"
              width={28}
              height={28}
            />
            <Box
              sx={(theme) => ({
                marginTop: theme.spacing(0.4),
              })}
            >
              <Typography variant="body2">
                <b>Otros errores en {tabsConfig[activeTab].label}</b>:
              </Typography>
              <Box
                component="ul"
                sx={(theme) => ({
                  paddingLeft: theme.spacing(3),
                  marginTop: theme.spacing(1),
                })}
              >
                {otherErrors.map((error) => (
                  <Box
                    key={error.message}
                    component="li"
                    sx={{
                      fontSize: '0.875rem',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {error.message}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Modal>
  )
}

export default BulkNoveltiesErrorsSummaryModal
