import { GoogleLogin } from '@react-oauth/google'

import { useMediaQuery } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'

import { isTest } from 'utils/general'
import useSessionService from 'utils/hooks/auth/session'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import messages from 'messages/notification'

const GoogleLoginButton = ({ action = 'signin' }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const { logIn } = useUser()
  const { showSuccessMessage } = useNotifications()
  const { handleError } = useErrorHandler()

  const { sessionMutation } = useSessionService()

  const onSuccess = async (response) => {
    sessionMutation.mutateAsync(
      {
        mutationMethod: 'POST_GOOGLE',
        credentials: {
          googleId: response.clientId,
          idToken: response.credential,
        },
      },
      {
        onSuccess: ({ data }) => {
          if (data.user.id) {
            Mixpanel.identify(data.user.id)

            try {
              Mixpanel.instance.people.set({
                $email: data.user.email,
                $name: data.user.name,
              })
            } catch (error) {
              // We don't want to break the app if Mixpanel fails
            }
          }

          logIn(data)

          Mixpanel.track(
            MIXPANEL_EVENTS.SIGNED_UP_WITH_GOOGLE,
            {
              user_id: data.user.id,
              company_id: data.user.company_id,
              user_role: data.user.role,
              multicompany: data.user.multicompany,
            },
            true
          )

          if (action === 'signin') showSuccessMessage(messages.LOGIN_OK)
        },
        onError: (error) => {
          handleError(error)
        },
      }
    )
  }

  if (isTest) return null

  return (
    <GoogleLogin
      size="large"
      shape="rectangular"
      text={action === 'signin' ? 'signin_with' : 'continue_with'}
      locale="es_ES"
      onSuccess={onSuccess}
      width={isMobile ? '288px' : '352px'}
    />
  )
}

export default GoogleLoginButton
