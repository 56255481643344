import { useLocation } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { redHeart } from 'utils/emojis'

import Emoji from '../Emoji'
import { BannerIcon, picturesData } from './helpers'

export function TrustBanner() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isWorker = searchParams.get('worker') === 'true'
  return (
    <Box
      sx={({ spacing }) => ({
        height: '100%',
        padding: spacing(4, 0, 4, 4),
      })}
    >
      <Box
        sx={({ palette, spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: palette.complementary1.main,
          height: '100%',
          padding: spacing(4),
          gap: spacing(5),
          borderRadius: spacing(3),
          maxHeight: '100vh',
          overflow: 'auto',
          ...(isWorker && {
            backgroundColor: palette.complementary2.main,
          }),
        })}
      >
        <Typography
          variant="h1"
          sx={{ textAlign: 'center', textWrap: 'balance' }}
        >
          Amado por +5.300 empresas y +51.000 empleados en Colombia{' '}
          <Emoji code={redHeart} />
        </Typography>
        <Box
          sx={({ spacing }) => ({
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: spacing(6),
          })}
        >
          {picturesData.map(({ id, src, mainText, description }) => (
            <BannerIcon
              key={id}
              mainText={mainText}
              src={src}
              description={description}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
