import { useFormikContext } from 'formik'

import { Box } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import CellphoneField from 'components/UI/Formik/CommonFields/CellphoneField'
import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'

import { documentTypesOptions } from 'utils/company'
import { getFileNameFromUrl, isValidUrl } from 'utils/general'

import { CompanyDocumentField } from './Credentials/helpers'
import LogoFormField from './LogoField'

const CompanyFormFieldsBasics = ({ documentType }) => {
  const { showHRFeatures } = usePremiumFeature()
  const { values } = useFormikContext()

  const taxDocumentUrl = isValidUrl(values?.files?.tax_document)
  const textDocumentName = getFileNameFromUrl(
    taxDocumentUrl ? taxDocumentUrl.search : undefined
  )

  return (
    <>
      <Box
        component="section"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          [theme.breakpoints.up('xl')]: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <FormField name="name" label="Razón social" optional={false} />
        <FormField
          name="email"
          type="email"
          label="Email contacto"
          optional={false}
        />
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            rowGap: theme.spacing(3),
            [theme.breakpoints.up('lg')]: {
              columnGap: theme.spacing(3),
              gridTemplateColumns: '1fr 1fr',
            },
            ...(documentType === 'ni' && {
              gridColumnEnd: 'span 2',
            }),
          })}
        >
          <DocumentTypeField options={documentTypesOptions} optional={false} />
          <CompanyIdentificationNumberField optional={false} />
        </Box>
        <CellphoneField name="phone" optional disableValidate />
        {!showHRFeatures ? (
          <CompanyDocumentField
            fieldName="tax_document"
            documentName={textDocumentName}
            documentUrl={taxDocumentUrl}
            label="RUT"
            tooltipContent="
              Adjunta el RUT de la empresa para realizar la correcta Facturación
              Electrónica de tu suscripción."
          />
        ) : null}
      </Box>
      <LogoFormField />
    </>
  )
}

export default CompanyFormFieldsBasics
