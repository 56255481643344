import { Tab, Tabs, tabClasses, tabsClasses } from '@mui/material'

const RoundedTabs = ({
  value,
  onChange,
  tabsConfig,
  sx,
  background,
  ...props
}) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={[
        (theme) => ({
          maxWidth: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor:
            background === 'gray'
              ? theme.palette.white.light
              : background === 'white'
                ? theme.palette.white.main
                : 'transparent',
          padding: theme.spacing(1),
          borderRadius: '2rem !important',
          height: '3.5rem',
          [`& .${tabsClasses.indicator}`]: {
            display: 'none',
          },
          [`& .${tabsClasses.flexContainer}`]: {
            gap: theme.spacing(2),
          },
          [`& .${tabClasses.root}`]: {
            display: 'flex',
            height: '2.5rem',
            minHeight: 'auto',
            padding: theme.spacing(0, 3),
            textTransform: 'none',
            color: theme.palette.black.main,
            ...theme.typography.lead2,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              borderRadius: '2rem !important',
              transition: 'background-color 250ms ease-in-out',
            },
          },
          [`& .${tabClasses.selected}`]: {
            color: `${theme.palette.white.main} !important`,
            backgroundColor: `${theme.palette.primary.dark} !important`,
            borderRadius: '2rem',
          },
        }),
        sx,
      ]}
      {...props}
    >
      {tabsConfig.map((tab, index) => {
        const {
          key = index,
          label = '',
          dataCy = '',
          disabled = false,
          onClick,
        } = tab

        return (
          <Tab
            key={key}
            label={label}
            value={key}
            data-cy={dataCy}
            disabled={disabled}
            onClick={onClick}
            wrapped
          />
        )
      })}
    </Tabs>
  )
}

export default RoundedTabs
