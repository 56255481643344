import { createContext, useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Paper } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import { useUpgradeToPayrollOnlyPlanModal } from 'components/Subscription/Atoms/useUpgradeToPayrollOnlyPlanModal'
import { PLANS_BY_CODED_NAME } from 'components/Subscription/helpers'
import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'
import LoadingModal from 'components/UI/Loading/LoadingModal'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import useEditContractModal from 'components/Worker/Contract/Modal/useEditContractModal'
import { FirstWorkerAddedModal } from 'components/Worker/Index/Modals/FirstWorkerAddedModal'
import { useFirstWorkerAddedModal } from 'components/Worker/Index/Modals/FirstWorkerAddedModal/useFirstWorkerAddedModal'
import NewWorkerModal from 'components/Worker/Index/Modals/NewWorkerModal'
import useNewWorkerModal from 'components/Worker/Index/Modals/NewWorkerModal/useNewWorkerModal'
import useWorker from 'components/Worker/useWorker'

import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'

import * as routes from 'config/routes'

import { WorkerProfileContext } from '../Show'
import ActivateButton from './ActivateButton'
import UpdateWorkerGeneralDataModal from './Modals/UpdateGeneralDataModal/UpdateGeneralDataModal'
import useUpdateGeneralWorkerDataModal from './Modals/UpdateGeneralDataModal/useUpdateGeneralDataModal'
import RecurrentConceptsContent from './RecurrentConcepts/RecurrentConceptsContent'
import AttachedDocumentsContainer from './Tabs/AttachedDocumentsContainer'
import BeneficiariesContainer from './Tabs/BeneficiariesContainer'
import DocumentsContainer from './Tabs/DocumentsContainer'
import EmployerContainer from './Tabs/EmployerContainer/EmployerContainer'
import FreeTimeContent from './Tabs/FreeTimeContent'
import LaboralDataContent from './Tabs/LaboralDataContent'
import PersonalDataContent from './Tabs/PersonalDataContainer'

export const WorkerShowContext = createContext()
WorkerShowContext.displayName = 'WorkerShowContext'

export const useWorkerShowContext = () => {
  const value = useContext(WorkerShowContext)

  if (!value) {
    throw new Error(
      'useWorkerShowContext must be used within WorkerShowProvider'
    )
  }

  return value
}

const WorkerBody = () => {
  const { activeTab, setActiveTab } = useContext(WorkerProfileContext)
  const confirm = useConfirm()
  const navigate = useNavigate()
  const { isWorker, user } = useUser()
  const { worker, isFromAffiliationView } = useWorker({
    useCache: true,
  })
  const { newWorkerModalState, openNewWorkerModal, closeNewWorkerModal } =
    useNewWorkerModal()

  const {
    firstWorkerAddedModalState,
    openFirstWorkerAddedModal,
    closeFirstWorkerAddedModal,
  } = useFirstWorkerAddedModal()

  const queryClient = useQueryClient()
  const { workerMutation } = useWorkerService({
    queryOptions: { enabled: false },
  })
  const { subscription } = useSubscription()
  const { openUpgradeToPayrollOnlyPlanModal } =
    useUpgradeToPayrollOnlyPlanModal()

  const showUpgradeToPayrollOnlyPlan =
    subscription?.plan?.coded_name ===
      PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan &&
    subscription?.payrolls_size === 3

  const {
    editContractModalState,
    openEditContractModal,
    closeEditContractModal,
  } = useEditContractModal()

  const { showHRFeatures } = usePremiumFeature()

  const { showSuccessMessage } = useNotifications()

  const handleFinishPersonRegistration = () => {
    if (showUpgradeToPayrollOnlyPlan) {
      openUpgradeToPayrollOnlyPlanModal({
        handleOnConfirm: () =>
          openNewWorkerModal({ workerId: worker.id, workerName: worker.name }),
      })
    } else {
      openNewWorkerModal({ workerId: worker.id, workerName: worker.name })
    }
  }

  const callback = (message) => {
    showSuccessMessage(message)
    queryClient.invalidateQueries('getWorkerTotals')
    navigate(routes.WORKER_INDEX('?tab=active'))
  }

  const deleteWorker = () => {
    workerMutation.mutate(
      {
        mutationMethod: 'DELETE',
        workerId: worker.id,
      },
      {
        onSuccess: (data) => callback(data.message),
      }
    )
  }

  const handleDeleteWorker = () => {
    confirm({
      type: 'error',
      title: 'Eliminar trabajador',
      description: '¿Estás seguro que deseas eliminar al trabajador?',
      okText: 'Eliminar',
      onOk: deleteWorker,
    })
  }

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  const {
    updateGeneralDataModalState,
    openGeneralDataModal,
    closeGeneralDataModal,
  } = useUpdateGeneralWorkerDataModal()

  let tabsConfig = []

  if (!isFromAffiliationView) {
    tabsConfig = [
      {
        key: 'laboral_data',
        label: 'Datos laborales',
      },
      {
        key: 'personal_data',
        label: 'Datos personales',
      },
      {
        key: 'recurrent_concepts',
        label: 'Conceptos recurrentes',
      },
    ]

    if (
      showHRFeatures &&
      isWorker &&
      (worker.workers_handle_novelties || user.workersHandleNovelties) &&
      worker.contract_category !== 'contractor'
    ) {
      tabsConfig.push({
        key: 'free_time',
        label: 'Solicitar tiempo fuera',
      })
    }

    if (
      showHRFeatures &&
      !isWorker &&
      worker.contract_category !== 'contractor'
    ) {
      tabsConfig.push({
        key: 'free_time_history',
        label: 'Historial tiempo fuera',
      })
    }

    if (showHRFeatures) {
      tabsConfig.push({
        key: 'documents',
        label: 'Documentos',
      })
    }
  } else {
    tabsConfig = [
      {
        key: 'personal_data',
        label: 'Datos personales',
      },
      {
        key: 'entities',
        label: 'Administradoras',
      },
      {
        key: 'attached_documents',
        label: 'Documentos adjuntos',
      },
      {
        key: 'beneficiaries',
        label: 'Beneficiarios',
      },
      {
        key: 'employer',
        label: 'Empleador',
      },
    ]
  }

  if (workerMutation.isLoading) return <LoadingModal />

  const contextValue = {
    openGeneralDataModal,
    closeGeneralDataModal,
    editContractModalState,
    openEditContractModal,
    closeEditContractModal,
  }

  return (
    <WorkerShowContext.Provider value={contextValue}>
      <Box
        sx={{
          gridColumn: '1 / -1',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper
          elevation={5}
          sx={{
            borderRadius: '1rem',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              margin: theme.spacing(3, 0, 6),
            })}
          >
            <RoundedTabs
              value={activeTab}
              onChange={handleChangeTab}
              tabsConfig={tabsConfig}
              background="gray"
            />
          </Box>
          {activeTab === 'laboral_data' ? (
            <LaboralDataContent
              handleFinishPersonRegistration={handleFinishPersonRegistration}
            />
          ) : null}
          {activeTab === 'personal_data' ? <PersonalDataContent /> : null}
          {activeTab === 'recurrent_concepts' ? (
            <RecurrentConceptsContent />
          ) : null}
          {activeTab === 'free_time' ? <FreeTimeContent /> : null}
          {activeTab === 'free_time_history' ? <FreeTimeContent /> : null}
          {activeTab === 'documents' ? <DocumentsContainer /> : null}
          {activeTab === 'entities' ? <LaboralDataContent /> : null}
          {activeTab === 'attached_documents' ? (
            <AttachedDocumentsContainer />
          ) : null}
          {activeTab === 'beneficiaries' ? <BeneficiariesContainer /> : null}
          {activeTab === 'employer' ? <EmployerContainer /> : null}
        </Paper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: {
              mobile: 'space-between',
              tablet: 'end',
            },
            gap: 2,
            marginTop: 3,
          }}
        >
          {!isFromAffiliationView &&
          ['employee', 'part_time_contract'].includes(
            worker?.contract_category
          ) ? (
            <ActivateButton isWorker={isWorker} worker={worker} />
          ) : null}
          {!isFromAffiliationView && !isWorker && worker.deleteable ? (
            <Button
              sx={{ width: { mobile: 1, tablet: 'auto' } }}
              endIcon={<Icon name="trash-2" />}
              onClick={handleDeleteWorker}
              size="large"
            >
              Eliminar
            </Button>
          ) : null}
        </Box>
      </Box>
      {updateGeneralDataModalState.open ? (
        <UpdateWorkerGeneralDataModal
          onCancel={closeGeneralDataModal}
          state={updateGeneralDataModalState}
        />
      ) : null}
      {newWorkerModalState.open ? (
        <NewWorkerModal
          state={newWorkerModalState}
          handleClose={closeNewWorkerModal}
          openFirstWorkerAddedModal={openFirstWorkerAddedModal}
        />
      ) : null}
      {firstWorkerAddedModalState.open ? (
        <FirstWorkerAddedModal
          state={firstWorkerAddedModalState}
          handleClose={closeFirstWorkerAddedModal}
        />
      ) : null}
    </WorkerShowContext.Provider>
  )
}

export default WorkerBody
