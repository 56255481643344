import { Box, LinearProgress, Paper, Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'

import { fire } from 'utils/emojis'

const fetchData = [
  {
    id: '1',
    label: 'Planificación para el futuro',
    percentage: 0,
  },
  {
    id: '2',
    label: 'Manejo de deudas',
    percentage: 0,
  },
  {
    id: '3',
    label: 'Cumplimiento de metas financieras',
    percentage: 0,
  },
  {
    id: '4',
    label: 'Control emocional sobre el dinero',
    percentage: 0,
  },
  {
    id: '5',
    label: 'Disciplina financiera',
    percentage: 0,
  },
]

export default function FinancialCoachingSessions({ fetchedData = {} }) {
  const { coaching_sessions_data } = fetchedData
  return (
    <Box
      sx={({ breakpoints, spacing }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        [breakpoints.up('md')]: {
          gridTemplateColumns: '22rem 1fr',
        },
        gap: spacing(4),
      })}
    >
      <Paper
        sx={({ spacing }) => ({
          height: '22rem',
          padding: spacing(3),
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <Typography variant="h5">Sesiones realizadas</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.5625rem',
              textAlign: 'center',
              color: 'black.dark',
            }}
          >
            Número de sesiones:
          </Typography>
          <Typography
            sx={{
              fontSize: '6.125rem',
              fontWeight: '700',
              textAlign: 'center',
              fontFamily: 'Montserrat',
            }}
          >
            {!coaching_sessions_data?.coaching_sessions_count
              ? '0'
              : coaching_sessions_data?.coaching_sessions_count <= 9
                ? `0${coaching_sessions_data?.coaching_sessions_count}`
                : coaching_sessions_data?.coaching_sessions_count}
          </Typography>
        </Box>
      </Paper>

      <Paper
        sx={({ spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          padding: spacing(3),
          gap: spacing(4),
        })}
      >
        <Box>
          <Typography
            variant="h5"
            sx={({ spacing }) => ({ marginBottom: spacing(1.5) })}
          >
            Sesiones de Coaching{' '}
            <Box component="span" color="info.main">
              Próximamente <Emoji code={fire} />
            </Box>
          </Typography>
          <Typography variant="body2" color="black.dark">
            Échale un ojito a los temas que priorizan las personas de tu equipo
            durante las sesiones de Coaching Financiero.
          </Typography>
        </Box>

        <Box
          sx={({ spacing }) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(2),
          })}
        >
          {fetchData.map(({ id, label, percentage }) => (
            <Box
              key={id}
              sx={({ spacing }) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: spacing(0.5),
              })}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">{label}</Typography>
                <Typography variant="lead2">{percentage}%</Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={percentage}
                sx={{
                  backgroundColor: 'white.light',
                  borderRadius: '0.125rem',
                  '& .MuiLinearProgress-bar': {
                    borderRadius: '0.125rem',
                  },
                }}
              />
            </Box>
          ))}
        </Box>
      </Paper>
    </Box>
  )
}
