import { Form, Formik } from 'formik'

import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import useCertificateService from 'utils/hooks/worker/certificate'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'
import { certificateYearOptions, getWorkerId } from 'utils/worker'

import {
  incomeCertificateFileTypeOptions,
  incomeCertificateInitialValues,
  incomeCertificateValidationSchema,
} from './helpers'

const IncomeCertificate = ({ state, handleClose }) => {
  const { certificateMutation } = useCertificateService()
  const downloadURI = useDownloadURI()
  const { isWorker } = useUser()

  const handleSubmit = (values) => {
    certificateMutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey: isWorker
          ? 'workerProfileIncomeCertificate'
          : 'incomeCertificate',
        workerId: state.worker.id,
        options: values,
      },
      {
        onSuccess: ({ file }) => {
          downloadURI(file)
          Mixpanel.trackWorker(
            MIXPANEL_EVENTS.DOWNLOAD_INCOME_CERTIFICATE_FILE_V2,
            {
              user_id: getUserId(),
              user_role: getUserRole(),
              company_id: getCompanyId(),
              worker_id: getWorkerId(),
              certificate_year: values.certificate_year,
            }
          )
          handleClose()
        },
      }
    )
  }

  return (
    <Modal
      open={state.open}
      header="Certificado de Ingresos y Retenciones"
      hideFooter
      onCancel={handleClose}
      isLoading={certificateMutation.isLoading}
    >
      <Typography>
        Escoge el año del cual quieres generar el Certificado de Ingresos y
        Retenciones.
      </Typography>
      <Formik
        initialValues={incomeCertificateInitialValues}
        validationSchema={incomeCertificateValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleSubmit: onSubmit }) => {
          return (
            <Form>
              <Box
                sx={(theme) => ({
                  marginTop: theme.spacing(3),
                  ...(!isWorker && {
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: theme.spacing(3),
                  }),
                })}
              >
                <FormField
                  name="certificate_year"
                  label="Año del certificado:"
                  variant="select"
                  options={certificateYearOptions}
                />
                {!isWorker ? (
                  <FormField
                    name="file_format"
                    variant="radio-group"
                    options={incomeCertificateFileTypeOptions}
                    row
                  />
                ) : null}
              </Box>

              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: theme.spacing(7),
                  rowGap: theme.spacing(2),
                  [theme.breakpoints.up('tablet')]: {
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: theme.spacing(4),
                    columnGap: theme.spacing(2),
                    rowGap: 0,
                  },
                })}
              >
                <Button
                  onClick={onSubmit}
                  loading={certificateMutation.isLoading}
                >
                  Generar archivo
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  Cancelar
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default IncomeCertificate
