import { isSameDay, isValid, isWithinInterval, parseISO } from 'date-fns'

import { Link } from '@mui/material'

const noveltyLabel = {
  holidays: 'vacaciones',
  incapacities: 'incapacidades',
  licenses: 'licencias',
}

export const getModalHeader = (novelty, workerName) => {
  return `¿Reportale ${noveltyLabel[novelty]} a ${workerName}?`
}

export const getDateRangeDaysClassification = (day, start, end) => {
  const dayIsBetween = start && end && isWithinInterval(day, { start, end })
  const isFirstDay = isSameDay(day, start)
  const isLastDay = isSameDay(day, end)

  return [dayIsBetween, isFirstDay, isLastDay]
}

export const noveltyDescription = {
  holidays: (
    <>
      Ingresa aquí los días de vacaciones disfrutados o compensados en dinero
      durante el periodo. Si tienes inquietudes ingresa a{' '}
      <Link
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-ingresar-vacaciones-disfrutadas-a-un-empleado"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
  incapacities: (
    <>
      Ingresa aquí los días de incapacidad por enfermedad general o accidente de
      trabajo de la persona. Si tienes inquietudes ingresa a{' '}
      <Link
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-registrar-las-incapacidades-de-un-empleado"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
  licenses: (
    <>
      Ingresa aquí los diferentes días de licencias que tuvo la persona en el
      periodo. Si tienes inquietudes ingresa a{' '}
      <Link
        href="https://ayuda.aleluya.com/portal/es/kb/articles/cómo-registrar-las-licencias-de-mis-empleados"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda.
      </Link>
    </>
  ),
}

export function parseCompensatedDayValue(value) {
  let result = parseInt(value, 10)

  if (Number.isNaN(result) || value < 0) {
    result = 0
  } else if (value > 30) {
    result = 30
  }

  return result
}

export const parseItemDay = (dateString) => {
  const date = parseISO(dateString)

  if (isValid(date)) return date

  return null
}
