import { useState } from 'react'
import { Navigate } from 'react-router-dom'

import { Box } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import Page from 'components/UI/Page/Page'

import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import * as routes from 'config/routes'

import { tabsData } from './helpers'

const OrganizerFiles = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { user } = useUser()

  const handleChangeTab = (e, newTab) => {
    setActiveTab(newTab)
  }

  const { externalOrganizer, flagsReady } = useFeatureFlags({
    flags: ['externalOrganizer'],
    trackingMode: 'attributes',
    attributes: {
      userEmail: user.email,
    },
  })
  const isExternalOrganizer = flagsReady && externalOrganizer?.value === 'on'

  if (isExternalOrganizer) {
    return <Navigate to={routes.ORGANIZER_DISABILITY_CLAIMS_INDEX()} />
  }

  return (
    <Page header="Acciones generales">
      <Box pb={1} boxShadow={4}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RoundedTabs
            value={activeTab}
            onChange={handleChangeTab}
            tabsConfig={tabsData}
            background="white"
          />
        </Box>

        <Box width="1" py={1} px={3}>
          {tabsData[activeTab].content}
        </Box>
      </Box>
    </Page>
  )
}

export default OrganizerFiles
