import { useEffect, useReducer } from 'react'
import { useLocation } from 'react-router-dom'

import { useBreadcrumbsConfig } from 'components/App/BreadcrumbsProvider'

import { validRoute } from 'utils/regex'

const iconList = {
  '/intro': 'flag',
  '/dashboard': 'tower-control',
  '/pay_payroll': 'wallet',
  '/periods_history': 'folder-open',
  '/novelties_request_history': 'calendar-1',
  '/workers': 'users',
  '/companies/*': 'factory',
  '/settings': 'settings',
  '/subscription': 'file-chart-line',
  '/subscription_detail': 'file-chart-line',
  '/holistic_payroll/disability_claims': 'hand-coins',
  '/holistic_payroll/financial_wellness': 'briefcase-business',
  '/organizer/disability_claims': 'hand-coins',
  '/profile': 'circle-user',
}

export const pathsMap = {
  // General routes
  '/intro': 'Primeros pasos',
  '/dashboard': 'Torre de control',
  '/payment_required': 'Pago requerido',
  '/social_security_integration_payment/transaction_response':
    'Estado de transacción',
  '/account_settings': 'Configuración de cuenta',
  '/partner_landing': 'Partners',

  // Period routes
  '/pay_payroll': 'Liquidar nómina',
  '/pay_payroll/generated_payroll': 'Resumen de nómina',
  '/pay_payroll/*/view_details/*': 'Cálculos del periodo',
  '/pay_payroll/*/novelties_history': 'Novedades',
  '/pay_payroll/*/social_benefits_period/*': 'Prestaciones sociales',
  '/pay_payroll/*/social_security': 'Seguridad social',
  '/pay_payroll/*/decree376/*': 'Pago decreto 376',
  '/periods_history/*/social_security': 'Seguridad social',
  '/periods_history/*/social_benefits_period/*': 'Prestaciones sociales',
  '/periods_history/*/novelties_history': 'Novedades',
  '/periods_history/*/*/view_details/*': 'Cálculos del periodo',
  '/periods_history': 'Historial de periodos',
  '/periods_history/*': 'Detalle periodo',
  '/periods_history/electronic_payroll/*': 'Detalle Nómina Electrónica',
  '/periods_history/electronic_payroll/*/worker_history/*':
    'Historial Nómina Electrónica',
  '/periods_history/*/decree376/*': 'Pago decreto 376',
  '/periods_history/*/generated_payroll': 'Resumen de nómina',

  // Worker routes (admin profile)
  '/workers': 'Personas',
  '/workers/import': 'Importar personas',
  '/workers/*/create': 'Crear nueva persona',
  '/workers/wages': 'Actualizar salarios',
  '/workers/affiliations': 'Afiliaciones a seguridad social',
  '/workers/affiliations/new': 'Nueva afiliación',
  '/workers/*': 'Perfil',
  '/workers/*/payments_history': 'Pagos',
  '/workers/*/holidays_history': 'Vacaciones',
  '/workers/*/novelties_history': 'Novedades',
  '/workers/*/contracts': 'Contratos',
  '/workers/*/contracts/*/wages': 'Salarios',
  '/workers/*/contracts/*/detail': 'Detalle de contrato',
  '/workers/*/contracts/*/termination/new': 'Liquidar contrato',
  '/workers/*/contracts/*/termination/edit': 'Editar liquidación',
  '/workers/*/contracts/*/social_benefits': 'Prestaciones sociales',
  '/workers/*/contracts/*/social_benefits/*': 'Detalle prestación social',
  '/workers/*/loans': 'Préstamos',
  '/workers/*/loans/*': 'Detalle préstamo',

  // Novelties request routes (admin profile)
  '/novelties_request_history': 'Historial de solicitudes de tiempo fuera',

  // Worker routes (worker profile)
  '/profile': 'Perfil',
  '/profile/payments_history': 'Historial de pagos',
  '/profile/payments_history/*': 'Cálculos de nómina',
  '/profile/financial_wellness': 'Bienestar financiero',

  // Company routes
  '/companies': 'Empresas',
  '/companies/*': 'Detalle de empresa',
  '/companies/*/edit': 'Editar empresa',

  // Subscription routes
  '/subscription': 'Suscripción',
  '/subscription/checkout': 'Pago de suscripción',
  '/subscription/payments': 'Historial de pagos',
  '/subscription/plans': 'Nuestros planes',

  // Settings routes
  '/settings': 'Configuración',
  '/settings/users': 'Usuarios',
  '/settings/advanced': 'Configuración avanzada',
  '/settings/company_history': 'Historial de acciones',
  '/settings/accounting': 'Contabilidad',
  '/settings/integrations': 'Integraciones',
  '/settings/integrations/alegra/accounting': 'Contabilidad Alegra',
  '/settings/integrations/social_security_payments_history':
    'Planillas seguridad social',
  '/settings/electronic_payroll': 'Nómina Electrónica',
  '/settings/workers_benefits': 'Beneficios y accesibilidad para personas',

  // Holistic payroll routes
  '/holistic_payroll/disability_claims': 'Reclamaciones',
  '/holistic_payroll/financial_wellness': 'Bienestar financiero',

  // Organizer routes
  '/organizer/companies': 'Empresas',
  '/organizer/companies/*/payment': 'Agregar pagos y comentarios',
  '/organizer/users': 'Usuarios',
  '/organizer/actions': 'Acciones generales',
  '/organizer/partners': 'Partners',
  '/organizer/partners/*': 'Detalle de partner',
  '/organizer/partners/*/payment': 'Crear pago',
  '/organizer/affiliations': 'Afiliaciones a seguridad social',
  '/organizer/affiliations/*': 'Detalle de afiliación',
  '/organizer/disability_claims': 'Reclamaciones',
  '/organizer/disability_claims/*': 'Reclamaciones en proceso',
}

const disabledBreadcrumbs = ['/companies']
const companiesRegex =
  /^\/companies\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}.*$/

const INITIAL_STATE = {
  breadcrumbs: [],
}

const breadcrumbsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_BREADCRUMBS':
      return {
        ...state,
        breadcrumbs: action.payload,
      }
    default:
      return state
  }
}

export const useBreadcrumbs = () => {
  const { pathname } = useLocation()
  const [state, dispatch] = useReducer(breadcrumbsReducer, INITIAL_STATE)
  const { breadcrumbsConfig } = useBreadcrumbsConfig()

  useEffect(() => {
    const pathnames = pathname.split('/')
    const paths = pathnames
      .map((_, index) => {
        const subpaths = pathnames.slice(0, index + 1)
        return {
          value: subpaths
            ?.map((value) =>
              validRoute.test(value) || value === 'decree376' ? value : '*'
            )
            .slice(1)
            .join('/'),
          originalPath: `${subpaths.join('/')}`,
        }
      })
      .slice(1)

    const breadcrumbsData = []
    paths.forEach((path, index) => {
      const to = path?.originalPath
      const pathValue = `/${path?.value}`
      const label = pathsMap[pathValue] || ''
      const isLast = index === paths.length - 1
      const config = breadcrumbsConfig[pathValue]

      if (!label) return undefined

      // TODO: fix this when Remix is implemented using the hook useMatches
      if (
        disabledBreadcrumbs.includes(pathValue) &&
        companiesRegex.test(pathname)
      )
        return undefined

      return breadcrumbsData.push({
        label,
        variant: to && !isLast ? 'link' : 'text',
        to,
        icon: iconList[pathValue],
        ...(config || {}),
      })
    })

    if (pathname) {
      dispatch({ type: 'SET_BREADCRUMBS', payload: breadcrumbsData })
    }
  }, [breadcrumbsConfig, pathname])

  return state.breadcrumbs
}
