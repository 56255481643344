import { useState } from 'react'

export function useFirstWorkerAddedModal() {
  const [state, setState] = useState({
    open: false,
  })

  const openFirstWorkerAddedModal = () => {
    setState({
      open: true,
    })
  }

  const closeFirstWorkerAddedModal = () => {
    setState({
      open: false,
    })
  }

  return {
    firstWorkerAddedModalState: state,
    openFirstWorkerAddedModal,
    closeFirstWorkerAddedModal,
  }
}
