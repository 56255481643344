import useModals from 'components/App/ModalsManager/useModals'

import { EmployeeUploadInformation } from './EmployeeUploadInformation'

export function useEmployeeUploadInformationModal() {
  const modals = useModals()

  const handleClose = () => {
    modals.closeModal('employeeUploadInformation')
  }

  const openEmployeeUploadInformationModal = () =>
    modals.openModal({
      id: 'employeeUploadInformation',
      content: <EmployeeUploadInformation handleClose={handleClose} />,
      modalProps: {
        onCancel: handleClose,
        hideFooter: true,
        paperSx: {
          maxWidth: '55rem',
        },
        contentWrapperSx: {
          overflow: 'hidden',
        },
      },
    })

  return { openEmployeeUploadInformationModal }
}
