import { Box, Button } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import {
  PlanCard,
  PlanDescriptionByCategory,
  PlanName,
} from 'components/Subscription/Atoms/PlanUIComponents'
import useSubscription from 'components/Subscription/Atoms/useSubscription'

import SubscriptionConfirmationModal from '../SubscriptionConfirmationModal'

const PartnerSubscriptionCardsModalContent = ({
  planData,
  selectedPlanIndex,
  currentPlanIndex,
}) => {
  const modals = useModals()
  const { subscription } = useSubscription()

  const { codedName, isCurrentPlan, isRecommendedPlan, planName } = planData

  const isPremiumHRPlan = codedName === 'premium_hr_per_worker_plan'
  const { active_until: activeUntil } = subscription || {}
  const planUpgrade = selectedPlanIndex > currentPlanIndex

  const handleSubscriptionChange = () => {
    modals.closeModal('partnerSubscriptionModal')
    modals.openModal({
      id: 'subscriptionConfirmationModal',
      content: (
        <SubscriptionConfirmationModal
          selectedPlan={planData}
          selectedModality="month"
          isAnUpgrade={planUpgrade}
          isCurrentPlan={isCurrentPlan}
          activeUntil={activeUntil}
          planUpgrade={planUpgrade}
          isChangingModality={false}
        />
      ),
      modalProps: {
        header: '¿Estás seguro de realizar este cambio?',
        hideFooter: true,
        paperSx: {
          maxWidth: '45rem',
          width: '100%',
        },
      },
    })
  }

  return (
    <PlanCard
      isCurrentPlan={isCurrentPlan}
      isRecommendedPlan={isRecommendedPlan}
    >
      <PlanName planName={planName} />
      <PlanDescriptionByCategory codedName={codedName} />

      {!isCurrentPlan ? (
        <Box
          sx={(theme) => ({
            width: '100%',
            marginTop: theme.spacing(2),
          })}
        >
          <Button
            fullWidth
            onClick={handleSubscriptionChange}
            variant={!isPremiumHRPlan ? 'outlined' : 'contained'}
          >
            Activar plan
          </Button>
        </Box>
      ) : null}
    </PlanCard>
  )
}

export default PartnerSubscriptionCardsModalContent
