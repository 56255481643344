import { Box, Card, Typography, useTheme } from '@mui/material'

import Icon from 'components/UI/Icon'
import Tooltip from 'components/UI/Tooltip'

const NextPaymentCard = ({
  title,
  value,
  backgroundColor = 'primary',
  isWhiteText = false,
  icon = <Icon name="list" fontSize="small" />,
  tooltipTitle,
  tooltipDescription,
  descriptionDate,
  textColor,
}) => {
  const themeInstance = useTheme()
  const backgroundColorKeys = backgroundColor.split('.')
  const themeBackgroundColor = backgroundColor?.includes('.')
    ? themeInstance.palette[backgroundColorKeys[0]][backgroundColorKeys[1]]
    : themeInstance.palette[backgroundColorKeys[0]]?.main

  return (
    <Card
      sx={(theme) => ({
        padding: theme.spacing(2),
        minHeight: '6.5rem',
        position: 'relative',
        backgroundColor: themeBackgroundColor,
        boxShadow: 'none',
        borderRadius: '1rem',
        color: textColor,
      })}
    >
      <Box
        sx={(theme) => ({
          color: isWhiteText ? 'white.main' : '',
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.spacing(1),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.75),
            justifyContent: 'space-between',
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(0.75),
            })}
          >
            {icon}
            <Typography variant="body1">{title}</Typography>
          </Box>
          <Tooltip
            title={tooltipTitle}
            description={tooltipDescription}
            placement="bottom-start"
            tooltipSx={(theme) => ({ boxShadow: theme.shadows[7] })}
          >
            <Box sx={{ display: 'flex' }}>
              <Icon name="info" sx={{ fontSize: '1.5rem' }} />
            </Box>
          </Tooltip>
        </Box>
        {descriptionDate}
        <Typography
          variant="h4"
          title={value || undefined}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {value}
        </Typography>
      </Box>
    </Card>
  )
}

export default NextPaymentCard
