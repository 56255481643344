import { Box, useMediaQuery } from '@mui/material'

import { TrustBanner } from 'components/UI/TrustBanner'

import AuthLogo from './AuthLogo'

const MainContainer = ({ children }) => {
  const laptopUp = useMediaQuery((theme) => theme.breakpoints.up('laptop'))

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        backgroundColor: theme.palette.white.main,
        alignItems: 'center',
        height: '100vh',
        [theme.breakpoints.up('laptop')]: {
          gridTemplateColumns: '1fr 1fr',
          height: '100%',
        },
      })}
    >
      {laptopUp ? <TrustBanner /> : null}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          overflow: 'auto',
          maxHeight: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={({ breakpoints, spacing }) => ({
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '26rem',
            padding: spacing(4),
            gap: spacing(2),
            justifyContent: 'center',
            [breakpoints.down('tablet')]: {
              padding: spacing(2),
              width: '20rem',
            },
          })}
        >
          <AuthLogo />
          <Box>{children}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MainContainer
