import { differenceInDays, isSameDay, parseISO } from 'date-fns'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box } from '@mui/material'

import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { formatDate, formatToUTCISO } from 'utils/dateTime'
import { getWordSingular } from 'utils/format'
import { getFileNameFromUrl, isValidUrl } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'
import useFreeTimeService from 'utils/hooks/worker/freeTime'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'
import { getWorkerId } from 'utils/worker'

import {
  conceptsToDelete,
  conceptsTypes,
  getInitialValues,
  validationSchema,
} from '../Index/helpers'

const RequestFreeTimeModal = ({
  onClose,
  workerId,
  contractCategory,
  requestData,
  isEditing,
}) => {
  const [payrollConcepts, setPayrollConcepts] = useState([])
  const currentData = requestData || {}
  const queryClient = useQueryClient()
  const confirm = useConfirm()
  const evidenceUrl = isValidUrl(currentData?.evidence)
  const evidenceFileName = getFileNameFromUrl(
    evidenceUrl ? evidenceUrl.search : undefined
  )

  const okButton = isEditing ? 'Editar solicitud' : 'Solicitar días libres'

  const { freeTimeMutation } = useFreeTimeService({
    serviceParams: {
      queryKey: ['payrollConcepts', workerId],
      workerId,
      enabled: false,
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        const newPayrollConcepts = []
        // Create an array and delete payroll concepts that are not necessary

        const addPayrollConcept = (concepts, key) => {
          concepts[key].map((concept) => {
            if (
              !conceptsToDelete(contractCategory).includes(concept.coded_name)
            )
              newPayrollConcepts.push(concept)

            return null
          })
        }

        conceptsTypes.map((type) => {
          addPayrollConcept(data, type)
          return null
        })

        newPayrollConcepts.map((concept, index) => {
          if (concept.coded_name === 'enjoyed_days')
            newPayrollConcepts[index].name = 'Vacaciones'
          return null
        })

        newPayrollConcepts.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })

        setPayrollConcepts(newPayrollConcepts)
      },
    },
  })

  const initialValues = getInitialValues(currentData, payrollConcepts)

  const { showSuccessMessage } = useNotifications()

  const createFreeTimeRequest = (values, typeRequest) => {
    const formData = new FormData()
    formData.append('payroll_concept_id', typeRequest.id)
    formData.append('initial_day', formatDate(values.initial_day))
    formData.append('end_day', formatDate(values.end_day))

    if (values.comment) formData.append('comment', values.comment)
    if (values.evidence) formData.append('evidence', values.evidence)

    freeTimeMutation.mutate(
      {
        mutationMethod: 'POST',
        workerId,
        freeTimeRequest: formData,
      },
      {
        onSuccess: () => {
          onClose()
          showSuccessMessage('Solicitud creada correctamente')
          queryClient.invalidateQueries('allRequests')
          Mixpanel.trackWorker(MIXPANEL_EVENTS.CREATE_NOVELTY_REQUEST_V2, {
            user_id: getUserId(),
            user_role: getUserRole(),
            company_id: getCompanyId(),
            worker_id: getWorkerId(),
            novelty_type: values.novelty_type,
            initial_day: formatToUTCISO(values.initial_day),
            end_day: formatToUTCISO(values.end_day),
          })
        },
      }
    )
  }

  const updateFreeTimeRequest = (values, typeRequest) => {
    const formData = new FormData()
    formData.append('payroll_concept_id', typeRequest.id)
    formData.append('initial_day', formatDate(values.initial_day))
    formData.append('end_day', formatDate(values.end_day))

    if (values.comment !== currentData.comment) {
      if (typeof values.comment === 'string' && values.comment.length > 0) {
        formData.append('comment', values.comment)
      } else if (
        typeof values.comment === 'undefined' ||
        [null, ''].includes(values.comment)
      ) {
        formData.append('comment', '')
      }
    }

    if (values.evidence !== currentData.evidence) {
      if (values.evidence && typeof values.evidence === 'object') {
        formData.append('evidence', values.evidence)
      } else if (
        typeof values.evidence === 'undefined' ||
        values.evidence === null
      ) {
        formData.append('evidence', '')
      }
    }

    freeTimeMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId,
        noveltyId: currentData.id,
        freeTimeRequest: formData,
      },
      {
        onSuccess: () => {
          onClose()
          showSuccessMessage('Solicitud actualizada correctamente')
          queryClient.invalidateQueries('allRequests')
        },
      }
    )
  }

  const noveltyOptions = payrollConcepts.map((option) => ({
    id: option.id,
    label: option.name,
    value: option.coded_name,
  }))

  const getDifferenceInDays = (initialDay, endDay) => {
    const firstDate =
      initialDay instanceof Date ? initialDay : parseISO(initialDay)
    const secondDate = endDay instanceof Date ? endDay : parseISO(endDay)

    if (isSameDay(firstDate, secondDate)) return 1

    return differenceInDays(secondDate, firstDate) + 1
  }

  const onSubmit = (values) => {
    if (payrollConcepts) {
      const typeRequest = payrollConcepts.filter(
        (option) => option.coded_name === values?.novelty_type
      )[0]

      const daysRequested = getDifferenceInDays(
        values?.initial_day,
        values?.end_day
      )

      confirm({
        title: 'Confirmación de solicitud de tiempo fuera',
        description: `Confirmas que deseas solicitar ${typeRequest?.name.toLowerCase()}  por ${daysRequested} ${getWordSingular(
          'días',
          daysRequested === 1
        )}.`,
        okText: 'Aceptar',
        onOk: () =>
          isEditing
            ? updateFreeTimeRequest(values, typeRequest)
            : createFreeTimeRequest(values, typeRequest),
      })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => {
        return (
          <Form>
            <hr />
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(3),
                marginBottom: theme.spacing(3),
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  columnGap: theme.spacing(2),
                  [theme.breakpoints.down('tablet')]: {
                    flexDirection: 'column',
                    rowGap: theme.spacing(3),
                  },
                })}
              >
                <FormField
                  name="initial_day"
                  variant="datepicker"
                  label="Día inicio"
                  maxDate={values?.end_day}
                  optional={false}
                />
                <FormField
                  name="end_day"
                  variant="datepicker"
                  label="Día final"
                  minDate={values?.initial_day}
                  disabled={!values.initial_day}
                  optional={false}
                />
              </Box>
              <FormField
                name="novelty_type"
                variant="select"
                label='Selecciona el tipo de "Tiempo fuera" que quieres solicitar'
                options={noveltyOptions}
                optional={false}
              />
              <FormField
                name="evidence"
                variant="file"
                fileType="application/pdf"
                fileName={evidenceFileName}
                accept="application/pdf"
                label="¿Deseas adjuntar un documento de soporte?"
                optional
              />
              <FormField
                name="comment"
                variant="textarea"
                label="Nota"
                rows="4"
                inputProps={{
                  maxLength: 300,
                }}
                optional
              />
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                gap: theme.spacing(2),
                flexWrap: 'wrap',
              })}
            >
              <Button onClick={handleSubmit}>{okButton}</Button>
              <Button variant="outlined" onClick={onClose}>
                Cancelar
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default RequestFreeTimeModal
