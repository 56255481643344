import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import LoadedFile from 'components/UI/Formik/CommonFields/LoadedFile'
import Link from 'components/UI/MaterialUI/Link'

import useDownloadURI from 'utils/hooks/useDownloadURI'

import { WORKER_SHOW } from 'config/routes'

import { useDocumentDeletion } from './helpers'

const FinishedContent = ({
  children,
  workerName,
  workerId,
  electronicSignatureQueryData,
  handleClose,
}) => {
  const handleConfirmFileDeletion = useDocumentDeletion()
  const downloadURI = useDownloadURI()

  const handleDeleteFile = () => {
    handleConfirmFileDeletion(workerId, handleClose)
  }

  const signedFile = electronicSignatureQueryData[0]?.signed_file

  return (
    <Box>
      <Box sx={(theme) => ({ margin: theme.spacing(0, 0, 4) })}>
        <Typography variant="body1" color="black.dark">
          ¡La rompiste! Esos contratos quedaron firmados, tienen validez
          jurídica y están guardados en la carpeta <b>Documentos Firmados</b> de
          la persona.
        </Typography>
        <Link to={WORKER_SHOW(workerId)} sx={{ textDecoration: 'underline' }}>
          Ir al perfil de {workerName}.
        </Link>
      </Box>

      <Typography
        variant="h6"
        color="primary.dark"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
        })}
      >
        Personas que firmaron este documento
      </Typography>

      <Box sx={(theme) => ({ margin: theme.spacing(0, 0, 4) })}>{children}</Box>

      <Typography variant="h6" color="primary.dark">
        Descarga tus documentos firmados
      </Typography>

      <Box sx={(theme) => ({ margin: theme.spacing(1, 0, 4) })}>
        <Typography variant="body1" color="black.dark">
          Recuerda que todos estos documentos quedarán guardados automáticamente
          en el perfil de cada persona y siempre los podrás consultar allí.
        </Typography>
        <Link to={WORKER_SHOW(workerId)} sx={{ textDecoration: 'underline' }}>
          Ir al perfil de {workerName}.
        </Link>
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          margin: theme.spacing(3, 0, 0),
        })}
      >
        <Typography variant="lead2">Documento adjunto</Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
          })}
        >
          <LoadedFile
            fileName="Documento adjunto"
            hideRemoveButton={false}
            handleDeleteFile={handleDeleteFile}
          />
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'center',
              [theme.breakpoints.up('tablet')]: {
                columnGap: theme.spacing(2),
                flexDirection: 'row',
              },
            })}
          >
            <Button
              variant="text"
              onClick={handleDeleteFile}
              sx={{
                margin: 0,
                padding: 0,
                width: 'fit-content',
                textDecoration: 'underline',
                minWidth: 0,
              }}
            >
              Eliminar
            </Button>
            <Button
              variant="text"
              onClick={() => downloadURI(signedFile)}
              value="Documento firmado"
              sx={{
                margin: 0,
                padding: 0,
                width: 'fit-content',
                textDecoration: 'underline',
                minWidth: 0,
              }}
            >
              Descargar
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FinishedContent
