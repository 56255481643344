import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import Modal from 'components/UI/Modal/Modal'

import { formatDisplayDateString } from 'utils/dateTime'
import { sunglassesFace } from 'utils/emojis'
import { formatCurrency } from 'utils/format'

import floatingWoman from 'assets/images/views/common/floating-woman.png'

const UpcomingDowngradeAlertModal = ({ state, handleClose }) => {
  const { subscription } = useSubscription()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const { open, downgradeData } = state

  const {
    plan: downgradePlan,
    current_type: downgradeModality,
    date: downgradeStartingDate,
    plan_value: planValue,
  } = downgradeData || {}

  const handleCloseModal = () => {
    localStorage.setItem('upcoming-downgrade-alert-modal', 'modalViewed')
    handleClose()
  }

  const isSamePlan = subscription.plan.name === downgradePlan

  return (
    <Modal
      open={open}
      onCancel={handleCloseModal}
      hideFooter
      paperSx={{
        maxWidth: '52rem',
        position: 'relative',
      }}
      contentWrapperSx={{
        overflow: 'hidden',
        minHeight: '22.5rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { mobile: '1fr', tablet: '18rem 1fr' },
        }}
      >
        <ButtonBase
          onClick={handleCloseModal}
          data-cy="close-dialog-button"
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(-1),
            top: theme.spacing(0.5),
            cursor: 'pointer',
            color: theme.palette.black.main,
          })}
        >
          <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
        </ButtonBase>
        {!isMobile ? (
          <>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.light,
                width: '27.75rem',
                height: '27.75rem',
                borderRadius: '100%',
                position: 'absolute',
                left: theme.spacing(-18.5),
                top: theme.spacing(-8.75),
              })}
            />
            <img
              loading="lazy"
              src={floatingWoman}
              alt=""
              width={292}
              height={268}
              style={{
                zIndex: 1,
              }}
            />
          </>
        ) : null}
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.up('tablet')]: {
              paddingLeft: theme.spacing(3),
            },
          })}
        >
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="h2"
                sx={(theme) => ({ margin: theme.spacing(0, 3, 2.5, 0) })}
              >
                ¡Recuerda que actualizaste tu plan!{' '}
                <Emoji code={sunglassesFace} />
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="black.dark"
              sx={(theme) => ({
                flexDirection: 'column',
                display: 'flex',
                gap: theme.spacing(1),
                marginBottom: theme.spacing(1),
              })}
            >
              <span>
                {isSamePlan ? (
                  <>
                    El ajuste en tu plan <b>{downgradePlan}</b>, comenzará a
                  </>
                ) : (
                  'A'
                )}{' '}
                partir del{' '}
                <b>{formatDisplayDateString(downgradeStartingDate)}</b>,{' '}
                {!isSamePlan ? (
                  <>
                    nos iremos de viaje con tu nuevo plan{' '}
                    {<b>{downgradePlan}</b>},
                  </>
                ) : null}{' '}
                con un costo{' '}
                <b>{`${downgradeModality === 'month' ? 'mensual' : 'anual'}`}</b>{' '}
                de <b>{formatCurrency(planValue)}</b>.
              </span>
              ¡Relax! No tienes que hacer nada. Solo sigue disfrutando de todos
              los superpoderes de tu plan actual.
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              marginTop: theme.spacing(3),
            })}
          >
            <Button onClick={handleCloseModal}>Cerrar</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default UpcomingDowngradeAlertModal
