import { Box, Paper, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Icon from 'components/UI/Icon'
import useWorker from 'components/Worker/useWorker'

import useRetentionsConfigurationService from 'utils/hooks/worker/retentionConfigurationService'

import useRecurrentConcepts from '../../useRecurrentConcepts'
import NewRecurrentConceptsItem from './RecurrentConceptsItem'

const RecurrentConceptsContent = ({ withoutHolidays }) => {
  const { isWorker } = useUser()
  const { worker } = useWorker({ useCache: true })
  const workerId = worker.id

  const { retentionsQuery } = useRetentionsConfigurationService({
    serviceParams: {
      queryKey: ['retentionConfigurations', workerId],
      workerId,
    },
    queryOptions: {
      enabled:
        Boolean(workerId) && worker?.retention_configuration && !isWorker,
    },
  })
  const {
    recurrentConcepts,
    isLoading: isRecurrentLoading,
    isError: isRecurrentError,
  } = useRecurrentConcepts()

  const isLoading = retentionsQuery.isLoading || isRecurrentLoading
  const isError = retentionsQuery.isError || isRecurrentError

  const getTotal = (conceptsItems) => {
    const filterItems = conceptsItems?.filter((item) => item.active)

    return filterItems?.reduce(
      (result, item) =>
        result + item.monthly_value || result + item.monthly_fee,
      0
    )
  }

  return (
    <Paper
      sx={(theme) => ({
        display: 'flex',
        minHeight: '9.5rem',
        padding: theme.spacing(2, 3),
        flexDirection: 'column',
        backgroundColor: theme.palette.primary.light,
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
        [theme.breakpoints.up('tablet')]: {
          padding: theme.spacing(2, 4, 2, 4),
        },
        [theme.breakpoints.between('tablet', 'laptop')]: {
          padding: theme.spacing(2),
        },
        [theme.breakpoints.between('laptop', 'lg')]: {
          padding: theme.spacing(2),
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(0.5),
          marginBottom: theme.spacing(1),
        })}
      >
        <Icon name="hand-coins" fontSize="large" />
        <Typography
          variant="body1"
          sx={{
            zIndex: 2,
          }}
        >
          Conceptos recurrentes
        </Typography>
      </Box>
      {!isError && !isLoading ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5),
            ...(withoutHolidays && {
              [theme.breakpoints.between('tablet', 1300)]: {
                gap: theme.spacing(1),
              },
            }),
          })}
        >
          <NewRecurrentConceptsItem
            label="+ Ingresos"
            value={getTotal(recurrentConcepts?.incomes)}
            withoutHolidays={withoutHolidays}
          />
          <NewRecurrentConceptsItem
            label="- Deducciones"
            value={getTotal(recurrentConcepts?.deductions)}
            withoutHolidays={withoutHolidays}
          />
          <NewRecurrentConceptsItem
            label="Retención"
            value={
              isWorker ? worker.payroll_retention : retentionsQuery.data?.active
            }
            withoutHolidays={withoutHolidays}
          />
        </Box>
      ) : null}
    </Paper>
  )
}

export default RecurrentConceptsContent
