import { useQueryClient } from 'react-query'

import { getCompanyId } from 'utils/company'
import { removeSuffix } from 'utils/general'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

export const COMPANY_ACCESS = {
  /** (without current_plan) access: basic functionalities only
   * Companies with trial period (15 days) expired, cancelled subscription, expired subscription or pending payments.
   * Free access, no access to any premium endpoint.
   */
  FREE: 'free',

  /** (Plan básico)
   * Modules:
   * - Nómina.
   */
  PREMIUM: 'premium',

  /** (Plan Superpoderes - Plan Full juguetes)
   * Modules:
   * - Nómina.
   * - Solicitudes tiempo fuera.
   * - Personas -> En contratación.
   */
  PREMIUM_HR: 'premium_hr',

  /** (Plan Emprendedor - Plan Solo nómina)
   * Modules:
   * - Nómina.
   * - Reclamaciones.
   */
  PREMIUM_PLUS: 'premium_plus',

  /** (Plan Nómina Holística) full access.
   * Modules:
   * - Nómina
   * - Tiempo fuera.
   * - Reclamaciones.
   * - Bienestar financiero.
   * - Personas -> En contratación.
   */
  PREMIUM_FULL: 'premium_full',
}

export const PLANS_BY_CODED_NAME = {
  old_plans: {
    basic_plan: 'premium_per_worker_plan', // Básico
    full_toys_plan: 'premium_hr_per_worker_plan', // Full Juguetes
    superpowers_plan: 'pro_per_worker_plan', // Superpoderes
  },
  new_plans: {
    entrepreneur_plan: 'entrepreneur_per_worker_plan20241111', // Emprendedor
    payroll_only_plan: 'premium_per_worker_plan20241111', // Solo Nómina
    holistic_payroll_plan: 'premium_full_per_worker_plan20241111', // Nómina Holística
  },
}

const RECOMMENDED_PLANS = [
  PLANS_BY_CODED_NAME.old_plans.full_toys_plan,
  PLANS_BY_CODED_NAME.new_plans.holistic_payroll_plan,
]

const BASE_PAYMENT_MODEL_PLANS = [
  PLANS_BY_CODED_NAME.new_plans.payroll_only_plan,
  PLANS_BY_CODED_NAME.new_plans.holistic_payroll_plan,
]

export function usePlansData() {
  const queryClient = useQueryClient()

  const plansQueryKey = ['getPlans', getCompanyId()]

  const plansQueryCache = queryClient.getQueryData(plansQueryKey)

  const { subscriptionQuery: plansQuery } = useSubscriptionService({
    queryOptions: { enabled: !plansQueryCache },
    serviceParams: {
      queryKey: plansQueryKey,
    },
  })

  return {
    plansData: plansQueryCache?.data?.plans ?? plansQuery?.data?.plans ?? [],
    isPlansQueryLoading: plansQuery.isLoading,
  }
}

export function getPlansFormatted({ plansData, modality = 'month' }) {
  const plansFormatted = plansData.map(
    ({ id, name, coded_name, subscription_value, current_plan }) => {
      return {
        id,
        codedName: coded_name,
        isCurrentPlan: current_plan,
        isRecommendedPlan:
          RECOMMENDED_PLANS.includes(
            coded_name.includes('20241111')
              ? coded_name
              : removeSuffix(coded_name, -8)
          ) && !current_plan,
        planName: name,
        planValue:
          modality === 'month'
            ? subscription_value.monthly.value
            : subscription_value.yearly.value,
        planPaymentModel: BASE_PAYMENT_MODEL_PLANS.includes(coded_name)
          ? '/base'
          : '/mes',
        personValue:
          modality === 'month'
            ? subscription_value.monthly.extra_worker_value
            : subscription_value.yearly.extra_worker_value,
        personPaymentModel:
          coded_name == PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan
            ? 'Hasta 3 personas'
            : '/persona',
      }
    }
  )

  return plansFormatted.filter(
    ({ codedName }) =>
      modality === 'month' ||
      (codedName !== PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan &&
        modality === 'year')
  )
}
