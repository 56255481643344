import { zohoScript } from 'utils/integrations/scripts/zoho'

export function removeCurrentChat() {
  window.$zoho.salesiq.reset()
  window.$zohosq = undefined
  window.$zoho = undefined
  document.getElementById('zoho_support_chat')?.remove()

  document
    .querySelectorAll('[data-id="zsalesiq"], [data-id="zsiqembed"]')
    .forEach((element) => element.remove())
}

function loadChatScript() {
  // Creates script element
  const script = document.createElement('script')
  script.setAttribute('id', 'zoho_support_chat')
  const { src, async, defer, child } = zohoScript

  // Sets script attributes
  script.src = src
  script.async = async
  script.defer = defer

  // Executes onload function
  script.onload = child()

  // Appends the script to the DOM
  document.body.appendChild(script)
}

export default loadChatScript
