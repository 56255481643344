import { useState } from 'react'

export function useBasicCompanyInfoModal() {
  const [state, setState] = useState({
    open: false,
  })

  const openBasicCompanyInfoModal = () => {
    setState({ open: true })
  }

  const closeBasicCompanyInfoModal = () => {
    setState({ open: false })
  }

  return {
    basicCompanyInfoModalState: state,
    openBasicCompanyInfoModal,
    closeBasicCompanyInfoModal,
  }
}
