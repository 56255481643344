import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Button } from '@mui/material'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import { useUser } from 'components/App/UserContext/useUser'
import Icon from 'components/UI/Icon'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { formatToUTCISO } from 'utils/dateTime'
import usePeriodService from 'utils/hooks/payroll/periodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { PERIOD_GENERATED_PAYROLL } from 'config/routes'

import { usePeriod } from '../helpers'

const PayPayrollButton = ({ disabled }) => {
  const { company } = useUser()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { period, payPreviousPayroll } = usePeriod()
  const { handleError } = useErrorHandler()
  const periodId = period?.id
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()

  const { periodMutation } = usePeriodService({
    queryOptions: {
      enabled: false,
    },
  })

  const periodQueryKey = [
    payPreviousPayroll ? 'periodById' : 'currentPeriod',
    company?.id,
    ...(payPreviousPayroll ? [periodId] : []),
  ]

  const endFreePayrollModal = useEndFreeModals()

  const handleOpenEndFreePayrollModal = () => {
    endFreePayrollModal.openEndFreePayrollModal()
  }

  const handleChangeStep = async () => {
    showLoadingModal()

    periodMutation.mutate(
      {
        mutationMethod: 'PATCH',
        period: {
          payrolls_paid: true,
        },
      },
      {
        onSuccess: async () => {
          Mixpanel.track(MIXPANEL_EVENTS.PROCESS_PAYROLL_V2, {
            company_id: getCompanyId(),
            user_id: getUserId(),
            user_role: getUserRole(),
            process_date: formatToUTCISO(new Date()),
          })
          navigate(PERIOD_GENERATED_PAYROLL(), { replace: true })
          await queryClient.invalidateQueries(periodQueryKey, {
            exact: false,
          })

          if (!company?.onboarding_first_steps?.first_payroll) {
            queryClient.invalidateQueries(['companyInformation', company?.id])
          }

          hideLoadingModal()
        },
        onError: (error) => {
          hideLoadingModal()
          handleError(error)
        },
      }
    )
  }

  const handleOnClick = () => {
    if (endFreePayrollModal.isNotValidCompany) {
      return handleOpenEndFreePayrollModal()
    }

    return handleChangeStep()
  }

  return (
    <Button
      onClick={handleOnClick}
      disabled={disabled}
      data-cy="pay-payroll-button"
      variant="contained"
      endIcon={<Icon name="move-right" />}
    >
      Continuar
    </Button>
  )
}

export default PayPayrollButton
