import { Box, Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Modal from 'components/UI/Modal/Modal'

import { superHappyFace } from 'utils/emojis'

import boy from 'assets/images/three-dimensional-icons/boy.png'

const ElectronicSignatureConfirmationModal = ({ state, handleClose }) => {
  return (
    <Modal
      open={state.open}
      okText="Finalizar"
      onOk={handleClose}
      hideCancelButton
      header={
        <Typography variant="h3">
          ¡Listo! <Emoji code={superHappyFace} />
        </Typography>
      }
      onCancel={handleClose}
      paperSx={{
        maxWidth: '46rem',
      }}
    >
      <Box>
        <Typography variant="body1" color="black.dark">
          Los documentos fueron enviados de forma exitosa ahora, ve y preparate
          una aromática, que nosotros nos encargamos de notificarle a cada
          persona de la firma y te avisaremos tan pronto estén listas.
        </Typography>
        <Typography
          sx={(theme) => ({ margin: theme.spacing(3, 0) })}
          variant="h6"
          color="primary.dark"
        >
          Documentos enviados a:
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: '1fr 1fr',
            },
          })}
        >
          {state.signers.map((signer) => (
            <Box
              key={signer?.signed_name}
              className="signer_icon_box"
              sx={(theme) => ({
                display: 'flex',
                boxShadow: theme.shadows[3],
                width: '100%',
                maxHeight: '5rem',
                borderRadius: '0.5rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              })}
            >
              <Box
                sx={{
                  display: 'flex',
                  minWidth: '5rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderTopLeftRadius: '0.5rem',
                  borderBottomLeftRadius: '0.5rem',
                  backgroundColor: 'complementary1.light',
                }}
              >
                <Box
                  component="img"
                  loading="lazy"
                  src={boy}
                  alt="Personas que firman este documento"
                  width={64}
                  height={64}
                />
              </Box>
              <Box
                sx={(theme) => ({
                  padding: theme.spacing(1.4),
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: '100%',
                })}
              >
                <Typography
                  variant="h6"
                  title={signer?.signed_name}
                  sx={(theme) => ({
                    marginBottom: theme.spacing(1),
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  })}
                >
                  {signer?.signed_name}
                </Typography>
                <Typography
                  title={signer?.signed_email}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {signer?.signed_email}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}

export default ElectronicSignatureConfirmationModal
