import { Form, Formik } from 'formik'

import { Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import useErrorHandler from 'utils/hooks/useErrorHandler'

const PayslipsModal = ({
  state,
  handleClose,
  handleDownloadPayslip,
  severanceDescription,
}) => {
  const {
    open,
    sendPaymentStubs,
    payroll,
    period,
    payslipTypes,
    payrollRanges,
    file_format: fileFormat,
    whatsappNotification,
  } = state

  const { handleError } = useErrorHandler()

  const sendingMethod = whatsappNotification ? 'WhatsApp' : 'email'

  const handleSubmit = async (values, form) => {
    const { payslip_type: payslipType, payroll_range: payrollRange } = values
    try {
      await handleDownloadPayslip({
        payroll,
        period,
        payslip_type: payslipType,
        payroll_range: payrollRange,
        file_format: fileFormat,
        sendPaymentStubs,
        whatsappNotification,
      })

      form.setSubmitting(false)
    } catch (error) {
      handleError(error, form)
    }
    handleClose()
  }

  // Find termination element to put default when it exists
  const terminationOption = payslipTypes?.find(
    (item) => item.value === 'termination'
  )

  return (
    <Formik
      initialValues={{
        payslip_type: terminationOption?.value || payslipTypes[0]?.value || '',
        payroll_range: payrollRanges[0]?.value || '',
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit: onSubmit, isSubmitting }) => {
        return (
          <Modal
            open={open}
            isLoading={isSubmitting}
            onOk={onSubmit}
            onCancel={handleClose}
            header={
              sendPaymentStubs
                ? `Enviar colillas de pago por ${sendingMethod}`
                : `Descargar ${
                    payroll || !period ? 'colilla' : 'colillas'
                  } de pago`
            }
            okText={sendPaymentStubs ? 'Enviar' : 'Generar'}
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            dialogProps={{
              maxWidth: 'sm',
              fullWidth: true,
            }}
          >
            <>
              {severanceDescription}
              <Form>
                {payslipTypes.length > 0 ? (
                  <>
                    <Typography gutterBottom>
                      {`Selecciona el tipo de colilla que deseas ${
                        sendPaymentStubs
                          ? `enviar por ${sendingMethod}.`
                          : 'generar.'
                      }`}
                    </Typography>
                    <FormField
                      name="payslip_type"
                      label="Tipo de colilla"
                      variant="select"
                      options={payslipTypes}
                    />
                  </>
                ) : null}
                {payrollRanges.length > 0 ? (
                  <FormField
                    name="payroll_range"
                    label="Periodo de colilla"
                    variant="select"
                    options={payrollRanges}
                  />
                ) : null}
              </Form>
            </>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default PayslipsModal
