import { Box, Link, Typography } from '@mui/material'

import { useNoveltyType } from 'components/Period/Payroll/helpers'

import NoveltyCard from './NoveltyCard'
import getNoveltiesCardConfig from './helpers'

const Content = () => {
  const { payrollState } = useNoveltyType()
  const { workerPayroll } = payrollState

  return (
    <Box
      sx={(theme) => ({
        paddingBottom: theme.spacing(1.5),
      })}
    >
      <Typography
        sx={(theme) => ({
          marginTop: theme.spacing(1),
          color: theme.palette.black.dark,
        })}
      >
        Selecciona el tipo de novedad que quieres agregar a la persona. Puedes
        agregar diferentes novedades a una misma persona. Para tener más
        información acerca de la carga de novedades,{' '}
        <Link
          href="https://ayuda.aleluya.com/portal/es/kb/liquidación-de-nómina/liquidar-nómina"
          target="_blank"
        >
          ingresa a esta sección de nuestro centro de ayuda.
        </Link>
      </Typography>
      <Box
        sx={(theme) => ({
          paddingTop: theme.spacing(3),
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 12.5rem)',
          justifyContent: 'center',
          gap: theme.spacing(3),
        })}
      >
        {getNoveltiesCardConfig(workerPayroll).map(
          ({ id, name, icon, value, button, visible }) => {
            if (!visible) return null

            return (
              <NoveltyCard
                key={id}
                dataCy={`${id}_card`}
                id={id}
                name={name}
                icon={icon}
                value={value}
                button={button}
              />
            )
          }
        )}
      </Box>
    </Box>
  )
}

export default Content
