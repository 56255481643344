import { Link as RouterLink } from 'react-router-dom'

import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDateString } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

export const columnsDataShow = [
  {
    Header: 'Pago #',
    accessor: 'id',
    Cell: ({ row }) => parseInt(row.id, 10) + 1,
  },
  {
    Header: 'Fecha',
    accessor: 'date',
    Cell: ({ row }) => formatDisplayDateString(row.original.date),
  },
  {
    Header: 'Valor',
    accessor: 'value',
    Cell: ({ row }) => formatCurrency(row.original.value),
  },
]

export const columnsDataIndex = (workerId) => {
  return [
    {
      Header: 'Préstamo',
      accessor: 'name',
      Cell: ({ row }) => (
        <Link
          to={routes.WORKER_LOAN_SHOW(workerId, row.original.id)}
          color="black.main"
        >
          {row.original.name}
        </Link>
      ),
    },
    {
      Header: 'Fecha',
      accessor: 'date',
      Cell: ({ row }) => formatDisplayDateString(row.original.date),
    },
    {
      Header: 'Valor ',
      accessor: 'value',
      Cell: ({ row }) => formatCurrency(row.original.value),
    },
    {
      Header: 'Saldo',
      accessor: 'balance',
      Cell: ({ row }) => formatCurrency(row.original.balance),
    },
    {
      Header: 'Estado',
      accessor: 'active',
      Cell: ({ row }) => (row.original.active ? 'Activo' : 'Inactivo'),
    },
  ]
}

export const getActions = (workerId) => [
  ({ id }) => ({
    id: 'openLoanDetail',
    tooltip: 'Ver más',
    icon: <Icon name="eye" />,
    onClick: () => {},
    buttonProps: {
      component: RouterLink,
      to: routes.WORKER_LOAN_SHOW(workerId, id),
    },
  }),
]
