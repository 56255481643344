import { Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

const EditPeriodHistoryModal = ({ state, handleClose, handleContinue }) => {
  return (
    <Modal
      open={state.open}
      header="Editar periodos históricos"
      okText="Continuar"
      onOk={handleContinue}
      onCancel={handleClose}
      paperSx={{
        maxWidth: '46rem',
      }}
    >
      <Typography color="black.dark">
        Al activar la opción de Editar periodos históricos, se pueden ver
        modificados valores de nómina que ya has emitido, pagado, generado y
        reportado anteriormente. Esta opción estará activa únicamente hasta el
        término del periodo actual.
      </Typography>
    </Modal>
  )
}

export default EditPeriodHistoryModal
