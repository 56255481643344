import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useBeneficiariesService from 'utils/hooks/affiliations/beneficiaries'
import useCompanyFilesService from 'utils/hooks/company/files'

import { KINSHIP_LABEL } from '../Form/Beneficiaries/helpers'
import {
  getBeneficiaryWithInvalidDocuments,
  getInitialValues,
  getKinship,
  getValidationSchema,
  renderAffiliationFilesFields,
} from './helpers'

const AffiliationFilesModal = ({ state = {}, handleClose }) => {
  const queryClient = useQueryClient()
  const affiliationQueryKey = ['getAffiliationById', state.workerId]
  const companyId = getCompanyId()

  const {
    affiliationsQuery: { data: affiliationsData, isLoading: queryIsLoading },
    affiliationsMutation,
  } = useAffiliationsService({
    serviceParams: {
      queryKey: affiliationQueryKey,
      workerId: state.workerId,
    },
  })

  const { beneficiariesMutation } = useBeneficiariesService()
  const { companyFilesMutation } = useCompanyFilesService()

  const {
    rejected_comment: rejectionComment,
    created_at: affiliationCreationDate,
    rejected_at: rejectionDate,
    rejected_reason: rejectionReason,
    invalid_files: invalidFiles,
    beneficiaries,
  } = affiliationsData || {}

  const handleAffiliationsMutation = (correctedData) => {
    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId: state.workerId,
        affiliationData: correctedData,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(affiliationQueryKey)
          await queryClient.invalidateQueries([
            'getCompanyOnboardings',
            companyId,
          ])
          handleClose()
        },
      }
    )
  }

  const handleSubmit = (values) => {
    const correctedAffiliationData = new FormData()
    const correctedData = new FormData()
    correctedAffiliationData.append('status', 'waiting_response')

    if (
      [
        'error_in_beneficiary_documents',
        'error_in_company_documents',
        'error_in_worker_documents',
      ].includes(rejectionReason)
    ) {
      Object.entries(values).forEach(([document, value]) => {
        if (rejectionReason === 'error_in_worker_documents') {
          correctedAffiliationData.append(document, value)
        } else {
          correctedData.append(document, value)
        }
      })
    }

    if (rejectionReason === 'error_in_beneficiary_documents') {
      correctedData.append('beneficiary_id', invalidFiles.id)
      beneficiariesMutation.mutate(
        {
          mutationMethod: 'PUT',
          workerId: state.workerId,
          beneficiaryData: correctedData,
        },
        {
          onSuccess: () => handleAffiliationsMutation(correctedAffiliationData),
        }
      )

      return
    }

    if (rejectionReason === 'error_in_company_documents') {
      companyFilesMutation.mutate(
        {
          mutationMethod: 'PATCH',
          files: correctedData,
        },
        {
          onSuccess: () => {
            handleAffiliationsMutation(correctedAffiliationData)
            queryClient.invalidateQueries([
              'companyInformation',
              getCompanyId(),
            ])
          },
        }
      )

      return
    }

    handleAffiliationsMutation(correctedAffiliationData)
  }

  const beneficiaryWithErrors = getBeneficiaryWithInvalidDocuments({
    beneficiaryWithInvalidDocumentsId: invalidFiles?.id,
    beneficiaries,
  })

  const mutationIsLoading =
    companyFilesMutation.isLoading ||
    beneficiariesMutation.isLoading ||
    affiliationsMutation.isLoading

  return (
    <Modal
      open={state.open}
      header="Debes revisar tu solicitud de afiliación a seguridad social"
      onCancel={handleClose}
      hideFooter
    >
      {queryIsLoading || !invalidFiles?.documents ? (
        <LoadingBox />
      ) : (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          })}
        >
          <Typography variant="h4" color="info.dark">
            Fecha de creación:{' '}
            {formatDisplayDateString(affiliationCreationDate)}
          </Typography>
          <Typography color="black.dark">
            Tu solicitud de afiliación fue rechazada porque hay errores o te
            faltan documentos importantes para poder continuar con el proceso.
          </Typography>
          {rejectionComment ? (
            <Typography color="black.dark">
              <b>Comentario adicional:</b> {rejectionComment}
            </Typography>
          ) : null}
          <Typography color="black.dark">
            <b>Fecha del rechazo:</b> {formatDisplayDateString(rejectionDate)}
          </Typography>
          {rejectionReason === 'error_in_beneficiary_documents' ? (
            <Typography color="black.dark">
              Adjunta los siguientes documentos para el beneficiario{' '}
              <b>
                {beneficiaryWithErrors?.name} (
                {KINSHIP_LABEL[beneficiaryWithErrors?.kinship]})
              </b>
              :
            </Typography>
          ) : null}
          <Formik
            initialValues={getInitialValues(invalidFiles?.documents)}
            validationSchema={getValidationSchema(invalidFiles?.documents)}
            onSubmit={handleSubmit}
          >
            {({ values }) => {
              return (
                <Form>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(2),
                    })}
                  >
                    {renderAffiliationFilesFields({
                      documents: invalidFiles?.documents,
                      rejectionReason,
                      kinship: getKinship({
                        beneficiaryId: invalidFiles?.id,
                        beneficiaries,
                      }),
                      values,
                    })}
                  </Box>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      gap: theme.spacing(2),
                      marginTop: theme.spacing(3),
                    })}
                  >
                    <Button type="submit" loading={mutationIsLoading}>
                      Enviar información
                    </Button>
                    <Button onClick={handleClose} variant="outlined">
                      Cancelar
                    </Button>
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </Box>
      )}
    </Modal>
  )
}

export default AffiliationFilesModal
