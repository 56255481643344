import { useOutletContext } from 'react-router-dom'

import { Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

export const userAreaOptions = [
  {
    value: 'hr',
    label: 'Recursos humanos',
  },
  {
    value: 'accounting',
    label: 'Contabilidad',
  },
  {
    value: 'administrative',
    label: 'Administrativa',
  },
  {
    value: 'general_management',
    label: 'Gerencia',
  },
  {
    value: 'education',
    label: 'Educación',
  },
  {
    value: 'other',
    label: 'Otra',
  },
]

export function OnboardingArea() {
  const { userName } = useOutletContext()
  return (
    <>
      <Typography
        variant="h4"
        sx={({ spacing }) => ({
          marginBottom: spacing(1),
          textAlign: 'center',
        })}
      >
        {userName
          ? `${userName}, ¿en qué área trabajas?`
          : '¿En qué área trabajas?'}
      </Typography>
      <Typography
        variant="body1"
        color="white.dark"
        sx={({ spacing }) => ({
          marginBottom: spacing(2),
          textAlign: 'center',
        })}
      >
        Esto nos ayudará a recomendarte las herramientas que más valor te
        generarán.
      </Typography>
      <FormField
        variant="chip-group"
        optional={false}
        name="user_area"
        label="Selecciona tu área"
        options={userAreaOptions}
        otherName="user_area_other_info"
        otherLabel="Escribe tu área"
      />
    </>
  )
}
