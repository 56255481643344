import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Grid, Paper } from '@mui/material'

import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import Page from 'components/UI/Page/Page'

import useCompanyService from 'utils/hooks/organizer/company'
import useCompanyPaymentService from 'utils/hooks/organizer/companyPaymentService'

import { getTabsData } from './helpers'

const OrganizerCompanyPaymentForm = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { companyId } = useParams()

  // Queries below are called here so that both child components can use the data from cache, avoding multiple calls.
  useCompanyService({
    serviceParams: {
      queryKey: ['getCompanyFromOrganizer', companyId],
      companyId,
    },
    queryOptions: {
      enabled: Boolean(companyId),
    },
  })

  useCompanyPaymentService({
    serviceParams: {
      queryKey: ['getPlans', companyId],
      companyId,
    },
    queryOptions: {
      enabled: Boolean(companyId),
    },
  })

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  const tabsData = getTabsData(companyId)

  return (
    <Page header="Nuevo Pago">
      <Grid container direction="row" justifyContent="center">
        <Box width={1} p={2} component={Paper}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <RoundedTabs
              value={activeTab}
              onChange={handleChangeTab}
              tabsConfig={getTabsData()}
              background="gray"
            />
          </Box>
          {tabsData[activeTab].content}
        </Box>
      </Grid>
    </Page>
  )
}

export default OrganizerCompanyPaymentForm
