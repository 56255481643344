import { Form, Formik } from 'formik'

import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import Button from 'components/UI/Button/Button'
import CellphoneField from 'components/UI/Formik/CommonFields/CellphoneField'
import Icon from 'components/UI/Icon'
import { Image } from 'components/UI/Image'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import useUsersService from 'utils/hooks/settings/usersService'
import useNotifications from 'utils/hooks/useNotifications'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import messages from 'messages/notification'

const initialValues = {
  phone: '',
}

const UpdateCompanyPhoneModal = ({ state, handleClose }) => {
  const { showSuccessMessage } = useNotifications()
  const { usersMutation } = useUsersService({
    queryOptions: { enabled: false },
  })

  const isLaptop = useMediaQuery((theme) => theme.breakpoints.up('tablet'))
  const userId = getUserId()
  const handleSubmit = (values) => {
    usersMutation.mutate(
      {
        mutationMethod: 'PATCH',
        mutationKey: 'updateUserInfo',
        user: { id: userId, phone: values.phone },
      },
      {
        onSuccess: () => {
          showSuccessMessage(messages.UPDATE_COMPANY_PHONE_SUCCESS)
          Mixpanel.track(MIXPANEL_EVENTS.PHONE_NUMBER_ADDED, {
            user_id: getUserId(),
            company_id: getCompanyId(),
            user_role: getUserRole(),
          })
          handleClose()
        },
      }
    )
  }

  return (
    <Modal
      open={state.open}
      hideFooter
      onCancel={handleClose}
      paperSx={{
        maxWidth: '56rem',
        maxHeight: {
          tablet: '30rem',
        },
      }}
      contentWrapperSx={{
        overflow: 'hidden',
      }}
    >
      <Box
        sx={(theme) => ({
          position: 'relative',
          margin: theme.spacing(0, 2),
        })}
      >
        <ButtonBase
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            cursor: 'pointer',
            color: theme.palette.black.main,
            right: theme.spacing(-1),
            top: theme.spacing(0),
          })}
        >
          <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
        </ButtonBase>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              mobile: '1fr',
              tablet: '1fr  3fr',
            },
          }}
        >
          {isLaptop ? (
            <Box>
              <Box
                sx={(theme) => ({
                  width: '32rem',
                  height: '32rem',
                  borderRadius: '100%',
                  position: 'absolute',
                  left: '-18rem',
                  top: '-4rem',
                  backgroundColor: theme.palette.info[400],
                })}
              />
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <Image
                  src="40300d98-32d6-4df8-e522-a7b8182b8c00"
                  height={373}
                  width={214}
                  layout="fill"
                />
              </Box>
            </Box>
          ) : null}
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up('laptop')]: {
                marginLeft: theme.spacing(4),
              },
            })}
          >
            <Typography
              variant="h2"
              sx={(theme) => ({
                marginBottom: theme.spacing(2),
                marginRight: theme.spacing(4),
              })}
            >
              ¿Quieres que una persona te acompañe con tus dudas?
            </Typography>

            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              <Form>
                <Typography
                  variant="body1"
                  color="black.dark"
                  sx={(theme) => ({
                    marginBottom: theme.spacing(4),
                    gap: theme.spacing(2),
                  })}
                >
                  Déjanos tu número y te contactaremos para ayudarte con lo que
                  necesites. Mientras tanto, podrás seguir explorando Aleluya.
                </Typography>
                <CellphoneField name="phone" label="Número celular" />
                <Button
                  type="submit"
                  loading={usersMutation.isLoading}
                  sx={(theme) => ({
                    padding: theme.spacing(0, 10),
                    marginTop: theme.spacing(4),
                    width: '100%',
                    [theme.breakpoints.up('tablet')]: {
                      width: 'auto',
                    },
                  })}
                >
                  Enviar
                </Button>
              </Form>
            </Formik>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default UpdateCompanyPhoneModal
