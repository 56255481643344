import { Box, Button, Card, Typography, useTheme } from '@mui/material'

import Tooltip from 'components/UI/Tooltip'

import Icon from '../Icon'

const TotalCardCompact = ({
  title,
  value,
  backgroundColor = 'primary.main',
  textColor,
  icon,
  showDetailButton = null,
  tooltipInfo,
}) => {
  const themeInstance = useTheme()
  const backgroundColorKeys = backgroundColor.split('.')
  const themeBackgroundColor = backgroundColor?.includes('.')
    ? themeInstance.palette[backgroundColorKeys[0]][backgroundColorKeys[1]]
    : themeInstance.palette[backgroundColorKeys[0]]?.main

  return (
    <Card
      sx={(theme) => ({
        position: 'relative',
        backgroundColor: themeBackgroundColor,
        color: textColor,
        boxShadow: 'none',
        padding: theme.spacing(2, 0.75, 1, 1.6),
      })}
    >
      <Box
        sx={{
          position: 'inherit',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(0.75),
          })}
        >
          <Icon name={icon || 'list'} />
          <Typography variant="body1" marginLeft="-4px">
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip
            placement="bottom-end"
            title="Estados"
            description={tooltipInfo}
            tooltipSx={(theme) => ({
              boxShadow: theme.shadows[4],
              width: '17.5625rem',
            })}
          >
            <Typography
              sx={(theme) => ({
                marginLeft: theme.spacing(3.75),
                cursor: tooltipInfo ? 'pointer' : '',
              })}
              variant="h1"
            >
              {value}
            </Typography>
          </Tooltip>
          {showDetailButton ? (
            <Button
              sx={(theme) => ({ marginLeft: theme.spacing(4) })}
              variant="outlined"
              size="small"
              onClick={showDetailButton.onClick}
            >
              {showDetailButton.title}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Card>
  )
}

export default TotalCardCompact
