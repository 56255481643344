import { Box, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'

import PartnerSubscriptionCardsModal from './PartnerSubscriptionCardsModal'

const usePartnerSubscriptionModal = () => {
  const modals = useModals()

  const handleClose = () => {
    modals.closeModal('partnerSubscriptionModal')
  }

  const openPartnerSubscriptionModal = () => {
    modals.openModal({
      id: 'partnerSubscriptionModal',
      content: <PartnerSubscriptionCardsModal />,
      modalProps: {
        header: (
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(1),
            })}
          >
            <Typography variant="h3" color="black.main">
              ¡Conoce los superpoderes de Aleluya!
            </Typography>
            <Typography color="black.dark">
              Mejora tu plan y obten superpoderes para automatizar tu nómina.
            </Typography>
          </Box>
        ),
        onCloseModal: handleClose,
        hideFooter: true,
        paperSx: {
          maxWidth: '72.1875rem',
        },
      },
    })
  }

  return { openPartnerSubscriptionModal }
}

export default usePartnerSubscriptionModal
