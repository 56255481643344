import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

import {
  ONBOARDING_AREA,
  ONBOARDING_COMPANY,
  ONBOARDING_GOAL,
} from 'config/routes'

import { OnboardingArea } from './Area'
import { OnboardingCompanyData } from './CompanyData'
import { OnboardingGoal } from './Goal'

yup.setLocale(yupLocaleES)

export const stepNumber = {
  company: 0,
  area: 1,
  goal: 2,
}

const backendStepNumber = {
  company_data: 0,
  user_area: 1,
  main_goal: 2,
}

const stepTitle = {
  company: 'Datos de tu empresa',
  area: 'Selecciona tu área',
  goal: 'Tu objetivo en Aleluya',
}

export const getCurrentOnboardingStepData = ({
  pathname = '',
  latestCompletedStep,
}) => {
  const pathnameParts = pathname.split('/').filter(Boolean)

  return {
    currentStepIndex: stepNumber[pathnameParts[pathnameParts.length - 1]],
    currentStepPathname: pathnameParts[pathnameParts.length - 1],
    currentStepTitle: stepTitle[pathnameParts[pathnameParts.length - 1]],
    latestCompletedStepIndex: backendStepNumber[latestCompletedStep] || 0,
  }
}

export const latestCompletedStepPathname = {
  company_data: ONBOARDING_COMPANY(),
  user_area: ONBOARDING_AREA(),
  main_goal: ONBOARDING_GOAL(),
}

export const stepsData = [
  {
    step: 0,
    id: 'company',
  },
  {
    step: 1,
    id: 'area',
  },
  {
    step: 2,
    id: 'goal',
  },
]

export const validationSchema = {
  company: yup.object({
    document_type: yup.string().nullable().required(),
    id_number: yup.string().nullable().required(),
    verification_digit: yup.string().nullable(),
    workers_number: yup.string().nullable().required(),
    payroll_frequency: yup.string().nullable().required(),
  }),
  area: yup.object({
    user_area: yup.string().nullable().required(),
    user_area_other_info: yup.string().when('user_area', {
      is: (user_area) => user_area === 'other',
      then: yup.string().required(),
    }),
  }),
  goal: yup.object({
    main_goal: yup.string().nullable().required(),
    main_goal_other_info: yup.string().when('main_goal', {
      is: (main_goal) => main_goal === 'other',
      then: yup.string().required(),
    }),
  }),
}

export const onboardingRoutes = [
  {
    path: 'company',
    element: <OnboardingCompanyData />,
  },
  {
    path: 'area',
    element: <OnboardingArea />,
  },
  {
    path: 'goal',
    element: <OnboardingGoal />,
  },
]

export const getInitialValues = (company) => ({
  company: {
    document_type: company?.document_type || 'ni',
    id_number: company?.id_number,
    verification_digit: company?.verification_digit,
    workers_number: company?.onboarding?.workers_number,
    payroll_frequency: company?.onboarding?.payroll_frequency,
  },
  area: {
    user_area: company?.onboarding?.user_area,
    user_area_other_info: company?.onboarding?.user_area_other_info,
  },
  goal: {
    main_goal: company?.onboarding?.main_goal,
    main_goal_other_info: company?.onboarding?.main_goal_other_info,
  },
})
