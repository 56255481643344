import { payrollFrequencies } from 'utils/company'

const numberOfEmployees = {
  '1-19': '1 a 19',
  '20-49': '20 a 49',
  '50-99': '50 a 99',
  '100-149': '100 a 149',
  '150-200': '150 a 200',
  over_200: 'Más de 200',
}

export const numberOfEmployeesOptions = Object.keys(numberOfEmployees).map(
  (key) => ({
    label: numberOfEmployees[key],
    value: key,
  })
)

const onboardingPayrollFrequencies = {
  ...payrollFrequencies,
  mixed: 'Mixta',
}

export const onboardingPayrollFrequenciesOptions = Object.keys(
  onboardingPayrollFrequencies
).map((value) => ({
  value,
  label: onboardingPayrollFrequencies[value],
}))
