import { useFormikContext } from 'formik'

import { Box, Chip, FormControlLabel, Radio, RadioGroup } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

const ChipGroupField = ({ options = [], name, otherLabel, otherName }) => {
  const { setFieldValue, values } = useFormikContext()

  const handleChange = (event) => {
    const value = event.target.value
    if (values[otherName] && value !== 'other') {
      setFieldValue(otherName, '')
    }
    setFieldValue(name, value)
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
      <RadioGroup
        data-cy={`${name}_chip_group`}
        name={name}
        onChange={handleChange}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          gap: 1,
        }}
      >
        {options.map(({ label, value }) => (
          <FormControlLabel
            key={value}
            value={value}
            sx={{ margin: 0 }}
            control={<Radio sx={{ display: 'none' }} />}
            label={
              <Chip
                label={label}
                clickable
                variant={values[name] === value ? 'filled' : 'outlined'}
                sx={(theme) => ({
                  borderRadius: theme.spacing(2),
                  padding: theme.spacing(1),
                  textAlign: 'center',
                  border: `1px solid ${theme.palette.white.dark}`,
                  ...(values[name] === value && {
                    backgroundColor: `${theme.palette.primary[300]} !important`,
                  }),
                  ...(values[name] !== value && {
                    '&:hover': {
                      outline: `2px solid ${theme.palette.primary[300]}`,
                    },
                  }),
                })}
              />
            }
          />
        ))}
      </RadioGroup>

      {values[name] === 'other' ? (
        <FormField name={otherName} label={otherLabel} optional={false} />
      ) : null}
    </Box>
  )
}

export default ChipGroupField
