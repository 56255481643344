import { useTheme } from '@mui/material'

import Table from 'components/UI/Table/Table'

import { columnsData } from './helpers'

const OrganizerPartnerShowPaymentFormTable = ({ paymentsData }) => {
  const theme = useTheme()

  return (
    <Table
      data={paymentsData}
      columns={columnsData}
      options={{
        toolbar: false,
        pagination: false,
        rowStyle: (_, row) => {
          return row.index === paymentsData.length - 1
            ? {
                backgroundColor: theme.palette.white.light,
                fontWeight: 600,
              }
            : {}
        },
      }}
    />
  )
}

export default OrganizerPartnerShowPaymentFormTable
