import { Box, Typography } from '@mui/material'

import HealthProviderField from 'components/Worker/Contract/Fields/HealthProvider'
import PensionProviderField from 'components/Worker/Contract/Fields/PensionProvider'
import SeveranceProviderField from 'components/Worker/Contract/Fields/SeveranceProvider'

const SocialSecurityFormFields = ({ worker }) => {
  const {
    contract_category: contractCategory,
    document_type: documentType,
    contract_detail: { contributor_subtype: contributorSubtype },
    wage_category: wageCategory,
  } = worker || {}

  return (
    <>
      {!['contractor', 'student_decree_055'].includes(contractCategory) ? (
        <>
          <Typography
            variant="h6"
            color="primary.dark"
            sx={(theme) => ({
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(2),
            })}
          >
            Administradoras de seguridad social
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
              columnGap: theme.spacing(3),
              [theme.breakpoints.up('tablet')]: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              },
            })}
          >
            <HealthProviderField
              contractCategory={contractCategory}
              optional={false}
            />
            <PensionProviderField
              documentType={documentType}
              contractCategory={contractCategory}
              contributorSubtype={contributorSubtype}
              optional={false}
            />
            <SeveranceProviderField
              wageCategory={wageCategory}
              contractCategory={contractCategory}
              optional={false}
            />
          </Box>
        </>
      ) : null}
    </>
  )
}

export default SocialSecurityFormFields
