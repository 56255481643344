import { Form, Formik } from 'formik'

import { Box, Link, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { formatDate, formatToUTCISO } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import fileService from 'services/payroll/fileService'
import payrollService from 'services/payroll/payrollService'

const BankFileModal = ({ state, handleClose }) => {
  const {
    open,
    payroll,
    period,
    paymentTypes,
    contractTypes,
    payrollRanges,
    fileFormats,
    bankName,
    locations,
  } = state
  const { enqueueDownload } = useDownloadManager()

  const showCreditsAlert = contractTypes?.some(
    (contractType) => contractType.value === 'payroll_credit'
  )

  const handleSubmit = async (values) => {
    const {
      payment_type: paymentType,
      file_format: fileFormat,
      pay_day: payDay,
      location,
    } = values

    const formattedPayDay = formatDate(payDay)

    const callback = () => {
      Mixpanel.track(MIXPANEL_EVENTS.DOWNLOAD_BANK_FILE_V2, {
        company_id: getCompanyId(),
        user_id: getUserId(),
        user_role: getUserRole(),
        bank: state.bankName,
        download_type: payroll ? 'individual' : 'massive',
        payment_type: paymentType,
        contract_type: values.contract_type,
        location: location,
        payroll_range: values.payroll_range,
        pay_day: formatToUTCISO(payDay),
        file_format: fileFormat,
      })
    }

    handleClose()
    enqueueDownload({
      name: 'archivo pago banco',
      description: (res) => (
        <div>
          <Typography variant="lead2" display="inline">
            Número de personas archivo:
          </Typography>{' '}
          <Typography variant="body2" display="inline">
            {res.size}
          </Typography>
          {', '}
          <Typography variant="lead2" display="inline">
            Pago total archivo:
          </Typography>{' '}
          <Typography variant="body2" display="inline">
            {formatCurrency(res.total_value)}
          </Typography>
        </div>
      ),
      fileCode: 'bank_file',
      firebasePath: !payroll ? `periods/${period.id}/bank_file` : undefined,
      service: () => {
        if (payroll) {
          const { payroll_range: payrollRange } = values

          return payrollService.getBankFile(payroll.id, {
            payroll_range: payrollRange,
            pay_day: formattedPayDay,
            payment_type: paymentType,
            file_format: fileFormat,
          })
        } else {
          const { contract_type: contractType } = values

          return fileService.getPeriodBankFile(period.id, {
            contract_type: contractType,
            pay_day: formattedPayDay,
            payment_type: paymentType,
            file_format: fileFormat,
            location,
          })
        }
      },
      callback,
    })
  }

  const getInitialValues = () => {
    return {
      payment_type:
        paymentTypes &&
        paymentTypes.some((item) => item.value === 'payroll_social_benefit')
          ? 'payroll_social_benefit'
          : 'payroll',
      contract_type: 'payroll',
      location: 'company',
      file_format: fileFormats.length > 0 ? fileFormats[0].value : '',
      pay_day: formatDate(new Date()),
    }
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, setValues, values }) => {
        return (
          <Modal
            open={open}
            onCancel={() => handleClose()}
            header={`Generar archivo del banco ${bankName || ' '}`}
            footer={
              <Box
                sx={(theme) => ({
                  gap: theme.spacing(2),
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  [theme.breakpoints.up('tablet')]: {
                    flexDirection: 'row',
                  },
                })}
              >
                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    submitForm()
                  }}
                  loading={isSubmitting}
                  data-cy="bank_file_modal_ok"
                >
                  Generar
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    setValues({
                      ...values,
                      file_format: 'xlsx',
                    })

                    submitForm()
                  }}
                  loading={isSubmitting}
                  variant="outlined"
                >
                  Resumen en Excel
                </Button>
                <Button
                  onClick={() => handleClose()}
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Box>
            }
          >
            <Form>
              {showCreditsAlert ? (
                <Alert
                  severity="info"
                  icon={false}
                  header="¡Tu nómina tiene deducciones por libranza!"
                >
                  Recuerda descargar el archivo de pago en Bancos para libranzas
                  <br />
                  ¡Si tienes dudas, puedes resolverlas{' '}
                  <Link
                    href="https://www.aleluya.com/blog/credito-libranza-empleados"
                    target="_blank"
                  >
                    AQUÍ
                  </Link>
                  !
                </Alert>
              ) : null}
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                })}
              >
                <Typography color="black.dark">
                  Se generará el archivo para pagar a personas que tengan
                  seleccionado como medio de pago: &quot;Transferencia
                  Bancaria&quot;.
                </Typography>
                {paymentTypes.length > 0 ? (
                  <FormField
                    name="payment_type"
                    label="¿Qué pagos quieres que incluya el archivo?"
                    options={paymentTypes}
                    variant="select"
                  />
                ) : null}
                {contractTypes.length > 0 ? (
                  <FormField
                    name="contract_type"
                    label="¿Vas a pagar la nómina o a los contratistas?"
                    variant="select"
                    options={contractTypes}
                  />
                ) : null}
                {contractTypes.length > 0 ? (
                  <FormField
                    name="location"
                    label="¿Incluir toda la empresa o solo una sede específica?"
                    variant="select"
                    options={locations}
                  />
                ) : null}
                {payrollRanges.length > 0 ? (
                  <FormField
                    name="payroll_range"
                    label="Período del archivo a generar"
                    variant="select"
                    options={payrollRanges}
                  />
                ) : null}
                <FormField
                  name="pay_day"
                  label="Fecha de aplicación del pago"
                  variant="datepicker"
                />
                {fileFormats.length > 0 ? (
                  <FormField
                    name="file_format"
                    label="Tipo de archivo"
                    variant="select"
                    options={fileFormats}
                  />
                ) : null}
              </Box>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default BankFileModal
