import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Grid, Typography } from '@mui/material'

import { getDocumentLabel } from 'components/Worker/WorkersOnboarding/SSAffiliation/Form/Beneficiaries/helpers'
import useWorker from 'components/Worker/useWorker'

import DownloadFile from '../DownloadFile'
import Item from '../Item'
import {
  EmptyState,
  KINSHIP_LABEL,
  getBeneficiaryEntitiesToAffiliate,
} from './helpers'

const BeneficiariesContainer = () => {
  const { worker } = useWorker({ useCache: true })
  const queryClient = useQueryClient()
  const affiliationQueryKey = ['getAffiliationById', worker.id]
  const affiliationCache = queryClient.getQueryData(affiliationQueryKey)

  const beneficiaries = useMemo(
    () => affiliationCache?.data?.beneficiaries || [],
    [affiliationCache?.data?.beneficiaries]
  )

  const sectionData = useMemo(() => {
    const sections = {
      spouse: [],
      child: [],
      progenitor: [],
    }

    beneficiaries.forEach((beneficiary, index) => {
      if (!Object.hasOwn(sections, beneficiary.kinship)) {
        return
      }

      const filesItems = Object.entries(beneficiary.files || {}).map(
        ([key, value]) => ({
          label: getDocumentLabel({
            kinship: beneficiary.kinship,
            documentType: key,
          }),
          value: <DownloadFile file={value} />,
        })
      )

      sections[beneficiary.kinship] = [
        ...sections[beneficiary.kinship],
        {
          id: `beneficiary_${index + 1}`,
          name: beneficiary.name,
          itemsList: filesItems,
          entitiesToAffiliate: beneficiary.entities_to_affiliate,
        },
      ]
    })

    return sections
  }, [beneficiaries])

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(0, 3, 6, 3),
      })}
    >
      <Typography
        variant="h4"
        sx={(theme) => ({
          marginBottom: theme.spacing(5),
        })}
      >
        Beneficiarios
      </Typography>

      {beneficiaries.length === 0 ? <EmptyState /> : null}
      {Object.entries(sectionData).map(([key, section], sectionIndex) => {
        if (sectionData[key].length > 0) {
          return (
            <Box
              key={key}
              sx={(theme) => ({
                ...(sectionIndex < Object.entries(sectionData).length - 1 && {
                  marginBottom: theme.spacing(8),
                }),
              })}
            >
              <Typography
                variant="h6"
                sx={(theme) => ({
                  marginBottom: theme.spacing(2),
                })}
              >
                {KINSHIP_LABEL[key]}
              </Typography>
              {section.map((beneficiary) => (
                <Box
                  key={beneficiary.id}
                  sx={(theme) => ({
                    marginBottom: theme.spacing(3),
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      gap: theme.spacing(1),
                      marginBottom: theme.spacing(2),
                      [theme.breakpoints.down('tablet')]: {
                        flexDirection: 'column',
                      },
                    })}
                  >
                    <Typography variant="lead1">{beneficiary.name} </Typography>
                    <Typography>
                      (Entidades solicitadas:{' '}
                      {getBeneficiaryEntitiesToAffiliate(
                        beneficiary.entitiesToAffiliate
                      )}
                      )
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    {beneficiary.itemsList.map((fileItem, index) => (
                      <Item
                        item={fileItem}
                        key={`${key}_file_${index.toString()}`}
                      />
                    ))}
                  </Grid>
                </Box>
              ))}
            </Box>
          )
        }

        return null
      })}
    </Box>
  )
}

export default BeneficiariesContainer
