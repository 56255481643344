import { useUser } from 'components/App/UserContext/useUser'

const useSubscription = () => {
  const { subscription } = useUser()
  const additionalWorkersInfo = subscription?.additional_workers_info
  const paidWorkers = subscription?.paid_workers
  const payrollsSize = subscription?.payrolls_size

  const isPaymentExoneratedCompany =
    subscription.payment_category === 'exonerated_payment'

  const isCompanyUsing20241111Plans =
    subscription.plan_category_with_suffix?.includes('20241111')

  return {
    additionalWorkersInfo,
    paidWorkers,
    subscription,
    isPaymentExoneratedCompany,
    payrollsSize,
    isCompanyUsing20241111Plans,
  }
}

export default useSubscription
