import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Card, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import EditButton from 'components/UI/Button/EditButton'
import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'

import { getUserId, getUserRole } from 'utils/auth'
import { documentTypesOptions, getCompanyId } from 'utils/company'
import { areObjectsEqual } from 'utils/general'
import useBillingService from 'utils/hooks/subscription/billing'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { billingValidationSchema, getBillingInitialValues } from './helpers'

const BillingCard = () => {
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const [editBilling, setEditBilling] = useState(false)
  const disableField = !editBilling
  const billingQueryKey = ['getBillingInformation', companyId]

  const { billingQuery, billingMutation } = useBillingService({
    serviceParams: {
      queryKey: billingQueryKey,
    },
  })

  const enableBillingForm = () => setEditBilling(true)

  const disableBillingForm = () => setEditBilling(false)

  const handleSubmit = (values) => {
    billingMutation.mutate(
      {
        mutationMethod: 'PATCH',
        billingInformation: {
          ...values,
        },
      },
      {
        onSuccess: () => {
          Mixpanel.track(MIXPANEL_EVENTS.UPDATE_BILLING_INFORMATION_V2, {
            company_id: getCompanyId(),
            user_id: getUserId(),
            user_role: getUserRole(),
          })
          queryClient.invalidateQueries(billingQueryKey)
          disableBillingForm()
        },
      }
    )
  }

  const handleSubmitBilling = (isFormChanged, onSubmit) => {
    if (isFormChanged) {
      onSubmit()
    } else {
      disableBillingForm()
    }
  }

  return (
    <Card
      sx={(theme) => ({
        padding: theme.spacing(2),
        minWidth: '100%',
      })}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={getBillingInitialValues(billingQuery.data)}
        validationSchema={billingValidationSchema}
        enableReinitialize
      >
        {({ handleSubmit: onSubmit, values }) => {
          const isFormChanged = !areObjectsEqual(billingQuery.data, values)

          return (
            <>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: theme.spacing(1),
                })}
              >
                <Typography variant="h5">Datos de facturación</Typography>
                {editBilling ? (
                  <Button
                    onClick={() => handleSubmitBilling(isFormChanged, onSubmit)}
                    variant={isFormChanged ? 'contained' : 'outlined'}
                    loading={billingMutation.isLoading}
                  >
                    {isFormChanged ? 'Actualizar' : 'Cancelar'}
                  </Button>
                ) : (
                  <EditButton onClick={enableBillingForm} size="small" />
                )}
              </Box>
              <Form>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                  })}
                >
                  <FormField
                    name="name"
                    label="Razón social"
                    disabled={disableField}
                    optional={false}
                  />
                  <DocumentTypeField
                    options={documentTypesOptions}
                    disabled={disableField}
                    optional={false}
                  />
                  <CompanyIdentificationNumberField disabled={disableField} />
                  <FormField
                    name="email"
                    label="Correo electrónico"
                    disabled={disableField}
                    optional={false}
                  />
                </Box>
              </Form>
            </>
          )
        }}
      </Formik>
    </Card>
  )
}

export default BillingCard
