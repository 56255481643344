import { useRef } from 'react'

import { Box, Button, Typography, alpha } from '@mui/material'

import { isObjectEmpty } from 'utils/general'

import LoadedFile from '../Formik/CommonFields/LoadedFile'
import Icon from '../Icon'

const Dropzone = ({
  value = null,
  type = 'normal',
  handleAdd,
  disabled = false,
  accept,
  fileType,
  showLoadedFile = false,
  givenFile,
  setValue,
}) => {
  const fileInputRef = useRef(null)

  const stopEvent = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onFileAdded = (event) => {
    if (disabled) return
    stopEvent(event)

    const file = event.target.files[0]
    handleAdd(file)
    fileInputRef.current.value = ''
  }

  const openFileDialog = () => {
    if (disabled) return

    fileInputRef.current.click()
  }

  const onDrop = (event) => {
    if (disabled) return
    stopEvent(event)

    const file = event.dataTransfer.files[0] || {}
    if (fileType && !isObjectEmpty(file)) {
      if (file.type === fileType) {
        handleAdd(file)
      }
    } else {
      handleAdd(file)
    }
  }

  const onDragOver = (event) => stopEvent(event)

  const handleDeleteFile = (event) => {
    stopEvent(event)

    setValue(null)
  }

  return value && showLoadedFile ? (
    <>
      <Box
        component="input"
        sx={{ display: 'none' }}
        type="file"
        ref={fileInputRef}
        accept={accept}
        onChange={onFileAdded}
        disabled={disabled}
        data-cy="file-input"
      />
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '3fr 1fr',
          [theme.breakpoints.down('tablet')]: {
            gap: theme.spacing(0.5),
          },
        })}
      >
        <LoadedFile
          fileName={value}
          handleDeleteFile={handleDeleteFile}
          value={givenFile}
          hideRemoveButton={false}
        />
        <Button
          onClick={openFileDialog}
          variant="text"
          sx={(theme) => ({
            color: theme.palette.black.dark,
            textDecoration: 'underline',
          })}
        >
          Actualizar archivo
        </Button>
      </Box>
    </>
  ) : (
    <Box
      role="none"
      onClick={openFileDialog}
      onDrop={onDrop}
      onDragOver={onDragOver}
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '10rem',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px dashed ${theme.palette.info.main}`,
        backgroundColor: alpha(theme.palette.info.light, 0.2),
        cursor: 'pointer',
        transition: '0.5s',
        borderRadius: '0.75rem',
        padding: theme.spacing(2),
        '&:hover': {
          backgroundColor: alpha(theme.palette.info.light, 0.1),
        },
        ...(type === 'inline' && {
          minHeight: 'auto',
          padding: theme.spacing(2),
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: 'fit-content',
          gap: theme.spacing(0.5),
          [theme.breakpoints.down('desktop')]: { flexDirection: 'column' },
        }),
      })}
    >
      <Box
        component="input"
        sx={{ display: 'none' }}
        type="file"
        ref={fileInputRef}
        accept={accept}
        onChange={onFileAdded}
        disabled={disabled}
        data-cy="file-input"
      />
      {type === 'normal' ? (
        <Icon
          name="hard-drive-upload"
          sx={(theme) => ({
            color: theme.palette.info.main,
            fontSize: '3rem',
            marginBottom: theme.spacing(2),
          })}
        />
      ) : null}
      <Typography variant="body1" color="info.main">
        {value || 'Arrastra tu archivo aquí'}
      </Typography>
      <Typography
        color="info.main"
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(0.5),
          flexWrap: 'wrap',
          justifyContent: 'center',
        })}
      >
        {!value ? 'o' : ''}
        <Typography
          variant="lead1"
          sx={{
            width: 'fit-content',
            display: 'flex',
            textDecoration: 'underline',
            textAlign: 'center',
          }}
        >
          {value ? 'Actualizar archivo' : 'selecciónalo desde tu dispositivo.'}
        </Typography>
      </Typography>
    </Box>
  )
}

export default Dropzone
