import { useFormikContext } from 'formik'
import { useState } from 'react'
import { flushSync } from 'react-dom'
import { useQueryClient } from 'react-query'

import { Box, Link as MuiLink, Typography } from '@mui/material'

import CreatePayrollConceptModal from 'components/Period/Payroll/Modals/CreatePayrollConceptModal'
import FormField from 'components/UI/Formik/FormField/Index'
import useWorker from 'components/Worker/useWorker'

import { getCompanyId } from 'utils/company'
import { getAutoCompleteElementFilteredOptions } from 'utils/form'

import messages from 'messages/worker'

import {
  incomeCategoryOptions,
  proportionalRecurrentPaymentOptions,
} from '../helpers'

const IncomesSection = () => {
  const [isCreatePayrollConceptModalOpen, setIsCreatePayrollConceptModalOpen] =
    useState(false)
  const [conceptToCreate, setConceptToCreate] = useState({})
  const { values, setFieldValue } = useFormikContext()
  const { worker } = useWorker({ useCache: true })
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const salaryIncomeQueryKey = ['payrollConcepts', 'salary_income', companyId]
  const nonSalaryIncomeQueryKey = [
    'payrollConcepts',
    'non_salary_income',
    companyId,
  ]
  const salaryIncomeCache = queryClient.getQueryData(salaryIncomeQueryKey)
  const nonSalaryIncomeCache = queryClient.getQueryData(nonSalaryIncomeQueryKey)
  const optionsIncomesConcepts = {
    salary_income: salaryIncomeCache?.data,
    non_salary_income: nonSalaryIncomeCache?.data,
  }

  const applySalaryIncome = worker.contract_category === 'employee'

  const categoryConcepts = (optionsIncomesConcepts[values.category] || [])?.map(
    (concept) => ({
      id: concept.id,
      name: concept.name,
    })
  )

  const handleCreateIncomeConcept = (_, newValue) => {
    if (newValue?.id === 'create') {
      setConceptToCreate(newValue.value ? { name: newValue.value } : {})

      flushSync(() => {
        setIsCreatePayrollConceptModalOpen(true)
      })
    } else {
      setFieldValue('payroll_concept_id', newValue)
    }
  }

  const handleCloseCreatePayrollConceptModal = () =>
    setIsCreatePayrollConceptModalOpen(false)

  const handleRevalidateConcepts = async ({ payrollConcepts, category }) => {
    await queryClient.invalidateQueries(salaryIncomeQueryKey)
    await queryClient.invalidateQueries(nonSalaryIncomeQueryKey)

    setFieldValue('payroll_concept_id', {
      id: payrollConcepts[0].id,
      name: payrollConcepts[0].name,
    })

    setFieldValue('category', category)
  }

  return (
    <>
      <Typography gutterBottom>
        Configura ingresos recurrentes que se agregan de forma automática a la
        nómina.
      </Typography>
      <Typography>
        <b>Recuerda:</b> Si agregas ingresos recurrentes no salariales y estos
        superan el 40% de los ingresos salariales en algún periodo, Aleluya
        calculará el IBC siguiendo lo estipulado en la Ley 1393 de 2010. Conoce
        más{' '}
        <MuiLink
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-como-calcula-aleluya-el-excedente-del-40-de-ingresos-no-constitutivos-de-salario-de-acuerdo-con-la-ley-1393-de-2010"
          target="_blank"
        >
          aquí
        </MuiLink>
        .
      </Typography>
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        })}
      >
        <FormField
          label="Tipo"
          name="category"
          variant="select"
          options={incomeCategoryOptions}
          optional={false}
        />
        <FormField
          name="concept_type"
          label="¿El ingreso es fijo o proporcional?"
          variant="select"
          options={proportionalRecurrentPaymentOptions}
          tooltipContent={messages.proportional_recurrent_payments}
          optional={false}
        />
        <FormField
          label="Concepto"
          name="payroll_concept_id"
          variant="autocomplete"
          placeholder="Escribe el concepto de ingreso a agregar..."
          noOptionsText="Cargando conceptos de ingreso..."
          loadingText="Cargando conceptos de ingreso..."
          options={categoryConcepts}
          onChange={handleCreateIncomeConcept}
          filterOptions={(opt, params) =>
            getAutoCompleteElementFilteredOptions(
              opt,
              params,
              'Crear nuevo concepto de ingreso recurrente'
            )
          }
          optional={false}
        />
        <FormField
          label="Valor mensual"
          name="monthly_value"
          variant="currency"
          align="left"
          optional={false}
        />
      </Box>
      {isCreatePayrollConceptModalOpen ? (
        <CreatePayrollConceptModal
          options={
            applySalaryIncome
              ? ['salary_income', 'non_salary_income']
              : ['non_salary_income']
          }
          conceptToCreate={conceptToCreate}
          handleClose={handleCloseCreatePayrollConceptModal}
          callback={handleRevalidateConcepts}
        />
      ) : null}
    </>
  )
}

export default IncomesSection
