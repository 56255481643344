import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Paper } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import Stepper from 'components/UI/Stepper'

import { getCompanyId } from 'utils/company'
import useElectronicPayrollConfigurationService from 'utils/hooks/ElectronicPayroll/electronicPayrollConfigurationService'
import { useCompanyService } from 'utils/hooks/company/companyService'
import useNotifications from 'utils/hooks/useNotifications'

import { SETTINGS } from 'config/routes'

import ElectronicPayrollAdvanced from './Steps/Advanced'
import CompanyInformation from './Steps/CompanyInformation'
import DianActivation from './Steps/DianActivation'
import DianConfirmation from './Steps/DianConfirmation'
import ElectronicFileInformation from './Steps/ElectronicFileInformation'
import {
  compareFinalValues,
  configurationSteps,
  getConfigurationInitialValues,
  getValidationSchema,
} from './helpers'

const ConfigurationProcess = ({
  closeForm,
  comeFromEdit,
  shouldShowSuccessBanner,
  dispatch,
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()

  const { company, refreshCompany } = useUser()
  const { showSuccessMessage } = useNotifications()
  const electronicPayrollConfigurationCache = queryClient.getQueryData([
    'electronicPayrollStatus',
    companyId,
  ])
  const initialValues = getConfigurationInitialValues(
    company,
    electronicPayrollConfigurationCache?.data || {}
  )

  const isAuthorized =
    electronicPayrollConfigurationCache?.data.government_enabled_status ===
    'authorized'

  const isActive = electronicPayrollConfigurationCache?.data.active
  const [currentStep, setCurrentStep] = useState(() =>
    isAuthorized && isActive ? 4 : 0
  )

  const { companyMutation } = useCompanyService({
    queryOptions: {
      enabled: false,
    },
  })

  const { electronicPayrollConfigurationMutation } =
    useElectronicPayrollConfigurationService({
      queryOptions: {
        enabled: false,
      },
    })

  const handleNextStep = () => setCurrentStep(currentStep + 1)

  const handlePreviousStep = () => setCurrentStep(currentStep - 1)

  const handleSubmit = async (currentValues) => {
    if (!compareFinalValues(currentStep, currentValues, initialValues)) {
      if (currentStep === 0) {
        companyMutation.mutate(
          {
            mutationMethod: 'PATCH',
            company: {
              address: currentValues.address,
              document_type: currentValues.document_type,
              verification_digit: currentValues.verification_digit,
              id_number: currentValues.id_number,
              city_id: currentValues.city.id,
              name: currentValues.name,
              government_email: currentValues.government_email,
            },
          },
          {
            onSuccess: () => {
              if (!isActive) {
                electronicPayrollConfigurationMutation.mutateAsync(
                  {
                    mutationMethod: 'PATCH',
                    configuration: {
                      active: true,
                    },
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries([
                        'electronicPayrollStatus',
                        companyId,
                      ])
                      dispatch({ type: 'first_config_attempt' })
                      refreshCompany()
                      handleNextStep()
                    },
                  }
                )
              } else {
                refreshCompany()
                handleNextStep()
              }
            },
          }
        )
      } else if (currentStep === 1) {
        // window.Cypress is for E2E testing purposes
        if (isAuthorized || window.Cypress) {
          handleNextStep()
        }
      } else if (currentStep === 3) {
        electronicPayrollConfigurationMutation.mutate(
          {
            mutationMethod: 'PATCH',
            configuration: {
              company_certificate: currentValues.company_certificate,
              ...(currentValues.company_certificate
                ? {
                    certificate_file: currentValues.certificate_file.replace(
                      'data:application/x-pkcs12;base64,',
                      ''
                    ),
                    certificate_file_name: currentValues.certificate_file_name,
                    certificate_password: btoa(
                      currentValues.certificate_password
                    ),
                  }
                : {}),
            },
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                'electronicPayrollStatus',
                companyId,
              ])
              handleNextStep()
            },
          }
        )
      } else if (currentStep === 4) {
        electronicPayrollConfigurationMutation.mutate(
          {
            mutationMethod: 'PATCH',
            configuration: {
              social_benefits: currentValues.social_benefits,
              holidays: currentValues.holidays,
              consecutive_number: Number(currentValues.consecutive_number),
              canceled_consecutive_number: Number(
                currentValues.canceled_consecutive_number
              ),
            },
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                'electronicPayrollStatus',
                companyId,
              ])
              showSuccessMessage(
                'La configuración ha sido actualizada correctamente'
              )

              if (shouldShowSuccessBanner) {
                closeForm()
              } else {
                navigate(SETTINGS())
              }
            },
          }
        )
      } else {
        if (shouldShowSuccessBanner) {
          closeForm()
          return
        }
        navigate(SETTINGS())
      }
    } else {
      if (currentStep === 4) {
        if (shouldShowSuccessBanner) {
          closeForm()
          return
        }
        navigate(SETTINGS())
      }
      handleNextStep()
    }
  }

  const onChangeStep = (index) => setCurrentStep(index)

  return (
    <>
      <Stepper
        stepsData={configurationSteps}
        current={currentStep}
        progress={currentStep}
        onChangeStep={onChangeStep}
      />
      <Paper
        sx={(theme) => ({
          padding: theme.spacing(4),
          borderRadius: '1rem',
          marginTop: theme.spacing(5),
        })}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema(currentStep)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ handleSubmit: onSubmitForm }) => {
            return (
              <Form>
                {currentStep === 0 ? <CompanyInformation /> : null}
                {currentStep === 1 ? (
                  <DianActivation isEditting={comeFromEdit} />
                ) : null}
                {currentStep === 2 ? <DianConfirmation /> : null}
                {currentStep === 3 ? <ElectronicFileInformation /> : null}
                {currentStep === 4 ? <ElectronicPayrollAdvanced /> : null}
                <Box
                  sx={(theme) => ({
                    width: '100%',
                    display: 'flex',
                    gap: theme.spacing(2),
                    marginTop: theme.spacing(7),
                    flexDirection: 'column',
                    [theme.breakpoints.up('tablet')]: {
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                    },
                  })}
                >
                  <Button
                    onClick={onSubmitForm}
                    data-cy="electronic-payroll-configuration-button"
                    loading={
                      companyMutation.isLoading ||
                      electronicPayrollConfigurationMutation.isLoading
                    }
                    // !window.Cypress is for E2E testing purposes
                    disabled={
                      currentStep === 1 && !isAuthorized && !window.Cypress
                    }
                  >
                    Guardar y continuar
                  </Button>
                  <Button
                    onClick={handlePreviousStep}
                    variant="outlined"
                    disabled={currentStep === 0}
                  >
                    Regresar
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Paper>
    </>
  )
}

export default ConfigurationProcess
