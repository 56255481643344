import { useEffect } from 'react'
import { useIsFetching, useQueryClient } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Box, Link, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Page from 'components/UI/Page/Page'

import useSocialSecurityService from 'utils/hooks/payroll/socialSecurity'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import * as routes from 'config/routes'

import SocialSecurityPaymentModal from '../PaymentModal/SocialSecurityPaymentModal'
import useSocialSecurityPaymentModal from '../PaymentModal/useSocialSecurityPaymentModal'
import InfoCard from './InfoCard'
import ErrorsModal from './Modals/ErrorsModal'
import useErrorsModal from './Modals/useErrorsModal'
import PaymentSummary from './PaymentSummary'
import { getInfoCards, getResultContent } from './helpers'

const UploadResult = () => {
  const queryClient = useQueryClient()
  const params = useParams()
  const { state } = useLocation()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const navigate = useNavigate()
  const { periodId } = params
  const {
    socialSecurityPaymentModalState,
    openSocialSecurityPaymentModal,
    closeSocialSecurityPaymentModal,
    activateSocialSecurityPaymentIntegration,
  } = useSocialSecurityPaymentModal({ id: periodId }, true, true)
  const { errorsModalState, openErrorsModal, closeErrorsModal } =
    useErrorsModal()
  const queryKey = ['getSSForm', periodId, state?.formType]
  const isArusProvider = state?.provider === 'arus'
  const { socialSecurityMutation, socialSecurityQuery } =
    useSocialSecurityService({
      serviceParams: {
        queryKey,
        periodId,
        formType: state?.formType,
      },
      queryOptions: {
        refetchOnWindowFocus: isArusProvider,
        onError: (error) => {
          handleError(error)
          navigate(-1)
        },
      },
    })
  const hasErrors =
    (socialSecurityQuery.data &&
      socialSecurityQuery.data.totals.errors !== 0) ||
    false
  const canRenderSummary = !socialSecurityQuery.isLoading
  const { totals: formTotals = {}, form: formDataValues = {} } =
    socialSecurityQuery.data || {}
  const hasWarnings =
    formTotals?.alerts !== 0 && formTotals?.possible_fixes !== 0
  const isRefetching = useIsFetching(queryKey, { exact: false }) === 1

  useEffect(() => {
    return () => {
      queryClient.removeQueries('getSSForm', {
        exact: false,
      })
    }
  }, [queryClient])

  const onPay = () => {
    socialSecurityMutation.mutate(
      {
        mutationMethod: 'POST',
        periodId,
        formKey: formDataValues.key,
      },
      {
        onSuccess: ({ data }) => {
          if (data?.payment_url) {
            if (formDataValues?.provider === 'arus') {
              window.open(data?.payment_url, '_blank')
            } else {
              window.location.href = data?.payment_url
            }
          }
        },
      }
    )
  }

  const handleDeleteForm = () => {
    socialSecurityMutation.mutate(
      {
        mutationMethod: 'DELETE',
        periodId,
        formKey: formDataValues.key,
        formType: formDataValues.type,
      },
      {
        onSuccess: ({ message }) => {
          showSuccessMessage(message)
          navigate(routes.SETTINGS_SOCIAL_SECURITY_INDEX(), {
            state: {
              provider: formDataValues.provider,
            },
          })
        },
      }
    )
  }

  const handleCloseErrorsModal = () => {
    closeErrorsModal()
  }

  const infoCards = getInfoCards(
    formTotals,
    formDataValues?.type,
    openErrorsModal,
    openSocialSecurityPaymentModal,
    formDataValues.provider
  )

  const resultContent = getResultContent(
    hasErrors,
    formDataValues?.status,
    formDataValues.provider
  )

  const getModalVariant = () => {
    if (hasErrors) return 'errors'
    if (formTotals?.alerts > 0) return 'alerts'
    return 'possible_fixes'
  }

  return (
    <Box
      sx={(theme) => ({
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content',
        overflowX: 'hidden',
        [theme.breakpoints.up('md')]: {
          gridTemplateRows: 'auto',
        },
        ...(canRenderSummary && {
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 25rem',
          },
        }),
      })}
    >
      <Page
        documentTitle="Resultado de carga de planilla"
        isLoading={socialSecurityQuery.isLoading}
        isLoadingWithModal={isArusProvider && isRefetching}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingTop: theme.spacing(5.5),
          })}
        >
          <Box>
            {resultContent?.title}
            <Typography
              variant="body1"
              sx={(theme) => ({
                marginTop: theme.spacing(2),
              })}
            >
              {resultContent?.subtitle}
            </Typography>
          </Box>

          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(4),
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginTop: theme.spacing(5),
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-start',
              },
            })}
          >
            {infoCards.map((card, key) => (
              <InfoCard
                key={key.toString()}
                card={card}
                hasErrors={hasErrors}
                hasWarnings={hasWarnings}
              />
            ))}
          </Box>

          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: theme.spacing(7),
              flexDirection: 'column',
              gap: theme.spacing(2),
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
              },
            })}
          >
            {hasErrors ? (
              <Button onClick={() => navigate(-1)}>
                Regresar a corregir errores
              </Button>
            ) : null}
            <Button
              variant="outlined"
              onClick={() => openErrorsModal(getModalVariant())}
            >
              Ver tabla de {hasErrors ? 'errores' : 'alertas'}
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                openSocialSecurityPaymentModal({
                  formType: formDataValues?.type,
                  provider: formDataValues.provider,
                })
              }
            >
              Ver personas
            </Button>
          </Box>
          <Typography
            variant="body2"
            align="center"
            sx={(theme) => ({
              marginTop: theme.spacing(18),
              marginBottom: theme.spacing(3),
            })}
          >
            Para más información, visita nuestro{' '}
            <Link
              href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-las-posibles-alertas-y-errores-que-pueden-generarse-al-pagar-la-seguridad-social-desde-aleluya"
              target="_blank"
            >
              <b>centro de ayuda.</b>
            </Link>
          </Typography>
        </Box>

        {errorsModalState.open ? (
          <ErrorsModal
            state={errorsModalState}
            periodId={periodId}
            formType={formDataValues?.type}
            handleClose={handleCloseErrorsModal}
          />
        ) : null}

        {socialSecurityPaymentModalState.open ? (
          <SocialSecurityPaymentModal
            state={socialSecurityPaymentModalState}
            handleClose={closeSocialSecurityPaymentModal}
            handleActivateIntegration={activateSocialSecurityPaymentIntegration}
          />
        ) : null}
      </Page>

      {canRenderSummary ? (
        <PaymentSummary
          formData={formDataValues}
          isLoading={
            socialSecurityMutation.isLoading || socialSecurityQuery.isLoading
          }
          handlePayForm={onPay}
          handleDeleteForm={handleDeleteForm}
          hasErrors={hasErrors}
          provider={formDataValues.provider}
        />
      ) : null}
    </Box>
  )
}

export default UploadResult
