import { useState } from 'react'

const useDiscardedModal = ({
  openAffiliationModal,
  openBackgroundCheckInitialConsultModal,
  openElectronicSignatureModal,
}) => {
  const [state, setState] = useState({
    open: false,
    process: null,
    affiliation: {},
    backgroundCheck: {},
    electronicSignature: {},
    onboardingId: null,
    workerName: null,
    workerId: null,
  })

  const closeDiscardedModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }))
  }

  const openDiscardedModal = ({
    process,
    affiliation,
    backgroundCheck,
    electronicSignature,
    onboardingId,
    workerName,
    workerId,
  }) => {
    if (process === 'affiliation') {
      setState((previousState) => ({
        ...previousState,
        open: true,
        process,
        affiliation,
        onboardingId,
        workerName,
        workerId,
      }))
    }

    if (process === 'backgroundCheck') {
      setState((previousState) => ({
        ...previousState,
        open: true,
        process,
        backgroundCheck,
        onboardingId,
        workerName,
        workerId,
      }))
    }

    if (process === 'electronicSignature') {
      setState((previousState) => ({
        ...previousState,
        open: true,
        process,
        electronicSignature,
        onboardingId,
        workerName,
        workerId,
      }))
    }
  }

  const reopenProcessModal = () => {
    switch (state.process) {
      case 'affiliation':
        closeDiscardedModal()
        openAffiliationModal({
          affiliation: state.affiliation,
          onboardingId: state.onboardingId,
          workerName: state.workerName,
          workerId: state.workerId,
        })
        break

      case 'backgroundCheck':
        closeDiscardedModal()
        openBackgroundCheckInitialConsultModal({
          backgroundCheck: state.backgroundCheck,
          onboardingId: state.onboardingId,
          workerName: state.workerName,
          workerId: state.workerId,
        })
        break

      case 'electronicSignature':
        closeDiscardedModal()
        openElectronicSignatureModal({
          electronicSignature: state.electronicSignature,
          onboardingId: state.onboardingId,
          workerName: state.workerName,
          workerId: state.workerId,
        })
        break

      default:
        break
    }
  }

  const resetDiscardedModalState = () => {
    setState((previousState) => ({
      ...previousState,
      process: null,
      affiliation: {},
      onboardingId: null,
      workerName: null,
      workerId: null,
    }))
  }

  return {
    discardedModalState: state,
    closeDiscardedModal,
    openDiscardedModal,
    reopenProcessModal,
    resetDiscardedModalState,
  }
}

export default useDiscardedModal
