import useModals from 'components/App/ModalsManager/useModals'

import PeriodEditingInactiveModal from './PeriodEditingInactiveModal'

const usePeriodEditingInactiveModal = () => {
  const modals = useModals()

  const handleClose = () => {
    modals.closeModal()
  }

  const openPeriodEditingInactiveModal = () => {
    modals.openModal({
      id: 'periodEditingInactiveModal',
      content: <PeriodEditingInactiveModal handleClose={handleClose} />,
      modalProps: {
        header: '¡PILAS! La edición de periodos históricos está inactiva',
        hideFooter: true,
        paperSx: {
          maxWidth: {
            tablet: '45rem',
            laptop: '48.5rem',
          },
        },
      },
    })
  }

  return {
    openPeriodEditingInactiveModal,
  }
}

export default usePeriodEditingInactiveModal
