import { Box, Card, Typography, useMediaQuery, useTheme } from '@mui/material'

import Icon from 'components/UI/Icon'

import { formatDisplayDate } from 'utils/dateTime'

/**
 * Object with date range
 * @typedef {object} Range
 * @property {string} initialDay - Period initial day
 * @property {string} endDay - Period end day
 */

/**
 * @param {string} color - Color of the background: primary || complementary2 || primary || accent2)
 * @param {Range} range - Period date range
 */
const RangeDateCard = ({
  color = 'primary',
  range = { initialDay: '', endDay: '' },
}) => {
  const isDesktopBreakpoint = useMediaQuery((theme) =>
    theme.breakpoints.up('desktop')
  )
  const themeInstance = useTheme()
  const colorKeys = color.split('.')
  const backgroundColor = color?.includes('.')
    ? themeInstance.palette[colorKeys[0]][colorKeys[1]]
    : themeInstance.palette[colorKeys[0]]?.main

  return (
    <Card
      sx={(theme) => ({
        backgroundColor,
        position: 'relative',
        padding: theme.spacing(2, 0, 2, 3),
        height: '100%',
        borderRadius: '1rem',
        [theme.breakpoints.up('desktop')]: {
          padding: theme.spacing(2),
        },
      })}
    >
      <Box
        sx={{
          position: 'inherit',
          zIndex: 2,
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.75),
            [theme.breakpoints.up('desktop')]: {
              flexDirection: 'row',
              marginBottom: theme.spacing(1),
            },
          })}
        >
          <Icon name="calendar-days" />
          <Typography variant="lead1">Fecha</Typography>
        </Box>
        {isDesktopBreakpoint ? (
          <Typography variant="h4">
            {formatDisplayDate(range.initialDay)} -{' '}
            {formatDisplayDate(range.endDay)}
          </Typography>
        ) : (
          <>
            <Typography variant="h1">
              {formatDisplayDate(range.initialDay)}
            </Typography>
            <Typography variant="h1">
              {formatDisplayDate(range.endDay)}
            </Typography>{' '}
          </>
        )}
      </Box>
    </Card>
  )
}

export default RangeDateCard
