import { addYears, endOfYear, startOfYear } from 'date-fns'
import { Form, Formik } from 'formik'

import { Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { formatDate } from 'utils/dateTime'
import usePeriodService from 'utils/hooks/organizer/periodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

const NewPeriodModal = ({ visible, handleClose, activeTab }) => {
  const { handleError } = useErrorHandler()
  const { showInfoMessage } = useNotifications()
  const { periodMutation } = usePeriodService()
  const maxDate = formatDate(endOfYear(addYears(new Date(), 1)))

  const minDate = formatDate(startOfYear(addYears(new Date(), -2)))

  const onSubmit = (values, form) => {
    if (activeTab === 'payroll_history') {
      periodMutation.mutate(
        {
          mutationMethod: 'POST',
          mutationKey: 'createPeriod',
          period: values,
        },
        {
          onSuccess: ({ message }) => {
            showInfoMessage(message)
            handleClose()
          },
          onError: (error) => handleError(error, form),
        }
      )
    }

    if (activeTab === 'electronic_payroll_history') {
      periodMutation.mutate(
        {
          mutationMethod: 'POST',
          mutationKey: 'createElectronicPayrollPeriod',
          period: values,
        },
        {
          onSuccess: ({ message }) => {
            showInfoMessage(message)
            handleClose()
          },
          onError: (error) => handleError(error, form),
        }
      )
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ date: formatDate(new Date()) }}
      enableReinitialize
    >
      {({ handleSubmit }) => {
        return (
          <Modal
            okText="Generar periodo"
            header="Generar nuevo periodo"
            open={visible}
            onOk={handleSubmit}
            onCancel={handleClose}
            isLoading={periodMutation.isLoading}
          >
            {activeTab === 'payroll_history' ? (
              <Typography gutterBottom>
                Con esta opción puedes crear un nuevo periodo, dependiendo de la
                frecuencia de pago de la empresa, se crea el periodo quincenal o
                mensual.
              </Typography>
            ) : (
              <Typography gutterBottom>
                Con esta opción puedes crear un nuevo periodo de Nómina
                Electrónica.
              </Typography>
            )}

            <Form>
              <FormField
                name="date"
                label="Fecha del periodo a crear"
                maxDate={maxDate}
                minDate={minDate}
                variant="datepicker"
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default NewPeriodModal
