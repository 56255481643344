import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import { useSupportMenuContext } from 'components/App/SupportMenuContext'
import usePaymentModal from 'components/Subscription/Atoms/PaymentModal/usePaymentModal'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'
import Modal from 'components/UI/Modal/Modal'

import useSubscription from '../useSubscription'
import { getInfoMessages } from './helpers'

const UpdatePaymentMethodInfoModal = ({
  state,
  handleClose,
  openUpdatePaymentMethodInfoModal,
}) => {
  const { open, actionMessage, activePayment, isFromPaymentMethodCard } = state
  const { subscription } = useSubscription()

  const paymentModal = usePaymentModal()
  const { openSupportMenuFromOutside } = useSupportMenuContext()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const handleCloseModal = () => {
    localStorage.setItem('one-by-session-info-modal', 'modalViewed')
    handleClose()
  }

  const handleOpenSupportMenu = () => {
    openSupportMenuFromOutside()
    handleCloseModal()
  }

  const { title, buttonLabel, description, picture } = getInfoMessages({
    actionMessage,
    handleOpenSupportMenu,
    activePayment,
    isFromPaymentMethodCard,
    subscription,
  })

  const openPaymentModal = () => {
    handleCloseModal()

    paymentModal.openModal({
      isEditingPaymentMethod: true,
      isFromUpdateMethodInfo: true,
      openUpdatePaymentMethodInfoModal,
    })
  }

  const handleClick = () => {
    if (actionMessage === 'invitation_to_update') {
      openPaymentModal()
    } else {
      handleCloseModal()
    }
  }

  return (
    <Modal
      open={open}
      onCancel={handleCloseModal}
      hideFooter
      paperSx={{
        maxWidth: '52rem',
        position: 'relative',
      }}
      contentWrapperSx={{
        overflow: 'hidden',
        minHeight: '22.5rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { mobile: '1fr', tablet: '18rem 1fr' },
        }}
      >
        {!isMobile ? (
          <>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.accent1.main,
                width: '27.75rem',
                height: '27.75rem',
                borderRadius: '100%',
                position: 'absolute',
                left: theme.spacing(-18.5),
                top: theme.spacing(-8.75),
              })}
            />
            <Box
              sx={{
                zIndex: 1,
                display: 'flex',
                width: '100%',
                alignItems: 'center',
              }}
            >
              {picture}
            </Box>
          </>
        ) : null}
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.up('tablet')]: {
              paddingLeft: theme.spacing(3),
            },
          })}
        >
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="h2"
                sx={(theme) => ({ margin: theme.spacing(0, 1, 2.5) })}
              >
                {title}
              </Typography>
              <ButtonBase
                onClick={handleCloseModal}
                data-cy="close-dialog-button"
                sx={(theme) => ({
                  alignSelf: 'self-start',
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
              </ButtonBase>
            </Box>
            {description}
          </Box>

          <Box
            sx={(theme) => ({
              marginTop: theme.spacing(3),
            })}
          >
            <Button onClick={handleClick}>{buttonLabel}</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default UpdatePaymentMethodInfoModal
