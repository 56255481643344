import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import useNotifications from 'utils/hooks/useNotifications'
import useDocumentsService from 'utils/hooks/worker/documents'

import { DocumentsContext } from '../Show/Body/Tabs/DocumentsContainer'
import useWorker from '../useWorker'
import { FoldersContainerContext } from './Index'
import {
  getActionModalInitialValues,
  getActionModalMutationMethod,
  getActionModalValidationSchema,
  getActionTitle,
  validateIsAffiliationFolder,
} from './helpers'

const ActionModal = ({ onClose }) => {
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const { showSuccessMessage } = useNotifications()
  const { actionModal } = useContext(FoldersContainerContext)
  const { openFolder } = useContext(DocumentsContext)
  const { worker } = useWorker({ useCache: true })
  const isOpenedFolder = Boolean(openFolder)
  const workerId = worker.id
  const folderId = isOpenedFolder ? openFolder?.id : actionModal.context.id
  const documentId = actionModal.context.id
  const modalMetadata = getActionTitle(isOpenedFolder, actionModal.type)
  const foldersCache =
    queryClient.getQueryData(['getAllFolders', companyId, workerId])?.data
      ?.folders || []
  const folderOptions = foldersCache
    ?.filter(
      (folder) =>
        folder.id !== openFolder?.id &&
        !validateIsAffiliationFolder(folder.name)
    )
    .map((folder) => ({
      id: folder.id,
      name: folder.name,
    }))
  const { documentsMutation } = useDocumentsService({
    queryOptions: {
      enabled: false,
    },
  })

  const onSubmit = (values) => {
    const data = {}

    if (actionModal.type === 'change_file_name') {
      data.document_name = values.document_name
    }

    if (actionModal.type === 'move_file') {
      data.new_folder_id = values.folder.id
    }

    if (actionModal.type === 'change_folder_name') {
      data.name = values.name
    }

    documentsMutation.mutate(
      {
        mutationMethod: getActionModalMutationMethod(actionModal.type),
        workerId,
        folderId,
        documentId,
        data,
      },
      {
        onSuccess: async ({ message }) => {
          await queryClient.invalidateQueries([
            'getAllFolders',
            companyId,
            workerId,
          ])

          if (isOpenedFolder) {
            await queryClient.invalidateQueries([
              'getAllDocuments',
              companyId,
              workerId,
              folderId,
            ])
          }

          if (message) {
            showSuccessMessage(message)
          }

          onClose()
        },
      }
    )
  }

  return (
    <Formik
      initialValues={getActionModalInitialValues(actionModal.type)}
      validationSchema={getActionModalValidationSchema(actionModal.type)}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Modal
          open
          header={modalMetadata.title}
          okText={modalMetadata.callToAction}
          cancelText="Cancelar"
          onCloseModal={onClose}
          onCancel={onClose}
          onOk={handleSubmit}
          isLoading={documentsMutation.isLoading}
          paperSx={{
            maxWidth: '45.5rem',
            width: '100%',
          }}
        >
          <Box sx={(theme) => ({ marginTop: theme.spacing(5) })}>
            <Form>
              {actionModal.type === 'move_file' ? (
                <FormField
                  name="folder"
                  variant="autocomplete"
                  label="Seleccionar carpeta"
                  options={folderOptions}
                />
              ) : null}
              {['change_file_name', 'change_folder_name'].includes(
                actionModal.type
              ) ? (
                <FormField
                  name={isOpenedFolder ? 'document_name' : 'name'}
                  placeholder="Nuevo nombre"
                  label={`Cambiar nombre ${
                    isOpenedFolder ? 'al archivo' : 'a la carpeta'
                  }`}
                  margin="none"
                />
              ) : null}
              {['delete_file', 'delete_folder'].includes(actionModal.type) ? (
                <Typography variant="body1" color="black.dark">
                  ¿Estás seguro de que quieres eliminar{' '}
                  {isOpenedFolder ? 'este archivo' : 'esta carpeta'}? Ten en
                  cuenta que tras realizar esta acción, no podrás recuperar
                  {isOpenedFolder ? 'lo' : 'la'}.
                </Typography>
              ) : null}
            </Form>
          </Box>
        </Modal>
      )}
    </Formik>
  )
}

export default ActionModal
