import { useFormikContext } from 'formik'
import { useContext, useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import { PLANS_BY_CODED_NAME } from 'components/Subscription/helpers'
import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'

import { SubscriptionViewContext } from '../Index'
import { useCalculateSubscription } from '../helpers'

const useOnchangeSubscription = ({ values, setFieldValue }) => {
  const { setSelectedPlan } = useContext(SubscriptionViewContext)
  const { calculateSubscription } = useCalculateSubscription()

  const onChangePlanOptions = (planId) => {
    setFieldValue('plan', planId)
    calculateSubscription(
      {
        extraWorkers: values?.workers_number,
        planId,
      },
      setSelectedPlan
    )
  }

  return { onChangePlanOptions }
}

const usePlanOptions = ({ plans, modality }) => {
  const { values, setFieldValue } = useFormikContext()
  const { isCompanyUsing20241111Plans, subscription } = useSubscription()
  const { onChangePlanOptions } = useOnchangeSubscription({
    values,
    setFieldValue,
  })

  let planOptions = plans

  const entrepreneurPlan = useMemo(
    () =>
      plans.find(
        ({ coded_name }) =>
          coded_name === PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan
      ) || {},
    [plans]
  )

  useEffect(() => {
    if (modality === 'year' && isCompanyUsing20241111Plans) {
      const payrollOnlyPlan = plans.find(
        ({ coded_name }) =>
          coded_name === PLANS_BY_CODED_NAME.new_plans.payroll_only_plan
      )

      if (values.plan === entrepreneurPlan.id) {
        onChangePlanOptions(payrollOnlyPlan.id)
      }
    }

    if (
      modality === 'month' &&
      values.plan === entrepreneurPlan.id &&
      values.workers_number > 3
    ) {
      setFieldValue('workers_number', 3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    entrepreneurPlan.id,
    isCompanyUsing20241111Plans,
    modality,
    plans,
    values.plan,
    values.workers_number,
  ])

  if (modality === 'year' && isCompanyUsing20241111Plans) {
    planOptions = plans.filter(
      ({ coded_name }) =>
        coded_name !== PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan
    )
  }

  if (
    modality === 'month' &&
    isCompanyUsing20241111Plans &&
    subscription.payrolls_size > 3
  ) {
    planOptions = planOptions.filter(
      ({ coded_name }) =>
        coded_name !== PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan
    )
  }

  const planOptionsFormatted = planOptions.map((plan) => ({
    label: plan.name,
    value: plan.id,
  }))

  return [planOptionsFormatted, onChangePlanOptions]
}

const PlanSelector = ({ modality }) => {
  const queryClient = useQueryClient()
  const location = useLocation()

  const { data: { plans: availablePlans } = {} } =
    queryClient.getQueryData(['getPlans', getCompanyId()]) || {}

  const plans = location?.state?.planOptions || availablePlans || []

  const [planOptions, onChangePlanOptions] = usePlanOptions({
    plans,
    modality,
  })

  return (
    <FormField
      fullWidth={false}
      name="plan"
      variant="select"
      optional={false}
      options={planOptions}
      onChange={({ target }) => onChangePlanOptions(target?.value)}
    />
  )
}

export default PlanSelector
