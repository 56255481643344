import { useRef } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Paper, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import { coffee } from 'utils/emojis'
import useBackgroundCheckService from 'utils/hooks/backgroundCheck/backgroundCheck'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import BackgroundCheckElement from './BackgroundCheckElement'
import { sortInstitutionsByName } from './helpers'

const BackgroundCheckModal = ({ state, handleClose }) => {
  const queryClient = useQueryClient()
  const backgroundCheckQueryKey = ['getBackgroundCheckResults', state.workerId]
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const { listenInBackground } = useUser()
  const socketConnections = useRef([])
  const openedSearchs = useRef({})
  const companyId = getCompanyId()
  const showFinishedAlert = useRef(false)

  const backgroundCheckSocketHandler = async (
    websocketResult,
    connectionRef
  ) => {
    socketConnections.current.push(connectionRef)

    if (websocketResult) {
      if (websocketResult.errors) {
        handleError(websocketResult.errors)
        connectionRef.off('value')
      }

      if (websocketResult === 'pending') {
        await queryClient.invalidateQueries(backgroundCheckQueryKey)
      }

      if (websocketResult === 'finished') {
        connectionRef.off('value')
        await queryClient.invalidateQueries(backgroundCheckQueryKey)
      }
    }
  }

  const { backgroundCheckQuery, backgroundCheckMutation } =
    useBackgroundCheckService({
      serviceParams: {
        queryKey: backgroundCheckQueryKey,
        workerId: state.workerId,
      },
      queryOptions: {
        enabled: Boolean(state.workerId),
        onSuccess: ({ data }) => {
          data?.forEach((backgroundCheckElement) => {
            if (
              !openedSearchs.current[backgroundCheckElement.coded_name] &&
              backgroundCheckElement.status === 'in_progress'
            ) {
              openedSearchs.current[backgroundCheckElement.coded_name] = true

              if (!showFinishedAlert.current) {
                showFinishedAlert.current = true
              }

              return listenInBackground(
                `workers/${state.workerId}/background_checks/${backgroundCheckElement.id}/${backgroundCheckElement.coded_name}`,
                backgroundCheckSocketHandler
              )
            }
            return null
          })

          if (
            data?.every(
              (backgroundCheckElement) =>
                backgroundCheckElement.status === 'finished'
            ) &&
            showFinishedAlert.current
          ) {
            showSuccessMessage(
              `La consulta de antecedentes de ${state.workerName} está lista.`,
              {
                persist: true,
                preventDuplicate: true,
              }
            )
          }
        },
      },
    })
  const isBackgroundCheckFinished = backgroundCheckQuery.data?.every?.(
    (checkElement) => checkElement.status === 'finished'
  )

  const handleCreateBackgroundCheck = () => {
    if (
      openedSearchs.current &&
      Object.keys(openedSearchs.current).length > 0
    ) {
      openedSearchs.current = {}
    }

    backgroundCheckMutation.mutate(
      {
        mutationMethod: 'POST',
        workerId: state.workerId,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(backgroundCheckQueryKey)
          await queryClient.invalidateQueries([
            'getCompanyOnboardings',
            companyId,
          ])
        },
      }
    )
  }

  const modalOnExited = () => {
    if (socketConnections.current.length > 0) {
      socketConnections.current.forEach((connection) => {
        connection?.off?.('value')
      })
    }

    if (
      openedSearchs.current &&
      Object.keys(openedSearchs.current).length > 0
    ) {
      openedSearchs.current = {}
    }

    showFinishedAlert.current = false
  }

  const modalOnExit = async () => {
    if (
      openedSearchs.current &&
      Object.keys(openedSearchs.current).length > 0
    ) {
      await queryClient.invalidateQueries(['getCompanyOnboardings', companyId])
    }
  }

  return (
    <Modal
      open={state.open}
      isLoading={backgroundCheckMutation.isLoading}
      hideFooter
      onCancel={handleClose}
      header="Consulta de antecedentes"
      paperSx={{
        maxWidth: '46rem',
      }}
      dialogProps={{
        TransitionProps: {
          onExited: modalOnExited,
          onExit: modalOnExit,
        },
      }}
    >
      <Box sx={(theme) => ({ paddingBottom: theme.spacing(3) })}>
        <Typography variant="body1" color="black.dark">
          Acá puedes consultar los antecedentes de <b>{state.workerName}</b> y
          conocer todo su historial.{' '}
          {state?.backgroundCheck?.status !== 'finished' ? (
            <>
              Esta consulta podría tomar un tiempo. Te avisaremos tan pronto
              esté lista. Mientras tanto, ¡tómate un café{' '}
              <Emoji code={coffee} />!
            </>
          ) : null}
        </Typography>
        <Typography
          variant="h6"
          sx={(theme) => ({
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(4),
          })}
          color="black.main"
        >
          Resultado de la búsqueda
        </Typography>
        {backgroundCheckQuery.isLoading ? (
          <LoadingBox />
        ) : (
          <Paper
            elevation={0}
            sx={(theme) => ({
              boxShadow: theme.shadows[4],
              borderRadius: '1rem',
              overflow: 'hidden',
            })}
          >
            {sortInstitutionsByName(backgroundCheckQuery.data)?.map(
              (result) => (
                <BackgroundCheckElement
                  key={result.label}
                  result={result}
                  handleCreateBackgroundCheck={handleCreateBackgroundCheck}
                  workerId={state.workerId}
                />
              )
            )}
          </Paper>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button
          endIcon={isBackgroundCheckFinished ? <Icon name="thumbs-up" /> : null}
          onClick={
            !isBackgroundCheckFinished
              ? handleCreateBackgroundCheck
              : handleClose
          }
          loading={backgroundCheckMutation.isLoading}
        >
          {!isBackgroundCheckFinished ? 'Volver a consultar' : 'Todo ¡OK!'}
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Regresar
        </Button>
      </Box>
    </Modal>
  )
}

export default BackgroundCheckModal
