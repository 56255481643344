const certificatesConfig = {
  risk_provider: {
    iconName: 'shield-check',
    backgroundColor: (theme) => theme.palette.complementary1.light,
  },
  health_provider: {
    iconName: 'shield-plus',
    backgroundColor: (theme) => theme.palette.primary.dark,
  },
  pension_provider: {
    iconName: 'hand-coins',
    backgroundColor: (theme) => theme.palette.accent2.main,
  },
  compensation_fund: {
    iconName: 'file-chart-pie',
    backgroundColor: (theme) => theme.palette.complementary2.main,
  },
}

export const getCertificatesData = (certificates) =>
  certificates?.map((certificate) => ({
    ...certificate,
    iconName: certificatesConfig[certificate.provider_type]?.iconName,
    backgroundColor:
      certificatesConfig[certificate.provider_type]?.backgroundColor,
  }))

export const ENTITY_LABELS = {
  compensation_fund: 'CCF',
  health_provider: 'EPS',
  pension_provider: 'AFP',
  risk_provider: 'ARL',
}

export const hasAnyDocumentReady = (certificates) =>
  certificates?.some((item) => typeof item.document_url === 'string')

export const getAffiliationEntities = (providersArray) =>
  providersArray?.filter((provider) => provider.hidden === false)
