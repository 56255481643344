import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import DeductionsSection from './Sections/DeductionsSection'
import IncomesSection from './Sections/IncomesSection'

export const deductionCategoryOptions = [
  { value: 'deductions', label: 'Deducción' },
  { value: 'loans', label: 'Préstamo' },
]

export const incomeCategoryOptions = [
  { value: 'salary_income', label: 'Salarial' },
  { value: 'non_salary_income', label: 'No Salarial' },
]

export const proportionalRecurrentPaymentOptions = [
  { value: 'proportional', label: 'Proporcional' },
  { value: 'fixed', label: 'Fijo' },
]

export const getContent = (
  modalType,
  worker,
  onCancel,
  handleError,
  showSuccessMessage,
  refreshRecurrentConcepts,
  loansMutation,
  recurrentConceptsMutation
) => {
  return modalType === 'incomes'
    ? {
        title: 'Agregar Ingreso Recurrente',
        body: <IncomesSection />,
        initialValues: {
          category: 'salary_income',
          payroll_concept_id: null,
          monthly_value: 0,
          active: true,
          concept_type: null,
        },
        handleSubmit: async (values) => {
          const valuesToSend = {
            ...values,
            payroll_concept_id: values.payroll_concept_id.id,
          }

          await recurrentConceptsMutation.mutateAsync(
            {
              mutationMethod: 'PUT',
              workerId: worker.id,
              recurrentConcepts: [valuesToSend],
            },
            {
              onSuccess: () => {
                Mixpanel.track(MIXPANEL_EVENTS.RECURRENT_INCOME_ADD, {
                  company_id: getCompanyId(),
                  user_id: getUserId(),
                  user_role: getUserRole(),
                  category: values.category,
                  concept_type: values.concept_type,
                })

                refreshRecurrentConcepts('recurrent_concept')
                showSuccessMessage('El concepto recurrente ha sido creado.')
                onCancel()
              },
            }
          )
        },
      }
    : {
        title: 'Agregar un préstamo o deducción',
        body: <DeductionsSection />,
        initialValues: {
          category: 'deductions',
          payroll_concept_id: null,
          active: true,
          concept_type: null,
        },
        handleSubmit: async (values) => {
          const { category } = values
          const valuesToSend = {
            ...values,
          }

          try {
            if (category === 'deductions') {
              valuesToSend.payroll_concept_id = values.payroll_concept_id.id

              await recurrentConceptsMutation.mutateAsync(
                {
                  mutationMethod: 'PUT',
                  workerId: worker.id,
                  recurrentConcepts: [valuesToSend],
                },
                {
                  onSuccess: () => {
                    refreshRecurrentConcepts('recurrent_concept')
                    showSuccessMessage('El concepto recurrente ha sido creado.')
                  },
                }
              )
            } else {
              valuesToSend.monthly_fee = values.monthly_fee.toString()
              valuesToSend.value = values.value.toString()

              await loansMutation.mutateAsync(
                {
                  mutationMethod: 'PUT',
                  workerId: worker.id,
                  loans: [valuesToSend],
                },
                {
                  onSuccess: () => {
                    refreshRecurrentConcepts('loans')
                    showSuccessMessage('El concepto recurrente ha sido creado.')
                  },
                }
              )
            }
            Mixpanel.track(MIXPANEL_EVENTS.RECURRENT_DEDUCTION_ADD, {
              company_id: getCompanyId(),
              user_id: getUserId(),
              user_role: getUserRole(),
              category: values.category,
              concept_type: values.concept_type,
            })

            onCancel()
          } catch (error) {
            handleError(error)
          }
        },
      }
}
