import { useState } from 'react'

import { Box, Paper, Typography } from '@mui/material'

import Page from 'components/UI/Page/Page'

import { getUserName } from 'utils/auth'
import useWorkerHolisticPayroll from 'utils/hooks/HolisticPayroll/WorkerServices'
import { getWorkerId } from 'utils/worker'

import walkingMan from 'assets/images/views/common/walking-man.png'

import HolisticPayrollTabs from './Commons/HolisticPayrollTabs'
import { getTabsConfig } from './helpers'

export default function WorkerFinancialWellness() {
  const [activeTab, setActiveTab] = useState(0)
  const workerId = getWorkerId()

  const { workerHolisticPayrollQuery } = useWorkerHolisticPayroll({
    serviceParams: {
      queryKey: ['getWorkerHolisticPayrollDashboard', workerId],
      workerId,
    },
  })

  const {
    coaching_sessions: coachingSessions,
    credit_score: creditScore,
    financial_wellness_indicator: financialWellnessIndicator,
  } = workerHolisticPayrollQuery.data || {}

  const workerName = getUserName()

  const tabsConfig = getTabsConfig({
    workerName,
    coachingSessions,
    creditScore,
    financialWellnessIndicator,
    setActiveTab,
    tabsView: 'worker_financial_wellness',
  })

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  return (
    <Page
      documentTitle="Bienestar financiero"
      grid
      isLoading={workerHolisticPayrollQuery.isLoading}
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Paper
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(3),
            borderRadius: '1rem',
            backgroundColor: 'accent1.main',
            columnGap: theme.spacing(4),
            minHeight: '12rem',
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
            })}
          >
            <Typography variant="h2">
              Salud financiera de{' '}
              <Typography variant="span" color="info.main">
                {workerName}
              </Typography>
            </Typography>
            <Typography variant="body1">
              Amamos construir tu futuro juntos y en HD! Por eso, prepara un
              café y siéntate... Es hora de ver cómo está tu salud financiera.
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              maxWidth: '20rem',
              position: 'relative',
              display: {
                mobile: 'none',
                laptop: 'block',
              },
            }}
          >
            <img
              src={walkingMan}
              alt=""
              width={272}
              height={287}
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                margin: 'auto',
              }}
            />
          </Box>
        </Paper>

        <HolisticPayrollTabs
          activeTab={activeTab}
          handleChangeTab={handleChangeTab}
          tabsConfig={tabsConfig}
        />
      </Box>
    </Page>
  )
}
