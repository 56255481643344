import { useFormikContext } from 'formik'
import { useState } from 'react'

import { Box } from '@mui/material'

import CurrentPaymentMethodSelector from 'components/Subscription/Atoms/PaymentModal/CurrentPaymentMethodSelector'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'

import MethodAccordion from './MethodAccordion'
import { getCurrentPaymentMethods } from './helpers'

const PaymentModalFields = ({
  tabsData,
  activePayment,
  paymentFields,
  setActivePayment,
  setIsCurrentMethodPayment,
  isEditingPaymentMethod,
  currentPlanPayment,
  extraWorkersEdit,
  isFromExtraWorkersModal,
  isFromUpdateMethodInfo,
  isFromImmediatePayment,
}) => {
  const { subscription } = useSubscription()
  const { setFieldValue } = useFormikContext()

  const { subscription_payment_methods_info: currentPaymentMethodsInfo } =
    subscription

  const currentPaymentMethods = getCurrentPaymentMethods(
    currentPaymentMethodsInfo
  )
  const [expandedAccordion, setExpandedAccordion] = useState(
    currentPaymentMethods?.includes(activePayment)
      ? 'current_payment_method'
      : activePayment
  )

  // Hides new automatic debit form for immediate payment flow
  const showPaymentMethodForm =
    ((isEditingPaymentMethod || (currentPlanPayment && !extraWorkersEdit)) &&
      activePayment === 'automatic_debit') ||
    activePayment !== 'automatic_debit'

  const showCurrentPaymentMethodOption =
    currentPaymentMethods?.includes(activePayment)

  const hasMultiplePaymentMethodsAvailable = tabsData?.length > 1

  const onExpandAccordion = (panel) => {
    const newIsCurrentMethodPaymentValue = panel === 'current_payment_method'
    setExpandedAccordion(panel)
    setIsCurrentMethodPayment(newIsCurrentMethodPaymentValue)
    setFieldValue('is_current_payment_method', newIsCurrentMethodPaymentValue)
    setFieldValue('payment_method', panel)
  }

  const onChangeTab = (newTab) => {
    const newIsCurrentMethodPaymentValue =
      currentPaymentMethods?.includes(newTab)

    setActivePayment(newTab)
    setIsCurrentMethodPayment(newIsCurrentMethodPaymentValue)
    setFieldValue('is_current_payment_method', newIsCurrentMethodPaymentValue)

    if (currentPaymentMethods?.includes(newTab)) {
      onExpandAccordion('current_payment_method')
    } else {
      onExpandAccordion(newTab)
    }
  }

  const currentMethodTitle = isEditingPaymentMethod
    ? 'Método actual'
    : 'Usar método actual'

  const newMethodTitle =
    activePayment === 'credit_card'
      ? 'Nueva tarjeta débito/crédito'
      : 'Nueva cuenta para débito'

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          margin: theme.spacing(4, 0, 3, 0),
        })}
      >
        {!hasMultiplePaymentMethodsAvailable ? (
          tabsData[0]?.label
        ) : (
          <RoundedTabs
            background="gray"
            tabsConfig={tabsData}
            value={activePayment}
            onChange={(_, newTab) => onChangeTab(newTab)}
            data-cy="subscription_payment_options"
          />
        )}
      </Box>
      {showCurrentPaymentMethodOption ? (
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            rowGap: theme.spacing(3),
            marginBottom: theme.spacing(2),
            overflow: 'hidden',
          })}
        >
          <MethodAccordion
            hideAccordionBorders={!showPaymentMethodForm}
            disableSelector={!showPaymentMethodForm}
            id="current_payment_method"
            title={currentMethodTitle}
            expanded={expandedAccordion}
            onChange={onExpandAccordion}
            isEditingPaymentMethod={isEditingPaymentMethod}
            isFromExtraWorkersModal={isFromExtraWorkersModal}
            isFromUpdateMethodInfo={isFromUpdateMethodInfo}
            isFromImmediatePayment={isFromImmediatePayment}
            accordionSummarySx={(theme) => ({
              padding: theme.spacing(1, 3),
              '&.MuiAccordionSummary-root.Mui-expanded': {
                minHeight: '1rem',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'grid',
                width: '100%',
                rowGap: theme.spacing(2.5),
                gridTemplateColumns: '1fr',
              })}
            >
              <CurrentPaymentMethodSelector activePayment={activePayment} />
            </Box>
          </MethodAccordion>
          {showPaymentMethodForm ? (
            <MethodAccordion
              id={activePayment}
              title={newMethodTitle}
              expanded={expandedAccordion}
              onChange={onExpandAccordion}
              isEditingPaymentMethod={isEditingPaymentMethod}
              isFromExtraWorkersModal={isFromExtraWorkersModal}
              isFromUpdateMethodInfo={isFromUpdateMethodInfo}
              isFromImmediatePayment={isFromImmediatePayment}
            >
              <Box sx={(theme) => ({ marginTop: theme.spacing(2) })}>
                {paymentFields[activePayment]?.fields}
              </Box>
            </MethodAccordion>
          ) : null}
        </Box>
      ) : (
        <Box sx={(theme) => ({ marginTop: theme.spacing(2) })}>
          {paymentFields[activePayment]?.fields}
        </Box>
      )}
    </>
  )
}

export default PaymentModalFields
