import { Box, Typography } from '@mui/material'

import Avatar from 'components/UI/Avatar'
import Link from 'components/UI/MaterialUI/Link'

import { getWordSingular } from 'utils/format'

import { WORKER_SHOW } from 'config/routes'

const renderAdditionalInfo = (numberYears, id) => {
  const label = `${numberYears} ${getWordSingular('años', numberYears === 1)}`

  return {
    birthdays: null,
    anniversaries: (
      <Typography variant="lead1" sx={{ whiteSpace: 'nowrap' }}>
        {label}
      </Typography>
    ),
    contracts_to_expire: (
      <Link
        to={WORKER_SHOW(id)}
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        Ver
      </Link>
    ),
  }
}

const EventItem = ({
  name,
  date,
  avatarId,
  picture,
  workerId,
  eventType,
  compact = true,
  years = null,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        gap: theme.spacing(1),
      })}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          gap: theme.spacing(1),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar avatarId={avatarId} src={picture} />
        </Box>
        <Box
          sx={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            variant="lead2"
            title={name}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            color="black.dark"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {date}
          </Typography>
        </Box>
      </Box>
      {compact ? null : renderAdditionalInfo(years, workerId)[eventType]}
    </Box>
  )
}

export default EventItem
