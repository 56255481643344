import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useNavigate } from 'react-router-dom'

import { Chip, ListItemText, Menu, MenuItem } from '@mui/material'

import useExtraWorkersModal from 'components/Subscription/Atoms/ExtraWorkersModal/useExtraWorkersModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import { useUpgradeToPayrollOnlyPlanModal } from 'components/Subscription/Atoms/useUpgradeToPayrollOnlyPlanModal'
import { PLANS_BY_CODED_NAME } from 'components/Subscription/helpers'
import Button from 'components/UI/Button/Button'
import { useEmployeeUploadInformationModal } from 'components/Worker/Index/Modals/EmployeeUploadInformationModal/useEmplyeeUploadInformationModal'

import * as routes from 'config/routes'

import Icon from '../Icon'

const ButtonAddWorker = ({
  variant,
  handleAddWorkerManually = () => {},
  enableFileOption = true,
  openNewWorkerModal = () => {},
}) => {
  const { additionalWorkersInfo, subscription } = useSubscription()
  const extraWorkersModal = useExtraWorkersModal()
  const { openEmployeeUploadInformationModal } =
    useEmployeeUploadInformationModal()
  const navigate = useNavigate()
  const { openUpgradeToPayrollOnlyPlanModal } =
    useUpgradeToPayrollOnlyPlanModal()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'addWorkersMenu',
  })

  const hasToPayNewWorkers = !additionalWorkersInfo?.add_workers
  const showUpgradeToPayrollOnlyPlan =
    subscription?.plan?.coded_name ===
      PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan &&
    subscription?.payrolls_size === 3

  const handleAddWorker = () => {
    popupState.close()

    if (hasToPayNewWorkers) {
      extraWorkersModal.openModal()
    } else if (showUpgradeToPayrollOnlyPlan) {
      openUpgradeToPayrollOnlyPlanModal({
        handleOnConfirm: handleAddWorkerManually,
      })
    } else if (handleAddWorkerManually) {
      handleAddWorkerManually()
    } else {
      openNewWorkerModal()
    }
  }

  const handleAddWorkerEasy = () => {
    popupState.close()
    openEmployeeUploadInformationModal()
  }

  return (
    <>
      <Button
        endIcon={
          enableFileOption ? (
            <Icon name="chevron-down" />
          ) : (
            <Icon name="user-plus" />
          )
        }
        {...(enableFileOption
          ? bindTrigger(popupState)
          : {
              onClick: handleAddWorker,
            })}
        variant={variant}
      >
        Agregar persona
      </Button>
      {enableFileOption ? (
        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleAddWorker}>
            <ListItemText>Ingresar manualmente</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (showUpgradeToPayrollOnlyPlan) {
                popupState.close()
                openUpgradeToPayrollOnlyPlanModal({
                  handleOnConfirm: () => navigate(routes.WORKER_IMPORT()),
                })
              } else {
                navigate(routes.WORKER_IMPORT())
              }
            }}
          >
            <ListItemText> Subir archivo</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleAddWorkerEasy}>
            <ListItemText>Carga fácil</ListItemText>
            <Chip label="Beta" size="medium" color="info" />
          </MenuItem>
        </Menu>
      ) : null}
    </>
  )
}

export default ButtonAddWorker
