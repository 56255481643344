import { Form, Formik } from 'formik'
import { useLocation } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Link from 'components/UI/MaterialUI/Link'

import useUsersService from 'utils/hooks/settings/usersService'
import useNotifications from 'utils/hooks/useNotifications'

import { LOGIN } from 'config/routes'

import LinkToSignup from '../common/LinkToSignup'
import MainContainer from '../common/MainContainer'
import { initialValues, validationSchema } from './helpers'

const PasswordForgot = () => {
  const location = useLocation()
  const { showSuccessMessage } = useNotifications()

  const { usersMutation } = useUsersService({
    queryOptions: {
      enabled: false,
    },
  })

  const searchParams = new URLSearchParams(location.search)
  const isWorker = searchParams.get('worker') === 'true'

  const successRestorePasswordMessage = (
    <Typography variant="body2" size={10}>
      <b>Recuperación de contraseña</b>
      <br />
      Si estás registrado en Aleluya, recibirás un mensaje para
      <br /> restablecer tu contraseña. Recuerda revisar tu{' '}
      <b>
        bandeja de <br />
        promociones y spam.
      </b>
    </Typography>
  )

  const handleSubmit = ({ email }, form) => {
    usersMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'restorePassword',
        email,
      },
      {
        onSuccess: () => {
          showSuccessMessage(successRestorePasswordMessage)
          form.resetForm({})
        },
      }
    )
  }

  return (
    <MainContainer>
      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(1),
          marginBottom: spacing(4),
        })}
      >
        <Typography variant="h3">Restaurar contraseña</Typography>
        <Typography
          variant="body1"
          color="white.dark"
          sx={{ textWrap: 'pretty' }}
        >
          ¡No te preocupes! Si olvidaste tu contraseña simplemente ingresa el
          correo electrónico y te enviaremos las instrucciones para
          restablecerla.{' '}
          <strong>Recuerda revisar tu bandeja de promociones y spam.</strong>
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {() => {
          return (
            <Box sx={{ width: '100%' }}>
              <Form>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    margin: theme.spacing(1, 0),
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      marginBottom: theme.spacing(4),
                    })}
                  >
                    <FormField
                      type="email"
                      name="email"
                      label="Tu correo electrónico"
                      placeholder="Ingresa tu correo electrónico"
                      autoComplete="email"
                      optional={false}
                    />
                  </Box>
                  <Button
                    type="submit"
                    loading={usersMutation.isLoading}
                    sx={(theme) => ({
                      width: '100%',
                      marginBottom: theme.spacing(4),
                    })}
                  >
                    Restaurar contraseña
                  </Button>
                </Box>
              </Form>
              <Link
                to={isWorker ? `${LOGIN}?worker=true` : LOGIN}
                sx={{
                  display: 'flex',
                  fontSize: '0.875rem',
                  justifyContent: 'center',
                }}
              >
                Volver al inicio de sesión
              </Link>
              {!isWorker ? (
                <Box
                  sx={({ spacing }) => ({
                    marginTop: spacing(1),
                    textAlign: 'center',
                  })}
                >
                  <LinkToSignup />
                </Box>
              ) : null}
            </Box>
          )
        }}
      </Formik>
    </MainContainer>
  )
}

export default PasswordForgot
