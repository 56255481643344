import {
  Link,
  matchPath,
  resolvePath,
  useLocation,
  useMatch,
  useResolvedPath,
} from 'react-router-dom'

import {
  Chip,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha,
} from '@mui/material'

import Icon from 'components/UI/Icon'

import { isArusDist } from 'utils/auth'

const useActiveNestedRoute = (routes = []) => {
  const { pathname } = useLocation()

  return routes
    .map((route) => {
      const resolvedPath = resolvePath(route)
      const matchedPath = matchPath(resolvedPath.pathname, pathname)

      return matchedPath
    })
    .filter(Boolean)
}

const NavigationOption = ({
  icon,
  text,
  to,
  pathsToHighlight = [],
  excludedPaths = [],
  hidden = false,
  onClick,
  groupDivider = false,
  target = null,
  badge,
}) => {
  const resolvedPath = useResolvedPath(to)
  const matchedPath = useMatch({ path: resolvedPath.pathname, end: true })
  const activeNestedRoutes = useActiveNestedRoute(pathsToHighlight).filter(
    (route) => !excludedPaths.includes(route.pathnameBase)
  )

  const isActive =
    (Boolean(matchedPath) && to !== null) || activeNestedRoutes.length > 0

  if (hidden) return null

  return (
    <>
      <ListItemButton
        component={Link}
        to={to}
        onClick={onClick}
        target={target}
        disableGutters
        sx={(theme) => ({
          '&:hover': {
            backgroundColor:
              theme.palette.primary[200] ??
              alpha(theme.palette.primary.light, 0.3),
          },
          '&.Mui-focusVisible': {
            backgroundColor:
              theme.palette.primary[100] ??
              alpha(theme.palette.primary.light, 0.3),
          },
          minHeight: '3rem',
          ...(isActive && {
            backgroundColor:
              theme.palette.primary[100] ??
              alpha(theme.palette.primary.light, 0.3),
            '&::before': {
              content: '""',
              height: '2.15rem',
              width: '0.33rem',
              borderRadius: '0 0.5rem 0.5rem 0',
              backgroundColor: theme.palette.primary.dark,
              position: 'absolute',
              left: 2,
              transform: 'translate(-50%)',
            },
            '&.Mui-disabled': {
              opacity: 1,
            },
          }),
          ...(isArusDist() &&
            isActive && {
              '&:hover': {
                backgroundColor: theme.palette.white.main,
              },
              '& .MuiListItemIcon-root': {
                color: theme.palette.primary.dark,
              },
            }),
        })}
      >
        <ListItemIcon
          sx={(theme) => ({
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(1),
          })}
        >
          <Icon
            name={icon}
            sx={(theme) => ({
              color: isActive ? theme.palette.primary.dark : 'inherit',
              fontSize: '1.5rem',
            })}
          />
        </ListItemIcon>
        <ListItemText
          disableTypography
          sx={(theme) => ({
            '&.MuiListItemText-root': {
              maxWidth: 'fit-content',
              margin: 0,
            },
            color: theme.palette.black.main,
            ...theme.typography.h6,
            ...(isActive && {
              color: theme.palette.primary.dark,
              ...theme.typography.lead1,
            }),
          })}
        >
          {text}
        </ListItemText>
        {typeof badge === 'string' && badge ? (
          <Chip
            sx={(theme) => ({
              marginX: theme.spacing(0.8),
              backgroundColor: alpha(theme.palette.info.main, 0.3),
              color: theme.palette.info.dark,
            })}
            label={badge}
            size="small"
          />
        ) : null}
      </ListItemButton>
      {groupDivider ? (
        <Divider
          sx={(theme) => ({
            margin: theme.spacing(2, 0),
            border: `1px dashed ${theme.palette.info.light}`,
          })}
        />
      ) : null}
    </>
  )
}

export default NavigationOption
