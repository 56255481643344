import cardValidator from 'card-validator'
import { useFormikContext } from 'formik'
import debounce from 'lodash/debounce'
import { useCallback, useContext, useMemo, useRef } from 'react'
import * as yup from 'yup'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
  alpha,
} from '@mui/material'

import Icon from 'components/UI/Icon'

import { removeSuffix } from 'utils/general'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import { EMAIL_SUPPORT } from 'config/organization'

import { PLANS_BY_CODED_NAME } from '../helpers'
import { SubscriptionViewContext } from './Index'

export const getDetailCard = (plansData, themeInstance) => {
  const description = {
    premium_per_worker_plan:
      'Liquida tu nómina y seguridad social en segundos de forma automática y sin errores.',
    premium_hr_per_worker_plan:
      'Nómina y Recursos Humanos en un solo lugar. Gestiona las personas de tu equipo de la forma más eficiente.',
    pro_per_worker_plan:
      'Nómina y Recursos Humanos en un solo lugar. Gestiona las personas de tu equipo de la forma más eficiente.',
  }

  const color = {
    premium_per_worker_plan: 'complementary1.dark',
    premium_hr_per_worker_plan: 'primary.dark',
    pro_per_worker_plan: 'info.dark',
  }

  const adminDescription = {
    premium_per_worker_plan: (
      <span>
        Ilimitados <br /> Soporte por chat en línea y en menos de una hora.
      </span>
    ),
    premium_hr_per_worker_plan: (
      <span>
        Ilimitados <br /> Soporte por chat en línea y en menos de una hora.
      </span>
    ),
    pro_per_worker_plan: (
      <span>
        Ilimitados <br />
        Ejecutivo de cuenta y soporte prioritario.
      </span>
    ),
  }

  const icon = {
    premium_per_worker_plan: (
      <Icon
        name="store"
        fontSize="large"
        sx={(theme) => ({
          color: theme.palette.complementary1.dark,
        })}
      />
    ),
    premium_hr_per_worker_plan: (
      <Icon
        name="building"
        fontSize="large"
        sx={(theme) => ({
          color: theme.palette.primary.dark,
        })}
      />
    ),
    pro_per_worker_plan: (
      <Icon
        name="building-2"
        fontSize="large"
        sx={(theme) => ({
          color: theme.palette.info.dark,
        })}
      />
    ),
  }

  const wrapperColor = {
    premium_per_worker_plan: themeInstance.palette.complementary1.dark,
    premium_hr_per_worker_plan: themeInstance.palette.primary.dark,
    pro_per_worker_plan: themeInstance.palette.info.dark,
  }

  const chipBackgroundColor = {
    premium_per_worker_plan: alpha(
      themeInstance.palette.complementary1.main,
      0.2
    ),
    premium_hr_per_worker_plan: alpha(themeInstance.palette.primary.main, 0.2),
    pro_per_worker_plan: alpha(themeInstance.palette.info.main, 0.2),
  }

  const data = plansData?.map((planData) => {
    const codedName = removeSuffix(planData?.coded_name, -8)
    const cardsStyles = {
      adminDescription: adminDescription[codedName],
      chipBackgroundColor: chipBackgroundColor[codedName],
      color: color[codedName],
      description: description[codedName],
      icon: icon[codedName],
      wrapperColor: wrapperColor[codedName],
    }
    return {
      planData,
      cardsStyles,
      codedName,
    }
  })

  return data
}

export const getInitialValues = ({
  subscription,
  selectedPlanId,
  company,
  modality,
}) => {
  const values = {
    modality:
      subscription?.payment_category === 'clara_payment'
        ? 'year'
        : modality || subscription?.type,
    plan: selectedPlanId,
    workers_number:
      subscription?.payment_category === 'clara_payment'
        ? 20
        : !subscription?.partner
          ? subscription?.payrolls_size || 1
          : 1,
    isPartner: subscription?.partner || false,
    payment_method: 'credit_card',
    credit_card_form: {
      payer_name: null,
      card_number: null,
      expiration_date: null,
      security_code: null,
    },
    automatic_debit_form: {
      bank: null,
      account_type: null,
      account_number: null,
      document_type: null,
      id_number: null,
    },
    pse_form: {
      bank: null,
      client_type: '',
    },
    company_form: {
      name: company?.name,
      email: company?.email,
      phone: company?.phone,
      document_type: company?.document_type,
      id_number: company?.id_number,
    },
  }

  return values
}

const creditCardFormSchema = yup
  .object()
  .shape({
    payer_name: yup.string().nullable().trim(),
    card_number: yup.number().nullable(),
    expiration_date: yup.string().nullable().trim(),
    security_code: yup.string().nullable().trim(),
  })
  .when('payment_method', {
    is: (paymentMethod) => paymentMethod === 'credit_card',
    then: (schema) =>
      schema.shape({
        payer_name: yup.string().trim().nullable().required(),
        card_number: yup
          .number()
          .nullable()
          .required()
          .test(
            'test-number',
            'Número de tarjeta de crédito inválido',
            (value) => cardValidator.number(value).isValid
          ),
        expiration_date: yup
          .string()
          .nullable()
          .required()
          .test(
            'test-expiration-year',
            'Debe ser una fecha válida',
            (value) => {
              return cardValidator.expirationDate(value).isValid
            }
          ),
        security_code: yup
          .string()
          .nullable()
          .required()
          .test(
            'test-number',
            'Código de seguridad inválido',
            function (value) {
              const type = cardValidator.number(this.parent.card_number).card
                ?.type
              return cardValidator.cvv(
                `${value}`,
                type === 'american-express' ? 4 : 3
              ).isValid
            }
          ),
      }),
  })

const automaticDebitFormSchema = yup
  .object()
  .shape({
    document_type: yup.string().nullable(),
    id_number: yup.string().nullable(),
    bank: yup.object().nullable(),
    account_type: yup.string().nullable(),
    account_number: yup.string().nullable(),
  })
  .when('payment_method', {
    is: (paymentMethod) => paymentMethod === 'automatic_debit',
    then: (schema) =>
      schema.shape({
        document_type: yup.string().nullable().required(),
        id_number: yup.string().nullable().required(),
        bank: yup.object().nullable().required(),
        account_type: yup.string().nullable().required(),
        account_number: yup.string().nullable().required(),
      }),
  })

const pseFormSchema = yup
  .object()
  .shape({
    client_type: yup.string().nullable(),
    bank: yup.object().nullable(),
  })
  .when('payment_method', {
    is: (paymentMethod) => paymentMethod === 'pse',
    then: (schema) =>
      schema.shape({
        client_type: yup.string().nullable().required(),
        bank: yup.object().nullable().required(),
      }),
  })

const companyFormSchema = yup.object({
  name: yup.string().nullable().required(),
  email: yup.string().nullable().required(),
  phone: yup.number().nullable().required(),
  document_type: yup.string().nullable().required(),
  id_number: yup.string().nullable().required(),
})

export const getValidationSchema = () =>
  yup.object({
    modality: yup.string().nullable(),
    plan: yup.string().nullable(),
    workers_number: yup.number().when(['isPartner'], {
      is: (isPartner) => !isPartner,
      then: yup
        .number()
        .nullable()
        .required('Debes ingresar la cantidad de personas'),
      otherwise: yup.number().nullable(),
    }),
    payment_method: yup.string().nullable().required(),
    credit_card_form: creditCardFormSchema,
    automatic_debit_form: automaticDebitFormSchema,
    pse_form: pseFormSchema,
    company_form: companyFormSchema,
  })

const debouncedHandler = debounce(
  (values, callback, handler) => handler(values, callback),
  800
)

export const useCalculateSubscription = () => {
  const { subscriptionMutation } = useSubscriptionService({
    mutationOptions: {
      mutationKey: 'calculateSubscriptionPrice',
    },
  })
  const { setFieldValue } = useFormikContext()
  const { subscription } = useContext(SubscriptionViewContext)
  const isClaraPayment = subscription?.payment_category === 'clara_payment'

  const calculateSubscription = useCallback(
    (values, callback) => {
      if (isClaraPayment && Number(values?.extraWorkers) < 20) {
        setFieldValue('workers_number', 20)
        return
      }

      subscriptionMutation.mutate(
        {
          mutationMethod: 'GET',
          ...values,
        },
        {
          onSuccess: ({ data }) => {
            callback(data)
          },
        }
      )
    },
    [isClaraPayment, setFieldValue, subscriptionMutation]
  )

  const calculateSubscriptionFromInput = useMemo(
    () => (values, callback) =>
      debouncedHandler(values, callback, calculateSubscription),
    [calculateSubscription]
  )

  return {
    calculateSubscription,
    calculateSubscriptionFromInput,
  }
}

export const useCalculateExtraWorkers = (getProratedPaymentValue) => {
  const { subscriptionMutation } = useSubscriptionService({
    mutationOptions: {
      mutationKey: 'calculateExtraWorkers',
    },
  })

  const calculateSubscription = useCallback(
    (
      {
        values,
        newPlanId,
        subscriptionType,
        workersNumber,
        currentSubscriptionType,
        currentPlanPayment,
      },
      callback
    ) => {
      const getMutationKey = () => {
        if (getProratedPaymentValue === undefined) return undefined
        if (getProratedPaymentValue) return 'getProratedPaymentValue'
        return 'getFullPaymentValue'
      }
      if (!values && getProratedPaymentValue === undefined) return
      subscriptionMutation.mutate(
        {
          mutationMethod: 'GET',
          extraWorkers: values,
          newPlanId,
          subscriptionType,
          mutationKey: getMutationKey(),
          workersNumber,
          actionType:
            currentSubscriptionType === 'month' && !currentPlanPayment
              ? 'upgrade_info'
              : undefined,
        },
        {
          onSuccess: ({ data }) => {
            callback(data)
          },
        }
      )
    },
    [getProratedPaymentValue, subscriptionMutation]
  )

  const calculateSubscriptionFromInput = useMemo(
    () => (values, callback) => {
      debouncedHandler(values, callback, calculateSubscription)
    },
    [calculateSubscription]
  )

  return {
    calculateSubscriptionFromInput,
  }
}

export const useSelectPlan = () => {
  const { setFieldValue } = useFormikContext()
  const firstInteraction = useRef(false)
  const { selectedPlan, onSelectPlan, modality } = useContext(
    SubscriptionViewContext
  )
  const { calculateSubscriptionFromInput } = useCalculateSubscription()

  const selectBasePlan = useCallback(
    (plan) => {
      onSelectPlan({ ...plan, payment_type: modality })
    },
    [onSelectPlan, modality]
  )

  const triggerCalculateSubscription = (workersNumber) => {
    if (Number(workersNumber)) {
      calculateSubscriptionFromInput(
        {
          planId: selectedPlan?.id,
          extraWorkers: workersNumber,
        },
        selectBasePlan
      )
    }
  }

  const onChangeWorkersNumber = (value) => {
    const { target: { value: inputValue } = {} } = value
    const currentValue = ['string', 'number'].includes(typeof value)
      ? value
      : inputValue

    if (!firstInteraction.current) {
      firstInteraction.current = true
    }

    const positiveValue =
      selectedPlan.coded_name ===
        PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan && inputValue >= 3
        ? 3
        : Math.abs(currentValue)

    setFieldValue('workers_number', positiveValue)
    triggerCalculateSubscription(positiveValue)
  }

  return {
    onChangeWorkersNumber,
    firstInteraction: firstInteraction.current,
  }
}

export const getPaymentData = ({
  values,
  paymentMethod,
  planId,
  subscriptionType,
  isPartner,
  extraWorkers,
  subscriptionChange,
  newSubscriptionType,
  currentPlanPayment,
  isPremiumExpiredSubscription,
  activeWorkers,
  isEditingPaymentMethod,
  isCurrentMethodPayment,
  isFromSubscriptionPaymentView,
  isFromExtraWorkersModal,
}) => {
  const paymentData = {}

  const {
    automatic_debit_form,
    pse_form,
    credit_card_form,
    workers_number: workersNumber,
  } = values

  const { client_type: clientType, bank: pseBank } = pse_form || {}

  const {
    expiration_date: expirationDate,
    card_number: cardNumber,
    security_code: securityCode,
    payer_name: payerName,
  } = credit_card_form || {}

  const {
    document_type: documentType,
    id_number: idNumber,
    account_type: accountType,
    account_number: accountNumber,
    bank: adBank,
  } = automatic_debit_form || {}

  // Premium expired can pay from Subscription Payment View or Payment Modal
  // So values can come from different sources
  paymentData.payment_type = isPremiumExpiredSubscription
    ? newSubscriptionType || values?.modality
    : values?.modality || subscriptionType

  paymentData.paid_workers = isFromSubscriptionPaymentView
    ? workersNumber
    : isPremiumExpiredSubscription || currentPlanPayment
      ? activeWorkers + extraWorkers
      : undefined

  if (isEditingPaymentMethod) paymentData.action = 'update'

  if (isCurrentMethodPayment) paymentData.action = 'reuse'

  if (paymentMethod === 'credit_card') {
    paymentData.card_number = cardNumber
    paymentData.security_code = securityCode
    paymentData.payer_name = payerName
    paymentData.expiration_date = expirationDate
      ? `${expirationDate?.substring(2)}/${expirationDate?.substring(0, 2)}`
      : undefined // MMYYYY => YYYY/MM
  }

  if (paymentMethod === 'pse') {
    paymentData.bank = pseBank?.id
    paymentData.client_type = clientType
  }

  if (paymentMethod === 'automatic_debit') {
    paymentData.document_type = documentType
    paymentData.id_number = idNumber
    paymentData.account_type = accountType
    paymentData.account_number = accountNumber
    paymentData.bank = adBank?.id
  }

  if (
    !isPartner &&
    (!subscriptionChange || currentPlanPayment || isPremiumExpiredSubscription)
  ) {
    paymentData.plan_id = planId
  }

  if (isPartner) {
    paymentData.payment_type = 'month'
  }

  // In these cases, not extra workers but paid workers
  if (
    (!isFromSubscriptionPaymentView &&
      !currentPlanPayment &&
      !isPremiumExpiredSubscription) ||
    isFromExtraWorkersModal
  ) {
    paymentData.extra_workers_number = extraWorkers
  }

  if (
    subscriptionChange &&
    !currentPlanPayment &&
    !isPremiumExpiredSubscription
  ) {
    paymentData.subscription_to_pay = {
      subscription_type: newSubscriptionType,
      plan_id: planId,
    }
  }

  return paymentData
}

const questions = [
  {
    title: '¿Cómo funciona la prueba de 15 días?',
    description:
      'Podrás utilizar todas las funcionalidades de Aleluya durante 15 días, sin necesidad de registrar una tarjeta de crédito. Una vez finalizado, podrás seleccionar tu plan y escoger tú método de pago.',
  },
  {
    title: '¿Debo firmar alguna cláusula de permanencia mínima?',
    description:
      '¡No! Con Aleluya, puedes cancelar tu suscripción en el momento que desees.',
  },
  {
    title: '¿Puedo cambiar de plan en cualquier momento?',
    description: (
      <>
        ¡Si! Solo tienes que dar clic en <b>Cambiar plan</b> y seleccionar el
        nuevo plan. Si tienes dudas puedes escribirnos a{' '}
        <Button
          variant="text"
          component="a"
          href={`mailto:${EMAIL_SUPPORT}`}
          sx={{ color: 'info.main', fontSize: '1rem' }}
        >
          soporte@aleluya.com.
        </Button>
      </>
    ),
  },
  {
    title: '¿Me van a cobrar por agregar usuarios administradores?',
    description:
      '¡No! En Aleluya puedes agregar los usuarios con rol administrador o contador que necesites y sin ningún costo adicional.',
  },
  {
    title:
      '¿Qué métodos de pago puedo utilizar para pagar mi suscripción en Aleluya?',
    description: (
      <>
        Los medios de pago que actualmente Aleluya tiene habilitados para pagar
        la suscripción por primera vez son:
        <li>Tarjeta Débito.</li>
        <li>Tarjeta Crédito.</li>
        Se permitirá el uso de PSE solamente para pagos de anualidades.
      </>
    ),
  },
  {
    title: '¿Cómo cobraran mi suscripción en Aleluya?',
    description:
      'Renovamos tu suscripción de forma automática cada mes o cada año, según el plan que tú selecciones.',
  },
  {
    title: '¿Qué costos extra podría tener con Aleluya?',
    description: (
      <>
        Si estás en el plan Solo Nómina podrás pagar por nuestros complementos
        de nómina, los cuales son:
        <br />
        afiliaciones a seguridad social, firma electrónica de documentos y
        validación de antecedentes.
      </>
    ),
  },
  {
    title:
      '¿Aleluya me cobra por documentos de nómina electrónica emitidos a la DIAN?',
    description:
      '¡No! Aleluya no te cobra por documento emitido e incluso tampoco por las notas de ajuste.',
  },
  {
    title:
      'Al hacer el pago de mi suscripción, ¿Se genera la factura electrónica?',
    description:
      'Al realizar la activación de tu suscripción en Aleluya, te generamos la factura electrónica de manera automática y así mismo es enviada a tu correo electrónico.',
  },
  {
    title: '¿Qué frecuencias de pago puedo manejar en Aleluya?',
    description: (
      <>
        Aleluya solo funciona para empresas que realizan pagos de nómina de
        forma quincenal o mensual.
        <br />
        Actualmente, no manejamos frecuencias de pago semanal, decadal o
        catorcenal.
      </>
    ),
  },
  {
    title:
      '¿Qué tipos de contratos puedo manejar para la liquidación de nómina en Aleluya?',
    description: (
      <>
        Actualmente, Aleluya maneja los siguientes tipos de contratos:
        <li>Empleado</li>
        <li>Pensionado</li>
        <li>Contratista (Prestación de servicios).</li>
        <li>Aprendiz SENA etapa Lectiva.</li>
        <li>Aprendiz SENA etapa Productiva.</li>
        <li>Estudiante Universitario (Aprendiz)</li>
        <li>Estudiante (Régimen especial Ley 789 de 2002)</li>
        <li>Estudiante Pasantía (Decreto 055 de 2015)</li>
        <li>Tiempo Parcial</li>
        Además, estos pueden ser a término fijo, indefinido o por obra o labor,
        según aplique acorde con la naturaleza del contrato
      </>
    ),
  },
  {
    title: '¿Aleluya cuenta con un módulo de facturación?',
    description:
      'En Aleluya no tenemos un módulo de facturación. No somos un software contable o de facturación. Somos el mejor software de nómina de Colombia, punto.',
  },
]

export const FrequentQuestions = ({ forwardedRef, filterIndexes = [] }) => {
  return (
    <Box ref={forwardedRef}>
      <Typography
        variant="h4"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
          color: 'black.main',
        })}
      >
        Preguntas frecuentes
      </Typography>

      <Grid
        container
        spacing={3}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            '& > Box': {
              maxWidth: '100%',
            },
          },
        })}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              borderRadius: '1.5rem',
              overflow: 'hidden',
            }}
          >
            {questions
              ?.filter(
                (_, questionIndex) => !filterIndexes.includes(questionIndex)
              )
              ?.map((question) => (
                <Accordion key={question.title}>
                  <AccordionSummary
                    expandIcon={
                      <Icon name="chevron-down" sx={{ fontSize: '1.5rem' }} />
                    }
                  >
                    <Typography>{question.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{question.description}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
