import { Box } from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Page from 'components/UI/Page/Page'

import { cryEmoji, flexedBiceps, worldMap } from 'utils/emojis'

import womanMotorcycle from 'assets/images/views/common/motorcycle-woman.png'

import ErrorPageAtom from './Atoms/ErrorPage'

const RouteNotFound = () => {
  return (
    <Page
      documentTitle="Página no encontrada"
      rootSx={{
        height: '100%',
        '::after': {
          display: 'none',
        },
      }}
      contentSx={{
        height: '100%',
        marginBottom: '0 !important',
      }}
    >
      <ErrorPageAtom
        title={
          <>
            ¡Ups! Lo sentimos, esta página ya no existe{' '}
            <Emoji code={cryEmoji} />
          </>
        }
        description={
          <>
            Parece que estás un poco perdido <Emoji code={worldMap} />,
            probablemente la página que buscas fue movida o eliminada. Pero ¡no
            te preocupes!, estamos aquí para ayudarte a encontrar lo que
            necesitas. <Emoji code={flexedBiceps} />
          </>
        }
        image={
          <Box
            component="img"
            src={womanMotorcycle}
            alt=""
            width={320}
            height={328}
          />
        }
        sx={{
          gridColumn: '1 / -1',
        }}
      />
    </Page>
  )
}

export default RouteNotFound
