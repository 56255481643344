import {
  Box,
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
} from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'

import { redCrossMark } from 'utils/emojis'

import Saly from 'assets/images/views/common/saly.png'

export function useConfirmOldPlansCancellation() {
  const modals = useModals()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const handleClose = () => {
    modals.closeModal('confirmationCancellationOldPlans')
  }

  const openConfirmOldPlansCancellation = ({
    handleOpenCancelSubscriptionModal,
  }) => {
    modals.openModal({
      id: 'confirmationCancellationOldPlans',
      content: (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              mobile: '1fr',
              tablet: '17rem 1fr',
            },
          }}
        >
          {!isMobile ? (
            <>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.accent1.main,
                  width: '27.75rem',
                  height: '27.75rem',
                  borderRadius: '100%',
                  position: 'absolute',
                  left: theme.spacing(-25),
                  top: theme.spacing(-11.25),
                })}
              />
              <Box
                component="img"
                alt="Personaje caminando"
                src={Saly}
                width={256}
                height={256}
                sx={(theme) => ({
                  zIndex: 1,
                  objectFit: 'contain',
                  marginLeft: theme.spacing(-2.5),
                })}
              />
            </>
          ) : null}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(2),
              })}
            >
              <Typography variant="h4">
                ¿Estás seguro de cancelar tu suscripción?
                <Emoji code={redCrossMark} />
              </Typography>
              <ButtonBase
                onClick={handleClose}
                data-cy="close-dialog-button"
                sx={(theme) => ({
                  alignSelf: 'self-start',
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
              </ButtonBase>
            </Box>

            <Typography variant="body2" color="black.dark">
              ¡Nos pone muy tristes que te vayas! Pero, antes de que lo hagas,
              queremos contarte que{' '}
              <b>
                este plan exclusivo ya no estará disponible para nuevos usuarios
              </b>
              . Si decides cancelarlo, le dirás chao a este beneficio único,
              aunque... ¡siempre podrás regresar pagando otro precio!
            </Typography>

            <Box
              sx={(theme) => ({
                marginTop: theme.spacing(3),
                display: 'grid',
                gridTemplateColumns: { mobile: '1fr', tablet: '1fr 1fr' },
                gap: theme.spacing(2),
              })}
            >
              <Button size="large" onClick={handleClose}>
                Regresar
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  handleClose()
                  handleOpenCancelSubscriptionModal()
                }}
              >
                Cancelar plan
              </Button>
            </Box>
          </Box>
        </Box>
      ),
      modalProps: {
        onCloseModal: handleClose,
        hideFooter: true,
        paperSx: {
          maxWidth: '49rem',
        },
        contentWrapperSx: {
          overflow: 'hidden',
        },
      },
    })
  }

  return { openConfirmOldPlansCancellation }
}
