import { Form, Formik } from 'formik'
import queryString from 'query-string'
import { useState } from 'react'
import { Navigate, Link as RouterLink, useLocation } from 'react-router-dom'

import { Box, Divider, Link as MuiLink, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import GoogleLoginButton from 'components/Auth/common/GoogleAuth'
import Button from 'components/UI/Button/Button'
import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import FormField from 'components/UI/Formik/FormField/Index'
import Link from 'components/UI/MaterialUI/Link'

import { isArusDist } from 'utils/auth'
import { generateRandomNumber } from 'utils/general'
import useRegistryService from 'utils/hooks/auth/registryService'
import useSessionService from 'utils/hooks/auth/session'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { LOGIN, ONBOARDING_COMPANY } from 'config/routes'

import MainContainer from '../common/MainContainer'
import LandingCustomField from './LandingCustomField'
import {
  CustomPasswordFieldError,
  getInitialValues,
  getValidationSchema,
} from './helpers'

const SignUp = () => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const { logIn } = useUser()
  const [signUpOk, setSignUpOk] = useState(false)
  const { handleError } = useErrorHandler()
  const { registryMutation } = useRegistryService()
  const { sessionMutation } = useSessionService()

  const [errors, setErrors] = useState([
    { id: 'toShort', show: true, content: '8 Caracteres Min.' },
    { id: 'containsNumber', show: true, content: '1 Dígito Min.' },
    { id: 'containsSpecialChar', show: true, content: '1 Símbolo Min.' },
    { id: 'containsLowercase', show: true, content: '1 Minúscula Min.' },
    { id: 'containsUppercase', show: true, content: '1 Mayúscula Min.' },
  ])

  if (signUpOk) {
    return <Navigate to={ONBOARDING_COMPANY()} />
  }

  const handleSubmit = async (values, form) => {
    try {
      const randomNumber = generateRandomNumber()
      const filledValues = {
        ...values,
        password: window.btoa(values.password),
        company_name: `Empresa Aleluya_${randomNumber}`,
      }

      if (isArusDist()) filledValues.distributor = 'arus'

      await registryMutation.mutateAsync(
        {
          mutationMethod: 'POST',
          registry: {
            ...queryParams,
            ...filledValues,
          },
        },
        {
          onSuccess: ({ data }) => {
            if (data.user.id) {
              Mixpanel.identify(data.user.id)

              try {
                Mixpanel.instance.people.set({
                  $email: data.user.email,
                })
              } catch (error) {
                // We don't want to break the app if Mixpanel fails
              }
            }

            Mixpanel.track(
              MIXPANEL_EVENTS.SIGNED_UP,
              {
                user_id: data.user.id,
                company_id: data.company.id,
              },
              true
            )
          },
        }
      )

      try {
        await sessionMutation.mutateAsync(
          {
            mutationMethod: 'POST',
            credentials: {
              ...queryParams,
              ...values,
            },
          },
          {
            onSuccess: ({ data }) => {
              logIn(data)
              setSignUpOk(true)
            },
          }
        )
      } catch (error) {
        handleError(error)
      }
    } catch (error) {
      handleError(error, form, {
        errorFieldMap: { id_number: 'company_id_number' },
      })
    }
  }

  const initialValues = getInitialValues(queryParams)

  const validationSchema = getValidationSchema()

  const validatePassword = (password) => {
    const newErrors = errors.map((error) => {
      switch (error.id) {
        case 'toShort':
          return { ...error, show: password.length < 8 }
        case 'toLong':
          return { ...error, show: password.length > 128 }
        case 'containsNumber':
          return { ...error, show: !/\d/.test(password) }
        case 'containsSpecialChar':
          return {
            ...error,
            show: !/[#!?@$%^&*+\-_,.;:(){}[\]|<=>/]/.test(password),
          }
        case 'containsLowercase':
          return { ...error, show: !/[a-z]/.test(password) }
        case 'containsUppercase':
          return { ...error, show: !/[A-Z]/.test(password) }
        default:
          return error
      }
    })
    setErrors(newErrors)
  }

  return (
    <MainContainer>
      <Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(2),
            gap: theme.spacing(1),
          })}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: 'left',
              width: '100%',
            }}
          >
            Crea una cuenta para tu compañía
          </Typography>
          <Typography variant="body1" color="white.dark">
            Ingresa tus datos para disfrutar 15 días gratis de la mejor
            experiencia liquidando nómina, sin tarjeta de crédito.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <GoogleLoginButton action="signup" />
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Divider
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            width: '50%',
            marginY: theme.spacing(2),
            alignSelf: 'center',
            '&::before, ::after': {
              borderColor: theme.palette.black.light,
            },
          })}
        >
          <Typography variant="h6" color="white.dark" translate="no">
            O
          </Typography>
        </Divider>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Box sx={{ width: '100%' }}>
              <Form name="signup_form">
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    margin: theme.spacing(1, 0),
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'grid',
                      gridTemplateColumns: '1fr',
                      rowGap: theme.spacing(2),
                      marginBottom: theme.spacing(4),
                    })}
                  >
                    <FormField
                      name="name"
                      label="Nombre completo"
                      placeholder="Ingresa tu nombre"
                      optional={false}
                    />
                    <LandingCustomField
                      type="email"
                      name="email"
                      label="Correo corporativo"
                      placeholder="Ingresa tu correo electrónico"
                      queryParams={queryParams}
                      optional={false}
                    />
                    <PasswordField
                      name="password"
                      label="Contraseña"
                      placeholder="Ingresa tu contraseña"
                      autoComplete="password"
                      customPasswordFieldError={
                        <CustomPasswordFieldError errors={errors} />
                      }
                      validatePassword={validatePassword}
                      optional={false}
                    />
                  </Box>
                  <Button
                    type="submit"
                    color="primary"
                    loading={isSubmitting}
                    sx={({ breakpoints, spacing }) => ({
                      width: '100%',
                      marginBottom: spacing(1),
                      marginTop: spacing(4),
                      [breakpoints.down('tablet')]: {
                        marginTop: spacing(1),
                      },
                    })}
                    data-cy="sign_up_button"
                  >
                    Crear cuenta
                  </Button>
                </Box>
              </Form>
              <Button
                component={RouterLink}
                to={`${LOGIN}?worker=true`}
                variant="outlined"
                sx={({ spacing, breakpoints }) => ({
                  width: '100%',
                  marginBottom: spacing(6),
                  [breakpoints.down('tablet')]: {
                    marginBottom: spacing(3),
                  },
                })}
              >
                Soy empleado
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant="body2"
                  color="white.dark"
                  sx={{ textAlign: 'center' }}
                >
                  ¿Tu empresa ya tiene cuenta en Aleluya?{' '}
                  <Link
                    to={{
                      pathname: LOGIN,
                      search: location.search,
                    }}
                    fontWeight={600}
                    sx={{ fontSize: '0.875rem' }}
                  >
                    Ingresa
                  </Link>
                </Typography>
              </Box>
            </Box>
          )
        }}
      </Formik>
      <Typography
        variant="body2"
        color="white.dark"
        sx={({ spacing, breakpoints }) => ({
          textAlign: 'center',
          marginTop: spacing(3),
          [breakpoints.down('tablet')]: {
            marginTop: spacing(2),
          },
        })}
      >
        Al crear tu cuenta aceptas nuestros{' '}
        <MuiLink
          href="https://aleluya.com/terminos-y-condiciones"
          target="_blank"
          sx={{
            fontWeight: 600,
            fontSize: '0.875rem',
          }}
        >
          términos y condiciones y política de tratamiento de datos
        </MuiLink>
        .
      </Typography>
    </MainContainer>
  )
}

export default SignUp
