import { formatCurrency, formatNumber, getWordSingular } from 'utils/format'

import calendar from 'assets/images/three-dimensional-icons/calendar.png'
import clock from 'assets/images/three-dimensional-icons/clock.png'
import copy from 'assets/images/three-dimensional-icons/copy.png'
import dollar from 'assets/images/three-dimensional-icons/dollar.png'
import girl from 'assets/images/three-dimensional-icons/girl.png'
import lock from 'assets/images/three-dimensional-icons/lock.png'
import locker from 'assets/images/three-dimensional-icons/locker.png'
import moneyBag from 'assets/images/three-dimensional-icons/money-bag.png'
import money from 'assets/images/three-dimensional-icons/money.png'
import plus from 'assets/images/three-dimensional-icons/plus.png'
import umbrella from 'assets/images/three-dimensional-icons/umbrella.png'

import AdditionalIncomeAction from '../../DataTable/Columns/AdditionalIncomeAction'
import CompanyOvertimeAction from '../../DataTable/Columns/CompanyOvertimeAction'
import DeductionsAction from '../../DataTable/Columns/DeductionsAction'
import HolidaysAction from '../../DataTable/Columns/HolidaysAction'
import IncapacitiesAction from '../../DataTable/Columns/IncapacitiesAction'
import LicensesAction from '../../DataTable/Columns/LicensesAction'
import LoansAction from '../../DataTable/Columns/LoansAction'
import OvertimeAction from '../../DataTable/Columns/OvertimeAction'
import PayrollRetentionAction from '../../DataTable/Columns/PayrollRetentionAction'
import SurchargesAction from '../../DataTable/Columns/SurchargesAction'
import WorkedDaysAction from '../../DataTable/Columns/WorkedDaysAction'

const transformDisplayValue = (
  value,
  { complement = '', format = formatNumber }
) => {
  if (value && format) return `${format(value)} ${complement}`

  return value
}

const getCompanyOvertimeValue = (payroll) => {
  if (
    payroll.contract_category === 'contractor' &&
    payroll.salary_category === 'per_hour'
  ) {
    return transformDisplayValue(payroll.worked_time, {
      complement: getWordSingular('horas', payroll.worked_time === 1),
    })
  }

  return payroll.details?.company_overtime?.quantity &&
    payroll.details.company_overtime.quantity !== '0.0'
    ? transformDisplayValue(payroll.details.company_overtime.quantity, {
        complement: getWordSingular(
          'horas',
          Number(payroll.details.company_overtime.quantity) === 1
        ),
      })
    : 0
}

const getNoveltiesCardConfig = (payroll) => {
  const overtimeVisible =
    ['employee', 'terminated', 'part_time_contract'].includes(
      payroll.contract_category
    ) &&
    payroll.salary_category &&
    payroll.salary_category !== 'integral_salary'
  const contractorWithPerHourSalary =
    payroll.contract_category === 'contractor' &&
    payroll.salary_category === 'per_hour'
  const otherConceptsVisible = overtimeVisible || contractorWithPerHourSalary
  const noveltiesVisible =
    payroll.contract_category && payroll.contract_category !== 'contractor'
  const holidaysVisible =
    noveltiesVisible &&
    [
      'employee',
      'student_law_789',
      'terminated',
      'part_time_contract',
    ].includes(payroll.contract_category)
  const licensesVisible =
    noveltiesVisible &&
    payroll.contract_category &&
    payroll.contract_category !== 'student_decree_055'
  const payrollRetentionVisible =
    payroll.contract_category === 'employee' ||
    payroll.contract_category === 'terminated'

  const cards = [
    {
      id: 'worked_days',
      visible: payroll.contract_category === 'part_time_contract',
      name: 'Días trabajados',
      icon: calendar,
      alt: 'Total días trabajados por la persona',
      value: transformDisplayValue(payroll.worked_time, {
        complement: getWordSingular('días', payroll.worked_time === 1),
      }),
      button: <WorkedDaysAction payroll={payroll} />,
    },
    {
      id: 'extra_hours',
      visible: overtimeVisible,
      name: 'Horas extras',
      icon: clock,
      alt: 'Total horas extras',
      value:
        payroll.details?.overtime?.quantity &&
        payroll.details.overtime.quantity !== '0.0'
          ? transformDisplayValue(payroll.details.overtime.quantity, {
              complement: getWordSingular(
                'horas',
                Number(payroll.details.overtime.quantity) === 1
              ),
            })
          : 0,
      button: <OvertimeAction payroll={payroll} />,
    },
    {
      id: 'surcharges',
      visible: overtimeVisible,
      name: 'Horas con recargo',
      icon: dollar,
      alt: 'Total horas con recargo',
      value:
        payroll.details?.surcharge?.quantity &&
        payroll.details.surcharge.quantity !== '0.0'
          ? transformDisplayValue(payroll.details.surcharge.quantity, {
              complement: getWordSingular(
                'horas',
                Number(payroll.details.surcharge.quantity) === 1
              ),
            })
          : 0,
      button: <SurchargesAction payroll={payroll} />,
    },
    {
      id: 'others',
      visible: otherConceptsVisible,
      name: contractorWithPerHourSalary
        ? 'Horas trabajadas'
        : 'Otras horas extras',
      icon: plus,
      alt: 'Otras horas extras',
      value: getCompanyOvertimeValue(payroll),
      button: <CompanyOvertimeAction payroll={payroll} />,
    },
    {
      id: 'holidays',
      visible: holidaysVisible,
      name: 'Vacaciones',
      icon: umbrella,
      alt: 'Total días de vacaciones',
      value: transformDisplayValue(payroll.details?.holidays?.quantity, {
        complement: getWordSingular(
          'días',
          payroll.details?.holidays?.quantity === 1
        ),
      }),
      button: <HolidaysAction payroll={payroll} />,
    },
    {
      id: 'incapacities',
      visible: noveltiesVisible,
      name: 'Incapacidades',
      icon: copy,
      alt: 'Total días de incapacidad',
      value: transformDisplayValue(payroll.details?.incapacities?.quantity, {
        complement: getWordSingular(
          'días',
          payroll.details?.incapacities?.quantity === 1
        ),
      }),
      button: <IncapacitiesAction payroll={payroll} />,
    },
    {
      id: 'licenses',
      visible: licensesVisible,
      name: 'Licencias',
      icon: girl,
      alt: 'Total días de licencias',
      value: transformDisplayValue(payroll.details?.licenses?.quantity, {
        complement: getWordSingular(
          'días',
          payroll.details?.licenses?.quantity === 1
        ),
      }),
      button: <LicensesAction payroll={payroll} />,
    },
    {
      id: 'additional_income',
      visible: true,
      name:
        payroll.contract_category === 'contractor'
          ? 'Ingresos'
          : 'Ingresos adicionales',
      icon: moneyBag,
      alt: 'Total ingresos adicionales',
      value: transformDisplayValue(payroll.details?.additional_income?.value, {
        format: formatCurrency,
      }),
      button: <AdditionalIncomeAction payroll={payroll} />,
    },
    {
      id: 'deductions',
      visible: true,
      name: 'Deducciones',
      icon: lock,
      alt: 'Total dinero en deducciones',
      value: transformDisplayValue(payroll.details?.deductions?.value, {
        format: formatCurrency,
      }),
      button: <DeductionsAction payroll={payroll} />,
    },
    {
      id: 'loans',
      visible: true,
      name: 'Préstamos',
      icon: money,
      alt: 'Total dinero en préstamos',
      value: transformDisplayValue(payroll.details?.loans?.value, {
        format: formatCurrency,
      }),
      button: <LoansAction payroll={payroll} />,
    },
    {
      id: 'payroll_retention',
      visible: payrollRetentionVisible,
      name: 'Retefuente',
      icon: locker,
      alt: 'Total retención en la fuente',
      value: transformDisplayValue(payroll.details?.payroll_retention?.value, {
        format: formatCurrency,
      }),
      button: <PayrollRetentionAction payroll={payroll} />,
    },
  ]

  return cards
}

export default getNoveltiesCardConfig
