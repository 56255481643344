import { useLocation } from 'react-router-dom'
import * as yup from 'yup'

import { Box, Chip, Typography, alpha } from '@mui/material'

import FinancialCoaching from 'components/Company/FinancialWellness/FinancialCoaching'
import FinancialWellnessIndicators from 'components/Company/FinancialWellness/FinancialWellnessIndicators'
import GeneralDiagnosis from 'components/Company/FinancialWellness/GeneralDiagnosis'
import Entities from 'components/Company/Form/Fields/Credentials/Entities'
import { useOrganizerDisabilityClaimsModals } from 'components/Organizer/HolisticPayroll/Commons/useOrganizerDisabilityClaimsModals'
import FilterButton from 'components/UI/Button/FilterButton'
import Emoji from 'components/UI/Emoji'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'
import Tooltip from 'components/UI/Tooltip'
import FinancialCoachingSessions from 'components/Worker/FinancialWellness/FinancialCoachingSessions'
import FinancialWellnessCalculator from 'components/Worker/FinancialWellness/FinancialWellnessCalculator'
import Summary from 'components/Worker/FinancialWellness/Summary'
import { ENTITY_LABELS } from 'components/Worker/WorkersOnboarding/SSAffiliation/InProcessModal/helpers'
import useUpdateCredentialsModal from 'components/Worker/WorkersOnboarding/SSAffiliation/useUpdateCredentialsModal'

import { isOrganizer } from 'utils/auth'
import { formatDisplayDateString } from 'utils/dateTime'
import {
  greenHeart,
  sunglassesFace,
  waterWave,
  winkingFace,
} from 'utils/emojis'
import { formatCurrency } from 'utils/format'

import calculator from 'assets/images/three-dimensional-icons/calculator.png'
import calendar from 'assets/images/three-dimensional-icons/calendar.png'
import clock from 'assets/images/three-dimensional-icons/clock.png'
import copy from 'assets/images/three-dimensional-icons/copy.png'
import explorer from 'assets/images/three-dimensional-icons/explorer.png'
import fileText from 'assets/images/three-dimensional-icons/file-text.png'
import lock from 'assets/images/three-dimensional-icons/lock.png'
import locker from 'assets/images/three-dimensional-icons/locker.png'
import moneyBag from 'assets/images/three-dimensional-icons/money-bag.png'
import money from 'assets/images/three-dimensional-icons/money.png'
import wallet from 'assets/images/three-dimensional-icons/wallet.png'
import DialogBlackShadow from 'assets/images/views/holisticPayroll/dialog_black_shadow.svg'
import DialogFrameImg from 'assets/images/views/holisticPayroll/dialog_frame.svg'
import WhiteDialog from 'assets/images/views/holisticPayroll/white_dialog.svg'

import { WORKER_SHOW } from 'config/routes'

import useDisabilityClaimsModals from './Commons/useDisabilityClaimsModals'
import DisabilityClaimsDetailsTable from './DisabilityClaimsDetailsTable'

export const claimsSectionCardInfo = [
  {
    id: 'upload_disabilities',
    number: 1,
    icon: copy,
    alt: 'Sube los archivos de las incapacidades',
    title: 'Carga tus incapacidades',
    numberBackgroundColor: 'complementary1.light',
  },
  {
    id: 'charge_disabilities',
    number: 2,
    icon: moneyBag,
    alt: 'Nosotros cobramos el dinero de tus incapacidades',
    title: 'Cobramos tus incapacidades',
    numberBackgroundColor: 'accent2.main',
  },
  {
    id: 'receive_money',
    number: 3,
    icon: money,
    alt: 'Recibe el dinero de las incapacidades en tu cuenta',
    title: 'La platica en tu cuenta bancaria',
    numberBackgroundColor: 'warning.light',
  },
]

export const coachingSectionCardsInfo = [
  {
    id: 'schedule_coaching',
    number: 1,
    icon: 'appointment-schedule',
    title: 'Solicita una cita gratuita con tu Coach',
    numberBackgroundColor: 'info.dark',
  },
  {
    id: 'receive_plan',
    number: 2,
    icon: 'people-handshake',
    title: 'Recibe un plan personalizado',
    numberBackgroundColor: 'accent2.main',
  },
  {
    id: 'financial_freedom',
    number: 3,
    icon: 'yoga-female',
    title: 'Comienza a vivir una vida libre de estrés financiero',
    numberBackgroundColor: 'primary.dark',
  },
]

export const getCompanyDisabilityClaimsDetailCards = ({ totalCardsValue }) => {
  return [
    {
      id: 'total_to_claim',
      icon: wallet,
      alt: 'Billete total a reclamar',
      tooltipText: null,
      title: 'Total que puedes reclamar',
      value: totalCardsValue?.total_value_to_claim || 0,
      iconBackground: 'info.light',
      valueColor: 'info.main',
    },
    {
      id: 'total_on_hold',
      icon: lock,
      alt: 'Billete total congelado',
      tooltipText:
        'Estas son las reclamaciones que no hemos podido iniciar porque aún necesitas agregar tus credenciales o te falta el soporte de incapacidad.',
      title: 'Platica congelada',
      value: totalCardsValue?.total_value_on_hold || 0,
      iconBackground: 'warning.light',
      valueColor: 'warning.main',
    },
    {
      id: 'total_in_claim',
      icon: calendar,
      alt: 'Billete total en reclamación',
      tooltipText: null,
      title: 'Platica en reclamación',
      value: totalCardsValue?.total_value_in_claim || 0,
      iconBackground: 'primary.light',
      valueColor: 'primary.main',
    },
    {
      id: 'total_recovered',
      icon: locker,
      alt: 'Billete total a reclamar',
      tooltipText: null,
      title: 'Total billete recuperado',
      value: totalCardsValue?.total_value_recovered || 0,
      iconBackground: 'complementary1.light',
      valueColor: 'complementary1.main',
    },
  ]
}

export const createFakeDataPie = (sizeArray) => {
  const data = []

  for (let index = 0; index < sizeArray; index++) {
    data.push({ value: 1 })
  }
  return data
}

export const getAdminFinancialWellnessSectionsData = (dashboardData) => [
  {
    id: 'general_diagnosis',
    title: 'Diagnóstico general',
    description: (
      <>
        Dale un vistazo al bienestar financiero de las personas de tu equipo.
        Aquí te mostramos el puntaje crediticio y el indicador de bienestar
        financiero (promedio), para que sepas si el dinero fluye con
        tranquilidad o si es hora de ajustar las velas.{' '}
        <Emoji code={winkingFace} /> <Emoji code={greenHeart} />
      </>
    ),
    content: <GeneralDiagnosis fetchedData={dashboardData} />,
  },
  {
    id: 'financial_wellness_indicators',
    title: 'Indicador de Bienestar Financiero',
    description: (
      <>
        Queremos que las personas estén bien tranquis con las finanzas, agarren
        maletas y cumplan todos sus sueños. Visualiza cómo está su bienestar.{' '}
        <Emoji code={greenHeart} /> <Emoji code={waterWave} />
      </>
    ),
    content: <FinancialWellnessIndicators fetchedData={dashboardData} />,
  },
  {
    id: 'financial_coaching',
    title: 'Coaching Financiero',
    description: (
      <>
        Nada mejor que construir un futuro, pero juntos y en HD. Aquí están las
        sesiones que han tomado en tu equipo con nuestro amazing Coach
        Financiero. <Emoji code={sunglassesFace} /> <Emoji code={greenHeart} />
      </>
    ),
    content: <FinancialCoaching fetchedData={dashboardData} />,
  },
]

const HolisticPayrollTabsLabel = ({ icon, label, alt }) => {
  return (
    <Typography
      variant="span"
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1),
      })}
    >
      <Box
        sx={(theme) => ({
          borderRadius: '0.5rem',
          padding: theme.spacing(0.5),
          backgroundColor: alpha(theme.palette.primary.light, 0.4),
        })}
      >
        <Box
          component="img"
          loading="lazy"
          src={icon}
          alt={alt}
          width={32}
          height={32}
        />
      </Box>
      {label}
    </Typography>
  )
}

export const getTabsConfig = ({
  workerName,
  coachingSessions,
  financialWellnessIndicator,
  setActiveTab,
  activeTab,
  tabsView,
  activeStatus,
  setActiveStatus,
}) => {
  if (tabsView === 'worker_financial_wellness') {
    return [
      {
        label: (
          <HolisticPayrollTabsLabel
            icon={fileText}
            label="Resumen"
            alt="Tu historial de Bienestar Financiero en Aleluya"
          />
        ),
        content: (
          <Summary
            coachingSessions={coachingSessions}
            financialWellnessIndicator={financialWellnessIndicator}
            changeTab={setActiveTab}
          />
        ),
      },
      {
        label: (
          <HolisticPayrollTabsLabel
            icon={calculator}
            label="Calculadora de bienestar"
            alt="Calcula tu Bienestar Financiero con Aleluya"
          />
        ),
        content: <FinancialWellnessCalculator workerName={workerName} />,
      },
      {
        label: (
          <HolisticPayrollTabsLabel
            icon={explorer}
            label="Coach financiero"
            alt="Agéndate con tu coach de Bienestar Financiero con Aleluya"
          />
        ),
        content: <FinancialCoachingSessions workerName={workerName} />,
      },
    ]
  }

  if (tabsView === 'disability_claims') {
    return [
      {
        label: (
          <HolisticPayrollTabsLabel
            icon={clock}
            label="Pendientes"
            alt="Tus reclamaciones pendientes"
          />
        ),
        content: (
          <DisabilityClaimsDetailsTable
            activeTab={activeTab}
            activeStatus={activeStatus}
            setActiveStatus={setActiveStatus}
          />
        ),
      },
      {
        label: (
          <HolisticPayrollTabsLabel
            icon={money}
            label="Reclamadas"
            alt="Tus reclamaciones completadas"
          />
        ),
        content: (
          <DisabilityClaimsDetailsTable
            activeTab={activeTab}
            activeStatus={activeStatus}
            setActiveStatus={setActiveStatus}
          />
        ),
      },
    ]
  }
}

export function DialogFrame({ children }) {
  return (
    <Box
      sx={({ spacing }) => ({
        position: 'relative',
        display: 'flex',
        width: '16.688rem',
        height: '14.688rem',
        marginBottom: spacing(-5),
        marginLeft: spacing(1),
      })}
    >
      <Box
        component="img"
        src={DialogBlackShadow}
        alt="sombra_del_dialogo"
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          width: '100%',
          height: '100%',
        }}
      />
      <Box
        component="img"
        src={WhiteDialog}
        alt="marco_del_dialogo"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
      <Box
        sx={({ palette, spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          textAlign: 'center',
          zIndex: 1,
          padding: spacing(2.5, 2, 2, 2.5),
          gap: spacing(2),
          width: '100%',
          height: '84%',
          borderTop: `1.5rem solid ${palette.accent2.main}`,
          borderLeft: `1.5rem solid ${palette.accent2.main}`,
          marginRight: spacing(0.25),
        })}
      >
        {children}
      </Box>

      <Box
        component="img"
        src={DialogFrameImg}
        alt="marco_del_dialogo"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 2,
        }}
      />
    </Box>
  )
}

export const disabilityClaimsStatus = {
  unstarted: {
    label: 'Sin comenzar',
    backgroundColor: (theme) => alpha(theme.palette.black.light, 0.2),
    textColor: 'black.dark',
    tooltipText: null,
  },
  in_progress: {
    label: 'En proceso',
    backgroundColor: (theme) => alpha(theme.palette.complementary2.main, 0.2),
    textColor: 'complementary2.dark',
    tooltipText: 'Nuestro equipo está gestionando tu solicitud.',
  },
  no_credential: {
    label: 'Falta credencial',
    backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
    textColor: 'error.dark',
    tooltipText:
      'Tu solicitud de reclamación está pendiente hasta que agregues tus credenciales.',
  },
  no_support: {
    label: 'Falta soporte',
    backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
    textColor: 'error.dark',
    tooltipText:
      'Para continuar el proceso, es necesario que agregues el soporte de la incapacidad.',
  },
  rejected: {
    label: 'Rechazada',
    backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
    textColor: 'error.dark',
    tooltipText: 'Mira la razón del rechazo en los detalles.',
  },
  claimed: {
    label: 'Reclamada',
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
    textColor: 'primary.dark',
    tooltipText: 'Tu platica ya fue enviada a tu cuenta por la entidad.',
  },
}

export const disabilityClaimsStatusOptions = [
  { value: 'in_progress', label: 'En proceso' },
  { value: 'no_credential', label: 'Sin credenciales' },
  { value: 'no_support', label: 'Sin soporte' },
  { value: 'rejected', label: 'Rechazada' },
  { value: 'claimed', label: 'Reclamada' },
]

export const DISABILITY_CLAIMS_STATUS = {
  unstarted: 'Sin comenzar',
  in_progress: 'En proceso',
  no_credential: 'Sin credenciales',
  no_support: 'Sin soporte',
  rejected: 'Rechazada',
  claimed: 'Reclamada',
}

export const getColumnsData = (isOrganizerRoute) => {
  return [
    {
      Header: 'Fecha',
      accessor: 'created_at',
      Cell: ({ row }) => (
        <Typography>
          {formatDisplayDateString(row.original.created_at)}
        </Typography>
      ),
      customWidth: '9rem',
    },
    {
      Header: 'Nombre',
      accessor: 'worker_name',
      Cell: ({ row }) => {
        return !isOrganizerRoute ? (
          <Link
            to={WORKER_SHOW(row.original.worker_id)}
            sx={({ palette }) => ({
              textDecoration: 'none',
              color: palette.black.main,
            })}
          >
            {row.original.worker_name}
          </Link>
        ) : (
          <Typography>{row.original.worker_name}</Typography>
        )
      },
      customWidth: '11rem',
    },
    {
      Header: 'Novedad',
      accessor: 'novelty_name',
      Cell: ({ row }) => <Typography>{row.original.novelty_name}</Typography>,
      customWidth: '9rem',
    },
    {
      Header: 'Tipo entidad',
      accessor: 'institution_category',
      alignCell: 'center',
      alignHeader: 'center',
      Cell: ({ row }) => (
        <Typography>
          {ENTITY_LABELS[row.original.institution_category]}
        </Typography>
      ),
      customWidth: '7rem',
    },
    {
      Header: 'Entidad',
      accessor: 'institution_id',
      editable: ({ row }) =>
        isOrganizerRoute ||
        (!row.original.institution_name &&
          row.original.status === 'no_credential'),
      Cell: ({ row }) => (
        <Typography>{row.original.institution_name}</Typography>
      ),
      Edit: ({ rowValues }) => <Entities rowValues={rowValues} />,
      alignCell: 'center',
      alignHeader: 'center',
      customWidth: '11rem',
    },
    {
      Header: 'Fecha inicial',
      accessor: 'novelty_initial_day',
      Cell: ({ row }) => (
        <Typography>
          {formatDisplayDateString(row.original.novelty_initial_day)}
        </Typography>
      ),
      alignCell: 'center',
      alignHeader: 'center',
      customWidth: '9rem',
    },
    {
      Header: 'Fecha final',
      accessor: 'novelty_end_day',
      Cell: ({ row }) => (
        <Typography>
          {formatDisplayDateString(row.original.novelty_end_day)}
        </Typography>
      ),
      alignCell: 'center',
      alignHeader: 'center',
      customWidth: '9rem',
    },
    {
      Header: 'Días de incapacidad',
      accessor: 'novelty_days',
      alignCell: 'center',
      alignHeader: 'center',
      customWidth: '5rem',
    },
    {
      Header: 'Valor aprox.',
      accessor: 'claim_value',
      editable: ({ row }) =>
        isOrganizerRoute && row.original.status !== 'claimed',
      alignCell: 'center',
      alignHeader: 'center',
      Cell: ({ row }) => (
        <Typography>{formatCurrency(row.original.claim_value)}</Typography>
      ),
      Edit: () => <FormField name="claim_value" variant="currency" />,
      customWidth: '11rem',
    },
    {
      Header: 'Estado',
      accessor: 'status',
      alignHeader: 'center',
      alignCell: 'center',
      editable: ({ row }) =>
        isOrganizerRoute && row.original.status !== 'claimed',
      customWidth: '11.5rem',
      Cell: ({ row }) => (
        <Tooltip
          description={disabilityClaimsStatus[row.original.status].tooltipText}
          tooltipSx={({ shadows }) => ({ boxShadow: shadows[3] })}
        >
          <Chip
            label={disabilityClaimsStatus[row.original.status].label}
            sx={{
              backgroundColor:
                disabilityClaimsStatus[row.original.status].backgroundColor,
              color: disabilityClaimsStatus[row.original.status].textColor,
            }}
          />
        </Tooltip>
      ),
      Edit: () => (
        <FormField
          name="status"
          variant="select"
          options={disabilityClaimsStatusOptions}
        />
      ),
    },
  ]
}

export const useTableAction = ({
  activeStatus,
  setActiveStatus,
  activeTab,
  claimsTotals,
  claimsDetailId,
}) => {
  const location = useLocation()
  const isOrganizerRoute = location.pathname.includes('organizer')
  const { openClaimSupportModal } = useOrganizerDisabilityClaimsModals()
  const { openUpdateCredentialsModal } = useUpdateCredentialsModal()
  const {
    openUploadDisabilitySupportModal,
    openRejectedClaimModal,
    openClaimStatusModal,
  } = useDisabilityClaimsModals()

  const handleOpenDisabilityClaimModal = (claimData = {}) => {
    const { status, id, worker_name } = claimData

    if (status === 'no_support') {
      openUploadDisabilitySupportModal(claimsDetailId, id, worker_name)
    }

    if (status === 'rejected') {
      openRejectedClaimModal(claimsDetailId, id)
    }

    if (status === 'in_progress' || status === 'claimed') {
      openClaimStatusModal(claimsDetailId, id)
    }

    if (status === 'no_credential') {
      openUpdateCredentialsModal(null, 'claim', {
        claimsDetailId,
        claimNoveltyId: id,
      })
    }
  }

  const getOptions = (claimsTotals = {}) =>
    Object.keys(claimsTotals).map((claims) => {
      return {
        label: `${DISABILITY_CLAIMS_STATUS[claims.split('_total')[0]]} (${
          claimsTotals[claims]
        })`,
        value: claims.split('_total')[0],
      }
    })

  return [
    (rowData) => ({
      id: 'details',
      tooltip: 'Ver detalle',
      disabled: !rowData.institution_name && rowData.status === 'no_credential',
      icon: <Icon name="eye" />,
      hidden:
        (isOrganizer() && isOrganizerRoute) || rowData.status === 'unstarted',
      onClick: () => handleOpenDisabilityClaimModal(rowData),
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'support_document',
      tooltip: 'Ver soporte',
      icon: <Icon name="file-spreadsheet" />,
      onClick: () => openClaimSupportModal(rowData, claimsDetailId),
      hidden: !isOrganizer() || !isOrganizerRoute,
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    {
      id: 'statusFilter',
      isFreeAction: true,
      position: 'left',
      hidden: activeTab === 1,
      Component: (
        <FilterButton
          defaultLabel="Todas"
          options={getOptions(claimsTotals)}
          value={activeStatus}
          onChange={setActiveStatus}
        />
      ),
    },
  ]
}

export const getEmptyStateText = (textOption, activeTab) => {
  if (textOption === 'admin' && activeTab === 1) {
    return 'Todavía no hay reclamaciones aprobadas por aquí.'
  }

  return 'Todavía no hay reclamaciones en proceso por aquí.'
}

const baseDocumentValidation = yup
  .mixed()
  .test('isPdf', 'El documento debe ser un PDF.', (value) => {
    if (value instanceof File) {
      return value.type === 'application/pdf'
    }
    return value ? typeof value === 'string' : true
  })
  .test(
    'fileSize',
    'El archivo que intentas subir no tiene contenido o se encuentra dañado.',
    (value) => {
      if (!value) return true

      if (value instanceof File) {
        return value.size > 0
      }

      return true
    }
  )
  .required('Debes anexar este documento.')

export const disabilityClaimSupporModalValidationSchema = yup.object({
  claim_support: baseDocumentValidation,
})

export const claimsDetailTableValidationSchema = yup.object({
  institution_id: yup.string().nullable().required(),
})
