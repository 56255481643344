import { Navigate } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import SubscriptionPlans from 'components/Subscription/Plans'

import { DASHBOARD } from 'config/routes'

const View = (props) => {
  const { isAPartnerChild } = useUser()
  const { subscription, isCompanyUsing20241111Plans } = useSubscription()

  if (isAPartnerChild || !isCompanyUsing20241111Plans) {
    return <Navigate to={DASHBOARD} />
  }

  return <SubscriptionPlans subscription={subscription} {...props} />
}

export default View
