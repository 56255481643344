import { Box, Card, Typography, useTheme } from '@mui/material'

import Tooltip from 'components/UI/Tooltip'

import Icon from '../Icon'

const TotalCard = ({
  title,
  value,
  backgroundColor = 'primary',
  textColor,
  compact = false,
  icon = <Icon name="list" fontSize="small" />,
  tooltip = false,
  tooltipTitle,
  tooltipDescription,
}) => {
  const themeInstance = useTheme()

  const backgroundColorKeys = backgroundColor.split('.')
  const themeBackgroundColor = backgroundColor?.includes('.')
    ? themeInstance.palette[backgroundColorKeys[0]][backgroundColorKeys[1]]
    : themeInstance.palette[backgroundColorKeys[0]]?.main

  return (
    <Card
      sx={(theme) => ({
        padding: theme.spacing(2.125, 2.875),
        position: 'relative',
        backgroundColor: themeBackgroundColor,
        color: textColor,
        borderRadius: '1rem',
        boxShadow: 'none',
        ...(compact && {
          padding: theme.spacing(1, 2),
        }),
      })}
    >
      <Box
        sx={{
          zIndex: 2,
          position: 'inherit',
        }}
      >
        <Box
          sx={(theme) => ({
            marginBottom: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.75),
            justifyContent: 'space-between',
          })}
        >
          <Box
            sx={(theme) => ({
              alignItems: 'center',
              display: 'flex',
              gap: theme.spacing(0.75),
            })}
          >
            {icon}
            <Typography variant="body2">{title}</Typography>
          </Box>
          {tooltip ? (
            <Tooltip
              title={tooltipTitle}
              description={tooltipDescription}
              tooltipSx={(theme) => ({ boxShadow: theme.shadows[7] })}
            >
              <Box sx={{ display: 'flex' }}>
                <Icon name="info" sx={{ fontSize: '1.5rem' }} />
              </Box>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
      <Typography
        variant="h3"
        title={value || undefined}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </Typography>
    </Card>
  )
}

export default TotalCard
