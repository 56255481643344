import { Typography } from '@mui/material'

import LinkButton from 'components/UI/Button/LinkButton'

import chatWoman from 'assets/images/views/common/chat-woman.png'
import selfieWoman from 'assets/images/views/common/selfie-woman.png'

export const getInfoMessages = ({
  actionMessage,
  openSupportMenu,
  activePayment,
  isFromPaymentMethodCard,
  subscription,
}) => {
  const paymentType =
    activePayment === 'automatic_debit' ? 'cuenta' : 'tarjeta débito/crédito'

  const { subscription_payment_methods_info: paymentMethodsInfo } =
    subscription || {}

  const paymentMethodInfo = Array.isArray(paymentMethodsInfo)
    ? paymentMethodsInfo.find(
        (paymentMethod) => paymentMethod?.category === activePayment
      ) || {}
    : {}

  const paymentMethodFranchise =
    activePayment === 'automatic_debit'
      ? paymentMethodInfo.automatic_debit_bank
      : paymentMethodInfo.credit_card_brand

  const paymentMethodLastNumbers =
    paymentMethodInfo[
      `${
        activePayment === 'automatic_debit'
          ? 'automatic_debit_account'
          : 'credit_card'
      }_last_numbers`
    ]

  const configMessages = {
    invitation_to_update: {
      title: `¡Yisus! Aún falta tu método de pago`,
      buttonLabel: 'Actualizar método de pago',
      description: (
        <>
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({ marginBottom: theme.spacing(1) })}
          >
            En este momento, no tienes un método de pago asociado a tu cuenta.
            Actualiza tus datos en menos de lo que canta un gallo.
          </Typography>
          <Typography variant="body1" color="black.dark">
            Si tienes dudas, puedes contactarnos dando{' '}
            <LinkButton onClick={openSupportMenu}>clic aquí.</LinkButton>
          </Typography>
        </>
      ),
      picture: (
        <img
          loading="lazy"
          src={chatWoman}
          alt=""
          width={146}
          height={283}
          style={{ position: 'relative', left: '2rem' }}
        />
      ),
    },
    successful_update: {
      title: `¡Método de pago actualizado!`,
      buttonLabel: 'Cerrar',
      description: isFromPaymentMethodCard ? (
        <Typography variant="body1" color="black.dark">
          En adelante, la <b>{`${paymentType} ${paymentMethodFranchise}`}</b>,
          terminada en <b>{paymentMethodLastNumbers}</b>, será tu método
          principal para realizar los pagos asociados a tu suscripción.
        </Typography>
      ) : (
        <>
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({ marginBottom: theme.spacing(2.5) })}
          >
            Tu débito automático a través de una{' '}
            <Typography variant="lead1">{paymentType}</Typography> ha sido
            agregado. Ahora haremos un par de validaciones para verificar que
            hayas ingresado tus datos de forma correcta.
          </Typography>
          <Typography variant="body1" color="black.dark">
            Te estaremos notificando cuando todo este OK!.
          </Typography>
        </>
      ),
      picture: (
        <img
          loading="lazy"
          src={selfieWoman}
          alt=""
          width={158}
          height={302}
          style={{
            position: 'relative',
            left: '3rem',
          }}
        />
      ),
    },
  }

  return configMessages[actionMessage]
}
