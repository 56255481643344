import { Box, Typography } from '@mui/material'

import { PlansContainer } from 'components/Subscription/Atoms/PlanUIComponents'
import {
  getPlansFormatted,
  usePlansData,
} from 'components/Subscription/helpers'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { removeSuffix } from 'utils/general'

import PartnerSubscriptionCardsModalContent from './PartnerSubscriptionCardsModalContent'

const PartnerSubscriptionCardsModal = () => {
  const { plansData, isPlansQueryLoading } = usePlansData()

  const plansFormatted = getPlansFormatted({ plansData })

  const currentPlan = plansData?.find((plan) => plan?.current_plan)
  const currentPlanIndex = plansData.indexOf(currentPlan)

  const plansDataForPartners =
    plansFormatted.filter(
      (plan) => removeSuffix(plan.codedName, -8) !== 'pro_per_worker_plan'
    ) || []

  return isPlansQueryLoading ? (
    <LoadingBox />
  ) : (
    <>
      <PlansContainer
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          columnGap: theme.spacing(3),
          rowGap: theme.spacing(4),
          boxShadow: 'none',
          [theme.breakpoints.up('tablet')]: {
            columnGap: theme.spacing(4),
            alignContent: 'center',
            rowGap: theme.spacing(8),
          },
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns:
              plansDataForPartners.length < 2 ? '1fr' : 'repeat(2, 1fr)',
          },
          [theme.breakpoints.up('laptop')]: {
            gridTemplateColumns:
              plansDataForPartners.length < 2
                ? '1fr'
                : `repeat(${plansDataForPartners.length}, 1fr)`,
          },
          [theme.breakpoints.between('sm', 'laptop')]: {
            '& div:last-child': {
              gridColumn: '1 / -1',
              justifySelf: 'center',
            },
          },
        })}
      >
        {plansDataForPartners.map((plan, index) => {
          return (
            <PartnerSubscriptionCardsModalContent
              key={plan.id}
              planData={plan}
              selectedPlanIndex={index}
              currentPlanIndex={currentPlanIndex}
            />
          )
        })}
      </PlansContainer>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
        })}
      >
        <Typography variant="h3">Aclaración facturación Partners</Typography>
        <Typography color="black.dark">
          Realizar esta activación tendrá un costo que empezará a reflejarse en
          tu próxima facturación. Si tienes dudas o necesitas ayuda, escríbenos
          a <OrganizationEmail color="info.dark" />.
        </Typography>
      </Box>
    </>
  )
}

export default PartnerSubscriptionCardsModal
