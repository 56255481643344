import { useState } from 'react'
import { useLocation } from 'react-router-dom'

export function useWelcomeModal() {
  const location = useLocation()
  const { showWelcomeModal } = location.state || {}

  const [state, setState] = useState({
    open: showWelcomeModal,
  })

  const closeWelcomeModal = () => {
    window.history.replaceState({}, '')
    setState({ open: false })
  }

  return { welcomeModalState: state, closeWelcomeModal }
}
