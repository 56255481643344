import { Form, Formik } from 'formik'

import { useUser } from 'components/App/UserContext/useUser'
import usePeriodEditingInactiveModal from 'components/Period/Payroll/Modals/usePeriodEditingInactiveModal'
import Modal from 'components/UI/Modal/Modal'
import { getWorkerDirtyValues } from 'components/Worker/Form/helpers'
import useWorker from 'components/Worker/useWorker'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'
import { getWorkerId } from 'utils/worker'

import BasicDataSection from './Sections/BasicDataSection'
import ContractDataSection from './Sections/ContractDataSection'
import EntitiesSection from './Sections/EntitiesSection'
import JobPositionSection from './Sections/JobPositionSection'
import PaymentDataSection from './Sections/PaymentDataSection'
import validationSchema from './validationSchema'

const UpdateWorkerGeneralDataModal = ({ onCancel, state }) => {
  const { title, modalType } = state
  const { isWorker } = useUser()
  const { worker, refreshWorker } = useWorker({
    applyFormat: true,
    useCache: true,
  })
  const { workerMutation } = useWorkerService({
    queryOptions: {
      enabled: false,
    },
  })
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const { openPeriodEditingInactiveModal } = usePeriodEditingInactiveModal()

  const callbackSuccess = () => {
    refreshWorker()
    showSuccessMessage(
      isWorker
        ? 'Los datos se actualizaron exitosamente.'
        : 'La persona fue actualizada exitosamente.'
    )
    onCancel()
  }

  const callbackError = (error, form) => {
    handleError(error, form, {
      errorToNotificate: [
        { object: 'worker' },
        { object: 'id_number' },
        { object: 'email' },
        // error returned when a user update the end_date of a contract but it can't
        // be done because the deductions exceed the value to be paid to the worker
        { object: 'worker_payment' },
      ],
      errorFieldMap: { user: 'email' },
    })
  }

  const handleSubmit = async (values, form) => {
    const { dirtyWorker } = getWorkerDirtyValues(worker, values, true)

    if (
      worker.contract_detail?.contributor_subtype === values.contributor_subtype
    ) {
      delete dirtyWorker.contributor_subtype
    }

    if (!isObjectEmpty(dirtyWorker)) {
      workerMutation.mutate(
        {
          mutationMethod: 'PATCH',
          mutationKey: isWorker ? 'workerSelfUpdate' : null,
          worker: {
            ...dirtyWorker,
          },
          workerId: worker.id,
        },
        {
          onSuccess: ({ data }) => {
            callbackSuccess(values, data)

            Mixpanel.trackWorker(MIXPANEL_EVENTS.EDIT_WORKER_PROFILE_DATA_V2, {
              user_id: getUserId(),
              user_role: getUserRole(),
              company_id: getCompanyId(),
              worker_id: getWorkerId(),
            })
          },
          onError: (error) => {
            if (['0502', '0101'].includes(error.errors[0].code)) {
              openPeriodEditingInactiveModal()
            } else {
              callbackError(error, form)
            }
          },
        }
      )
    } else {
      onCancel()
    }
  }

  return (
    <Formik
      initialValues={{
        ...worker,
        contributor_subtype: worker?.contract_detail?.contributor_subtype,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema[modalType]}
      enableReinitialize
    >
      {({ handleSubmit: onSubmit }) => {
        return (
          <Modal
            header={title}
            open
            onOk={onSubmit}
            isLoading={workerMutation.isLoading}
            onCancel={onCancel}
            okText="Guardar"
            paperSx={{
              width: '100%',
              maxWidth: '45.5rem',
            }}
          >
            <Form>
              {modalType === 'contract_data' ? (
                <ContractDataSection onClose={onCancel} />
              ) : null}
              {modalType === 'entities' ? <EntitiesSection /> : null}
              {modalType === 'job_position_data' ? (
                <JobPositionSection />
              ) : null}
              {modalType === 'basic_data' ? <BasicDataSection /> : null}
              {modalType === 'payment_data' ? <PaymentDataSection /> : null}
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default UpdateWorkerGeneralDataModal
