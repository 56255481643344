import { forwardRef } from 'react'

import { CircularProgress, Button as MuiButton } from '@mui/material'

const Button = forwardRef(function ButtonForwardRef(
  { loading = false, disabled = false, children, ...rest },
  ref
) {
  return (
    <MuiButton
      ref={ref}
      disabled={disabled || loading}
      startIcon={
        loading ? (
          <span aria-hidden="true">
            <CircularProgress size={18} color="black" />
          </span>
        ) : null
      }
      {...rest}
    >
      {children}
    </MuiButton>
  )
})

export default Button
