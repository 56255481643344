import { Link as RouterLink } from 'react-router-dom'

import { Typography } from '@mui/material'

import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { getUserId, getUserRole, isWorker } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { formatDateRange } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'
import { getWorkerId } from 'utils/worker'

import * as routes from 'config/routes'

export const getColumnsData = () => {
  return [
    {
      Header: 'Periodo',
      accessor: 'initial_day',
      Cell: ({ row }) => {
        return (
          <Link
            to={
              isWorker()
                ? routes.WORKER_PAYMENT_DETAIL(row.original.payroll_id)
                : routes.PERIOD_PAYROLL_VIEW_DETAILS(row.original.payroll_id)
            }
            color="black.main"
          >
            {formatDateRange(row.original.initial_day, row.original.end_day)}
          </Link>
        )
      },
    },
    {
      Header: 'Salarios',
      accessor: 'salary',
      Cell: ({ row }) => (
        <Typography variant="h6">
          {formatCurrency(row.original.salary)}
        </Typography>
      ),
    },
    {
      Header: 'Otros ingresos',
      accessor: 'other_income',
      Cell: ({ row }) => formatCurrency(row.original.other_income),
    },
    {
      Header: 'Deduc. y Reten.',
      accessor: 'deductions_retentions',
      Cell: ({ row }) =>
        `(${formatCurrency(row.original.deductions_retentions)})`,
    },
    {
      Header: 'Pago Neto',
      accessor: 'worker_payment',
      Cell: ({ row }) => (
        <Typography variant="h6">
          {formatCurrency(row.original.worker_payment)}
        </Typography>
      ),
    },
  ]
}

export const getTableActions = ({
  handleClickNoveltyReport,
  showPayslip,
  hasPayslipsAccess,
}) => {
  const isWorkerRole = isWorker()

  return [
    (rowData) => ({
      id: 'seeCalculations',
      tooltip: 'Ver cálculos',
      icon: <Icon name="eye" />,
      buttonProps: {
        component: RouterLink,
        to: isWorkerRole
          ? routes.WORKER_PAYMENT_DETAIL(rowData.payroll_id)
          : routes.PERIOD_PAYROLL_VIEW_DETAILS(rowData.payroll_id),
        target: '_blank',
      },
      onClick: () => {
        Mixpanel.trackWorker(MIXPANEL_EVENTS.VIEW_PAYROLLS_DETAIL_V2, {
          user_id: getUserId(),
          user_role: getUserRole(),
          company_id: getCompanyId(),
          worker_id: getWorkerId(),
        })
      },
    }),
    {
      id: 'downloadPaymentsHistory',
      tooltip: 'Descargar historial de pagos',
      onClick: handleClickNoveltyReport,
      isFreeAction: true,
      icon: <Icon name="download" />,
      hidden: isWorkerRole && !hasPayslipsAccess,
    },
    (rowData) => ({
      id: 'downloadPayment',
      tooltip: 'Descargar colilla',
      onClick: () => showPayslip(rowData),
      icon: <Icon name="download" />,
      hidden: isWorkerRole && !hasPayslipsAccess,
    }),
  ]
}
