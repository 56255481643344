import { useFormikContext } from 'formik'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Paper, Typography, alpha } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'
import Table from 'components/UI/Table/Table'

import { formatCurrency } from 'utils/format'
import useTerminationService from 'utils/hooks/worker/terminationService'

import terminationService from 'services/worker/terminationService'

import * as routes from 'config/routes'

import { getColumnData, getTableData } from './helpers'

const WorkerContractTerminationFormSummary = ({
  dirtyTermination,
  handleCancel,
  isEditing,
  isSaving,
  scrollToCauseInput,
}) => {
  const { values, handleSubmit, setFieldTouched } = useFormikContext()
  const { enqueueDownload } = useDownloadManager()
  const { terminationMutation } = useTerminationService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleTerminationPreview = () => {
    enqueueDownload({
      name: 'previsualización de la liquidación',
      fileCode: 'termination_preview',
      nameGender: 'female',
      service: () =>
        terminationService.createPreview(values.contract_id, dirtyTermination, {
          file_format: 'pdf',
        }),
    })
  }

  const handlePaymentsHistory = () => {
    enqueueDownload({
      name: 'reporte de pagos salariales',
      fileCode: 'salary_payments_file',
      firebasePath: `contracts/${values.contract_id}/salary_payments_file`,
      service: () =>
        terminationMutation.mutateAsync({
          mutationMethod: 'GET_PAYMENTS_HISTORY',
          contractId: values?.contract_id,
          date: values?.date,
        }),
    })
  }

  const validateBeforeSubmit = () => {
    if (values.cause && values.cause?.length > 0) {
      handleSubmit()
      return
    }

    scrollToCauseInput()

    setFieldTouched('cause', true, true)
  }

  const endFreeWorkerModal = useEndFreeModals()

  const tableData = getTableData(values)
  const columnData = getColumnData()

  return (
    <Paper
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: theme.spacing(4),
        padding: theme.spacing(5),
        borderRadius: '1rem',
        [theme.breakpoints.down('tablet')]: {
          padding: theme.spacing(5, 2),
        },
      })}
    >
      <Typography variant="h2" color="primary.dark">
        Resumen liquidación
      </Typography>

      <Box
        sx={(theme) => ({ boxShadow: theme.shadows[7], borderRadius: '1rem' })}
      >
        <Table
          data={tableData || []}
          columns={columnData}
          options={{
            pagination: false,
            search: false,
            sorting: false,
            toolbar: false,
            flatStyle: true,
          }}
          sx={{
            tableContainerSx: (theme) => ({
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              marginBottom: 0,
              padding: theme.spacing(2.5, 0, 5),
              [theme.breakpoints.down('tablet')]: {
                padding: theme.spacing(2.5, 0, 5),
              },
            }),
          }}
        />
        <Box
          sx={(theme) => ({
            borderBottomRightRadius: '1rem',
            borderBottomLeftRadius: '1rem',
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2.5, 3),
          })}
        >
          <Typography variant="h3">Total liquidación</Typography>
          <Typography variant="h3">
            {values.worker_payment < 0
              ? `- ${formatCurrency(Math.abs(values.worker_payment))}`
              : formatCurrency(values.worker_payment)}
          </Typography>
        </Box>
      </Box>

      <Typography variant="h2" color="primary.dark">
        Revisión
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box display="flex" flexWrap="wrap" gap={2}>
          <Button
            variant="text"
            onClick={handleTerminationPreview}
            endIcon={<Icon name="eye" />}
          >
            Previsualizar liquidación
          </Button>
          <Button
            variant="text"
            onClick={handlePaymentsHistory}
            endIcon={<Icon name="download" />}
          >
            Descargar pagos salariales
          </Button>
          {isEditing ? (
            <Button
              component={RouterLink}
              variant="text"
              to={`${routes.PERIOD_PAYROLL_VIEW_DETAILS(
                values.payroll_id
              )}?selected_tab=termination`}
              target="_blank"
              startIcon={<Icon name="eye" />}
            >
              Ver cálculos
            </Button>
          ) : null}
        </Box>
        <Typography>
          Si deseas que en la liquidación aparezca el salario pendiente por
          cancelar, primero debes terminar de liquidar el empleado y en el
          siguiente paso podrás descargarla de esta forma.
        </Typography>

        <Typography gutterBottom>
          Al confirmar la liquidación, el empleado no volverá a aparecer en la
          lista de empleados activos, ni en el siguiente pago de nómina. Si
          luego deseas editar algo o reincorporar nuevamente la persona, lo
          podrás hacer desde la sección &quot;Personas&quot;.
        </Typography>
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'start',
          gap: theme.spacing(2),
          marginTop: theme.spacing(3),
          [theme.breakpoints.down('tablet')]: {
            flexDirection: 'column',
          },
        })}
      >
        <Button
          onClick={
            endFreeWorkerModal.isNotValidCompany
              ? () => endFreeWorkerModal.openEndFreeWorkerModal()
              : validateBeforeSubmit
          }
          loading={isSaving}
          data-cy="termination_save_button"
        >
          {isEditing ? 'Editar Liquidación' : 'Liquidar Persona'}
        </Button>
        <Button variant="outlined" disabled={isSaving} onClick={handleCancel}>
          Cancelar
        </Button>
      </Box>
    </Paper>
  )
}

export default WorkerContractTerminationFormSummary
