import { useState } from 'react'

import { usePremiumActions } from 'components/App/Premium/helpers'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import { useUpgradeToPayrollOnlyPlanModal } from 'components/Subscription/Atoms/useUpgradeToPayrollOnlyPlanModal'
import { PLANS_BY_CODED_NAME } from 'components/Subscription/helpers'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import ActivationModal from './Modals/ActivationModal'

const WorkerShowActivateButton = ({ isWorker, worker }) => {
  const [showActivationModal, setShowActivationModal] = useState(false)
  const { handlePayExtraWorkers } = usePremiumActions()
  const { subscription } = useSubscription()
  const { openUpgradeToPayrollOnlyPlanModal } =
    useUpgradeToPayrollOnlyPlanModal()

  const hasToPayNewWorkers = !subscription?.additional_workers_info?.add_workers
  const showUpgradeToPayrollOnlyPlan =
    subscription?.plan?.coded_name ===
      PLANS_BY_CODED_NAME.new_plans.entrepreneur_plan &&
    subscription?.payrolls_size === 3
  const isADowngradeToEntrepreneurPlan =
    subscription?.plan?.coded_name ===
      PLANS_BY_CODED_NAME.new_plans.payroll_only_plan &&
    subscription?.payrolls_size === 4

  const handleWorkerActivation = () => {
    if (hasToPayNewWorkers && !worker?.active) {
      handlePayExtraWorkers()
    } else if (showUpgradeToPayrollOnlyPlan && !worker?.active) {
      openUpgradeToPayrollOnlyPlanModal({
        handleOnConfirm: () => setShowActivationModal(true),
      })
    } else {
      setShowActivationModal(true)
    }
  }

  return !isWorker && !worker?.terminated ? (
    <>
      <Button
        sx={{ width: { mobile: 1, tablet: 'auto' } }}
        variant="outlined"
        size="large"
        endIcon={
          worker?.active ? (
            <Icon name="circle-x" />
          ) : (
            <Icon name="circle-check" />
          )
        }
        onClick={handleWorkerActivation}
      >
        {worker?.active ? 'Desactivar' : 'Activar'}
      </Button>
      {showActivationModal ? (
        <ActivationModal
          onCancel={() => setShowActivationModal(false)}
          isADowngradeToEntrepreneurPlan={isADowngradeToEntrepreneurPlan}
          showUpgradeToPayrollOnlyPlan={showUpgradeToPayrollOnlyPlan}
        />
      ) : null}
    </>
  ) : null
}

export default WorkerShowActivateButton
