import { Form, Formik } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import { createContext } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { Box, Link, Typography } from '@mui/material'

import Page from 'components/UI/Page/Page'

import useDecree376Service from 'utils/hooks/payroll/decree376Service'

import useSocialBenefitsResponse from '../common/Modal/useSocialBenefitsResponse'
import DataTable from './DataTable'
import PeriodTotals from './PeriodTotals'
import { getTitle, getValuesToSend, validationSchema } from './helpers'

export const Decree376Context = createContext()

const formatDataDisableCheck = (data) => {
  const newData = data
  newData.decree376_payments.forEach((payment, index) => {
    if (payment.payment_options.length === 0) {
      newData.decree376_payments[index].disableCheck = true
    }
  })
  return newData
}

const Decree376Index = () => {
  const queryClient = useQueryClient()
  const modalSocialBenefit = useSocialBenefitsResponse()

  const { periodId, decree376Id } = useParams()
  const decree376QueryKey = ['getDecree376Payments', periodId, decree376Id]
  const decree376DataCache = queryClient.getQueryData(decree376QueryKey) || {}
  const periodTotals = {
    total_pension_contribution:
      decree376DataCache?.decree376_period?.total_pension_contribution,
    total_pension_paid_contribution:
      decree376DataCache?.decree376_period?.total_pension_paid_contribution,
    pension_contribution_balance:
      decree376DataCache?.decree376_period?.pension_contribution_balance,
  }

  const { decree376Query, decree376Mutation } = useDecree376Service({
    serviceParams: {
      queryKey: decree376QueryKey,
      params: { periodId, decree376Id },
    },
    queryOptions: {
      onSuccess: ({ data: decree376Data }) => {
        if (decree376Data) {
          queryClient.setQueryData(
            decree376QueryKey,
            formatDataDisableCheck(cloneDeep(decree376Data))
          )
        }
      },
    },
  })

  const category = 'decree_376'
  const label = 'los aportes según el decreto 376 de tu empresa'

  const handleSubmit = async (values) => {
    const valuesToSend = getValuesToSend(
      decree376DataCache.decree376_payments,
      values.decree376_payments
    )

    decree376Mutation.mutate(
      {
        mutationMethod: 'PUT',
        params: {
          periodId,
          decree376Id,
          dataToSend: valuesToSend,
        },
      },
      {
        onSuccess: () => {
          modalSocialBenefit.openModal(category, label)
          queryClient.invalidateQueries(decree376QueryKey)
        },
      }
    )
  }

  return decree376DataCache ? (
    <Decree376Context.Provider value={{ periodId, decree376Id }}>
      <Page
        documentTitle="Pago aportes a pensión decreto 376"
        header={
          <Box
            sx={{
              gridColumn: '1 / -1',
            }}
          >
            <Typography variant="h2">
              {getTitle(decree376DataCache?.decree376_period)}
            </Typography>
            <Typography
              sx={(theme) => ({
                marginTop: theme.spacing(2),
              })}
              variant="body1"
            >
              Si fuiste beneficiado por el decreto 558 de 2020, debes realizar
              los aportes faltantes a seguridad social de tu empresa y empleados
              según el decreto 376 de 2021. Conoce más información{' '}
              <Link
                href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-aplica-el-decreto-558-de-2020-en-el-pago-de-aportes-a-pensiones-de-tu-compañía"
                target="_blank"
              >
                aquí
              </Link>{' '}
              y mira el paso a paso para efectuarlo.
            </Typography>
          </Box>
        }
        isLoading={decree376Query.isLoading}
        isLoadingWithModal={decree376Mutation.isLoading}
        grid
      >
        <PeriodTotals totals={periodTotals} />
        <Box
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          <Formik
            initialValues={{
              decree376_payments: decree376DataCache.decree376_payments,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(form) => {
              const { values } = form

              return (
                <Form>
                  <DataTable decreePayments={values.decree376_payments} />
                </Form>
              )
            }}
          </Formik>
        </Box>
      </Page>
    </Decree376Context.Provider>
  ) : null
}

export default Decree376Index
