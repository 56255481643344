import queryString from 'query-string'
import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import ReacTourProvider from 'components/App/ReacTourProvider'
import usePsePaymentCheck from 'components/Subscription/Atoms/usePsePaymentCheck'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import Page from 'components/UI/Page/Page'

import useWorkerService from 'utils/hooks/worker/workerService'

import WorkersOnboarding from '../WorkersOnboarding'
import { onboardingTourSteps } from '../WorkersOnboarding/helpers'
import DownloadIncomesCertificatesModal from './DownloadIncomesCertificatesModal'
import { FirstWorkerAddedModal } from './Modals/FirstWorkerAddedModal'
import { useFirstWorkerAddedModal } from './Modals/FirstWorkerAddedModal/useFirstWorkerAddedModal'
import NewWorkerModal from './Modals/NewWorkerModal'
import useNewWorkerModal from './Modals/NewWorkerModal/useNewWorkerModal'
import WorkersTable from './Table'
import useDownloadIncomesCertificatesModal from './useDownloadIncomesCertificatesModal'

export const WorkerIndexContext = createContext()

const WorkerIndex = () => {
  const { showHRFeatures } = usePremiumFeature()
  const { search, state } = useLocation()
  const navigate = useNavigate()

  const [addWorkerFromPayPayroll, setAddWorkerFromPayPayroll] = useState(
    state?.addWorkerFromPayPayroll
  )

  const { openPseTransactionResponseModal, pseTransactionStatus } =
    usePsePaymentCheck()

  const {
    firstWorkerAddedModalState,
    openFirstWorkerAddedModal,
    closeFirstWorkerAddedModal,
  } = useFirstWorkerAddedModal()

  const queryParams = queryString.parse(search)
  const tabQueryParam = queryParams.tab

  const isPsePayment = new URLSearchParams(search).get('pse_payment') === 'true'

  const [tab, setTab] = useState(() => {
    if (
      tabQueryParam &&
      ['new', 'active', 'disabled'].includes(tabQueryParam)
    ) {
      if (!showHRFeatures && tabQueryParam === 'new') return 'active'
      return tabQueryParam
    }

    return 'active'
  })
  const [activeContract, setActiveContract] = useState(() => {
    if (state?.filter) return state.filter

    if (tab === 'active') return 'active'

    if (tab === 'disabled') return 'disabled'

    return 'active'
  })

  const { workerQuery } = useWorkerService({
    serviceParams: { queryKey: 'getWorkerTotals' },
  })
  const workerTotalData = useMemo(
    () => workerQuery.data || [],
    [workerQuery.data]
  )
  const paginationRef = useRef(null)

  const tabsConfig = [
    { label: 'Activas', key: 'active' },
    ...(showHRFeatures ? [{ label: 'En contratación', key: 'new' }] : []),
    { label: 'Liquidadas/Inactivas', key: 'disabled' },
  ]

  const { newWorkerModalState, openNewWorkerModal, closeNewWorkerModal } =
    useNewWorkerModal()

  const {
    downloadIncomesCertificatesModalState,
    openDownloadIncomesCertificatesModal,
    closeDownloadIncomesCertificatesModal,
  } = useDownloadIncomesCertificatesModal()

  const handleChange = (_, newValue) => {
    navigate(`?tab=${newValue}`)
    setTab(newValue)

    if (tab !== 'new' && paginationRef.current) {
      paginationRef.current?.resetPagination()
    }

    if (newValue === 'active') {
      setActiveContract('active')
    } else if (newValue === 'disabled') {
      setActiveContract('disabled')
    }
  }

  const handleAddWorkerManually = () => {
    openNewWorkerModal()
  }

  useEffect(() => {
    if (addWorkerFromPayPayroll) {
      openNewWorkerModal()
      setAddWorkerFromPayPayroll(false)
    }
  }, [addWorkerFromPayPayroll, openNewWorkerModal, setAddWorkerFromPayPayroll])

  useEffect(() => {
    if (
      (!showHRFeatures && tabQueryParam === 'new') ||
      !search ||
      !['new', 'active', 'disabled'].includes(tabQueryParam)
    ) {
      navigate('?tab=active')
    }
  }, [navigate, search, showHRFeatures, tabQueryParam])

  useEffect(() => {
    if (isPsePayment && pseTransactionStatus) {
      openPseTransactionResponseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPsePayment, pseTransactionStatus])

  return (
    <Page documentTitle="Personas" grid header="Personas">
      <WorkerIndexContext.Provider
        value={{
          workerTotalData,
          tab,
          openDownloadIncomesCertificatesModal,
          handleAddWorkerManually,
          activeContract,
          setActiveContract,
          openNewWorkerModal,
        }}
      >
        <Box
          sx={(theme) => ({
            gridColumn: '1 / -1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(5),
          })}
        >
          <RoundedTabs
            value={tab}
            onChange={handleChange}
            tabsConfig={tabsConfig}
            background="white"
          />
        </Box>
        <Box
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          {tab !== 'new' ? (
            <WorkersTable paginationRef={paginationRef} />
          ) : firstWorkerAddedModalState.open ? (
            <WorkersOnboarding
              openNewWorkerModal={openNewWorkerModal}
              newWorkerModalState={newWorkerModalState}
              isNewWorkerModalOpen={newWorkerModalState.open}
            />
          ) : (
            <ReacTourProvider
              showNavigation={false}
              showCloseButton={false}
              showBadge={false}
              steps={onboardingTourSteps}
            >
              <WorkersOnboarding
                openNewWorkerModal={openNewWorkerModal}
                newWorkerModalState={newWorkerModalState}
                isNewWorkerModalOpen={newWorkerModalState.open}
              />
            </ReacTourProvider>
          )}
        </Box>
        {newWorkerModalState.open ? (
          <NewWorkerModal
            state={newWorkerModalState}
            handleClose={closeNewWorkerModal}
            openFirstWorkerAddedModal={openFirstWorkerAddedModal}
            setTab={setTab}
          />
        ) : null}
        {downloadIncomesCertificatesModalState.open ? (
          <DownloadIncomesCertificatesModal
            state={downloadIncomesCertificatesModalState}
            handleClose={closeDownloadIncomesCertificatesModal}
          />
        ) : null}
        {firstWorkerAddedModalState.open ? (
          <FirstWorkerAddedModal
            state={firstWorkerAddedModalState}
            handleClose={closeFirstWorkerAddedModal}
          />
        ) : null}
      </WorkerIndexContext.Provider>
    </Page>
  )
}

export default WorkerIndex
