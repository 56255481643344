import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Typography, useMediaQuery } from '@mui/material'

import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { getCompanyId } from 'utils/company'
import useWorkHoursConfigurationService from 'utils/hooks/company/workHoursConfigurationService.js'

import {
  WORK_HOURS_INFO,
  getInitialValues,
  useTableActions,
  validationSchema,
} from '../helpers'

const CreateWorkHoursConfigurationModal = ({
  handleCloseModal,
  isEditing = false,
  dataToUpdate,
}) => {
  const queryClient = useQueryClient()
  const { getActionNotification } = useTableActions()
  const confirm = useConfirm()
  const { workHoursConfigurationMutation } = useWorkHoursConfigurationService({
    queryOptions: { enabled: false },
  })

  const companyId = getCompanyId()
  const initialValues = getInitialValues(dataToUpdate)

  const handleCreateWorkHoursConfiguration = (values) => {
    if (isEditing) {
      confirm({
        title: 'Modificar configuración de jornada laboral',
        description:
          '¿Estás seguro de este cambio? Ajustar la configuración jornada laboral  afectará todas las nóminas en las fechas de este ajuste',
        okText: 'Actualizar',
        confirmCheckbox: true,
        onOk: async () => {
          await workHoursConfigurationMutation.mutateAsync(
            {
              mutationMethod: 'PATCH',
              mutationKey: 'editWorkHoursConfiguration',
              workHoursConfigurationsId: values?.work_hours_configurations_id,
              workHoursConfiguration: values,
            },
            {
              onSuccess: async () => {
                await queryClient.invalidateQueries([
                  'getWorkHoursConfiguration',
                  companyId,
                ])
                getActionNotification('actualizada')
              },
            }
          )
        },
      })
    } else {
      workHoursConfigurationMutation.mutate(
        {
          mutationMethod: 'POST',
          mutationKey: 'createWorkHoursConfiguration',
          workHoursConfiguration: values,
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([
              'getWorkHoursConfiguration',
              companyId,
            ])
            getActionNotification('creada')
          },
        }
      )
    }
  }

  const tabletDown = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleCreateWorkHoursConfiguration}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => {
        return (
          <Form>
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                rowGap: theme.spacing(2),
              })}
            >
              <Typography variant="body1" color="black.dark">
                Acá puedes {isEditing ? 'editar la' : 'crear una nueva'}{' '}
                configuración para tu Jornada laboral de acuerdo a la{' '}
                <Link to={WORK_HOURS_INFO} target="_blank">
                  Ley 2101 de julio 15 de 2021
                </Link>
                .
              </Typography>
              {isEditing ? (
                <Typography variant="body1" color="black.dark">
                  Recuerda que modificar la jornada laboral afectará todas las
                  nóminas en las fechas de este ajuste.
                </Typography>
              ) : null}
            </Box>

            <Box
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: '1fr',
                margin: theme.spacing(4, 0),
                rowGap: theme.spacing(2),
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  rowGap: theme.spacing(2),
                  [theme.breakpoints.up('tablet')]: {
                    flexDirection: 'row',
                    marginBottom: theme.spacing(2),
                    columnGap: theme.spacing(2),
                  },
                })}
              >
                <FormField
                  variant="datepicker"
                  name="initial_day"
                  label="Fecha inicial"
                  optional={false}
                />
                {!tabletDown ? (
                  <Icon
                    name="move-right"
                    sx={(theme) => ({
                      marginTop: theme.spacing(3),
                    })}
                  />
                ) : null}
                <FormField
                  variant="datepicker"
                  name="end_day"
                  label="Fecha final"
                />
              </Box>
              <FormField
                name="weekly_hours"
                label="Número de horas a la semana"
                variant="number"
                placeholder="0,0"
                isNumericString
                optional={false}
              />
            </Box>

            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'center',
                columnGap: theme.spacing(2),
                marginTop: theme.spacing(7),
                [theme.breakpoints.up('laptop')]: {
                  justifyContent: 'flex-start',
                },
              })}
            >
              <Button
                onClick={handleSubmit}
                loading={workHoursConfigurationMutation.isLoading}
              >
                {isEditing ? 'Actualizar' : 'Crear'}
              </Button>
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancelar
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateWorkHoursConfigurationModal
