import { Link as MuiLink } from '@mui/material'

export const deductionDescription = {
  deductions: (
    <>
      Agrega aquí las deducciones adicionales sobre el pago de la persona en el
      periodo. Si tienes dudas ingresa a{' '}
      <MuiLink
        href="https://ayuda.aleluya.com/portal/es/kb/articles/conoce-cómo-registrar-deducciones-recurrentes-a-tu-empleado"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda
      </MuiLink>
      .
    </>
  ),
  loans: (
    <>
      Agrega aquí el valor a deducir por concepto de préstamos sobre el pago de
      la persona en el periodo. Si tienes dudas ingresa a{' '}
      <MuiLink
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/agrega-préstamos-a-tus-empleados"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda
      </MuiLink>
      .
    </>
  ),
  payroll_retention: (
    <>
      Agrega aquí el valor a deducir por concepto de retención en la fuente por
      salarios sobre el pago de la persona en el periodo. Si deseas que Aleluya
      calcule automáticamente este valor o tienes dudas ingresa a{' '}
      <MuiLink
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-realizar-la-retención-en-la-fuente-por-salarios-a-mi-empleado-en-aleluya"
        target="_blank"
      >
        este artículo de nuestro centro de ayuda
      </MuiLink>
      .
    </>
  ),
}

export const getModalHeader = (novelty, workerName) => {
  const label = {
    deductions: 'deducciones',
    loans: 'préstamos',
    payroll_retention: 'retefuente',
  }

  return `¿Reportale ${label[novelty]} a ${workerName}?`
}
