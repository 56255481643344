import { Link, Paper, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'
import Modal from 'components/UI/Modal/Modal'

import isotipoAEL from 'assets/images/logos/aportes_en_linea/isotipo_ael.png'
import isotipoArus from 'assets/images/logos/arus/isotipo_arus.png'

import { getArusHeaderMessage, getArusHelpMessage } from './helpers'

const integrationProviders = {
  aportes_en_linea: {
    name: 'Aportes en línea',
    logo: isotipoAEL,
    logoWidth: 39,
    logoHeight: 38,
    alert:
      'Eliminar esta planilla es permanente y no se podrá recuperar, ¿Estás seguro?',
  },
  arus: {
    name: 'SuAporte',
    logo: isotipoArus,
    logoWidth: 41,
    logoHeight: 32,
    alert:
      'Si eliminas tu planilla de seguridad social acá, también debes ingresar a SuAporte y eliminarla allí.',
  },
}

const Header = ({ provider, status, isArusProvider }) => {
  const headerMessage = getArusHeaderMessage(status)

  return (
    <Typography
      variant="h5"
      sx={(theme) => ({
        paddingRight: theme.spacing(2),
        fontWeight: 'normal',
        '& > span': {
          fontWeight: 'bold',
        },
        '& > img': {
          marginLeft: theme.spacing(0.5),
          marginBottom: theme.spacing(-1),
        },
      })}
    >
      {isArusProvider && status !== 'created'
        ? headerMessage.baseText
        : 'Eliminar planilla de seguridad social de '}
      <span>{integrationProviders[provider]?.name}</span>
      <img
        src={integrationProviders[provider]?.logo}
        alt={`Isotipo de ${integrationProviders[provider]?.name}`}
        width={integrationProviders[provider]?.logoWidth}
        height={integrationProviders[provider]?.logoHeight}
      />
      {isArusProvider && status !== 'created' ? (
        <span>{headerMessage.statusText}</span>
      ) : null}
    </Typography>
  )
}

const DeleteForm = ({ open, provider, onCancel, onOk, isLoading, status }) => {
  const isArusProvider = provider === 'arus'

  return (
    <Modal
      open={open}
      header={
        <Header
          provider={provider}
          status={status}
          isArusProvider={isArusProvider}
        />
      }
      onOk={onOk}
      okText="Eliminar"
      onCancel={onCancel}
      cancelText="Cancelar"
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      isLoading={isLoading}
    >
      {isArusProvider && status !== 'created' ? (
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: theme.palette.black.dark,
            '& > span': {
              fontWeight: 'bold',
            },
            marginBottom: theme.spacing(2),
          })}
        >
          {getArusHelpMessage(status)}
        </Typography>
      ) : (
        <Paper
          sx={(theme) => ({
            color: theme.palette.black.dark,
            display: 'flex',
            gap: theme.spacing(2.5),
            padding: theme.spacing(1.5, 3, 1.5, 5),
            alignItems: 'center',
            marginBottom: theme.spacing(3),
          })}
        >
          <Icon
            name="file-pen-line"
            sx={(theme) => ({
              fontSize: '4rem',
              color: theme.palette.black.main,
            })}
          />
          <div>
            <Typography variant="lead2">Eliminar planilla</Typography>
            <Typography variant="body2">
              {integrationProviders[provider]?.alert}
            </Typography>
          </div>
        </Paper>
      )}
      <Typography variant="body1" color="black.dark">
        Si tienes dudas acerca de este proceso puedes consultar nuestra página
        de{' '}
        <Link
          target="_blank"
          href="https://efectoaleluya.zohodesk.com/portal/es/kb"
        >
          centro de ayuda
        </Link>{' '}
        y acá podrás resolverlas todas.
      </Typography>
    </Modal>
  )
}

export default DeleteForm
