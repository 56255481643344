import { MenuItem, Select } from '@mui/material'

const SelectField = ({ id, value, field, options = [], ...props } = {}) => {
  const { placeholder, ...restProps } = props

  return (
    <Select
      id={id}
      {...field}
      displayEmpty
      value={value?.toString() ?? ''}
      {...restProps}
    >
      <MenuItem
        disabled
        value=""
        sx={{ '&.Mui-disabled': { backgroundColor: 'transparent !important' } }}
      >
        <em>{placeholder || 'Selecciona...'}</em>
      </MenuItem>
      {options.map((option) => {
        const { label, value: optionValue, disabled } = option

        return (
          <MenuItem key={optionValue} value={optionValue} disabled={disabled}>
            {label}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default SelectField
