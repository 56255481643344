import { Form, Formik } from 'formik'

import { Link, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import useAccountingService from 'utils/hooks/settings/accounting'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import { accountingFileValidationSchema } from './helpers'

const AccountingFileModal = ({ state, handleClose }) => {
  const { open, period, providerOptions, periodOptions, fileOptions } = state

  const { accountingMutation } = useAccountingService()
  const { enqueueDownload } = useDownloadManager()

  const fortnightlyOptions = fileOptions.filter(
    (e) => e.value !== 'social_security_payment'
  )

  const handleSubmitForm = async (values, form) => {
    form.setSubmitting(false)
    handleClose()
    enqueueDownload({
      name: 'archivo de Interfaz Contable',
      fileCode: 'accounting_file',
      firebasePath: `periods/${period.id}/accounting_file`,
      service: () =>
        accountingMutation.mutateAsync({
          mutationMethod: 'GET',
          mutationKey: 'getAccountingFile',
          periodId: period.id,
          providerId: values.provider_id.id,
          fileOptions: values.file_options,
          periodOption: values.period_option,
        }),
      callback: () => {
        Mixpanel.track(MIXPANEL_EVENTS.DOWNLOAD_ACCOUNTING_FILE_V2, {
          company_id: getCompanyId(),
          user_id: getUserId(),
          user_role: getUserRole(),
          accounting_provider: values.provider_id.name,
          file_options: values.file_options,
          period_option: values.period_option,
        })
      },
    })
  }

  const options = providerOptions?.map((provider) => ({
    selected: provider.selected,
    id: provider.value,
    name: provider.label,
  }))
  const selectedProvider = options.find((provider) => provider.selected)

  const initialValues = {
    provider_id: selectedProvider,
    file_options: [],
    // if there are periodOptions, set first value as initial value
    period_option: periodOptions[0]?.value || '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={accountingFileValidationSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, values }) => {
        return (
          <Modal
            open={open}
            onOk={handleSubmit}
            onCancel={handleClose}
            isLoading={isSubmitting}
            okText="Generar"
            header="Generar Interfaz Contable"
            loadingText="Generando Archivo..."
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
          >
            <Form>
              <Typography
                sx={(theme) => ({
                  marginBottom: theme.spacing(2),
                  color: theme.palette.black.dark,
                })}
              >
                Selecciona el software contable para el cual deseas generar el
                archivo:
              </Typography>
              {providerOptions.length > 0 ? (
                <FormField
                  name="provider_id"
                  label="Software contable"
                  variant="autocomplete"
                  options={options}
                  disableClearable
                />
              ) : null}
              {periodOptions.length > 0 ? (
                <FormField
                  name="period_option"
                  label="Periodo"
                  variant="select"
                  options={periodOptions}
                  formControlSx={(theme) => ({
                    marginTop: theme.spacing(2),
                  })}
                />
              ) : null}
              <Typography
                sx={(theme) => ({
                  margin: theme.spacing(2, 0),
                  color: theme.palette.black.dark,
                })}
              >
                Selecciona el tipo de archivo que deseas generar. Puedes
                seleccionar varios tipos. Los archivos de{' '}
                <b>&quot;provisión&quot;</b> contienen la provisión de los
                diferentes conceptos y los archivos de{' '}
                <b>&quot;causación&quot;</b> contienen los pagos de estos, haz{' '}
                <Link
                  href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cuál-es-la-diferencia-entre-los-archivos-contables-provisión-y-egreso-que-genera-aleluya"
                  target="_blank"
                >
                  click aquí
                </Link>{' '}
                para conocer las diferencias.
              </Typography>
              {fileOptions.length > 0 ? (
                <FormField
                  name="file_options"
                  label="Tipo de archivo"
                  variant="checkbox-group"
                  options={
                    values.period_option === 'fortnightly'
                      ? fortnightlyOptions
                      : fileOptions
                  }
                />
              ) : null}
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default AccountingFileModal
