import { useOutletContext } from 'react-router-dom'

import { Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

export const mainGoalOptions = [
  {
    value: 'payroll',
    label: 'Liquidar nómina',
  },
  {
    value: 'electronic_payroll',
    label: 'Emisión de Nómina Electrónica',
  },
  {
    value: 'financial_wellness',
    label: 'Bienestar Financiero de empleados',
  },
  {
    value: 'other',
    label: 'Otro',
  },
]

export function OnboardingGoal() {
  const { userName } = useOutletContext()
  return (
    <>
      <Typography
        variant="h4"
        sx={({ spacing }) => ({
          marginBottom: spacing(1),
          textAlign: 'center',
        })}
      >
        {userName
          ? `${userName}, ¿cuál es tu objetivo principal con Aleluya?`
          : '¿Cuál es tu objetivo principal con Aleluya?'}
      </Typography>
      <Typography
        variant="body1"
        color="white.dark"
        sx={({ spacing }) => ({
          marginBottom: spacing(2),
          textAlign: 'center',
        })}
      >
        Selecciona lo que más te interesa para personalizar tu experiencia.
      </Typography>
      <FormField
        variant="chip-group"
        optional={false}
        name="main_goal"
        label="Selecciona tu objetivo"
        options={mainGoalOptions}
        otherName="main_goal_other_info"
        otherLabel="Escribe tu objetivo"
      />
    </>
  )
}
