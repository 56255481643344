import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueries, useQueryClient } from 'react-query'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormBox from 'components/UI/FormBox'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import Page from 'components/UI/Page/Page'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import {
  getCompanyConfiguration,
  getConfigurationOptions,
} from 'services/settings/configurationService'

import { SETTINGS } from 'config/routes'

import messages from 'messages/notification'

import Fields from './Fields/Fields'
import {
  getCompanyConfigurationInitialValues,
  tabs,
  validationSchema,
} from './helpers'

const SettingsAdvanced = () => {
  const queryClient = useQueryClient()
  const [redirectToSettings, setRedirectToSettings] = useState(false)
  const queryFunction = [getConfigurationOptions, getCompanyConfiguration]
  const [companyConfigurationOptionsQuery, companyConfigurationQuery] =
    useQueries(
      ['companyConfigurationOptions', 'companyConfiguration'].map(
        (query, index) => {
          return {
            queryKey: query,
            queryFn: () => queryFunction[index](),
          }
        }
      )
    )

  const { companyConfigurationMutation } = useCompanyConfigurationService({
    queryOptions: { enabled: false },
  })
  const isQueriesLoading =
    companyConfigurationOptionsQuery.isLoading ||
    companyConfigurationQuery.isLoading

  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab') || 'payment_settings'

  const { data: companyConfigurationOptions } = companyConfigurationOptionsQuery
  const { data: companyConfiguration } = companyConfigurationQuery
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const [activeTab, setActiveTab] = useState(() =>
    tab ? tabs.findIndex((currentTab) => currentTab.name === tab) : 0
  )
  const navigate = useNavigate()

  const handleChangeTab = (_, newTab) => {
    setActiveTab(newTab)
    navigate(`?tab=${tabs[newTab].name}`)
  }

  const companyConfigurationInitialValues =
    getCompanyConfigurationInitialValues(companyConfiguration?.data || {}, tab)

  const onSubmit = (values, form) => {
    const formData = new FormData()

    const dirtyValues = getDirtyValues(
      companyConfigurationInitialValues,
      values
    )

    if (isObjectEmpty(dirtyValues)) return setRedirectToSettings(true)

    Object.entries(dirtyValues).forEach((value) => {
      formData.append(value[0], value[1])
    })

    return companyConfigurationMutation.mutate(
      {
        mutationMethod: 'PUT',
        configuration: formData,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries('companyConfiguration')
          showSuccessMessage(messages.COMPANY_UPDATE_SUCCESS)
          setRedirectToSettings(true)
        },
        onError: (error) => {
          handleError(error, form)
        },
      }
    )
  }

  if (redirectToSettings) return <Navigate to={SETTINGS()} />

  return (
    <Page
      header="Editar configuración empresa"
      isLoading={isQueriesLoading}
      isLoadingWithModal={companyConfigurationMutation.isLoading}
      grid
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(1),
          margin: `${theme.spacing(-4)} 0 ${theme.spacing(4)} 0`,
          gridColumn: '1 / -1',
          width: '100%',
        })}
      >
        <Typography variant="body1" color="black.dark">
          A continuación, podrás encontrar como puedes configurar la información
          de pago, como liquidas tu nómina, edición de periodos históricos,
          ajustes de fechas en cambios de jornada laboral y configurar las UVT´s
          para retefuente.
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'flex-start',
          gridColumn: '1 / -1',
          backgroundColor: theme.palette.white.main,
          borderRadius: '1rem',
          justifyContent: 'space-between',
          padding: theme.spacing(4, 2, 4, 2),
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: theme.spacing(2),
            justifyContent: 'flex-start',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <RoundedTabs
            background="gray"
            value={activeTab}
            onChange={handleChangeTab}
            tabsConfig={tabs}
          />
        </Box>
        <Box
          sx={(theme) => ({
            gridColumn: '1 / -1',
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(2),
            width: '100%',
          })}
        >
          <FormBox
            sx={(theme) => ({
              gridColumn: '1 / -1',
              boxShadow: 'none',
              width: '100%',
              [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0),
              },
            })}
          >
            <Formik
              initialValues={companyConfigurationInitialValues}
              validationSchema={validationSchema[tab]}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ handleSubmit }) => (
                <Form>
                  <Fields
                    options={companyConfigurationOptions?.data || {}}
                    step={tabs[activeTab]?.name}
                  />
                  {tabs[activeTab].name !== 'working_time' && (
                    <Button
                      sx={{ width: '12rem' }}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Guardar
                    </Button>
                  )}
                </Form>
              )}
            </Formik>
          </FormBox>
        </Box>
      </Box>
    </Page>
  )
}

export default SettingsAdvanced
