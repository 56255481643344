import { QueryClient, QueryClientProvider as RQProvider } from 'react-query'

import { isDev } from 'utils/general'

import ReactQueryDevtools from './ReactQueryDevtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const ReactQueryProvider = ({ children }) => {
  return (
    <RQProvider client={queryClient}>
      {children}
      {isDev && !window.Cypress ? <ReactQueryDevtools /> : null}
    </RQProvider>
  )
}

export default ReactQueryProvider
