import http, { downloadFileWebSocket, getApiURL } from '../httpService'

export const getPeriodPayslips = async (
  periodId,
  { file_format: fileFormat = 'pdf', payslip_type: payslipType = 'payroll' }
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/payslips`,
    searchParams: {
      file_format: fileFormat,
      payslip_type: payslipType,
      async: true,
    },
  })

  await http.get(url)
}

export const sendPeriodPayslips = async (
  periodId,
  { payslip_type: payslipType = 'payroll', whatsappNotification }
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/payslip_mails`,
    searchParams: {
      payslip_type: payslipType,
      whatsapp_notification: whatsappNotification,
    },
  })

  const response = await http.post(url)

  return response
}

/**
 *
 * @param {*} periodId
 * @param {object} queryParams
 * @param {string} queryParams.file_type API Defaults to company payment frequency (monthly or fortnightly).
 */
export const getPayrollsSummary = async (
  periodId,
  { file_type: fileType, data_type: dataType } = {}
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/payroll_summary`,
    searchParams: { file_type: fileType, data_type: dataType, async: true },
  })

  await http.get(url)
}

export const getPeriodOptions = async (periodId) => {
  const url = getApiURL({ pathname: `/periods/${periodId}/options` })

  const { data } = await http.get(url)

  return data
}

export const getSocialSecurityFile = async (periodId, formType, fileType) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_file`,
    searchParams: { form_type: formType, file_type: fileType, async: true },
  })

  await http.get(url)
}

export const getSocialSecurityPaymentSummary = async (periodId, formType) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_summary_data`,
    searchParams: { form_type: formType, async: true },
  })

  await http.get(url)

  const { data } = await downloadFileWebSocket(
    `/periods/${periodId}/social_security_summary_data`
  )

  return data
}

export const getSSPaymentSummary = (periodId, formType) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/social_security_summary_data`,
    searchParams: { form_type: formType, async: false },
  })

  return http.getV2(url)
}

export const getPayslipOptions = async (periodId) => {
  const url = getApiURL({ pathname: `/periods/${periodId}/payslip_options` })

  const { data } = await http.get(url)

  return data
}

export const getNoveltiesHistory = (
  periodId,
  { filter, page, search, file_format: fileFormat = 'json', async = true }
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/novelties_history`,
    searchParams: { filter, page, search, file_format: fileFormat, async },
  })

  return http.getV2(url)
}

export const getBankFileOptions = async (periodId) => {
  const url = getApiURL({ pathname: `/periods/${periodId}/bank_file_options` })

  const { data } = await http.get(url)

  return data
}

export const getPeriodBankFile = async (
  periodId,
  {
    contract_type: contractType,
    pay_day: payDay,
    payment_type: paymentType,
    file_format: fileFormat,
    location,
  }
) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/bank_files`,
    searchParams: {
      contract_type: contractType,
      pay_day: payDay,
      payment_type: paymentType,
      file_format: fileFormat,
      location,
      async: true,
    },
  })

  await http.get(url)
}

export const getAccountingFileOptions = async (periodId) => {
  const url = getApiURL({ pathname: `/periods/${periodId}/accounting/options` })

  const { data } = await http.get(url)

  return data
}

/**
 * @param {string} periodId
 * @param {Object} options
 * @param {string} options.provider_id
 * @param {string[]} options.file_options
 * @param {string} options.period_option
 */
export const getAccountingFile = async ({
  periodId,
  providerId,
  fileOptions,
  periodOption,
  async = true,
} = {}) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/accounting/files`,
    searchParams: {
      provider_id: providerId,
      file_options: fileOptions,
      period_option: periodOption,
      async,
    },
  })

  await http.getV2(url)
}

export const getPayslipsElectronicPayroll = (
  electronicPayrollId,
  fileType,
  async = true
) => {
  const url = getApiURL({
    pathname: `/electronic_payroll/government_files/${electronicPayrollId}`,
    searchParams: { file_type: fileType, async },
  })

  return http.getV2(url)
}

export const getElectronicPayrollSummary = (electronicPayrollPeriodId) => {
  const url = getApiURL({
    pathname: `/electronic_payroll/summaries/${electronicPayrollPeriodId}`,
    searchParams: { async: true },
  })

  return http.getV2(url)
}

export const getIndividualPdfElectronicPayroll = (
  electronicPayrollId,
  periodId
) => {
  const url = getApiURL({
    pathname: `/electronic_payroll/periods/${periodId}/payslips/${electronicPayrollId}`,
  })

  return http.getV2(url)
}

export const getBulkPdfElectronicPayroll = (periodId, fileType, async) => {
  const url = getApiURL({
    pathname: `/electronic_payroll/periods/${periodId}/payslips`,
    searchParams: { type: fileType, async },
  })

  return http.getV2(url)
}

export const getExogenaReport = () => {
  const url = getApiURL({
    pathname: `/exogenous_information`,
    searchParams: {
      async: true,
    },
  })

  return http.getV2(url)
}

export const getRetentionSummaryFile = (periodId) => {
  const url = getApiURL({
    pathname: `/workers_retention_summary_file/${periodId}`,
    searchParams: {
      async: true,
      retentions_summary_file: true,
    },
  })

  return http.getV2(url)
}

export default {
  getPeriodPayslips,
  sendPeriodPayslips,
  getPayrollsSummary,
  getPeriodOptions,
  getSocialSecurityFile,
  getSocialSecurityPaymentSummary,
  getNoveltiesHistory,
  getPayslipOptions,
  getBankFileOptions,
  getPeriodBankFile,
  getAccountingFileOptions,
  getAccountingFile,
  getPayslipsElectronicPayroll,
  getElectronicPayrollSummary,
  getSSPaymentSummary,
  getIndividualPdfElectronicPayroll,
  getBulkPdfElectronicPayroll,
  getExogenaReport,
  getRetentionSummaryFile,
}
