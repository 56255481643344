import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import useNotifications from 'utils/hooks/useNotifications'
import useContractsService from 'utils/hooks/worker/contracts'

import { WORKER_SHOW } from 'config/routes'

import ContractDetailModal from './ContractDetailModal'
import { columnsData, findAdjacentContractDetail, getActions } from './helpers'
import useContractDetailModal from './useContractDetailModal'

const ContractDetail = () => {
  const queryClient = useQueryClient()
  const confirm = useConfirm()
  const { workerId, contractId } = useParams()
  const { worker } = useWorker({ useCache: true })
  const { showSuccessMessage } = useNotifications()
  const { contractsQuery, contractsMutation } = useContractsService({
    serviceParams: {
      queryKey: ['getAllContractDetail', worker.id],
      contractId,
    },
  })
  const {
    contractDetailModalState,
    openContractDetailModal,
    closeContractDetailModal,
    resetContractDetailModal,
  } = useContractDetailModal()
  const lastContractDetail = contractsQuery.data ? contractsQuery.data[0] : {}

  const handleDeleteContractDetail = (contractDetailId) => {
    confirm({
      type: 'warning',
      title: 'Eliminar detalle de contrato',
      description:
        'Eliminar este detalle de contrato es permanente y no se podrá deshacer. ¿Estás seguro?',
      okText: 'Eliminar detalle de contrato',
      onOk: async () => {
        await contractsMutation.mutateAsync(
          {
            mutationMethod: 'DELETE',
            mutationKey: 'deleteContractDetail',
            contractDetailId,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                'getAllContractDetail',
                worker.id,
              ])
              showSuccessMessage(
                'El detalle de contrato se ha eliminado correctamente'
              )
            },
          }
        )
      },
    })
  }

  const handleOpenCreateContractDetailModal = () => {
    openContractDetailModal({ worker, lastContractDetail })
  }

  const handleEditContractDetail = (contractDetail) => {
    const { previousContractDetail, nextContractDetail } =
      findAdjacentContractDetail(contractsQuery.data, contractDetail?.rowIndex)

    openContractDetailModal({
      worker,
      contractDetail,
      lastContractDetail,
      previousContractDetail,
      nextContractDetail,
      isEditing: true,
    })
  }

  return (
    <Page
      documentTitle={`Detalle de contrato de ${
        worker ? worker.fullName : 'la persona'
      }`}
      header={
        <Typography
          variant="h2"
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          Detalle de contrato de{' '}
          {worker ? (
            <Link
              to={WORKER_SHOW(workerId)}
              sx={{
                fontSize: 'inherit',
              }}
            >
              {worker.fullName}
            </Link>
          ) : (
            'la persona'
          )}
        </Typography>
      }
      isLoading={contractsQuery.isLoading}
      grid
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Typography>
          Este es el historial de detalle del contrato. Aquí puedes ver todos
          los detalles de cada uno de los contratos que una persona ha tenido
          desde que fue creada en Aleluya.
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          gridColumn: '1 / -1',
          marginTop: theme.spacing(5),
        })}
      >
        <Table
          data={contractsQuery.data || []}
          columns={columnsData}
          options={{
            pagination: false,
            search: false,
            alignActionsCell: 'center',
            version: {
              toolbar: 'v2',
            },
          }}
          actions={getActions({
            handleOpenCreateContractDetailModal,
            handleDeleteContractDetail,
            handleEditContractDetail,
          })}
        />
      </Box>
      {contractDetailModalState.open ? (
        <ContractDetailModal
          state={contractDetailModalState}
          handleClose={closeContractDetailModal}
          resetModal={resetContractDetailModal}
        />
      ) : null}
    </Page>
  )
}

export default ContractDetail
