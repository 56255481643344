import Modal from 'components/UI/Modal/Modal'

import PeriodEditingInactiveModal from './PeriodEditingInactiveModal'

export default function InactivePeriodEditingModal({ state, handleClose }) {
  return (
    <Modal
      open={state.open}
      header="¡PILAS! La edición de periodos históricos está inactiva"
      onCancel={handleClose}
      okText="Guardar"
      hideFooter
      paperSx={{
        maxWidth: {
          tablet: '45rem',
          laptop: '48.5rem',
        },
      }}
    >
      <PeriodEditingInactiveModal handleClose={handleClose} />
    </Modal>
  )
}
