import { useFormikContext } from 'formik'
import { useState } from 'react'

import { Link } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'

import { wait } from 'utils/general'
import {
  getMinimumSalary,
  getPayrollRetentionBaseSalaryByYear,
} from 'utils/worker'

import messages from 'messages/wage'

const BaseSalaryField = ({
  contractCategory,
  isEditing = false,
  optional,
  ...props
}) => {
  const confirm = useConfirm()
  const form = useFormikContext()

  const { values } = form
  const [baseSalary, setBaseSalary] = useState(values.base_salary)

  const handleValueChange = ({ floatValue: baseSalaryToChange }) => {
    const twoSMMLV = getMinimumSalary({
      multiplier: 2,
    })

    let transportSubsidy

    if (contractCategory !== 'contractor') {
      if (baseSalaryToChange <= twoSMMLV) {
        if (values.base_salary <= twoSMMLV) {
          transportSubsidy = values.transport_subsidy
        } else {
          transportSubsidy = true
        }
      } else {
        transportSubsidy = false
      }
    } else {
      transportSubsidy = null // N.A
    }

    form.setValues({
      ...values,
      base_salary: baseSalaryToChange,
      transport_subsidy: transportSubsidy,
    })
  }

  const handleFocus = () => setBaseSalary(values.base_salary)

  const handleMinimumSalaryLimit = (minimumSalary, wageCategory) => {
    if (
      values.initial_day &&
      contractCategory !== 'contractor' &&
      values.base_salary < minimumSalary
    ) {
      wait(200).then(() =>
        confirm({
          type: 'warning',
          okText: 'Sí',
          cancelText: 'No',
          title: `¿Estás seguro de que esta persona tendrá un salario inferior al salario ${
            wageCategory !== 'integral_salary' ? 'mínimo' : 'integral'
          }?`,
          onCancel: () => {
            form.setFieldValue('base_salary', minimumSalary)
          },
        })
      )
    }
  }

  const handlePayrollRetentionLimit = () => {
    confirm({
      type: 'info',
      okText: 'Entendido',
      hideCancelButton: true,
      title: 'Activa el cálculo automático de la retención en la fuente',
      description: (
        <>
          Este empleado está sujeto a retención en la fuente por concepto de
          salarios dado que su salario supera el tope legal. En el perfil de la
          Persona, en la opción de Conceptos Recurrentes puedes activar el
          cálculo automático de este valor bajo el procedimiento 1. Conoce más{' '}
          <Link
            href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-realizar-la-retención-en-la-fuente-por-salarios-a-mi-empleado-en-aleluya"
            target="_blank"
          >
            aquí
          </Link>
          .
        </>
      ),
    })
  }

  const handleBlur = () => {
    let minimumSalary = 0
    let wageCategory = 'monthly'
    if (values.base_salary !== baseSalary) {
      const checkSalaryLimits = () => {
        // little delay
        wait(200).then(() => {
          if (values.initial_day && contractCategory !== 'contractor') {
            minimumSalary = getMinimumSalary({
              date: values.initial_day,
            })

            if (values.wage_category === 'integral_salary') {
              wageCategory = 'integral_salary'
              minimumSalary = getMinimumSalary({
                date: values.initial_day,
                multiplier: 13,
              })
            }

            const payrollRetentionLimit = getPayrollRetentionBaseSalaryByYear(
              typeof values.initial_day === 'string'
                ? new Date(values.initial_day?.replace(/-/g, ','))
                : values.initial_day
            )

            if (
              values.base_salary < minimumSalary &&
              ['employee', 'student_decree_055', 'student_law_789'].includes(
                contractCategory
              )
            ) {
              handleMinimumSalaryLimit(minimumSalary, wageCategory)
            } else if (values.base_salary >= payrollRetentionLimit) {
              handlePayrollRetentionLimit()
            }
          }
        })
      }

      if (isEditing) {
        confirm({
          type: 'warning',
          title: 'Editar salario',
          description:
            '¿Estás seguro de querer editar este salario? Este cambio no puede deshacerse y afectará a todas las nóminas que incluyan este contrato.',
          okText: 'Sí',
          cancelText: 'No',
          onCancel: () => {
            form.setFieldValue('base_salary', baseSalary)
          },
          onOk: () => checkSalaryLimits(),
        })
      } else checkSalaryLimits()
    }
  }

  return (
    <FormField
      name="base_salary"
      label="Valor del salario"
      variant="currency"
      tooltipContent={messages.base_salary}
      onBlur={handleBlur}
      onValueChange={handleValueChange}
      onFocus={handleFocus}
      align="left"
      optional={optional}
      {...props}
    />
  )
}

export default BaseSalaryField
