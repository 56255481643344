import { Box, Typography } from '@mui/material'

import { Image } from '../Image'

export const picturesData = [
  {
    id: 'loved_by_customers',
    src: 'c905338a-f409-42e4-3e32-2fb1bafa7a00',
    mainText: 'NPS 83',
    description: (
      <>
        Nuestros clientes nos <b>aman</b>
      </>
    ),
  },
  {
    id: 'dian_certified',
    src: '9ba0517f-5d54-43a2-566a-e728d85d9800',
    mainText: 'Tecnología',
    description: (
      <>
        Certificada por la <b>DIAN</b>
      </>
    ),
  },
  {
    id: 'ugpp_approved',
    src: 'a9c9b8d5-ea39-423f-058e-06b05e6b0500',
    mainText: 'Avalados',
    description: (
      <>
        Por la <b>UGPP</b>
      </>
    ),
  },
]
export function BannerIcon({ mainText, src, description }) {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Image src={src} width={98.3} height={98.3} />
      <Typography variant="h4">{mainText}</Typography>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        {description}
      </Typography>
    </Box>
  )
}
