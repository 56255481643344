import { Link } from 'react-router-dom'

import { Box, Button, Chip, Typography } from '@mui/material'

import { formatLongDisplayDate } from 'utils/dateTime'

export const columnsData = [
  {
    Header: 'Acción pendiente',
    accessor: 'name',
    Cell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography color="black.dark">{row.original.name}</Typography>
      </Box>
    ),
    noWrapHeader: true,
  },
  {
    Header: 'Fecha',
    accessor: 'date',
    Cell: ({ row }) => {
      if (!row.original.date) return null

      return (
        <Typography color="black.dark">
          {formatLongDisplayDate(row.original.date)}
        </Typography>
      )
    },
  },
  {
    Header: 'Estado',
    accessor: 'status',
    alignHeader: 'center',
    alignCell: 'center',
    Cell: ({ row }) => {
      return (
        <Chip
          color={row.original.status ? 'success' : 'warning'}
          label={row.original.status ? 'Aprobada' : 'Pendiente'}
        />
      )
    },
  },
  {
    Header: 'Acciones',
    accessor: 'action',
    Cell: ({ row }) => (
      <Button variant="text" component={Link} to={row.original.pathTo}>
        Ver más
      </Button>
    ),
    noWrapCell: true,
    alignHeader: 'center',
    alignCell: 'center',
  },
]

export const getSocialBenefitName = (category) => {
  if (category === 'severance') return 'cesantías'
  if (category === 'service_bonus') return 'prima'
  if (category === 'severance_interests') return 'intereses a las cesantías'

  return null
}
