import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Typography } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import usePartnerSubscriptionModal from 'components/Subscription/Index/Plan/PartnerSubscription/usePartnerSubscriptionModal'
import SubscriptionCardsModal from 'components/Subscription/Index/Plan/SubscriptionCardsModal'
import { COMPANY_ACCESS } from 'components/Subscription/helpers'
import useWorker from 'components/Worker/useWorker'

import { isOrganizer } from 'utils/auth'

import { SUBSCRIPTION_INDEX, SUBSCRIPTION_PLANS } from 'config/routes'

import PartnerChildPremiumExpiredModal from '../../Subscription/Index/Plan/PartnerSubscription/PartnerChildPremiumExpiredModal'
import useModals from '../ModalsManager/useModals'
import { useUser } from '../UserContext/useUser'

const usePremiumFeature = () => {
  const modals = useModals()
  const { subscription, isCompanyUsing20241111Plans } = useSubscription()
  const { worker } = useWorker({ useCache: true })
  const { isAPartnerChild } = useUser()
  const { openPartnerSubscriptionModal } = usePartnerSubscriptionModal()
  const navigate = useNavigate()
  const location = useLocation()

  const adminCompanyAccess = subscription?.company_access
  const workerCompanyAccess = worker?.company_access
  const companyAccess = adminCompanyAccess || workerCompanyAccess

  const showPremiumFeatures = [
    COMPANY_ACCESS.PREMIUM,
    COMPANY_ACCESS.PREMIUM_HR,
    COMPANY_ACCESS.PREMIUM_PLUS,
    COMPANY_ACCESS.PREMIUM_FULL,
  ].includes(companyAccess)

  const showHRFeatures = [
    COMPANY_ACCESS.PREMIUM_HR,
    COMPANY_ACCESS.PREMIUM_FULL,
  ].includes(companyAccess)

  const showHPFeatures =
    isCompanyUsing20241111Plans && companyAccess === COMPANY_ACCESS.PREMIUM_FULL

  const openPremiumFeatureModal = useCallback(() => {
    const isPlanBaseAliados =
      subscription.plan?.coded_name.includes('plan_base_aliados')

    if (isAPartnerChild && subscription?.status === 'premium_expired') {
      modals.openModal({
        id: 'partnerChildPremiumExpiredModal',
        content: <PartnerChildPremiumExpiredModal />,
        modalProps: {
          hideFooter: true,
          paperSx: {
            maxWidth: '49rem',
          },
        },
      })

      return
    }

    if (isAPartnerChild) {
      openPartnerSubscriptionModal()
      return
    }

    if (
      isCompanyUsing20241111Plans &&
      location.pathname === SUBSCRIPTION_INDEX()
    ) {
      navigate(SUBSCRIPTION_PLANS())
      return
    }

    modals.openModal({
      id: 'subscriptionCardsModal',
      content: <SubscriptionCardsModal isPlanBaseAliados={isPlanBaseAliados} />,
      modalProps: {
        hideFooter: true,
        header: (
          <Typography variant="h3" color="black.main">
            Precios transparentes y sin letra chiquita
          </Typography>
        ),
        paperSx: {
          maxWidth: '72.1875rem',
        },
      },
    })
  }, [
    isAPartnerChild,
    isCompanyUsing20241111Plans,
    location.pathname,
    modals,
    navigate,
    openPartnerSubscriptionModal,
    subscription.plan?.coded_name,
    subscription?.status,
  ])

  return {
    openPremiumFeatureModal,
    showPremiumFeatures: showPremiumFeatures || isOrganizer(),
    showPremiumFeatureIcon: !showPremiumFeatures,
    showHRFeatures,
    showHPFeatures,
  }
}

export default usePremiumFeature
