import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useItemsService from 'utils/hooks/payroll/items'
import usePayrollService from 'utils/hooks/payroll/payroll'
import usePayrollConceptsService from 'utils/hooks/payroll/payrollConcepts'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'
import {
  deductionsRecurrentConcepts,
  generateEmptyPayrollItem,
} from 'utils/payroll'

import { useNoveltyType, usePeriodAPI } from '../../helpers'
import CreatePayrollConceptModal from '../CreatePayrollConceptModal'
import Info from '../common/Info'
import Deductions from './Deductions'
import Loans from './Loans'
import PayrollRetention from './PayrollRetention'
import { deductionDescription, getModalHeader } from './helpers'
import useDeductionsConfig from './useDeductionsConfig'

const DeductionsModal = ({ state, handleClose }) => {
  const { payrollState } = useNoveltyType()
  const { updatePeriodCallback } = usePeriodAPI()
  const { workerName, workerPayroll, noveltyConcept } = payrollState
  const queryClient = useQueryClient()
  const payrollId = workerPayroll.id
  const { showWarningMessage } = useNotifications()
  const [initialData, setInitialData] = useState({})
  const [deductions, setDeductions] = useState([])
  const [concepts, setConcepts] = useState([])
  const [loanItems, setLoanItems] = useState([])
  const [deletedItems, setDeletedItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [uvtValueUpLimit, setUvtValueUpLimit] = useState(false)
  const [art206LimitValue, setArt206LimitValue] = useState(
    workerPayroll.art_206_uvt_limit
  )
  const [art336LimitValue, setArt336LimitValue] = useState(
    workerPayroll.art_336_uvt_limit
  )
  const [isCreatePayrollConceptModalOpen, setIsCreatePayrollConceptModalOpen] =
    useState(false)
  const { handleError } = useErrorHandler()
  const { getInitialData } = useDeductionsConfig()
  const deductionsQueryKey = ['payrollConcepts', 'deductions', payrollId]
  const deductionsItemsQueryKey = ['itemsByCategory', 'deductions', payrollId]
  const hasArt206UVTValueChanged =
    art206LimitValue !== workerPayroll.art_206_uvt_limit
  const hasArt336UVTValueChanged =
    art336LimitValue !== workerPayroll.art_336_uvt_limit

  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  const { payrollConceptsQuery } = usePayrollConceptsService({
    serviceParams: {
      queryKey: deductionsQueryKey,
      conceptsCategory: 'deductions',
    },
  })

  const { itemsQuery, itemsMutation } = useItemsService({
    serviceParams: {
      queryKey: deductionsItemsQueryKey,
      payrollId,
      conceptsCategory: 'deductions',
    },
    queryOptions: {
      enabled: payrollConceptsQuery.isSuccess,
      onSuccess: ({ data: itemsData }) => {
        const initData = getInitialData(
          payrollConceptsQuery.data,
          itemsData,
          workerPayroll.contract_category
        )

        setInitialData(initData)
        setDeductions(initData.deductionItems)
        setConcepts(initData.deductionConcepts)
        setLoanItems(initData.loanItems)
      },
    },
  })

  const isGettingData = payrollConceptsQuery.isLoading || itemsQuery.isLoading

  const handleChangeLoanValue = (
    { currentTarget: { rawValue } },
    modifiedIndex
  ) => {
    const newLoanItems = loanItems.map((loan, index) => {
      return index === modifiedIndex ? { ...loan, value: rawValue || 0 } : loan
    })

    setLoanItems(newLoanItems)
  }

  const handleSelectItem = (payrollConceptId, index) => {
    const prevItem = deductions[index]

    let selectedConcept

    const newConcepts = concepts.map((concept) => {
      const conceptCopy = { ...concept }

      if (prevItem.payroll_concept_id === conceptCopy.id)
        conceptCopy.selected = false

      if (conceptCopy.id === payrollConceptId) {
        conceptCopy.selected = true

        selectedConcept = conceptCopy
      }

      return conceptCopy
    })

    const newDeductions = [...deductions]

    newDeductions[index] = {
      ...prevItem,
      name: selectedConcept.name,
      payroll_concept_id: selectedConcept.id,
      coded_name: selectedConcept.coded_name,
    }

    setConcepts(newConcepts)
    setDeductions(newDeductions)
  }

  const handleChangeItemValue = ({ target: { rawValue } }, index) => {
    const newDeductions = [...deductions]

    newDeductions[index] = { ...newDeductions[index], value: Number(rawValue) }

    setDeductions(newDeductions)
  }

  const handleDeleteItem = (index) => {
    const newDeductions = [...deductions]

    const itemToDelete = newDeductions[index]

    newDeductions.splice(index, 1)

    if (!deductionsRecurrentConcepts.includes(itemToDelete.coded_name)) {
      setDeletedItems([...deletedItems, itemToDelete])
    }

    if (itemToDelete.payroll_concept_id !== null) {
      const newConcepts = concepts.map((concept) => {
        const conceptCopy = { ...concept }

        if (itemToDelete.payroll_concept_id === conceptCopy.id)
          conceptCopy.selected = false

        return conceptCopy
      })
      setConcepts(newConcepts)
    }

    if (newDeductions.length === 0) {
      newDeductions.push(generateEmptyPayrollItem())
    }

    setDeductions(newDeductions)
  }

  const handleAddConcept = () => {
    const newDeductions = [...deductions]

    newDeductions.push(generateEmptyPayrollItem())

    setDeductions(newDeductions)
  }

  const handleCloseCreatePayrollConceptModal = (createdConcept) => {
    if (createdConcept) {
      const newConcepts = [...concepts, { ...createdConcept, selected: true }]

      setConcepts(newConcepts)

      const newDeductions = [
        ...deductions,
        generateEmptyPayrollItem(createdConcept.id, createdConcept.name),
      ]

      setDeductions(newDeductions)
    }

    setIsCreatePayrollConceptModalOpen(false)
  }

  const onCloseModal = () => {
    handleClose()
    queryClient.removeQueries(deductionsQueryKey)
    queryClient.removeQueries(deductionsItemsQueryKey)
  }

  const submitItemsMutation = (dataToSend) => {
    return itemsMutation.mutateAsync(
      {
        mutationMethod: 'PUT',
        payrollId,
        deductionItem: dataToSend,
      },
      {
        onSuccess: ({ data }) => {
          updatePeriodCallback(data)
          Mixpanel.track(MIXPANEL_EVENTS.PAYROLL_LOAN_OTHER_DEDUCTION_ADD, {
            company_id: getCompanyId(),
            user_id: getUserId(),
            user_role: getUserRole(),
          })
        },
      }
    )
  }

  const submitPayrollRetentionMutation = () => {
    return payrollMutation.mutateAsync(
      {
        mutationMethod: 'PATCH',
        payrollId,
        data: {
          payroll: {
            art_206_uvt_limit: art206LimitValue,
            art_336_uvt_limit: art336LimitValue,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          updatePeriodCallback(data)
        },
      }
    )
  }

  const handleSendData = async () => {
    setIsLoading(true)
    const dataToSend = [...deletedItems]

    const initialDeductions =
      initialData.deductionItems &&
      initialData.deductionItems.map((deduction) => ({
        ...deduction,
      }))

    // Compare initial deductions with current deductions to get the changed ones
    deductions.forEach((ded) => {
      if (ded.payroll_concept_id) {
        let dedChecked = false
        initialDeductions.some((initDed) => {
          if (
            ded.payroll_concept_id === initDed.payroll_concept_id ||
            ded.id === initDed.id
          ) {
            if (
              ded.value !== initDed.value ||
              ded.payroll_concept_id !== initDed.payroll_concept_id
            ) {
              dataToSend.push({
                ...ded,
                id: initDed.id,
              })
            }
            // Have to disable this line because this key is needed out of loop
            // eslint-disable-next-line no-param-reassign
            initDed.checked = true
            dedChecked = true

            return true
          }
          return false
        })

        let addToDataSend

        if (deductionsRecurrentConcepts.includes(ded.coded_name)) {
          addToDataSend = !dedChecked && ded.payroll_concept_id && ded.value > 0
        } else {
          addToDataSend = !dedChecked && ded.payroll_concept_id
        }

        if (addToDataSend) {
          dataToSend.push(ded)
        }
      }
    })

    // Those not checked are added to deletion
    initialDeductions?.forEach((initDed) => {
      if (!initDed.checked && initDed.payroll_concept_id) {
        dataToSend.push({
          id: initDed.id,
          payroll_concept_id: initDed.payroll_concept_id,
          value: 0,
        })
      }
    })

    for (let i = 0; i < loanItems.length; i += 1) {
      const initLoan = initialData.loanItems[i]
      const loan = loanItems[i]

      if (initLoan.value !== loan.value) dataToSend.push(loan)
    }

    const deductionPromises = []

    if (
      dataToSend.length > 0 ||
      (!uvtValueUpLimit &&
        (hasArt206UVTValueChanged || hasArt336UVTValueChanged))
    ) {
      if (dataToSend.length > 0) {
        const submitItemsMutationResponse = submitItemsMutation(dataToSend)
        deductionPromises.push(submitItemsMutationResponse)
      }

      if (hasArt206UVTValueChanged || hasArt336UVTValueChanged) {
        const submitPayrollRetentionMutationResponse =
          submitPayrollRetentionMutation()
        deductionPromises.push(submitPayrollRetentionMutationResponse)
      }

      try {
        await Promise.all(deductionPromises)
        setIsLoading(false)
        onCloseModal()
      } catch (error) {
        handleError(error)
        setIsLoading(false)
      }
    } else if (uvtValueUpLimit) {
      setIsLoading(false)
      showWarningMessage('Estás excediendo el límite legal de UVTs')
    } else {
      setIsLoading(false)
      onCloseModal()
    }
  }

  const payrollRetentionItemIndex = deductions?.findIndex(
    (deduction) => deduction.coded_name === 'withholding_tax'
  )
  const payrollRetentionItem = deductions[payrollRetentionItemIndex]

  const payrollRetentionInfo = {
    payrollRetentionItemIndex,
    payrollRetentionItem,
  }

  return (
    <Modal
      open={state.open}
      header={getModalHeader(noveltyConcept, workerName)}
      onOk={handleSendData}
      okText="Guardar"
      onCancel={onCloseModal}
      isLoading={isLoading}
      paperSx={{
        maxWidth: {
          tablet: '48.5rem',
        },
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        })}
      >
        <Box>
          <Typography
            paragraph
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
              color: theme.palette.black.dark,
            })}
          >
            {deductionDescription[noveltyConcept]}
          </Typography>
          {!isGettingData && !isObjectEmpty(initialData) ? (
            <>
              {noveltyConcept === 'deductions' ? (
                <Deductions
                  novelty={noveltyConcept}
                  payrollRetentionInfo={payrollRetentionInfo}
                  tabData={{ deductions, concepts }}
                  handleSelectItem={handleSelectItem}
                  handleChangeItemValue={handleChangeItemValue}
                  handleDeleteItem={handleDeleteItem}
                  handleAddConcept={handleAddConcept}
                  setIsCreatePayrollConceptModalOpen={
                    setIsCreatePayrollConceptModalOpen
                  }
                />
              ) : null}
              {noveltyConcept === 'loans' ? (
                <Loans
                  loanItems={loanItems}
                  handleChangeLoanValue={handleChangeLoanValue}
                />
              ) : null}
              {noveltyConcept === 'payroll_retention' ? (
                <PayrollRetention
                  handleChangeItemValue={handleChangeItemValue}
                  payrollRetentionInfo={payrollRetentionInfo}
                  workerPayroll={workerPayroll}
                  uvtValueUpLimit={uvtValueUpLimit}
                  setUvtValueUpLimit={setUvtValueUpLimit}
                  setArt206LimitValue={setArt206LimitValue}
                  setArt336LimitValue={setArt336LimitValue}
                />
              ) : null}
            </>
          ) : (
            <LoadingBox />
          )}
        </Box>
        {isCreatePayrollConceptModalOpen ? (
          <CreatePayrollConceptModal
            category="deductions"
            handleClose={handleCloseCreatePayrollConceptModal}
          />
        ) : null}
        <Info novelty={noveltyConcept} />
      </Box>
    </Modal>
  )
}

export default DeductionsModal
