import Modal from 'components/UI/Modal/Modal'

import FinalSigningsStatusModalContent from './FinalSigningsStatusModalContent'
import Header from './Header'

const FinalSigningsStatusModal = ({ state, handleClose }) => {
  return (
    <Modal
      open={state.open}
      header={<Header status={state?.electronicSignature?.status} />}
      onCancel={handleClose}
      hideFooter
      paperSx={{
        maxWidth: '46rem',
        width: '100%',
      }}
    >
      <FinalSigningsStatusModalContent
        workerId={state?.workerId}
        workerName={state?.workerName}
        status={state?.electronicSignature?.status}
        handleClose={handleClose}
      />
    </Modal>
  )
}

export default FinalSigningsStatusModal
