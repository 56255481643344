import { useEffect } from 'react'

import {
  Box,
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'
import { Image } from 'components/UI/Image'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import useConfetti from 'utils/hooks/useConfetti'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

export function WelcomeModal({
  state,
  handleClose,
  company,
  openUpdateCompanyPhoneModal,
}) {
  const isLaptopUp = useMediaQuery((theme) => theme.breakpoints.up('laptop'))
  const { throwConfetti } = useConfetti()

  const handleStartTour = () => {
    Mixpanel.track(MIXPANEL_EVENTS.INTERACTIVE_TOUR_STARTED, {
      user_id: getUserId(),
      company_id: getCompanyId(),
      user_role: getUserRole(),
    })
    handleClose()
    window.location.href = 'https://app.storylane.io/share/zte4ufakmei0'
  }

  const {
    onboarding: { workers_number: workersNumber },
  } = company

  const hasTwentyorMoreWorkers = workersNumber !== '1-19'

  useEffect(() => {
    throwConfetti({ origin: { x: 0.5, y: 0.9 }, particleCount: 150 })
    throwConfetti({ origin: { x: 0.3, y: 0.4 }, particleCount: 150 })
    throwConfetti({ origin: { x: 0.7, y: 0.4 }, particleCount: 150 })
  }, [throwConfetti])

  const onClose = () => {
    if (hasTwentyorMoreWorkers) {
      openUpdateCompanyPhoneModal()
    }

    handleClose()
  }

  return (
    <Modal
      open={state.open}
      onCancel={onClose}
      hideFooter
      paperSx={{
        maxWidth: '56rem',
      }}
      contentWrapperSx={{
        overflow: 'hidden',
      }}
    >
      <ButtonBase
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          cursor: 'pointer',
          color: theme.palette.black.main,
          right: theme.spacing(-1),
          top: theme.spacing(0),
        })}
      >
        <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
      </ButtonBase>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            mobile: '1fr',
            laptop: '1fr  3fr',
          },
        }}
      >
        {isLaptopUp ? (
          <Box>
            <Box
              sx={(theme) => ({
                width: '35rem',
                height: '35rem',
                borderRadius: '100%',
                position: 'absolute',
                left: '-17rem',
                top: '-8.75rem',
                backgroundColor: theme.palette.complementary1.main,
              })}
            />
            <Box
              sx={{
                position: 'relative',
                height: '100%',
                width: '18rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Image
                src="68ebf52f-1afc-4fec-1f84-0be8f9640100"
                height={330}
                width={290}
              />
            </Box>
          </Box>
        ) : null}
        <Box
          sx={(theme) => ({
            marginRight: theme.spacing(2.5),
            [theme.breakpoints.up('laptop')]: {
              marginLeft: theme.spacing(4),
            },
          })}
        >
          <Typography
            variant="h2"
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
            })}
          >
            ¡Bienvenido a Aleluya!
          </Typography>
          <Typography
            variant="body1"
            color="black.light"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
              gap: theme.spacing(2),
            })}
          >
            Has desbloqueado el{' '}
            <Typography sx={{ fontWeight: 'bold' }} component="span">
              plan Nómina holística GRATIS por 15 días.{' '}
            </Typography>
            Aprovecha esta oportunidad para explorar con nuestro{' '}
            <Typography sx={{ fontWeight: 'bold' }} component="span">
              tour interactivo
            </Typography>{' '}
            algunas de las funcionalidades que tenemos para ti.
          </Typography>
          <Typography
            variant="body1"
            color="black.light"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
            })}
          >
            Y recuerda, para una mejor experiencia ingresa a Aleluya desde tu
            computadora!
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(2),
              [theme.breakpoints.down('tablet')]: {
                flexDirection: 'column',
              },
            })}
          >
            <Button onClick={handleStartTour}>Comencemos</Button>
            <Button variant="outlined" onClick={onClose}>
              Cerrar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
