import { Box, Typography } from '@mui/material'

import WellnessHalfDonutChart from 'components/HolisticPayroll/Commons/WellnessHalfDonutChart'

import { formatDisplayDateString } from 'utils/dateTime'

export const getCardsContent = (
  financialWellnessIndicatorAverage,
  coachingSessions
) => {
  return [
    {
      id: 'coming_soon',
      title: 'Próximamente',
      mainContent: (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <WellnessHalfDonutChart value={0} enableEmptyState />
          </Box>

          <Typography
            variant="h4"
            sx={(theme) => ({
              textAlign: 'center',
              marginTop: theme.spacing(5),
            })}
          >
            Próximamente
          </Typography>
        </Box>
      ),
    },
    {
      id: 'indicator_score',
      title: 'Indicador de Bienestar',
      mainContent: (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <WellnessHalfDonutChart
              value={financialWellnessIndicatorAverage || 0}
            />
          </Box>

          {!financialWellnessIndicatorAverage ? (
            <Typography
              variant="h4"
              sx={(theme) => ({
                textAlign: 'center',
                marginTop: theme.spacing(5),
              })}
            >
              Sin datos medibles aún
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="h5"
                sx={(theme) => ({
                  textAlign: 'center',
                  margin: theme.spacing(3.6, 0, 1),
                })}
              >
                Puntaje promedio
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: 'center', color: 'info.main' }}
              >
                Actualizado el{' '}
                {formatDisplayDateString(
                  financialWellnessIndicatorAverage?.update_at
                )}
              </Typography>
            </Box>
          )}
        </Box>
      ),
    },
    {
      id: 'coaching_sessions',
      title: 'Sesiones de Coaching',
      mainContent: (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.5625rem',
                color: 'black.light',
                textAlign: 'center',
              }}
            >
              Número de sesiones realizadas:
            </Typography>
            <Typography
              sx={{
                fontSize: '6.125rem',
                fontWeight: '700',
                textAlign: 'center',
                fontFamily: 'Montserrat',
              }}
            >
              {!coachingSessions
                ? '0'
                : coachingSessions <= 9
                  ? `0${coachingSessions}`
                  : coachingSessions}
            </Typography>
          </Box>
        </>
      ),
    },
  ]
}

export const getFinancialWellnessDimensionsInfo = (dimensionInfo = {}) => {
  const {
    present_security,
    contingency_prevention,
    future_planning,
    debt_management,
    financial_abundance,
  } = dimensionInfo

  return [
    {
      id: 'present_security',
      label: 'Seguridad en el presente',
      linearBackgroundColor: 'accent2.main',
      percentage: present_security?.score || 0,
    },
    {
      id: 'contingency_prevention',
      label: 'Prevención de imprevistos',
      linearBackgroundColor: 'success.main',
      percentage: contingency_prevention?.score || 0,
    },
    {
      id: 'future_planning',
      label: 'Planeación del futuro',
      linearBackgroundColor: 'info.main',
      percentage: future_planning?.score || 0,
    },
    {
      id: 'debt_management',
      label: 'Gestión de deudas',
      linearBackgroundColor: 'info.main',
      percentage: debt_management?.score || 0,
    },
    {
      id: 'financial_abundance',
      label: 'Abundancia financiera',
      linearBackgroundColor: 'error.main',
      percentage: financial_abundance?.score || 0,
    },
  ]
}
