import { Form, Formik } from 'formik'

import { Box, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId, payrollFrequenciesOptions } from 'utils/company'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import fileService from 'services/payroll/fileService'

const dataTypeOptions = [
  { label: 'Resumido', value: 'summary' },
  { label: 'Detallado', value: 'detailed' },
]

const PayrollSummaryFileModal = ({ state, handleClose }) => {
  const { open, period = {} } = state
  const { enqueueDownload } = useDownloadManager()

  /** Check if its fortnightly and its in the second fortnight */
  const isFortnightly = period?.initial_day?.getDate() === 16

  const handleSubmit = async (values) => {
    handleClose()

    enqueueDownload({
      name: 'resumen de nómina',
      fileCode: 'payroll_summary',
      firebasePath: `periods/${period.id}/payroll_summary`,
      service: () => fileService.getPayrollsSummary(period.id, values),
      callback: () => {
        Mixpanel.track(MIXPANEL_EVENTS.DOWNLOAD_PAYROLL_SUMMARY_FILE_V2, {
          company_id: getCompanyId(),
          user_id: getUserId(),
          user_role: getUserRole(),
          file_type: values.data_type,
          file_frequency: isFortnightly ? values.file_type : 'monthly',
        })
      },
    })
  }

  return (
    <Formik
      initialValues={{
        data_type: 'summary',
        file_type: payrollFrequenciesOptions[0].value,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit: onSubmit, isSubmitting }) => {
        return (
          <Modal
            open={open}
            onOk={onSubmit}
            onCancel={handleClose}
            isLoading={isSubmitting}
            okText="Generar"
            loadingText="Generando..."
            header="Generar resumen de nómina"
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            dialogProps={{
              maxWidth: 'sm',
              fullWidth: true,
            }}
          >
            <Form>
              {isFortnightly ? (
                <Typography gutterBottom>
                  Selecciona el tipo de archivo a generar. &quot;Quicenal&quot;
                  genera el archivo para una sola quincena, mientras que
                  &quot;Mensual&quot; genera el archivo para el mes o las dos
                  quincenas.
                </Typography>
              ) : null}
              <Typography gutterBottom>
                Ademas permite generar la información resumida con el valor
                &quot;Resumido&quot; o detallada por conceptos de nómina con el
                valor &quot;Detallado&quot;
              </Typography>
              {isFortnightly ? (
                <FormField
                  name="file_type"
                  label="Tipo de archivo"
                  variant="select"
                  options={payrollFrequenciesOptions}
                />
              ) : null}
              <Box
                sx={(theme) => ({
                  marginTop: theme.spacing(2),
                })}
              >
                <FormField
                  name="data_type"
                  label="Tipo de información"
                  variant="select"
                  options={dataTypeOptions}
                />
              </Box>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default PayrollSummaryFileModal
