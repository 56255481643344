import { Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import useExogenaReportService from 'utils/hooks/periodHistory/exogenaReport'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

const ExogenaReportModal = ({ openModal, handleClose }) => {
  const lastYear = new Date().getFullYear() - 1
  const { exogenaReportMutation } = useExogenaReportService()
  const { enqueueDownload } = useDownloadManager()

  const handleSubmit = () => {
    enqueueDownload({
      name: 'reporte para exógena',
      fileCode: 'exogena_report',
      firebasePath: `companies/${getCompanyId()}/exogenous_information_file`,
      service: () =>
        exogenaReportMutation.mutateAsync(
          {
            mutationMethod: 'GET',
          },
          {
            onSuccess: () => {
              Mixpanel.track(MIXPANEL_EVENTS.DOWNLOAD_EXOGENA_FILE, {
                company_id: getCompanyId(),
                user_id: getUserId(),
                user_role: getUserRole(),
              })
              handleClose()
            },
          }
        ),
    })
  }

  return (
    <Modal
      okText="Generar archivo"
      header="Reporte Anual para reportar información de Exógena"
      loadingText="Generando archivo..."
      open={openModal}
      onOk={handleSubmit}
      onCancel={handleClose}
      isLoading={exogenaReportMutation.isLoading}
    >
      <Typography color="black.dark">
        Con esta opción puedes descargar un reporte consolidado con la
        información que necesitas para construir el reporte de medios
        magneticos: Exógena.
      </Typography>
      <Typography
        color="black.dark"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
          marginTop: theme.spacing(0.5),
        })}
      >
        <b>Recuerda:</b> Se va generar el archivo correspondiente al año{' '}
        <b>{lastYear}</b>
      </Typography>
    </Modal>
  )
}

export default ExogenaReportModal
