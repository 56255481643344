export const EMAIL_SUPPORT = 'soporte@aleluya.com'
export const EMAIL_DATA = 'datos@aleluya.com'
export const EMAIL_HELLO = 'hola@aleluya.com'
export const EMAIL_ACTIVATION = 'habilitaciones@aleluya.com'
export const EMAIL_ACCOUNTANT = 'contabilidad@aleluya.com'
export const DISABILITY_CLAIMS_EMAIL = 'reclamaciones@aleluya.com'
export const WHATSAPP_SALES =
  'https://api.whatsapp.com/send?phone=573006368842&text&app_absent=0'
export const HELP_CENTER = 'https://ayuda.aleluya.com/portal/es/kb/'
export const PLANS_DETAIL_HELP_CENTER =
  'https://ayuda.aleluya.com/portal/es/kb/articles/conoce-las-funcionalidades-de-cada-plan-en-aleluya#Nuestros_planes'
export const EMAIL_ADD_EMPLOYEES = 'carga@aleluya.com'
