import { Image as UnpicImage } from '@unpic/react'

import { uuid } from 'utils/regex'

const CLOUDFLARE_IMAGES_URL = `https://${process.env.REACT_APP_CLOUDFLARE_IMAGES_HOST}/cdn-cgi/imagedelivery/${process.env.REACT_APP_CLOUDFLARE_IMAGES_ACCOUNT_HASH}`

/**
 * A React component for rendering optimized images using the Unpic library.
 *
 * Features:
 * - Supports both direct image URLs and Cloudflare Images IDs
 * - Automatic image optimization and transformation via Cloudflare Images
 *
 * @see https://unpic.pics/img/react/#image-props - Unpic Image documentation
 * @see https://developers.cloudflare.com/images/transform-images/transform-via-url/#options - Cloudflare Images transformation options
 */
export function Image({ src, alt = '', className, operations, ...props }) {
  const isCloudflareImage = src && uuid.test(src)

  return (
    <UnpicImage
      src={isCloudflareImage ? `${CLOUDFLARE_IMAGES_URL}/${src}` : src}
      alt={alt}
      className={className}
      objectFit="contain"
      {...props}
      {...(isCloudflareImage && {
        cdn: 'cloudflare_images',
        operations: {
          cloudflare_images: {
            fit: 'scale-down',
            ...operations,
            format: 'auto',
          },
        },
      })}
    />
  )
}
