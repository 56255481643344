import { Form, Formik } from 'formik'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Box,
  Collapse,
  Divider,
  Link as MuiLink,
  Typography,
} from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import GoogleLoginButton from 'components/Auth/common/GoogleAuth'
import Button from 'components/UI/Button/Button'
import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import FormField from 'components/UI/Formik/FormField/Index'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Link from 'components/UI/MaterialUI/Link'

import useSessionService from 'utils/hooks/auth/session'
import useUsersService from 'utils/hooks/settings/usersService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

import oauthService from 'services/auth/oauthService'

import { LOGIN_OAUTH, PASSWORD_RECOVERY } from 'config/routes'

import messages from 'messages/notification'

import LinkToSignup from '../common/LinkToSignup'
import MainContainer from '../common/MainContainer'
import { AlertMessage, getValidationSchema, initialValues } from './helpers'

const SignIn = () => {
  const [otpAuthStep, setOtpAuthStep] = useState(1)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState({
    code: '',
    message: '',
  })

  const location = useLocation()
  const { handleError } = useErrorHandler()
  const { logIn } = useUser()
  const { showSuccessMessage } = useNotifications()

  const {
    client_id: clientId,
    unlock_token,
    worker,
  } = queryString.parse(location.search)

  const isWorker = worker === 'true'
  const fromOAuth = location.pathname === LOGIN_OAUTH

  if (fromOAuth && !clientId) {
    throw new Error('Error en la autenticación OAuth')
  }

  const { sessionMutation } = useSessionService()
  const { usersQuery } = useUsersService({
    serviceParams: {
      queryKey: 'unlockAccount',
      unlock_token,
    },
    queryOptions: {
      enabled: Boolean(unlock_token),
      onSuccess: () => {
        setShowMessage(true)
      },
    },
  })

  const { isLoading } = sessionMutation

  useEffect(() => {
    if (unlock_token) {
      setMessage((previousMessage) => ({
        ...previousMessage,
        message: usersQuery.data.message,
      }))
    }
  }, [unlock_token, usersQuery.data.message])

  const handleSubmit = async (values, form) => {
    try {
      if (fromOAuth) {
        const { access_token: accessToken, token_type: tokenType } =
          await oauthService.login(values)

        const { redirect_uri: redirectUri } = await oauthService.requestAuth(
          clientId,
          tokenType,
          accessToken
        )

        form.setSubmitting(false)
        window.location.href = redirectUri
      } else {
        await sessionMutation.mutateAsync(
          {
            mutationMethod: 'POST',
            credentials: {
              ...values,
            },
          },
          {
            onSuccess: ({ data, otp_auth: otpAuth }) => {
              if (data) {
                showSuccessMessage(messages.LOGIN_OK)
                if (data.user.id) {
                  Mixpanel.identify(data.user.id)

                  try {
                    Mixpanel.instance.people.set({
                      $email: data.user.email,
                      $name: data.user.name,
                    })
                  } catch (error) {
                    // We don't want to break the app if Mixpanel fails
                  }
                }

                logIn(data)

                Mixpanel.track(
                  MIXPANEL_EVENTS.SIGN_IN_V2,
                  {
                    user_id: data.user.id,
                    company_id: data.user.company_id,
                    user_role: data.user.role,
                    multicompany: data.user.multicompany,
                  },
                  true
                )
              } else if (otpAuth) {
                setOtpAuthStep(2)
              }
            },
          }
        )
      }
    } catch (error) {
      const { errors } = error

      setMessage(errors[0])
      setShowMessage(true)
      handleError(error, form, { redirect: false })
    }
  }

  const handleCloseAlert = () => {
    setShowMessage(false)
  }

  return (
    <MainContainer>
      <Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(2),
            gap: theme.spacing(1),
          })}
        >
          <Typography variant="h3">
            {otpAuthStep === 1
              ? isWorker
                ? '¿Tu empresa ya te dio acceso?'
                : `Ingresa a tu cuenta de Aleluya`
              : 'Ingresa tu código de verificación'}
          </Typography>
          <Typography variant="body1" color="black.light">
            {isWorker
              ? 'Para ingresar, es necesario que tu empresa use Aleluya y haya habilitado tu acceso.'
              : 'Ingresa los siguientes datos para iniciar sesión en tu cuenta de Aleluya.'}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {otpAuthStep === 1 ? <GoogleLoginButton action="signin" /> : null}
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Divider
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            width: '50%',
            marginY: theme.spacing(2),
            alignSelf: 'center',
            '&::before, ::after': {
              borderColor: theme.palette.black.light,
            },
          })}
        >
          <Typography variant="h6" color="white.dark" translate="no">
            O
          </Typography>
        </Divider>
      </Box>
      <Collapse in={showMessage}>
        <AlertMessage
          message={message}
          otpAuthStep={otpAuthStep}
          handleCloseAlert={handleCloseAlert}
        />
      </Collapse>
      <Collapse in={Boolean(location.state?.passwordRecoveredSuccessfully)}>
        <Alert
          severity="success"
          closable
          header="¡Tu contraseña fue actualizada!"
          sx={(theme) => ({
            margin: theme.spacing(2, 0),
          })}
        >
          Acabas de cambiar tu contraseña, puedes continuar disfrutando de la
          mejor experiencia liquidando nómina.
        </Alert>
      </Collapse>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={getValidationSchema(otpAuthStep)}
      >
        {({ resetForm }) => {
          return (
            <Box sx={{ width: '100%' }}>
              <Form>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    margin: theme.spacing(1, 0),
                  })}
                >
                  <Box
                    sx={({ spacing, breakpoints }) => ({
                      display: 'grid',
                      gridTemplateColumns: '1fr',
                      rowGap: spacing(2),
                      marginBottom: spacing(6),
                      [breakpoints.down('tablet')]: {
                        marginBottom: spacing(4),
                      },
                    })}
                  >
                    {otpAuthStep === 1 ? (
                      <>
                        <FormField
                          type="email"
                          name="email"
                          label="Correo"
                          placeholder="Ingresa tu correo electrónico"
                          autoComplete="email"
                          disabled={isLoading}
                          optional={false}
                        />
                        <PasswordField
                          name="password"
                          label="Contraseña"
                          placeholder="Ingresa tu contraseña"
                          autoComplete="password"
                          disabled={isLoading}
                          optional={false}
                        />
                      </>
                    ) : (
                      <FormField
                        type="name"
                        name="otp_code"
                        optional={false}
                        label="Código de autenticación"
                        inputProps={{
                          maxLength: 6,
                          autoComplete: 'one-time-code',
                        }}
                        disabled={isLoading}
                      />
                    )}
                  </Box>
                  <Button
                    type="submit"
                    loading={isLoading}
                    sx={({ spacing, breakpoints }) => ({
                      width: '100%',
                      marginBottom: spacing(6),
                      [breakpoints.down('tablet')]: {
                        marginBottom: spacing(3),
                      },
                    })}
                    data-cy="sign_in_button"
                  >
                    Ingresar
                  </Button>
                </Box>
              </Form>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {otpAuthStep === 1 ? (
                  <>
                    <Link
                      to={
                        isWorker
                          ? `${PASSWORD_RECOVERY}?worker=true`
                          : PASSWORD_RECOVERY
                      }
                      sx={{ fontSize: '0.875rem' }}
                    >
                      ¿Olvidaste tu contraseña?
                    </Link>
                    {isWorker ? (
                      <Typography
                        variant="body2"
                        color="white.dark"
                        sx={({ spacing, breakpoints }) => ({
                          marginTop: spacing(1),
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: spacing(0.5),
                          [breakpoints.down('tablet')]: {
                            gap: 0,
                          },
                        })}
                      >
                        ¿Primera vez ingresando como empleado?
                        <MuiLink
                          sx={{ fontSize: '0.875rem' }}
                          href="https://ayuda.aleluya.com/portal/es/kb/articles/¿cómo-ingreso-a-mi-perfil-de-empleado-en-aleluya?#Beneficios_de_acceder_a_tu_perfil_web_en_Aleluya"
                          target="_blank"
                          fontWeight={600}
                        >
                          Déjanos ayudarte
                        </MuiLink>
                      </Typography>
                    ) : null}
                  </>
                ) : (
                  <MuiLink
                    component="button"
                    onClick={() => {
                      setOtpAuthStep(1)
                      resetForm()
                    }}
                  >
                    Volver
                  </MuiLink>
                )}
                {otpAuthStep === 1 && !isWorker ? (
                  <Box
                    sx={({ spacing }) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: spacing(2),
                      marginTop: spacing(0.5),
                      textAlign: 'center',
                    })}
                  >
                    <LinkToSignup />
                  </Box>
                ) : null}
              </Box>
            </Box>
          )
        }}
      </Formik>
    </MainContainer>
  )
}

export default SignIn
