import {
  Box,
  Button,
  Divider,
  Link,
  Typography,
  alpha,
  useMediaQuery,
} from '@mui/material'

import Alert from 'components/UI/MaterialUI/Alert/Alert'

import { formatCurrency } from 'utils/format'

const Formula = ({ formula, isTemplate = true, information }) => {
  const { label, numerator, denominator, value, complement } = formula

  const isBelowLaptop = useMediaQuery((theme) =>
    theme.breakpoints.down('laptop')
  )

  return (
    <Alert
      severity="info"
      icon={false}
      sx={(theme) => ({
        padding: theme.spacing(2.5, 4, 2.5, 4),
        borderRadius: '1rem',
        ...(isTemplate && {
          margin: theme.spacing(2.5, 0, 2.5, 0),
        }),
        ...(!isTemplate && {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          display: 'flex',
          justifyContent: 'center',
        }),
      })}
    >
      {!isTemplate ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: theme.spacing(5.5),
            [theme.breakpoints.down('tablet')]: {
              flexDirection: 'column',
              columnGap: 0,
              rowGap: theme.spacing(5),
            },
          })}
        >
          <Typography
            variant={isBelowLaptop ? 'h3' : 'h4'}
            color="primary.dark"
          >
            {label}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              fontSize={isBelowLaptop ? '1rem' : '1.5rem'}
              textAlign="center"
            >
              {numerator}
            </Typography>
            <Divider
              sx={(theme) => ({
                marginY: theme.spacing(1),
                width: '100%',
                borderColor: 'black.main',
                [theme.breakpoints.up('tablet')]: {
                  marginY: theme.spacing(2),
                },
              })}
            />
            <Typography fontSize={isBelowLaptop ? '1rem' : '1.5rem'}>
              {denominator}
            </Typography>
          </Box>
          {complement ? (
            <Typography
              fontSize={isBelowLaptop ? '1rem' : '1.5rem'}
              textAlign="center"
            >
              {complement}
            </Typography>
          ) : null}
          <Typography
            variant={isBelowLaptop ? 'h2' : 'h1'}
            color="primary.dark"
          >
            ={formatCurrency(value)}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography variant="lead2" color="info.dark">
            {label}
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
              [theme.breakpoints.up('tablet')]: {
                rowGap: 0,
                flexDirection: 'row',
                columnGap: theme.spacing(1),
                alignItems: 'center',
              },
            })}
          >
            <Typography
              sx={(theme) => ({
                [theme.breakpoints.down('tablet')]: {
                  marginTop: theme.spacing(1),
                },
              })}
            >
              {numerator} {denominator ? `Dividido el ${denominator}.` : null}
            </Typography>
            {information.link ? (
              <Button
                component={Link}
                href={information.link}
                target="_blank"
                variant="text"
                sx={{
                  color: 'initial',
                  '&:hover, &:focus': {
                    color: 'initial',
                    textDecoration: 'none',
                  },
                }}
              >
                Ver más detalles acerca del cálculo de{' '}
                {information.title.replace(/[0-9]/g, '').replace('-', '')}
              </Button>
            ) : null}
          </Box>
        </Box>
      )}
    </Alert>
  )
}

export default Formula
