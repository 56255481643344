import { Box, useMediaQuery } from '@mui/material'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

export default function HolisticPayrollTabs({
  activeTab,
  handleChangeTab,
  tabsConfig,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          margin: theme.spacing(7, 0),
        })}
      >
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          tabs={tabsConfig}
          variant={isMobile ? 'scrollable' : 'fullWidth'}
          scrollButtons={isMobile}
        />
      </Box>
      {tabsConfig[activeTab]?.content}
    </>
  )
}
