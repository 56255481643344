export const leadingZerosOrNotDigits = /^(0|[^\d])+|[^\d]/g
export const notAlphanumerics = /[^0-9a-z]/gi
export const notDigits = /[^\d]/g
export const nineDigits = /^[\d]{9}$/
export const sevenToThirteenDigits = /^[\d]{6,13}$/
export const sevenToThirteenAlphanumerics = /^[\w]{7,13}$/
export const fourToFifteenDigits = /^[\d]{4,15}$/
export const fourToFifteenAlphanumerics = /^[\w]{4,15}$/
export const cellphoneInput = /^3\d{9}$/
export const validRoute = /^[A-Za-z_]+$/i

export const validPeriodsHistoryRoute =
  /^\/periods_history\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

export const validPassword =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#!?@$%^&*+\-_,.;:(){}[\]|<=>/])[A-Za-z\d#!?@$%^&*+\-_,.;:(){}[\]|<=>/]{8,}$/

export const uuid =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
