import {
  Box,
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'
import { Image } from 'components/UI/Image'
import OrganizationEmail from 'components/UI/OrganizationEmail'

export function EmployeeUploadInformation({ handleClose }) {
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.up('laptop'))

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        margin: theme.spacing(0, 2),
      })}
    >
      <ButtonBase
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          cursor: 'pointer',
          color: theme.palette.black.main,
          right: theme.spacing(-1),
          top: theme.spacing(0),
        })}
      >
        <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
      </ButtonBase>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            mobile: '1fr',
            laptop: '1fr  3fr',
          },
        }}
      >
        {isLaptop ? (
          <Box>
            <Box
              sx={(theme) => ({
                width: '45rem',
                height: '45rem',
                borderRadius: '100%',
                position: 'absolute',
                left: '-30rem',
                top: '-7rem',
                backgroundColor: theme.palette.complementary2.light,
              })}
            />
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Image
                src="8ef0329d-aefe-4ceb-7aa1-d953b7788b00"
                height={444}
                width={231}
                layout="fill"
              />
            </Box>
          </Box>
        ) : null}
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('laptop')]: {
              marginLeft: theme.spacing(2),
            },
          })}
        >
          <Typography
            variant="h2"
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
              marginRight: theme.spacing(2),
            })}
          >
            ¡Bienvenido a la creación simplificada de personas!
          </Typography>
          <Typography
            variant="body2"
            color="black.dark"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
              gap: theme.spacing(2),
            })}
          >
            Tienes dos opciones para agregar personas a tu equipo:
          </Typography>
          <Typography
            variant="body2"
            color="black.dark"
            sx={{
              fontWeight: 'bold',
            }}
          >
            1. Carga de archivo propio
          </Typography>
          <Typography
            variant="body2"
            color="black.dark"
            sx={(theme) => ({
              marginBottom: theme.spacing(3),
            })}
          >
            Envía un archivo con la información de tu equipo y de sus contratos
            a <OrganizationEmail color="info.main" variant="addEmployees" /> y
            nosotros nos encargamos de crearlos en Aleluya.
          </Typography>
          <Typography
            variant="body2"
            color="black.dark"
            sx={{
              fontWeight: 'bold',
            }}
          >
            2. Auto-gestión de empleados
          </Typography>
          <Typography
            variant="body2"
            color="black.dark"
            sx={(theme) => ({
              marginBottom: theme.spacing(3),
            })}
          >
            Envía un archivo con los nombres y correos de tus empleados a{' '}
            <OrganizationEmail color="info.main" variant="addEmployees" />{' '}
            Nosotros enviaremos un link a cada uno para que puedan ingresar su
            propia información.
          </Typography>
          <Typography
            variant="body2"
            color="black.dark"
            sx={(theme) => ({
              marginBottom: theme.spacing(3),
            })}
          >
            ¡Escoge el camino que prefieras y deja que Aleluya se encargue del
            proceso!
          </Typography>
          <Button
            onClick={handleClose}
            sx={(theme) => ({
              padding: theme.spacing(0, 10),
              marginTop: theme.spacing(2),
              width: '100%',
              [theme.breakpoints.up('tablet')]: {
                width: 'auto',
              },
            })}
          >
            Cerrar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
