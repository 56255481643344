import { Form, Formik } from 'formik'

import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'

import { formatDisplayDateString } from 'utils/dateTime'
import { formatCurrency, getWordSingular } from 'utils/format'
import { isObjectEmpty } from 'utils/general'

import selfieWoman from 'assets/images/views/common/selfie-woman.png'

import { useCalculateExtraWorkers } from '../../Index/helpers'
import usePaymentModal from '../PaymentModal/usePaymentModal'
import {
  getInitialCopy,
  getInitialValues,
  getValidationSchema,
} from './helpers'

const ExtraWorkersModalContent = ({ onClose }) => {
  const { paidWorkers, additionalWorkersInfo, payrollsSize } = useSubscription()
  const { calculateSubscriptionFromInput } = useCalculateExtraWorkers()
  const paymentModal = usePaymentModal()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const handlePayment = (value) => {
    paymentModal.openModal({
      extraWorkers: value,
      isFromExtraWorkersModal: true,
    })
  }

  const initialDay = formatDisplayDateString(
    additionalWorkersInfo?.subscription_initial_day
  )

  const endDay = formatDisplayDateString(
    additionalWorkersInfo?.subscription_end_day
  )

  return (
    <Formik
      initialValues={getInitialValues(additionalWorkersInfo)}
      validationSchema={getValidationSchema(
        additionalWorkersInfo?.extra_workers_number || 1
      )}
    >
      {({ values, setFieldValue, errors, setTouched }) => {
        const callback = (data) =>
          setFieldValue(
            'pricing_value',
            data?.additional_workers_info?.extra_workers_value
          )

        const handleOnChange = (event) => {
          calculateSubscriptionFromInput(
            { values: event.target.value },
            callback
          )
        }

        const onFocus = () => setTouched({ extra_workers_number: true })

        return (
          <Box
            sx={(theme) => ({
              display: 'grid',
              gap: theme.spacing(2),
              gridTemplateColumns: {
                mobile: '1fr',
                tablet: '1fr 2fr',
              },
            })}
          >
            <ButtonBase
              onClick={onClose}
              data-cy="close-dialog-button"
              sx={(theme) => ({
                position: 'absolute',
                right: theme.spacing(-1),
                top: theme.spacing(0),
                cursor: 'pointer',
                color: theme.palette.black.main,
              })}
            >
              <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
            </ButtonBase>
            {!isMobile ? (
              <>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.palette.accent1.main,
                    margin: theme.spacing(-5, 0, -5, -5),
                  })}
                >
                  <img
                    loading="lazy"
                    src={selfieWoman}
                    alt=""
                    width={158}
                    height={302}
                  />
                </Box>
              </>
            ) : null}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant="h3"
                    sx={(theme) => ({ margin: theme.spacing(0, 3, 2, 0) })}
                  >
                    Agrega personas a tu plan anual
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {getInitialCopy(paidWorkers, payrollsSize)} el límite de{' '}
                  <b>
                    {paidWorkers}{' '}
                    {getWordSingular('personas', paidWorkers === 1)}
                  </b>{' '}
                  con el que actualmente cuentas en tu plan.{' '}
                  {paidWorkers < payrollsSize ? (
                    <>
                      Ahora mismo, tienes{' '}
                      <b>{payrollsSize} colaboradores activos</b>.
                    </>
                  ) : null}{' '}
                  Para agregar más, debes realizar un pago proporcional al
                  tiempo restante de tu suscripción.
                </Typography>
                <Box sx={(theme) => ({ marginTop: theme.spacing(2) })}>
                  <Typography variant="lead1">
                    ¿Cuántas personas quieres agregar?
                  </Typography>
                  <Form>
                    <Box
                      sx={(theme) => ({
                        display: 'grid',
                        gridTemplateColumns: '1.5fr 1fr',
                        width: '15.125rem',
                        alignItems: 'center',
                        columnGap: theme.spacing(1),
                        marginTop: theme.spacing(1),
                      })}
                    >
                      <FormField
                        variant="number"
                        name="extra_workers_number"
                        onChange={handleOnChange}
                        onFocus={onFocus}
                        sx={{
                          color: 'primary.main',
                          fontSize: '1.5rem',
                        }}
                        inputProps={{
                          sx: { textAlign: 'center' },
                        }}
                      />
                      <Typography
                        color="primary"
                        variant="h6"
                        sx={(theme) => ({
                          marginTop: theme.spacing(1.5),
                          alignSelf: 'baseline',
                        })}
                      >
                        {getWordSingular(
                          'personas',
                          values.extra_workers_number === '1'
                        )}
                      </Typography>
                    </Box>
                  </Form>
                </Box>
                <Box
                  sx={(theme) => ({
                    marginTop: theme.spacing(2.5),
                    display: 'grid',
                    gridTemplateColumns: '55% 42%',
                    gap: theme.spacing(2.5),

                    [theme.breakpoints.down('laptop')]: {
                      gridTemplateColumns: '100%',
                    },
                  })}
                >
                  <Box>
                    <Typography variant="lead1">Periodo del plan</Typography>
                    <Box
                      sx={(theme) => ({
                        width: 'fit-content',
                        display: 'flex',
                        height: '3.125rem',
                        backgroundColor: 'white.light',
                        padding: theme.spacing(1),
                        borderRadius: '0.5rem',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: theme.spacing(0.5),
                      })}
                    >
                      <Icon name="clock" />
                      <Typography variant="lead1">{initialDay}</Typography>
                      <Icon name="move-right" />
                      <Typography variant="lead1">{endDay}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="lead1">Total a pagar</Typography>
                    <Typography
                      color="primary"
                      variant="h5"
                      sx={(theme) => ({
                        width: 'fit-content',
                        display: 'flex',
                        height: '3.125rem',
                        backgroundColor: 'white.light',
                        padding: theme.spacing(1, 3.5),
                        borderRadius: '0.5rem',
                        alignItems: 'center',
                      })}
                    >
                      {formatCurrency(values.pricing_value)}
                    </Typography>
                    <Typography
                      color="primary"
                      variant="tiny"
                      sx={{ alignSelf: 'center' }}
                    >
                      *Incluye descuento de anualidad
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    gap: theme.spacing(2),
                    marginTop: theme.spacing(3),
                    [theme.breakpoints.down('tablet')]: {
                      flexDirection: 'column',
                    },
                  })}
                >
                  <Button
                    disabled={!isObjectEmpty(errors)}
                    onClick={() => handlePayment(values.extra_workers_number)}
                  >
                    Agregar{' '}
                    {getWordSingular(
                      'personas',
                      values.extra_workers_number === '1'
                    )}
                  </Button>
                  <Button variant="outlined" onClick={onClose}>
                    Cancelar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    </Formik>
  )
}

export default ExtraWorkersModalContent
