import { Box, Typography, useMediaQuery } from '@mui/material'

import ButtonAddWorker from 'components/UI/Button/ButtonAddWorker'
import { Image } from 'components/UI/Image'

const EmptyState = ({
  title,
  description,
  handleAddWorkerManually,
  enableFileOption,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  return (
    <Box
      sx={({ spacing, breakpoints }) => ({
        padding: spacing(4, 2),
        height: '15rem',
        [breakpoints.up('tablet')]: {
          height: '33rem',
        },
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {!isMobile ? (
          <Image
            src="6b52c040-3536-4a89-0599-874d9e3ea900"
            width={190}
            height={243}
          />
        ) : null}
        <Box
          sx={{
            maxWidth: '35.75rem',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
            })}
            variant="h3"
          >
            {title}
          </Typography>
          <Typography
            sx={({ spacing, breakpoints }) => ({
              [breakpoints.up('tablet')]: {
                marginBottom: spacing(4),
              },
            })}
            variant="body1"
          >
            {description}
          </Typography>
          {!isMobile ? (
            <ButtonAddWorker
              handleAddWorkerManually={handleAddWorkerManually}
              enableFileOption={enableFileOption}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default EmptyState
