import { Box, Button, Typography } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'

import EventItem from './EventItem'
import { getDataConfig } from './helpers'

const EventCard = ({ eventType, data, isLoading, handleShowDetail }) => {
  const sizeData = data?.length

  const { title, shortTitle, description, icon } = getDataConfig(
    eventType,
    sizeData
  )

  const handleShowMore = () => {
    handleShowDetail({
      title,
      description,
      items: data,
      eventType,
    })
  }

  return (
    <Box
      sx={(theme) => ({
        backgroundColor:
          theme.palette.primary[300] ?? theme.palette.primary.light,
        borderRadius: '1rem',
        padding: theme.spacing(2, 3),
        width: '100%',
        height: '100%',
      })}
    >
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          {sizeData > 0 ? (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(0.5),
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                })}
              >
                <Box
                  sx={(theme) => ({
                    alignItems: 'center',
                    columnGap: theme.spacing(0.5),
                    display: 'flex',
                  })}
                >
                  <Typography component="span" sx={{ display: 'inline-flex' }}>
                    {shortTitle}
                  </Typography>
                  {icon}
                </Box>
                <Button variant="text" onClick={handleShowMore}>
                  Ver más
                </Button>
              </Box>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                  width: '100%',
                })}
              >
                {data?.slice(0, 3)?.map((item, index) => {
                  const avatarId = (index % 5) + 1
                  const key = `${eventType}-${index}`

                  return (
                    <EventItem
                      key={key}
                      eventType={eventType}
                      name={item.worker_name}
                      date={item.date}
                      years={item.years}
                      workerId={item.workerId}
                      avatarId={avatarId}
                      picture={item.picture}
                    />
                  )
                })}
              </Box>
            </Box>
          ) : (
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: theme.spacing(1),
                height: '100%',
              })}
            >
              {icon}
              <Typography variant="h4" color="black.main">
                {shortTitle}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={(theme) => ({
                  maxWidth: '100%',
                  [theme.breakpoints.up('desktop')]: {
                    maxWidth: '18rem',
                  },
                })}
              >
                {description}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default EventCard
