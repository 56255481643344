import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Button as MButton, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'

import { getCompanyId } from 'utils/company'
import useCompanyWorkCenters from 'utils/hooks/company/decree2012Service'
import useNotifications from 'utils/hooks/useNotifications'

import ActivityCIIU from '../ActivityCIIU'
import { getInitialValues, validationSchema } from './helpers'

const WorkCenterModal = ({
  handleCloseModal,
  workCenterToUpdate,
  isNewHistory,
  isEdit,
}) => {
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()

  const { companyWorkCentersMutation } = useCompanyWorkCenters({
    queryOptions: { enabled: false },
  })

  const initialValues = getInitialValues(workCenterToUpdate)

  const callback = (action) => {
    handleCloseModal()
    showSuccessMessage(`Centro de trabajo ${action} correctamente`)
  }

  const onSubmit = (values) => {
    const sendData = {
      name: values.name,
      initial_day: values.initial_day,
      activity_code_id: values.activity_code_id.id,
      code: values.code,
    }

    if (isEdit) {
      Object.assign(sendData, { new_history: isNewHistory })

      companyWorkCentersMutation.mutate(
        {
          mutationMethod: 'PUT',
          workCenterId: values.work_center_id,
          workCenterData: {
            work_center: { ...sendData },
          },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries(['getWorkCenters', companyId])
            callback('actualizado')
          },
        }
      )
    } else {
      companyWorkCentersMutation.mutate(
        {
          mutationMethod: 'POST',
          workCenterData: { work_center: { ...sendData } },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries(['getWorkCenters', companyId])
            callback('creado')
          },
        }
      )
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <Form>
            <Box
              sx={{
                maxWidth: '46.875rem',
              }}
            >
              <Typography>
                {isEdit
                  ? 'Acá puedes editar un nuevo centro de trabajo, recuerda tener presente la fecha de cambio ya que este cambio afecta el centro de trabajo.'
                  : 'Acá puedes crear un nuevo centro de trabajo y puedes agrupar empleados que comparten la misma Actividad CIUU y por lo tanto el mismo nivel de riesgo.'}
              </Typography>

              <Box
                sx={(theme) => ({
                  marginTop: theme.spacing(4),
                  marginBottom: theme.spacing(6),
                  gap: theme.spacing(3),
                  display: 'flex',
                  flexDirection: 'column',
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                    [theme.breakpoints.up(790)]: {
                      flexDirection: 'row',
                    },
                  })}
                >
                  <FormField
                    name="initial_day"
                    label="Fecha de inicio"
                    variant="datepicker"
                    optional={false}
                  />
                  <FormField
                    name="name"
                    label="Nombre"
                    placeholder="Nombre centro de trabajo"
                    optional={false}
                  />
                </Box>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                    [theme.breakpoints.up('sm')]: {
                      flexDirection: 'row',
                    },
                  })}
                >
                  <FormField
                    name="code"
                    label="Código"
                    placeholder="Código"
                    optional={false}
                    variant="number"
                    thousandSeparator=""
                  />
                  <ActivityCIIU isEdit={isEdit} />
                  <FormField
                    name="risk_type"
                    label="Nivel de riesgo"
                    disabled
                    placeholder="Nivel de riesgo"
                    optional={false}
                  />
                </Box>
              </Box>
              <Box
                sx={(theme) => ({
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: theme.spacing(2),
                  marginTop: theme.spacing(6),
                  flexWrap: 'wrap',
                  [theme.breakpoints.up('tablet')]: {
                    justifyContent: 'flex-start',
                  },
                })}
              >
                <Button
                  onClick={handleSubmit}
                  loading={companyWorkCentersMutation.isLoading}
                >
                  {isEdit ? 'Editar' : 'Crear'} centro de trabajo
                </Button>
                <MButton variant="outlined" onClick={handleCloseModal}>
                  Volver
                </MButton>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default WorkCenterModal
