import { useState } from 'react'

import {
  Box,
  ButtonBase,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
  dialogClasses,
} from '@mui/material'

import Button from 'components/UI/Button/Button'

import Icon from '../Icon'

const ConfirmationDialog = ({ open, options, onCancel, onOk, okClicked }) => {
  const [checked, setChecked] = useState(false)

  const {
    title,
    description,
    okText,
    cancelText,
    dialogProps,
    okButtonProps,
    cancelButtonProps,
    confirmCheckbox,
    hideCancelButton,
  } = options

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  const handleClose = (_, reason) => {
    if (reason === 'backdropClick') {
      if (onCancel) {
        onCancel()
      }
      return
    }

    if (reason === 'escapeKeyDown') {
      if (onCancel) {
        onCancel()
      }
    }
  }

  return (
    <Dialog
      {...dialogProps}
      open={open}
      onClose={handleClose}
      sx={(theme) => ({
        [`& .${dialogClasses.paper}`]: {
          borderRadius: '1rem',
          [theme.breakpoints.down('tablet')]: {
            margin: theme.spacing(2),
          },
          maxWidth: '45.5rem',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
        },
      })}
    >
      <Box sx={{ zIndex: 2 }}>
        {title ? (
          <DialogTitle
            id="dialog-confirm-title"
            component="div"
            sx={(theme) => ({
              marginTop: 0,
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              columnGap: theme.spacing(3.5),
              alignItems: 'start',
              padding: theme.spacing(4, 4, 3, 4),
              [theme.breakpoints.down('tablet')]: {
                padding: theme.spacing(3),
              },
            })}
          >
            <Typography variant="h3">{title}</Typography>
            {onCancel ? (
              <ButtonBase
                onClick={onCancel}
                data-cy="close-dialog-confirm-button"
                sx={(theme) => ({
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
              </ButtonBase>
            ) : null}
          </DialogTitle>
        ) : null}
        {description ? (
          <DialogContent
            sx={(theme) => ({
              padding: theme.spacing(0, 4, confirmCheckbox ? 4 : 7, 4),
              [theme.breakpoints.down('tablet')]: {
                padding: theme.spacing(0, 3, confirmCheckbox ? 4 : 7, 3),
              },
            })}
          >
            <DialogContentText component="span" color="black.dark">
              {description}
            </DialogContentText>
          </DialogContent>
        ) : null}
        {confirmCheckbox ? (
          <DialogContent
            sx={(theme) => ({
              padding: theme.spacing(0, 4, 7, 4),
              [theme.breakpoints.down('tablet')]: {
                padding: theme.spacing(0, 3, 7, 3),
              },
            })}
          >
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChange} />}
              label="Confirmar"
            />
          </DialogContent>
        ) : null}
        <DialogActions
          sx={(theme) => ({
            justifyContent: 'center',
            gap: theme.spacing(2),
            flexWrap: 'wrap',
            padding: theme.spacing(0, 3, 3, 3),
            [theme.breakpoints.up('tablet')]: {
              justifyContent: 'flex-start',
              flexWrap: 'unset',
              padding: theme.spacing(0, 4, 4, 4),
            },
          })}
        >
          <Button
            {...okButtonProps}
            onClick={() => {
              onOk()
              setChecked(false)
            }}
            loading={okClicked}
            disabled={(confirmCheckbox && !checked) || okClicked}
            data-cy="confirm_dialog_ok"
          >
            {okText}
          </Button>
          {!hideCancelButton ? (
            <Button
              variant="outlined"
              {...cancelButtonProps}
              onClick={() => {
                onCancel()
                setChecked(false)
              }}
              data-cy="confirm_dialog_cancel"
              disabled={okClicked}
            >
              {cancelText}
            </Button>
          ) : null}
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ConfirmationDialog
