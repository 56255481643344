import { useLayoutEffect } from 'react'

import { Box, Button, Grow } from '@mui/material'

import { useDateRangePickerContext } from '.'

const CalendarYearsGrid = ({
  open,
  state,
  onCloseYearGrid,
  years,
  yearsRefs,
}) => {
  const { isDesktop } = useDateRangePickerContext()
  const currentYear = new Date().getFullYear().toString()

  useLayoutEffect(() => {
    if (open) {
      if (yearsRefs[currentYear]) {
        yearsRefs[currentYear].current?.focus({ focusVisible: true })
      }
    }
  }, [currentYear, open, yearsRefs])

  const onChange = (index) => {
    const date = years[index].value
    state.setFocusedDate(date)
    onCloseYearGrid()
  }

  return (
    <Grow in={open} unmountOnExit>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          right: 0,
          left: 0,
          top: theme.spacing(7),
          transformOrigin: 'top center 0',
          width: '100%',
          height: `calc(100% - ${theme.spacing(7)} - 72px)`,
          backgroundColor: theme.palette.white.main,
          zIndex: '2',
          ...(isDesktop && {
            height: `calc(100% - ${theme.spacing(7)})`,
          }),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            overflowY: 'scroll',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: theme.spacing(1),
            height: '100%',
          })}
        >
          {years.map((yearItem, index) => (
            <Button
              key={yearItem.label}
              ref={yearsRefs[yearItem.label]}
              size="small"
              onClick={() => onChange(index)}
              sx={(theme) => ({
                backgroundColor: 'transparent',
                color: theme.palette.black.main,
                '&:hover': {
                  color: theme.palette.white.main,
                },
              })}
            >
              {yearItem.label}
            </Button>
          ))}
        </Box>
      </Box>
    </Grow>
  )
}

export default CalendarYearsGrid
