import { useCallback, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import ElectronicPayrollAlertWrapper from 'components/Period/ElectronicPayroll/ElectronicPayrollAlert/ElectronicPayrollAlertWrapper'
import BulkNoveltiesAlertWrapper from 'components/Period/Payroll/BulkNoveltiesAlert/BulkNoveltiesAlertWrapper'
import SubscriptionAlertModal from 'components/Subscription/Atoms/SubscriptionAlertModal'
import useSubscriptionAlertModal from 'components/Subscription/Atoms/SubscriptionAlertModal/useSubscriptionAlertModal'
import UpdatePaymentMethodInfoModal from 'components/Subscription/Atoms/UpdatePaymentMethodInfoModal'
import useUpdatePaymentMethodInfoModal from 'components/Subscription/Atoms/UpdatePaymentMethodInfoModal/useUpdatePaymentMethodInfoModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Loading from 'components/UI/Loading/Loading'

import {
  isOrganizer,
  isPremiumExpiredByCancellationCompany,
  isWorker,
} from 'utils/auth'
import { isTest } from 'utils/general'
import { useHasPendingOnboarding } from 'utils/hooks/auth/onboarding'

import * as routes from 'config/routes'

import Header from './Header/Index'
import MobileAppBar from './MobileAppBar'
import AppSubscriptionStatusAlert from './Premium/SubscriptionStatusAlert'
import Sidebar from './Sidebar/Sidebar'
import SupportMenuButton from './SupportMenuButton/Index'
import { useUser } from './UserContext/useUser'

const App = () => {
  const { isAuthenticated, company, isLoading, isAPartnerChild } = useUser()
  const { subscription } = useSubscription()
  const location = useLocation()
  const navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = useState(false)
  const isOnboardingActive = location.pathname.includes('/onboarding')
  const isOrganizerUser = isOrganizer()
  const {
    openSubscriptionAlertModal,
    closeSubscriptionAlertModal,
    subscriptionAlertModalState,
  } = useSubscriptionAlertModal()
  const {
    updatePaymentMethodInfoModalState,
    closeUpdatePaymentMethodInfoModal,
    openUpdatePaymentMethodInfoModal,
  } = useUpdatePaymentMethodInfoModal()

  const subscriptionExpiredByCancellation =
    isPremiumExpiredByCancellationCompany(subscription)

  const isInSubscriptionStatusView =
    location.pathname === routes.SUBSCRIPTION_INDEX()

  const isInSubscriptionView =
    location.pathname === routes.SUBSCRIPTION_CHECKOUT()
  const isPendingPaymentStatus = subscription.payment_status === 'pending'

  const showSubscriptionStatusAlert =
    !isOnboardingActive &&
    (subscription?.status === 'premium_expired' ||
      subscriptionExpiredByCancellation ||
      isPendingPaymentStatus) &&
    !isInSubscriptionView

  const handleDrawerToggle = useCallback(
    () => setMobileOpen((previousValue) => !previousValue),
    []
  )

  const pendingOnboarding = useHasPendingOnboarding(
    company?.onboarding?.onboarding_step
  )

  useEffect(() => {
    window.Appcues?.page()
  }, [location.pathname])

  useEffect(() => {
    if (pendingOnboarding.value && !isOnboardingActive) {
      navigate(pendingOnboarding.pathname, { replace: true })
    }
  }, [
    navigate,
    isOnboardingActive,
    pendingOnboarding.pathname,
    pendingOnboarding.value,
  ])

  useEffect(() => {
    if (
      !isTest &&
      !isOrganizerUser &&
      !isWorker() &&
      company?.onboarding?.onboarding_step &&
      !pendingOnboarding.value &&
      !company?.onboarding_first_steps?.finished_all_steps &&
      !localStorage.getItem('one-by-session-redirected-to-intro')
    ) {
      navigate(routes.ONBOARDING_INTRO(), { replace: true })
      localStorage.setItem('one-by-session-redirected-to-intro', true)
    }
  }, [
    navigate,
    company?.onboarding_first_steps?.finished_all_steps,
    pendingOnboarding.value,
    isOrganizerUser,
    company?.onboarding?.onboarding_step,
  ])

  useEffect(() => {
    if (
      !isTest &&
      !isAPartnerChild &&
      localStorage.getItem('one-by-session-info-modal') !== 'modalViewed'
    ) {
      if (
        (['premium_expired', 'premium_due'].includes(subscription?.status) ||
          subscriptionExpiredByCancellation) &&
        !isPendingPaymentStatus
      ) {
        openSubscriptionAlertModal({
          subscriptionStatus: subscription.status,
          subscriptionExpiredByCancellation,
        })
      } else if (
        subscription?.payment_category === null &&
        !['exonerated_payment', 'premium_trial', 'free'].includes(
          subscription?.status
        ) &&
        !isOnboardingActive
      ) {
        openUpdatePaymentMethodInfoModal({
          actionMessage: 'invitation_to_update',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAPartnerChild,
    location.pathname,
    subscription?.payment_category,
    subscription.status,
    subscriptionExpiredByCancellation,
  ])

  if (!isAuthenticated()) {
    let nextPath = location.pathname
    if (nextPath !== '/' && nextPath.startsWith('/')) {
      nextPath = `${routes.LOGIN}?next=${nextPath}${location.search}`
    } else {
      nextPath = routes.LOGIN
    }

    return <Navigate to={nextPath} />
  }

  return isLoading ? (
    <Loading />
  ) : (
    <Box
      sx={(theme) => ({
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('laptop')]: {
          gridTemplateColumns: 'auto 1fr',
        },
      })}
    >
      {pendingOnboarding?.value && !isOrganizerUser ? null : (
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
        />
      )}
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          overflow: 'auto',
          paddingTop: !isOnboardingActive ? theme.mobileAppbar : 0,
          [theme.breakpoints.up('laptop')]: {
            paddingTop: 0,
          },
          ...(pendingOnboarding?.value &&
            !isOrganizerUser && {
              gridColumn: '1 / -1',
            }),
        })}
      >
        {!isOnboardingActive ? (
          <>
            <MobileAppBar
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
              openUpdatePaymentMethodInfoModal={
                openUpdatePaymentMethodInfoModal
              }
            />
            <Header />
          </>
        ) : null}
        {showSubscriptionStatusAlert ? (
          <AppSubscriptionStatusAlert
            isInSubscriptionStatusView={isInSubscriptionStatusView}
          />
        ) : null}
        {subscriptionAlertModalState.open ? (
          <SubscriptionAlertModal
            state={subscriptionAlertModalState}
            handleClose={closeSubscriptionAlertModal}
          />
        ) : null}
        {updatePaymentMethodInfoModalState.open ? (
          <UpdatePaymentMethodInfoModal
            state={updatePaymentMethodInfoModalState}
            handleClose={closeUpdatePaymentMethodInfoModal}
            openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
          />
        ) : null}

        <ElectronicPayrollAlertWrapper>
          <BulkNoveltiesAlertWrapper>
            <Outlet />
          </BulkNoveltiesAlertWrapper>
        </ElectronicPayrollAlertWrapper>
        <SupportMenuButton />
      </Box>
    </Box>
  )
}

export default App
