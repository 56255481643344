import { useRef } from 'react'

import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'
import Page from 'components/UI/Page/Page'

import { isFreeCompany } from 'utils/auth'

import HandWriting from 'assets/images/views/common/hand_writing.png'

import { useCancelSubscriptionModal } from '../Atoms/CancelSubscription/useCancelSubscriptionModal'
import { ModalitySwitch, useModalitySwitch } from '../Atoms/ModalitySwitch'
import { PlansContainer } from '../Atoms/PlanUIComponents'
import SubscriptionCardsContent from '../Index/Plan/SubscriptionCardsContent'
import { FrequentQuestions } from '../Index/helpers'
import { getPlansFormatted, usePlansData } from '../helpers'

export default function SubscriptionPlans({ subscription }) {
  const {
    active_until: activeUntil,
    status,
    payment_status: paymentStatus,
  } = subscription || {}

  const frequentQuestionsRef = useRef()
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { openCancelSubscriptionModal } = useCancelSubscriptionModal()
  const { plansData, isPlansQueryLoading } = usePlansData()
  const { modality, handleChangeModality, annualDiscountValue } =
    useModalitySwitch({ subscription, plansData })

  const isAFreeCompany = isFreeCompany(status, paymentStatus)

  const plansFormatted = getPlansFormatted({
    plansData,
    modality,
  })

  const currentPlan = plansFormatted?.find((plan) => plan?.isCurrentPlan)
  const currentPlanIndex = plansFormatted.indexOf(currentPlan)

  return (
    <Page
      grid
      documentTitle="Planes"
      header={
        <Box sx={{ gridColumn: '1/-1' }}>
          <Typography
            variant="h2"
            sx={(theme) => ({ marginBottom: theme.spacing(2) })}
          >
            Precios transparentes y sin letra chiquita
          </Typography>
          <Typography variant="body2">
            Elige un plan que se adapte a ti.
          </Typography>
        </Box>
      }
    >
      {isPlansQueryLoading ? (
        <Box
          sx={(theme) => ({
            gridColumn: '1 / -1',
            marginBottom: theme.spacing(7.75),
          })}
        >
          <LoadingBox />
        </Box>
      ) : (
        <>
          <Box
            sx={(theme) => ({
              gridColumn: '1 / -1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              margin: theme.spacing(0, 12, 2, 0),
            })}
          >
            <ModalitySwitch
              onChange={handleChangeModality}
              modality={modality}
              discount={annualDiscountValue}
            />
          </Box>

          <Box
            sx={(theme) => ({
              gridColumn: '1 / -1',
              marginBottom: theme.spacing(7.75),
              ...(plansFormatted.length === 2 ? { gridColumn: '2 / 12' } : {}),
            })}
          >
            <PlansContainer
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: '1fr',
                columnGap: theme.spacing(2),
                rowGap: theme.spacing(4),
                [theme.breakpoints.up('tablet')]: {
                  alignContent: 'center',
                },
                [theme.breakpoints.up('sm')]: {
                  gridTemplateColumns: 'repeat(2, 1fr)',
                },
                [theme.breakpoints.up('laptop')]: {
                  gridTemplateColumns: `repeat(${plansFormatted.length}, 1fr)`,
                },
                [theme.breakpoints.between('sm', 'laptop')]: {
                  '& div:nth-of-type(3)': {
                    gridColumn: 'span 2',
                    justifySelf: 'center',
                  },
                },
              })}
            >
              {plansFormatted.map((plan, index) => (
                <SubscriptionCardsContent
                  key={plan.id}
                  planData={plan}
                  isAFreeCompany={isAFreeCompany}
                  selectedModality={modality}
                  selectedPlanIndex={index}
                  currentPlanIndex={currentPlanIndex}
                  currentPlanModality={subscription.type}
                  plansData={plansData}
                />
              ))}
              <Box
                sx={{
                  gridColumn: '-1 / 1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="text"
                  target="_blank"
                  href="https://aleluya.com/precios/"
                  sx={{ width: '100%', maxWidth: '27rem' }}
                >
                  Ver detalles de los planes
                </Button>
              </Box>
            </PlansContainer>
          </Box>
        </>
      )}

      <Box sx={{ gridColumn: '1/-1' }}>
        <FrequentQuestions forwardedRef={frequentQuestionsRef} />
      </Box>

      {!isAFreeCompany && status !== 'premium_canceled' ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            gridColumn: '1/-1',
            marginTop: theme.spacing(5),
            borderRadius: '1.5rem',
            backgroundColor: 'accent2.light',
            minHeight: '13.25rem',
            justifyContent: 'space-between',
          })}
        >
          <Box
            sx={(theme) => ({
              margin: theme.spacing(3.75, 4.875),
              maxWidth: '39rem',
              width: { mobile: '100%' },
            })}
          >
            <Typography
              variant="h2"
              sx={{ textAlign: { mobile: 'center', tablet: 'left' } }}
            >
              ¿Quieres cancelar tu plan? <br /> Tranqui, nosotros te ayudamos.
            </Typography>
            <Button
              onClick={() => openCancelSubscriptionModal({ activeUntil })}
              sx={(theme) => ({
                marginTop: theme.spacing(3),
                minWidth: { mobile: '100%', tablet: '15.375rem' },
              })}
            >
              Cancelar plan
            </Button>
          </Box>

          {isTabletUp ? (
            <Box
              component="img"
              src={HandWriting}
              alt=""
              width={428}
              height={229}
              sx={(theme) => ({
                objectFit: 'contain',
                marginTop: theme.spacing(-1.375),
                marginBottom: theme.spacing(-0.75),
                marginRight: theme.spacing(3),
              })}
            />
          ) : null}
        </Box>
      ) : null}
    </Page>
  )
}
