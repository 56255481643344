import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Link as MuiLink, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'
import useWorker from 'components/Worker/useWorker'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'
import useRetentionsConfigurationService from 'utils/hooks/worker/retentionConfigurationService'
import { isDataCached } from 'utils/reactQuery'
import { booleanLookupOptions } from 'utils/table'

import messages from 'messages/retention_configuration'

import { getDataToSend, validationSchema } from './helpers'

const RetentionConfigurationModal = ({ handleClose }) => {
  const queryClient = useQueryClient()
  const { worker, refreshWorker } = useWorker({ useCache: true })
  const workerId = worker.id
  const queryKey = ['retentionConfigurations', workerId]
  const retentionConfigurationDataCache = queryClient.getQueryData(queryKey)
  const { retentionsQuery, retentionsMutation } =
    useRetentionsConfigurationService({
      serviceParams: {
        queryKey,
        workerId,
      },
      queryOptions: {
        enabled:
          Boolean(workerId) &&
          worker.retention_configuration &&
          !isDataCached(retentionConfigurationDataCache),
      },
    })
  const initialValues = isDataCached(retentionConfigurationDataCache)
    ? retentionConfigurationDataCache.data
    : retentionsQuery.data

  const isRetentionConfigurationCreated = worker.retention_configuration
  const { showSuccessMessage } = useNotifications()

  const handleSubmit = (values) => {
    let method = 'POST'

    const dirtyValues = getDirtyValues(initialValues, values)

    if (isObjectEmpty(dirtyValues)) {
      handleClose()
    } else {
      const dataToSend = getDataToSend(isRetentionConfigurationCreated, values)

      if (worker.retention_configuration) {
        method = 'PATCH'
      }

      retentionsMutation.mutate(
        {
          mutationMethod: method,
          workerId: worker.id,
          data: {
            retention_configuration: dataToSend,
          },
        },
        {
          onSuccess: () => {
            refreshWorker()
            queryClient.invalidateQueries(queryKey)

            showSuccessMessage(
              'Las opciones de retención en la fuente fueron actualizadas.'
            )
            handleClose()
          },
        }
      )
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit: onSubmit, values }) => {
        return (
          <Modal
            header="Retención en la fuente procedimiento 1"
            open
            onOk={onSubmit}
            onCancel={handleClose}
            isLoading={retentionsMutation.isLoading}
            okText="Guardar"
            dialogProps={{ maxWidth: 'md', fullWidth: true }}
          >
            <Typography gutterBottom>
              Activa esta opción para deducir el valor correspondiente al
              empleado en cada periodo de forma automática.
            </Typography>
            <Typography gutterBottom>
              Si liquidas la nómina quincenalmente, y te encuentras en la
              segunda quincena, al activar esta opción también verás cambios en
              la quincena anterior. Conoce más detalles en{' '}
              <MuiLink
                href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-realizar-la-retención-en-la-fuente-por-salarios-a-mi-empleado-en-aleluya"
                target="_blank"
              >
                este artículo
              </MuiLink>{' '}
              en nuestro Centro de ayuda.
            </Typography>
            <Typography gutterBottom>
              <b>Nota:</b> Actualmente estos cálculos no incluyen los pagos por
              indemnizaciones laborales o prima de servicios.
            </Typography>
            {retentionsQuery.data ? (
              <Form>
                <FormField
                  name="active"
                  variant="switch"
                  label={
                    <Typography variant="lead1">
                      Activar Retención en la Fuente Automática
                    </Typography>
                  }
                />
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                  })}
                >
                  <FormField
                    name="activate_since"
                    variant="datepicker"
                    label={
                      <Typography variant="lead2">
                        Fecha de activación
                      </Typography>
                    }
                    disabled={!values?.active}
                    optional={false}
                  />

                  <FormField
                    name="housing_interests"
                    variant="currency"
                    align="left"
                    label={
                      <Typography variant="body2">
                        <strong>Valor mensualizado </strong>
                        de los intereses en préstamos para adquisición de
                        vivienda
                      </Typography>
                    }
                    tooltipContent={messages.housing_interests}
                    disabled={!values?.active}
                    optional={false}
                  />

                  <FormField
                    name="prepaid_medicine"
                    variant="currency"
                    align="left"
                    label={
                      <Typography variant="body2">
                        <strong>Valor mensualizado </strong>
                        de los pagos a medicina prepagada durante el año
                        anterior
                      </Typography>
                    }
                    tooltipContent={messages.prepaid_medicine}
                    disabled={!values?.active}
                    optional={false}
                  />

                  <FormField
                    name="dependents_deduction"
                    label={
                      <Typography variant="body2">
                        Deducción por dependientes o personas a cargo
                      </Typography>
                    }
                    variant="radio-group"
                    options={booleanLookupOptions}
                    tooltipContent={messages.dependents_deduction}
                    disabled={!values?.active}
                  />
                </Box>
              </Form>
            ) : null}
          </Modal>
        )
      }}
    </Formik>
  )
}

export default RetentionConfigurationModal
