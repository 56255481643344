import { isAfter, isEqual, isValid, parseISO } from 'date-fns'
import * as yup from 'yup'

import { Link } from '@mui/material'

import { paymentTypesOptions } from 'utils/company'
import { formatDate } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'

import CommentsTable from './CommentsTable'
import BankTransferForm from './Form/BankTransferForm'
import CompanyPaymentForm from './Form/CompanyPaymentForm'

yup.setLocale(yupLocaleES)

export const getTabsData = (companyId) => {
  return [
    {
      label: 'Transaccion Bancaria',
      content: <BankTransferForm companyId={companyId} />,
    },
    {
      label: 'Pagos Empresa',
      content: <CompanyPaymentForm companyId={companyId} />,
    },
    {
      label: 'Comentarios',
      content: <CommentsTable companyId={companyId} />,
    },
  ]
}

export const bankTransferInitialValues = {
  payment_date: formatDate(new Date()),
  payment_type: 'month',
}

export const paymentCategoriesOptions = [
  { label: 'Ajuste de pago', id: 'payment_adjustment' },
  { label: 'Historial de Ajustes de Pago', id: 'payment_adjustments_history' },
  { label: 'Cambio fecha proximo pago', id: 'change_date' },
  { label: 'Tipo de Pago', id: 'payment_type' },
  { label: 'Cancelar o Activar Suscripción', id: 'remove_subscription' },
]

export const getCurrentPaymentCategory = (value) => {
  const distributorPaymentTypeOption = paymentTypesOptions.find(
    (opt) => opt.value === value
  )

  return distributorPaymentTypeOption?.value
}

export const getInitialValues = (values, payment) => {
  const {
    next_payment_date: nextPaymentDate,
    payment_category: paymentCategory,
  } = values

  switch (payment) {
    case 'change_date':
      return { next_payment_date: nextPaymentDate }
    case 'payment_type':
      return { payment_category: paymentCategory }
    default:
      return {}
  }
}

export const tooltipMessages = {
  category_type: (
    <>
      Selecciona el tipo de ajuste que deseas aplicar. Para más información
      sobre los tipos de ajuste, visita este{' '}
      <Link
        href="https://app.archbee.com/docs/JhIRaFl4qhNIYBS3H2r9s/UlwEtFRA2roD4vuB7VYL9"
        target="_blank"
      >
        link
      </Link>
      .
    </>
  ),
  adjustment_type:
    'Indica si el ajuste se aplica a la suscripción mensual o anual.',
  plan_id:
    'Establece si el ajuste se aplica a un plan específico o a todos los planes disponibles.',
  payment_limit_date:
    'Establece la fecha límite de la cual dispone el usuario para realizar el pago antes de perder el descuento. Esta debe ser mayor o igual que el día de hoy. Si se deja en blanco, no habrá fecha límite.',
  adjustment_months:
    'Número de meses por los cuales estará vigente el ajuste (entre 0 y 24). Si se deja en blanco o en cero, el ajuste solo aplica para un pago.',
  comment:
    'Utiliza este campo para dejar aclaraciones relevantes respecto del ajuste realizado.',
}

export const fieldOptions = {
  partnerCategoryOptions: [
    { label: 'Porcentaje', value: 'percentage' },
    { label: 'Valor adicional', value: 'additional_value' },
    { label: 'Descuento', value: 'discount_value' },
  ],
  categoryOptions: [
    { label: 'Porcentaje', value: 'percentage' },
    { label: 'Valor adicional', value: 'additional_value' },
    { label: 'Descuento', value: 'discount_value' },
    { label: 'Valor a pagar único', value: 'unique_payment' },
    { label: 'Valor a pagar recurrente', value: 'recurring_payment' },
  ],
  subscriptionTypeOptions: [
    { label: 'Mensual', value: 'month' },
    { label: 'Anual', value: 'year' },
  ],
}

export const getValidationSchema = (activeTabID, isPartner, currentDate) => {
  let schema = yup.object({
    payment_category: yup.string(),
  })

  if (activeTabID === 'payment_adjustment') {
    schema = schema.concat(
      yup.object({
        category: yup.string().nullable().required(),
        value: yup.string().when('category', {
          is: 'percentage',
          then: yup
            .string()
            .test(
              'is-between-0-100',
              'El valor debe ser mayor que 0 y menor o igual 100',
              (value) => +value > 0 && +value <= 100
            )
            .required(
              'Este campo es obligatorio cuando la categoría es porcentaje'
            ),
          otherwise: yup
            .string()
            .test(
              'is-greater-than-0',
              'El valor debe ser mayor que 0',
              (value) => {
                const numericValue = value?.includes('.')
                  ? +value?.replace(/\./g, '')
                  : +value

                return numericValue > 0
              }
            )
            .required(),
        }),
        payment_limit_date: yup
          .string()
          .nullable()
          .test(
            'payment-limit-date-greater-or-equal-than-current-date',
            'La fecha límite de pago no puede ser anterior a la fecha actual',
            (paymentLimitDate) => {
              if (!paymentLimitDate) {
                return true
              }

              const isValidDate =
                isValid(parseISO(paymentLimitDate)) &&
                (isAfter(parseISO(paymentLimitDate), parseISO(currentDate)) ||
                  isEqual(parseISO(paymentLimitDate), parseISO(currentDate)))

              return isValidDate
            }
          ),
        comment: yup
          .string()
          .nullable()
          .required()
          .min(5, 'El comentario debe tener al menos 5 caracteres'),
        adjustment_months: yup.string().when(['category'], {
          is: (category) =>
            ['unique_payment', 'recurring_payment'].includes(category),
          then: yup.string().nullable().required(),
          otherwise: yup.string().nullable(),
        }),
      })
    )

    if (!isPartner) {
      schema = schema.concat(
        yup.object({
          plan_id: yup.string().nullable().required(),
          adjustment_type: yup.string().nullable().required(),
        })
      )
    }
  }

  return schema
}
