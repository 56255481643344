import { Form, Formik } from 'formik'
import { useState } from 'react'

import { Box } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import usePeriodService from 'utils/hooks/payroll/periodService'

import { getContentByReportType, validationSchema } from './helpers'

const ReportFileDownloadModal = ({ state, handleClose }) => {
  const { open, reportType } = state

  const [options, setOptions] = useState({})
  const [endDayOptions, setEndDayOptions] = useState([])
  const [initialDayOptions, setInitialDayOptions] = useState([])

  const companyId = getCompanyId()
  const { enqueueDownload } = useDownloadManager()
  const { periodMutation } = usePeriodService({
    serviceParams: {
      queryKey: ['periodOptions', companyId],
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        setInitialDayOptions(data.initial_dates)
        setEndDayOptions(data.end_dates)
        setOptions(data)
      },
    },
  })

  const { mutationKey, headerTitle, description, downloadFileConfig } =
    getContentByReportType(reportType, companyId)

  const handleSubmit = (values) => {
    enqueueDownload({
      name: downloadFileConfig.name,
      fileCode: downloadFileConfig.fileCode,
      firebasePath: downloadFileConfig.pathname,
      service: () =>
        periodMutation.mutateAsync(
          {
            mutationMethod: 'GET',
            mutationKey,
            dates: values,
          },
          {
            onSuccess: () => {
              handleClose()
            },
          }
        ),
    })
  }

  const handleOptionsChange = (event, formProps) => {
    const { name, value } = event.target

    if (name === 'initial_day') {
      const newEndDayOptions = options.end_dates.filter((date) => date > value)

      setEndDayOptions(newEndDayOptions)
    }

    if (name === 'end_day') {
      const newInitialDayOptions = options.initial_dates.filter(
        (date) => date < value
      )

      setInitialDayOptions(newInitialDayOptions)
    }

    formProps.setFieldValue(name, value)
  }

  const formatOptions = (optionsDate) =>
    optionsDate.map((date) => {
      return {
        value: date,
        label: formatDisplayDateString(date),
      }
    })

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ initial_day: '', end_day: '' }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formProps) => {
        const { isSubmitting, handleSubmit: onSubmit } = formProps

        return (
          <Modal
            open={open}
            header={headerTitle}
            okText="Generar archivo"
            onOk={onSubmit}
            onCancel={handleClose}
            loadingText="Generando archivo..."
            isLoading={isSubmitting || periodMutation.isLoading}
          >
            {description}
            <Form>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  gap: theme.spacing(2),
                  marginTop: theme.spacing(2),
                })}
              >
                <FormField
                  name="initial_day"
                  label="Periodo inicial del reporte"
                  placeholder="Periodo inicial del reporte"
                  variant="select"
                  onChange={(event) => handleOptionsChange(event, formProps)}
                  options={formatOptions(initialDayOptions)}
                  optional={false}
                />
                <FormField
                  name="end_day"
                  label="Periodo final del reporte"
                  placeholder="Periodo final del reporte"
                  variant="select"
                  onChange={(event) => handleOptionsChange(event, formProps)}
                  options={formatOptions(endDayOptions)}
                  optional={false}
                />
              </Box>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default ReportFileDownloadModal
