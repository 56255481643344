import { Box, StepButton, stepLabelClasses } from '@mui/material'

export function OnboardingStepper({ currentStep, steps, onStepChange }) {
  return (
    <Box
      sx={({ spacing, breakpoints }) => ({
        width: '19.5rem',
        display: 'flex',
        gap: spacing(1),
        [breakpoints.down('tablet')]: {
          width: '17rem',
        },
      })}
    >
      {steps.map((step, index) => (
        <StepButton
          disabled={index >= currentStep}
          sx={{
            margin: 0,
            padding: 0,
            [`& .${stepLabelClasses.root}`]: {
              width: '100%',
            },
          }}
          key={step.id}
          onClick={() => onStepChange(index)}
        >
          <Box
            sx={({ palette }) => ({
              backgroundColor: palette.primary.dark,
              height: '0.235rem',
              borderRadius: '1.4rem',
              ...(index === currentStep && {
                backgroundColor: palette.primary[300],
              }),
              ...(index > currentStep && {
                backgroundColor: palette.white.light,
              }),
            })}
          />
        </StepButton>
      ))}
    </Box>
  )
}
