import { Box, Slide, Typography, useMediaQuery } from '@mui/material'

import PaymentMethod from '../PaymentMethod/Index'
import SubscriptionSummary from './SubscriptionSummary'

const SubscriptionSummaryIndex = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        height: '100%',
        gridTemplateColumns: '1fr',
        margin: theme.spacing(0, 2),
        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(4),
          marginRight: 0,
          gridTemplateColumns: '1fr 25rem',
        },
        [theme.breakpoints.up('laptop')]: {
          gridTemplateColumns: '1fr 30rem',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          marginBottom: theme.spacing(4),
          [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(4),
          },
        })}
      >
        <Typography
          variant="h2"
          color="black.main"
          sx={(theme) => ({
            margin: theme.spacing(4, 0),
          })}
        >
          Ingresa los datos de pago
        </Typography>
        <PaymentMethod />
      </Box>
      {isMobile ? (
        <Box>
          <SubscriptionSummary />
        </Box>
      ) : (
        <Slide direction="left" timeout={500} in>
          <Box>
            <SubscriptionSummary />
          </Box>
        </Slide>
      )}
    </Box>
  )
}

export default SubscriptionSummaryIndex
