import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { isValidElement } from 'react'
import { useParams } from 'react-router-dom'

import { Badge, IconButton, Tooltip } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import useSendElectronicPayrolls from 'components/Period/ElectronicPayroll/useSendElectronicPayrolls'

import CommentsDialog from './Comments'

const ActionButton = ({ action, completeRow }) => {
  const { periodId, workerId } = useParams()
  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'dialogPopover',
  })

  const { handleSendElectronicPayroll, webSocketState } =
    useSendElectronicPayrolls({
      invalidateQueries: workerId
        ? 'getElectronicPayrollHistoryByWorker'
        : 'electronicPayrollPeriod',
    })

  const handleOnClick = () => {
    if (
      !showPremiumFeatures &&
      ['seeBill', 'download_pdf', 'sendBill'].includes(action.id)
    ) {
      openPremiumFeatureModal()
      return
    }

    if (action.id === 'to_issued') {
      handleSendElectronicPayroll(periodId, completeRow)
      return
    }

    if (action.onClick) {
      action.onClick(completeRow)
    }
  }

  const icon = isValidElement(action.icon) ? action.icon : <action.icon />

  return !action.hidden ? (
    <>
      <Tooltip
        title={action.tooltip || ''}
        disableInteractive
        enterDelay={300}
        arrow
      >
        <span>
          <IconButton
            disabled={
              action.id === 'to_issued' && !action.disabled
                ? webSocketState === 'inProgress'
                : action.disabled
            }
            onClick={handleOnClick}
            {...action.buttonProps}
            {...(action.commentsDialog ? bindTrigger(popupState) : {})}
            sx={{
              width: '2rem',
              height: '2rem',
            }}
          >
            {action.badge ? (
              <Badge color="accent2" variant="dot" {...action.badgeProps}>
                {icon}
              </Badge>
            ) : (
              icon
            )}
            {['seeBill', 'download_pdf', 'sendBill'].includes(action.id) ? (
              <PremiumFeatureIcon isTableActionIcon />
            ) : null}
          </IconButton>
        </span>
      </Tooltip>
      {action.commentsDialog ? (
        <CommentsDialog payroll={completeRow} popupState={popupState} />
      ) : null}
    </>
  ) : null
}

export default ActionButton
