import { addDays, differenceInCalendarDays, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Alert, Box, Typography } from '@mui/material'

import usePayslipModal from 'components/Period/Payroll/Modals/usePayslipModal'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import { formatDate } from 'utils/dateTime'
import useOrganizerWorkerService from 'utils/hooks/organizer/workerService'
import usePeriodService from 'utils/hooks/payroll/periodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useContractsService from 'utils/hooks/worker/contracts'
import useTerminationService from 'utils/hooks/worker/terminationService'
import { parseDate } from 'utils/worker'

import * as routes from 'config/routes'

import ContractModal, { validateCreateContract } from '../Modal/ContractModal'
import useContractModal from '../Modal/useContractModal'
import getColumnsData from './columnsData'

export function formatContracts(contracts = [], isFromIndex = false) {
  return contracts?.map((contract, i) => {
    const newContract = { ...contract }
    const index = i + 1

    if (!contract?.health_provider?.id) newContract.health_provider = null
    if (!contract?.pension_provider?.id) newContract.pension_provider = null
    if (!contract?.severance_provider?.id) newContract.severance_provider = null

    if (isFromIndex) {
      newContract.number = index

      if (contracts[index] !== undefined && contracts[index].end_day)
        newContract.prevEndDay = formatDate(
          addDays(parseISO(contracts[index].end_day), 1)
        )
    }

    return newContract
  })
}

const ContractIndex = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { workerId } = useParams()
  const [hasDateGapsBetweenContracts, setHasDateGapsBetweenContracts] =
    useState(false)
  const { createContract } = location?.state || {}
  const confirm = useConfirm()
  const { worker, refreshWorker } = useWorker()
  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const { handleDownloadPayslip } = usePayslipModal()

  const { contractModalState, openContractModal, closeContractModal } =
    useContractModal(workerId)
  const contractsQueryKey = ['allContracts', workerId]
  const { contractsQuery } = useContractsService({
    serviceParams: {
      queryKey: contractsQueryKey,
      workerId,
    },
  })
  const formattedData = formatContracts(contractsQuery.data, true)

  const { terminationMutation } = useTerminationService({
    queryOptions: {
      enabled: false,
    },
  })

  const { periodQuery } = usePeriodService({
    serviceParams: {
      queryKey: ['currentPeriod'],
    },
    queryOptions: {
      enabled: !!contractsQuery.data && contractsQuery.data?.length > 0,
      onSuccess: ({ data }) => {
        if (
          !!formattedData &&
          formattedData.length > 0 &&
          !!data &&
          createContract
        ) {
          const currentContract = formattedData[0]
          validateCreateContract({
            currentContract,
            currentPeriod: data,
            handleRedirectToTermination: () => {
              navigate(
                routes.WORKER_CONTRACT_TERMINATION_NEW(
                  workerId,
                  currentContract.id
                )
              )
            },
            handleShowModal: () => {
              let newContract = {}

              if (currentContract) {
                const {
                  health_provider: healthProvider,
                  pension_provider: pensionProvider,
                  severance_provider: severanceProvider,
                  work_center: workCenter,
                  contract_detail: { contributor_subtype: contributorSubtype },
                  high_risk_pension: highRiskPension,
                  end_day: endDay,
                } = currentContract

                newContract = {
                  health_provider: healthProvider,
                  pension_provider: pensionProvider,
                  severance_provider: severanceProvider,
                  work_center: workCenter,
                  contributor_subtype: contributorSubtype,
                  high_risk_pension: highRiskPension,
                  prevEndDay: formatDate(addDays(parseDate(endDay), 1)),
                }
              }
              openContractModal(newContract)
            },
            confirm,
          })
        }
      },
    },
  })

  const { organizerWorkerMutation } = useOrganizerWorkerService()

  const updateContracts = () => queryClient.invalidateQueries(contractsQueryKey)

  const handleCreateContract = () => {
    const currentContract = contractsQuery.data?.[0] || {}

    validateCreateContract({
      currentContract,
      currentPeriod: periodQuery.data,
      handleRedirectToTermination: () => {
        navigate(
          routes.WORKER_CONTRACT_TERMINATION_NEW(workerId, currentContract.id)
        )
      },
      handleShowModal: () => {
        let newContract = {}

        if (currentContract) {
          const {
            health_provider: healthProvider,
            pension_provider: pensionProvider,
            severance_provider: severanceProvider,
            work_center: workCenter,
            contract_detail: { contributor_subtype: contributorSubtype },
            high_risk_pension: highRiskPension,
            end_day: endDay,
          } = currentContract

          newContract = {
            health_provider: healthProvider,
            pension_provider: pensionProvider,
            severance_provider: severanceProvider,
            work_center: workCenter,
            contributor_subtype: contributorSubtype,
            high_risk_pension: highRiskPension,
            prevEndDay: formatDate(addDays(parseDate(endDay), 1)),
          }
        }

        openContractModal(newContract)
      },
      confirm,
    })
  }

  const handlePayslipTermination = (contractId) => {
    terminationMutation.mutate(
      {
        mutationMethod: 'GET',
        contractId,
      },
      {
        onSuccess: ({ data }) => {
          const { payroll_id: payrollId } = data
          handleDownloadPayslip({
            payroll: { id: payrollId },
            payslip_type: 'termination',
          })
        },
      }
    )
  }

  const handleEditContract = (contract) => {
    confirm({
      title: '¿Estás seguro de editar el contrato?',
      description:
        'Este cambio afectará a todas las nóminas anteriores del contrato.',
      onOk: () => openContractModal(contract),
    })
  }

  const deleteTerminationCallback = () => {
    updateContracts()
    refreshWorker()
  }

  const handleDeleteTermination = (contractId) => {
    confirm({
      title: 'Eliminar liquidación laboral',
      description:
        '¿Estás seguro de eliminar la liquidación laboral de esta persona? Este cambio no puede deshacerse. Esta persona se incluirá de nuevo en todas las nóminas hasta su fecha final de contrato.',
      type: 'warning',
      onOk: async () => {
        await terminationMutation.mutateAsync(
          {
            mutationMethod: 'DELETE',
            contractId,
          },
          {
            onSuccess: () => {
              showSuccessMessage('Liquidación laboral eliminada')
              deleteTerminationCallback()
            },
          },
          {
            onError: (error) => handleError(error),
          }
        )
      },
      okText: 'Eliminar liquidación',
    })
  }

  const contractModalSubmitCallback = async () => {
    if (location.state) {
      // remove the createLocation flag when the new contract has been created
      const newLocationState = { ...location.state }
      delete newLocationState.createContract

      navigate(location.pathname, {
        state: newLocationState,
      })
    }

    updateContracts()
  }

  const deleteContract = (id) => {
    organizerWorkerMutation.mutate(
      {
        mutationMethod: 'DELETE',
        contractId: id,
      },
      {
        onSuccess: ({ message }) => {
          updateContracts()
          refreshWorker()
          showSuccessMessage(message)
        },
      }
    )
  }

  const handleDeleteContract = (contractId) => {
    confirm({
      type: 'warning',
      title: 'Eliminar contrato',
      description:
        'Eliminar este contrato será permanente y no se podrá deshacer. ¿Estás seguro?',
      okText: 'Eliminar',
      confirmCheckbox: true,
      onOk: () => deleteContract(contractId),
    })
  }

  // checks if exist date gaps between contracts
  useEffect(() => {
    if (worker?.contract_category !== 'contractor') {
      for (let i = 0; i < formattedData?.length; i += 1) {
        const cont = formattedData[i]
        const nextCont = formattedData[i + 1]

        if (nextCont) {
          cont.prevInitialDay = nextCont.initial_day

          const dateLeft = cont.initial_day
          const dateRight = nextCont.end_day

          const diff = differenceInCalendarDays(
            parseISO(dateLeft),
            parseISO(dateRight)
          )

          if (diff > 1) {
            setHasDateGapsBetweenContracts(diff > 1)
            break
          }
        } else {
          setHasDateGapsBetweenContracts(false)
        }
      }
    }
  }, [formattedData, worker])

  return (
    <Page
      documentTitle={`Contratos de ${worker ? worker.fullName : 'trabajador'}`}
      header={
        <Typography
          variant="h2"
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          Contratos de{' '}
          {worker ? (
            <Link
              to={routes.WORKER_SHOW(workerId)}
              sx={{
                fontSize: 'inherit',
              }}
            >
              {worker.fullName}
            </Link>
          ) : (
            'trabajador'
          )}
        </Typography>
      }
      isLoading={contractsQuery.isLoading}
      isLoadingWithModal={terminationMutation.isLoading}
      grid
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        {hasDateGapsBetweenContracts ? (
          <Alert
            severity="info"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
            })}
          >
            Existen días vacíos entre la duración de algunos contratos, estos
            días se contarán como si el trabajador hubiera estado inactivo.
          </Alert>
        ) : null}
        <Table
          columns={getColumnsData(
            workerId,
            handleEditContract,
            handleDeleteTermination,
            handlePayslipTermination,
            handleDeleteContract
          )}
          data={formattedData}
          options={{
            pagination: false,
            search: false,
            version: {
              toolbar: 'v2',
            },
          }}
          actions={[
            {
              id: 'add_contract',
              tooltip: 'Crear nuevo Contrato',
              isFreeAction: true,
              onClick: () => handleCreateContract(),
              disabled: !periodQuery.data,
            },
          ]}
        />
      </Box>
      {contractModalState.open ? (
        <ContractModal
          state={contractModalState}
          handleClose={closeContractModal}
          submitCallback={contractModalSubmitCallback}
        />
      ) : null}
    </Page>
  )
}

export default ContractIndex
