import { Form, Formik } from 'formik'
import queryString from 'query-string'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Link as MuiLink, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import Alert from 'components/UI/MaterialUI/Alert/Alert'
import Link from 'components/UI/MaterialUI/Link'

import useUsersService from 'utils/hooks/settings/usersService'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { LOGIN, PASSWORD_RECOVERY } from 'config/routes'

import MainContainer from '../common/MainContainer'
import { initialValues, validationSchema } from './helpers'

const PasswordReset = () => {
  const navigate = useNavigate()
  const [invalidToken, setInvalidToken] = useState(false)
  const { usersMutation } = useUsersService({
    queryOptions: {
      enabled: false,
    },
  })

  const { reset_password_token: resetPasswordToken, worker } =
    queryString.parse(useLocation().search)

  const isWorker = worker === 'true'

  const { handleError } = useErrorHandler()

  if (!resetPasswordToken) {
    throw new Error('Error al restablecer contraseña')
  }

  const handleSubmit = async (values, form) => {
    usersMutation.mutate(
      {
        mutationMethod: 'PUT',
        user: {
          password: window.btoa(values.password),
          reset_password_token: resetPasswordToken,
        },
      },
      {
        onSuccess: () => {
          navigate(isWorker ? `${LOGIN}?worker=true` : LOGIN, {
            state: { passwordRecoveredSuccessfully: true },
          })
        },
        onError: (error) => {
          handleError(error, form, {
            errorsToNotificate: [{ object: 'reset_password_token' }],
            notistackOptions: {
              preventDuplicate: true,
            },
          })

          if (
            error.errors[0].code === '0101' &&
            error.errors[0].object === 'reset_password_token'
          ) {
            setInvalidToken(true)
          }
        },
      }
    )
  }

  return (
    <MainContainer>
      <Box
        sx={({ spacing }) => ({
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(1),
          marginBottom: spacing(4),
        })}
      >
        <Typography variant="h3">Crear contraseña</Typography>
      </Box>
      {invalidToken ? (
        <Alert
          sx={(theme) => ({
            textAlign: 'left',
            marginBottom: theme.spacing(3),
          })}
        >
          <Typography variant="body2">
            El código para restablecer la contraseña ya expiró o es inválido.{' '}
          </Typography>
          <br />
          <Typography variant="body2">
            Vuelve a realizar el proceso haciendo{' '}
            <Link to={PASSWORD_RECOVERY}>click aquí</Link>.
          </Typography>
          <br />
          <Typography variant="body2">
            Para más detalles puedes ver:{' '}
            <MuiLink
              href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-puedes-recuperar-tu-contraseña"
              target="_blank"
            >
              ¿Cómo recupero mi contraseña?
            </MuiLink>
          </Typography>
        </Alert>
      ) : null}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {() => {
          return (
            <Box sx={{ width: '100%' }}>
              <Form>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginTop: theme.spacing(1),
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'grid',
                      gridTemplateColumns: '1fr',
                      rowGap: theme.spacing(3),
                      marginBottom: theme.spacing(4),
                    })}
                  >
                    <PasswordField
                      name="password"
                      label="Tu contraseña (Mínimo 8 caracteres)"
                      placeholder="Ingresa tu contraseña"
                      autoComplete="password"
                    />
                    <PasswordField
                      name="password_confirmation"
                      label="Repetir contraseña (Mínimo 8 caracteres)"
                      placeholder="Confirma tu contraseña"
                      autoComplete="password"
                    />
                  </Box>
                  <Button type="submit" loading={usersMutation.isLoading}>
                    Cambiar mi contraseña
                  </Button>
                </Box>
              </Form>
            </Box>
          )
        }}
      </Formik>
    </MainContainer>
  )
}

export default PasswordReset
