import * as yup from 'yup'

import FormField from 'components/UI/Formik/FormField/Index'
import Link from 'components/UI/MaterialUI/Link'

import { formatNumberDisplayDate } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

import { WORKER_SHOW } from 'config/routes'

yup.setLocale(yupLocaleES)

export const columns = [
  {
    Header: 'Nombre',
    accessor: 'worker_name',
    Cell: ({ row }) => {
      return (
        <Link to={WORKER_SHOW(row.original.worker_id)} color="black.main">
          {row.original.worker_name}
        </Link>
      )
    },
    customWidth: '250px',
  },
  {
    Header: 'Vlr total empresa',
    accessor: 'company_contribution_value',
    Cell: ({ row }) => formatCurrency(row.original.company_contribution_value),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Vlr pagado empresa',
    accessor: 'company_paid_contribution',
    Cell: ({ row }) => formatCurrency(row.original.company_paid_contribution),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Saldo de pago empresa',
    accessor: 'company_balance',
    Cell: ({ row }) => formatCurrency(row.original.company_balance),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Vlr total empleado',
    accessor: 'worker_contribution_value',
    Cell: ({ row }) => formatCurrency(row.original.worker_contribution_value),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Vlr pagado empleado',
    accessor: 'worker_paid_contribution',
    Cell: ({ row }) => formatCurrency(row.original.worker_paid_contribution),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Saldo de pago empleado',
    accessor: 'worker_balance',
    Cell: ({ row }) => formatCurrency(row.original.worker_balance),
    alignHeader: 'right',
    alignCell: 'right',
  },
  {
    Header: 'Opción de pago',
    accessor: 'selected_option',
    customWidth: 240,
    Cell: ({ row }) => {
      const paymentOptions = []

      if (row.original.payment_options.includes('company'))
        paymentOptions.push({
          value: 'company',
          label: 'Sólo empresa',
        })
      if (row.original.payment_options.includes('company_and_worker'))
        paymentOptions.push({
          value: 'company_and_worker',
          label: 'Empresa y empleado',
        })

      return (
        <FormField
          name={`decree376_payments[${row.index}].paid_in_period`}
          variant="select"
          options={paymentOptions}
          disabled={paymentOptions.length <= 0}
        />
      )
    },
  },
]

export const validationSchema = yup.object({
  decree376_payments: yup.array().of(
    yup.object({
      paid_in_period: yup.string().when('selection', {
        is: true,
        then: yup.string().nullable().required(),
        otherwise: yup.string().nullable(),
      }),
    })
  ),
})

export const MODAL_INFORMATION = {
  title: '¡Liquidación de aportes por decreto 376!',
  description:
    'En Aleluya nos pusimos la 10 para ayudarte a liquidar de forma fácil los aportes a pensión pendientes de los periodos Abril y Mayo del 2020. En este video te enseñamos cómo podrás liquidar estos aportes pendientes sin enredos.',
  videoLink: 'https://www.loom.com/embed/208d4dd7c8644bceaaa9b649016bda50',
}

export const decree376PeriodTotalsConfiguration = {
  total_pension_contribution: {
    title: 'Valor total a pensión',
    backgroundColor: 'success.main',
  },
  total_pension_paid_contribution: {
    title: 'Valor pagado a pensión',
    backgroundColor: 'complementary2.light',
  },
  pension_contribution_balance: {
    title: 'Valor pendiente de pago a pensión',
    backgroundColor: 'complementary1.light',
  },
}

export const getValuesToSend = (oldPayments, newPayments) => {
  const valuesToSend = []

  newPayments.forEach((newPayment) => {
    const oldPayment = oldPayments.find(
      (oldValue) => oldValue.id === newPayment.id
    )
    const hasValueChanged =
      oldPayment.paid_in_period !== newPayment.paid_in_period ||
      (oldPayment.paid_in_period !== null && !newPayment.selection)

    if (hasValueChanged) {
      let action = newPayment.paid_in_period
      if (oldPayment.paid_in_period && !newPayment.selection) action = 'delete'
      valuesToSend.push({
        contract_id: newPayment.contract_id,
        action,
      })
    }
  })

  return { decree376_payments: valuesToSend }
}

export const getTitle = (period) => {
  const baseTitle = 'Aportes decreto 376'

  if (period?.initial_day && period?.end_day) {
    return `${baseTitle} - ${formatNumberDisplayDate(
      period?.initial_day
    )} al ${formatNumberDisplayDate(period?.end_day)}`
  }

  return baseTitle
}
