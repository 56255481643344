import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import LinkButton from 'components/UI/Button/LinkButton'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import { Image } from 'components/UI/Image'
import Modal from 'components/UI/Modal/Modal'

import { partyPopperEmoji } from 'utils/emojis'
import useConfetti from 'utils/hooks/useConfetti'

import { ONBOARDING_INTRO } from 'config/routes'

export function FirstWorkerAddedModal({ state, handleClose }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('laptop'))
  const { throwConfetti } = useConfetti()

  useEffect(() => {
    throwConfetti({ origin: { x: 0.5, y: 0.9 }, particleCount: 150 })
    throwConfetti({ origin: { x: 0.3, y: 0.4 }, particleCount: 150 })
    throwConfetti({ origin: { x: 0.7, y: 0.4 }, particleCount: 150 })
  }, [throwConfetti])

  return (
    <Modal
      open={state.open}
      onCancel={handleClose}
      hideFooter
      paperSx={{
        maxWidth: '49rem',
      }}
      contentWrapperSx={{
        overflow: 'hidden',
      }}
    >
      <ButtonBase
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          cursor: 'pointer',
          color: theme.palette.black.main,
          right: theme.spacing(-1),
          top: theme.spacing(0),
        })}
      >
        <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
      </ButtonBase>
      <Box
        sx={(theme) => ({
          zIndex: 3,
          display: 'grid',
          gridTemplateColumns: {
            tablet: '1fr',
            laptop: '15rem 1fr',
          },
          gap: theme.spacing(5),
        })}
      >
        {!isMobile ? (
          <>
            <Box
              sx={(theme) => ({
                width: '35rem',
                height: '35rem',
                borderRadius: '100%',
                position: 'absolute',
                left: '-19rem',
                top: '-8.75rem',
                backgroundColor: theme.palette.complementary2.main,
              })}
            />
            <Box sx={(theme) => ({ zIndex: 1, paddingLeft: theme.spacing(2) })}>
              <Image
                src="8ef0329d-aefe-4ceb-7aa1-d953b7788b00"
                width={150}
                height={289}
              />
            </Box>
          </>
        ) : null}
        <Box>
          <Typography
            variant="h2"
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
              marginRight: theme.spacing(3),
              textWrap: 'balance',
            })}
          >
            ¡Creaste tu primera persona! <Emoji code={partyPopperEmoji} />
          </Typography>
          <Typography
            variant="body1"
            color="black.light"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
              gap: theme.spacing(2),
            })}
          >
            Tu primera persona ya está en Aleluya. Ahora puedes seguir
            configurando tu cuenta y prepararando todo para la primera
            liquidación de nómina.
          </Typography>
          <LinkButton
            variant="contained"
            component={Link}
            to={ONBOARDING_INTRO()}
            onClick={() => handleClose()}
            sx={(theme) => ({
              padding: theme.spacing(0, 2),
              width: '100%',
              [theme.breakpoints.up('tablet')]: {
                width: 'auto',
              },
            })}
          >
            Continuar
          </LinkButton>
        </Box>
      </Box>
    </Modal>
  )
}
