import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { usePeriod } from 'components/Period/Payroll/helpers'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import LoadingModal from 'components/UI/Loading/LoadingModal'
import Modal from 'components/UI/Modal/Modal'

import useErrorHandler from 'utils/hooks/useErrorHandler'

import fileService from 'services/payroll/fileService'

import isotipoAEL from 'assets/images/logos/aportes_en_linea/isotipo_ael.png'
import isotipoArus from 'assets/images/logos/arus/isotipo_arus.png'

import * as routes from 'config/routes'

import payrollMessages from 'messages/payroll'

import StepTwo from './Content/StepTwo'
import ContentSummary from './Content/Summary/Summary'
import Header from './Header'

const providers = {
  aportes_en_linea: {
    name: 'Aportes en línea',
    logo: isotipoAEL,
    logoWidth: 39,
    logoHeight: 38,
  },
  arus: {
    name: 'SuAporte',
    logo: isotipoArus,
    logoWidth: 41,
    logoHeight: 32,
  },
}

const PeriodSocialSecurityPaymentModal = ({ state, handleClose }) => {
  const { period, isIntegrationActive, isOnlyWorkers, formType, provider } =
    state

  const [step, setStep] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [stepTwoCompleted, setStepTwoCompleted] = useState(false)
  const [data, setData] = useState({
    workers: [],
    totals: {},
  })
  const { payPreviousPayroll } = usePeriod() || {}
  const [showWorkers, setShowWorkers] = useState(isOnlyWorkers)

  const confirm = useConfirm()
  const navigate = useNavigate()
  const { handleError } = useErrorHandler()

  const handleOk = () => {
    if (step > 0) {
      navigate(
        payPreviousPayroll
          ? routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_SECURITY_SHOW(period.id)
          : routes.PERIOD_SOCIAL_SECURITY_SHOW(period.id),
        {
          state: { formType, provider },
        }
      )
    } else if (isIntegrationActive) {
      if (isOnlyWorkers) handleClose()
      else {
        if (showWorkers) {
          setShowWorkers(false)
        }
        setStep(step + 1)
      }
    }
  }

  const handlePrevious = () => {
    if (step > 0) {
      setStep(step - 1)
      setStepTwoCompleted(false)
    } else {
      handleClose()
    }
  }

  useEffect(() => {
    const fetchSSPaymentSummary = async () => {
      setIsLoading(true)

      try {
        const { workers, totals } =
          await fileService.getSocialSecurityPaymentSummary(period.id, formType)

        setData({
          workers: [
            ...workers,
            {
              name: 'Totales',
              ...totals,
            },
          ],
          totals,
        })
        setIsLoading(false)
      } catch (error) {
        if (error.errors && error.errors[0]) {
          const { code, object } = error.errors[0]

          if (code === '0607' && object === 'file') {
            confirm({
              type: 'info',
              okText:
                payrollMessages.SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_OK_TEXT,
              title:
                payrollMessages.SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_TITLE,
              description:
                payrollMessages.SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_DESCRIPTION,
              onOk: () =>
                navigate(routes.WORKER_INDEX('?tab=active'), {
                  state: { filter: 'not_finished' },
                }),
            })
          } else {
            handleError(error)
          }
        } else {
          handleError(error)
        }

        handleClose()
      }
    }

    fetchSSPaymentSummary()
  }, [confirm, formType, handleClose, navigate, handleError, period.id])

  if (isLoading) return <LoadingModal />

  const { workers, totals } = data
  const integrationProvider = providers[provider]

  return (
    <Modal
      open
      header={
        <Header
          showWorkers={showWorkers}
          isOnlyWorkers={isOnlyWorkers}
          integrationProvider={integrationProvider}
          setShowWorkers={setShowWorkers}
        />
      }
      footer={isOnlyWorkers ? null : undefined}
      onOk={handleOk}
      okText="Continuar"
      onCloseModal={handleClose}
      onCancel={handlePrevious}
      cancelText={step === 0 ? 'Cancelar' : 'Regresar'}
      isLoading={step === 1 ? !stepTwoCompleted : false}
      dialogProps={
        showWorkers ? { maxWidth: false } : { maxWidth: 'xs', fullWidth: true }
      }
    >
      {step === 0 ? (
        <ContentSummary
          workers={workers}
          totals={totals}
          setStep={setStep}
          isOnlyWorkers={isOnlyWorkers}
          showWorkers={showWorkers}
          setShowWorkers={setShowWorkers}
          providerName={integrationProvider?.name || ''}
          provider={provider}
        />
      ) : null}
      {step === 1 ? (
        <StepTwo
          periodId={period.id}
          formType={formType}
          setStep={setStep}
          completed={stepTwoCompleted}
          setCompleted={setStepTwoCompleted}
          handleClose={handleClose}
          providerName={integrationProvider?.name || ''}
          provider={provider}
        />
      ) : null}
    </Modal>
  )
}

export default PeriodSocialSecurityPaymentModal
